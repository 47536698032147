var moment = require('moment');

module.exports = {
    controller: newsItemsController,
    templateUrl: 'news/components/news-overview-page.html'
};

newsItemsController.$inject = [
    '$analytics', 
    '$location',
    '$window',
    'common',
    'newsDataService'
];

function newsItemsController(
    $analytics, 
    $location,
    $window,
    common,
    newsDataService
) {
    var vm = {
        $onInit: attached,
        dateOptions: {},
        format: 'dd-MM-yyyy',
        open1: open1,
        open2: open2,
        popup1: {
            opened: false,
        },
        popup2: {
            opened: false,
        },
        query: {
            StartDate: null,
            EndDate: null
        },
        copyNewsItemsToClipboard: copyNewsItemsToClipboard,
        searchNews: searchNews
    };
    return vm;

    function attached() {
        $analytics.pageTrack('/news-overview');
        vm.query.StartDate = moment().subtract(7, 'day').toDate();
        vm.query.EndDate = moment().toDate();
        vm.baseUrl = new $window.URL($location.absUrl()).origin;
        searchNews();
    }
    
    function searchNews() {
        vm.isLoading = true;
        return newsDataService.getNewsOverview(vm.query.StartDate, vm.query.EndDate)
            .then(function (newsItems) {
                vm.newsItems = _.map(newsItems, function (newsItem) {
                    if (newsItem.Category === 'EU Case Law') {
                        newsItem.Title = newsItem.Title.replace(/#(?!\!)/g, '</p><p>');
                        newsItem.Url = vm.baseUrl + '/#!/links/case-law/eu/celex/' + newsItem.CELEX;
                    }
                    else {
                        newsItem.Url = vm.baseUrl + '/#!/links/eur-lex/celex/' + newsItem.CELEX;
                    }
                    return newsItem;
                });
                vm.isLoading = false;
            })
            .then(function() {
                vm.isLoading = false;
            });
    }

    function copyNewsItemsToClipboard() {
        var text = document.getElementById('news-overview-table').outerHTML;
        text = text.replace(/<\!--(?:.|\s)+?-->/g, '');
        text = text.replace(/class="[^"]*"/g, '');
        text = text.replace(/ng-\w+="[^"]*"/g, '');
        common.interaction.copyTextToClipboard(text);
    }

    function open1() {
        vm.popup1.opened = true;
    }

    function open2() {
        vm.popup2.opened = true;
    }
}