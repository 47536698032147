module.exports = [
    '$analytics', 
    '$uibModal', 
    annotationsModal
];

function annotationsModal(
    $analytics, 
    $uibModal
) {

    var modal = {
        chapter: null,
        section: null,
        show: showModal
    };

    annotationsModalController.$inject = [
        '$q', 
        '$uibModalInstance', 
        'annotationsDataService', 
        'section', 
        'chapter',
    ];

    function annotationsModalController(
        $q, 
        $uibModalInstance, 
        annotationsDataService, 
        section, 
        chapter
    ) {
        
        var vm = {
            $onInit: attached,
            cancel: cancel,
            chapter: chapter,
            section: section
        };

        function attached() {
            vm.isLoading = true;
            var promises = [];
            if (chapter) {
                promises.push(annotationsDataService
                    .getChapterNote(chapter)
                    .then(function(note) {
                        vm.chapterNote = note;
                    }));
            }
            if (section) {
                promises.push(annotationsDataService
                    .getSectionNote(section)
                    .then(function(note) {
                        vm.sectionNote = note;
                    }));
            }
            return $q.all(promises)
                .then(function() {
                    vm.isLoading = false;
                });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        return vm;
    }

    function showModal(section, chapter) {
        $analytics.eventTrack('open-modal-legal-notes', { category: 'legal-notes' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: annotationsModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                chapter: function() {
                    return chapter;
                },
                section: function() {
                    return section;
                }
            },
            size: 'lg',
            templateUrl: 'texts/modals/annotations.html',
            windowClass: 'modal-wide'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
