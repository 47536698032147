﻿module.exports = ['$filter', '$translate', 'common', goodDisplayService];

function goodDisplayService($filter, $translate, common) {
    var service = {
        collapseMeasures: collapseMeasures,
        copyDescription: copyDescription,
        countryCodeMatcher: countryCodeMatcher,
        countryNameMatcher: countryNameMatcher,
        expandMeasures: expandMeasures,
        getFinancialConditionDescription: getFinancialConditionDescription,
        hasAdditionalCode: hasAdditionalCode,
        hasAdditionalCodeDescription: hasAdditionalCodeDescription,
        translations: []
    };

    function attached() {
        $translate([
            'goods.financialConditions.E.title',
            'goods.financialConditions.E.headers.col1',
            'goods.financialConditions.E.headers.col2',
            'goods.financialConditions.F.title',
            'goods.financialConditions.F.headers.col1',
            'goods.financialConditions.F.headers.col2',
            'goods.financialConditions.L.title',
            'goods.financialConditions.L.headers.col1',
            'goods.financialConditions.L.headers.col2',
            'goods.financialConditions.M.title',
            'goods.financialConditions.M.headers.col1',
            'goods.financialConditions.M.headers.col2',
            'goods.financialConditions.V.title',
            'goods.financialConditions.V.headers.col1',
            'goods.financialConditions.V.headers.col2',
        ]).then(function (result) {
            service.translations = result;
        });
    }

    function copyDescription(lines, movement) {
        console.log('copy descroiption', lines, movement);
        var description = _.chain(lines)
            .drop() // drop chapter
            .map(function(line, index) {
                var indents = _.repeat('- ', line.indent);
                var code = line.code;
                if (index === lines.length - 2) { // minus 2 because we dropped one
                    if (movement === 'export') {
                        code = $filter('formatGnCode')(code);
                    }
                    else {
                        code = $filter('formatTaricCode')(code);
                    }
                }
                else {
                    code = $filter('stripTaricCode')(code);
                    code = $filter('formatTaricCode')(code);
                }
                return code + '\t' + indents + line.description;
            })
            .join("\r\n")
            .value();
            common.interaction.copyTextToClipboard(description);
    }

    function collapseMeasures(measures) {
        _.forEach(measures, function (measure) {
            measure.expanded = false;
            if (measure.measures && measure.measures.length > 0) {
                _.forEach(measure.measures, function (submeasure) {
                    submeasure.expanded = false;
                });
            }
        });
    }

    function countryCodeMatcher(strs) {
        return function findMatches(q, cb) {
            var matches, substrRegex;

            // an array that will be populated with substring matches
            matches = [];

            if (q.length > 1) {
                q = q.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
                // regex used to determine if a string contains the substring `q`
                substrRegex = new RegExp(q, 'i');

                // iterate through the pool of strings and for any string that
                // contains the substring `q`, add it to the `matches` array
                _.each(strs, function (str, i) {
                    if (substrRegex.test(str.code)) {
                        // the typeahead jQuery plugin expects suggestions to a
                        // JavaScript object, refer to typeahead docs for more info
                        matches.push({ value: str.name + ' (' + str.code + ')' });
                    }
                });
            }
            cb(matches);
        };
    }

    function countryNameMatcher(strs) {
        return function findMatches(q, cb) {
            var matches, substrRegex;

            // an array that will be populated with substring matches
            matches = [];

            q = q.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
            // regex used to determine if a string contains the substring `q`
            substrRegex = new RegExp(q, 'i');

            // iterate through the pool of strings and for any string that
            // contains the substring `q`, add it to the `matches` array
            // second check country names
            _.each(strs, function (str, i) {
                if (substrRegex.test(str.name)) {
                    // the typeahead jQuery plugin expects suggestions to a
                    // JavaScript object, refer to typeahead docs for more info
                    matches.push({ value: str.name + ' (' + str.code + ')' });
                }
            });

            cb(matches);
        };
    }

    function expandMeasures(measures) {
        _.forEach(measures, function (measure) {
            measure.expanded = true;
            if (measure.measures && measure.measures.length > 0) {
                _.forEach(measure.measures, function (submeasure) {
                    submeasure.expanded = true;
                });
            }
        });
    }

    function getFinancialConditionDescription(code) {
        var output = {
            title: service.translations['goods.financialConditions.' + code + '.title'],
            headers: [service.translations[
                'goods.financialConditions.' + code + '.headers.col1'], 
                service.translations['goods.financialConditions.' + code + '.headers.col2'
            ]]
        };

        return output;
    }

    function hasAdditionalCode(measure, additionalCodeFilter) {
        var filterNormalizedToAdditionalCode;
        if (additionalCodeFilter === null || additionalCodeFilter === undefined || additionalCodeFilter === '')
            return true;

        filterNormalizedToAdditionalCode = additionalCodeFilter.toLowerCase().replace("-", "").replace(/\s/g, "");

        for (var i = 0; i < measure.additional_codes.length; i++) {
            if (measure.additional_codes[i] !== null) {
                if (measure.additional_codes[i].description.toLowerCase().indexOf(additionalCodeFilter.toLowerCase()) > -1)
                    return true;
                if (measure.additional_codes[i].type.toLowerCase().indexOf(filterNormalizedToAdditionalCode.substr(0, 1)) > -1) { // search query starts with additional-code-type
                    if (measure.additional_codes[i].code.indexOf(filterNormalizedToAdditionalCode.substr(1)) == 0)
                        return true;
                }
                else if (measure.additional_codes[i].code.indexOf(filterNormalizedToAdditionalCode) == 0) { // type not entered but code matches
                    return true;
                }
            }
        }
        return false;
    }

    function hasAdditionalCodeDescription(additionalCode, additionalCodeFilter) {
        var filterNormalizedToAdditionalCode;

        if (additionalCodeFilter === null || additionalCodeFilter === undefined || additionalCodeFilter === '')
            return true;

        filterNormalizedToAdditionalCode = additionalCodeFilter.toLowerCase().replace("-", "").replace(/\s/g, "");

        if (additionalCode.description !== null && additionalCode.description !== undefined && additionalCode.description.toLowerCase().indexOf(additionalCodeFilter.toLowerCase()) > -1)
            return true;

        if (additionalCode.type.toLowerCase().indexOf(filterNormalizedToAdditionalCode.substr(0, 1)) > -1) { // search query starts with additional-code-type
            if (additionalCode.code.indexOf(filterNormalizedToAdditionalCode.substr(1)) == 0)
                return true;
        }
        else if (additionalCode.code.indexOf(filterNormalizedToAdditionalCode) == 0) { // type not entered but code matches
            return true;
        }

        return false;
    }

    attached();

    return service;
}