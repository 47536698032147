module.exports = [
    '$analytics', 
    '$uibModal', 
    transactionValueCalculatorModal
];

function transactionValueCalculatorModal(
    $analytics, 
    $uibModal
) {

    var modal = {
        show: showModal
    };

    transactionValueCalculatorModalController.$inject = [
        '$window', 
        'transactionValueDataService', 
        '$uibModalInstance',
    ];

    function transactionValueCalculatorModalController(
        $window, 
        transactionValueDataService, 
        $uibModalInstance
    ) {

        var vm = {
            $onInit: attached,
            cancel: cancel,
            ok: ok,
            print: print,
        };

        return vm;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function ok() {
            $uibModalInstance.close(transactionValueDataService.data.transactionValue.result);
        }

        function print() {
            $window.print();
        }

        function attached() {
            vm.data = transactionValueDataService.data;
        }

    }

    function showModal() {
        $analytics.eventTrack('open-modal-transaction-value-calculator', { category: 'transaction-value-calculator' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            controller: transactionValueCalculatorModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            size: 'lg',
            templateUrl: 'customs-value/modals/transaction-value-calculator.html',
            windowClass: 'modal-transaction-value-calculator modal-wide'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
