﻿angular.module('taric.footnotes', [])
    .config(['$routeProvider', routeConfigurator])
    .component('footnoteOverviewPage', require('./components/footnote-overview-page'))
    .component('footnoteButtons', require('./components/footnote-buttons'))
    .factory('footnoteDataService', require('./services/footnote-data'))
    .factory('footnoteModal', require('./modals/footnote'))
    .factory('footnoteModalsService', require('./modals/modals'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/footnotes', {
            template: '<footnote-overview-page></footnote-overview-page>'
        })
        ;
}
