module.exports = {
    controller: referenceCountryPickerController,
    templateUrl: 'widgets/components/reference-country-picker.html'
};

referenceCountryPickerController.$inject = [
    '$window', 
    'countryDataService',
    'identity',
    'preferenceService', 
    'applicationStateService'
];

function referenceCountryPickerController(
    $window,
    countryDataService,
    identity, 
    preferences, 
    state
) {
    var vm = {
        $onInit: attached,
        searchFilter: state.filter,
        countryNames: countryDataService.countries,
        referenceCountries: [],
        setReferenceCountry: setReferenceCountry
    };
    
    return vm;

    function attached() {
        vm.referenceCountries = identity.getAllowedTradeTariffs();
        vm.disallowedReferenceCountries = identity.getDisallowedTradeTariffs();
    }
    
    function setReferenceCountry(referenceCountry) {
        state.filter.referenceCountry = referenceCountry;
        preferences.setReferenceCountry(referenceCountry).then(function () {
            $window.location.reload();
        });
    }
}