﻿module.exports = ['$analytics', '$uibModal', ukOrderQuotaModal];

function ukOrderQuotaModal($analytics, $uibModal) {
    var modal = {
        order: null,
        show: showModal
    };

    ukOrderNumberModalController.$inject = ['$uibModalInstance', 'ukQuotaInformation'];

    function ukOrderNumberModalController($uibModalInstance, ukQuotaInformation) {

        var vm = {
            cancel: cancel,
            ukQuotaInformation: ukQuotaInformation,
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        return vm;
    }

    function showModal(ukQuotaInformation) {
        $analytics.eventTrack('open-modal-quota-details', { category: 'quota' });
        return $uibModal.open({
            backdrop: true,
            controller: ukOrderNumberModalController,
            controllerAs: 'vm',
            keyboard: true,
            modalFade: true,
            resolve: {
                ukQuotaInformation: function () {
                    return ukQuotaInformation;
                }
            },
            size: 'lg',
            templateUrl: 'quota/modals/uk-order-quota.html',
            windowClass: 'modal-order-number'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
