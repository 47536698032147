﻿module.exports = ['common', 'countryDataService', 'applicationStateService', searchService];

function searchService(common, countryDataService, state) {

    var service = {
        createQueryFromLastQuery: createQueryFromLastQuery,
        parseQuery: parseQuery,
        serializeQuery: serializeQuery,
        parseLocationSearchObject: parseLocationSearchObject
    };

    function parseLocationSearchObject(locationSearchObject) {
        var queryObject = _.clone(locationSearchObject);
        queryObject.includeBtis = (queryObject.includeBtis === 'true');
        queryObject.includeClassificationRegulations = (queryObject.includeClassificationRegulations === 'true');
        queryObject.includeCaseLaw = (queryObject.includeCaseLaw === 'true');      
        return queryObject; 
    }

    function createQueryFromLastQuery(lastQuery) {
        return {
            commodityCode: lastQuery.CurrentCommodityCode,
            query: lastQuery.QueryString,
            includeBtis: lastQuery.IncludeBtis,
            includeCaseLaw: lastQuery.IncludeDutchCaseLaw || lastQuery.IncludeEuCaseLaw,
            includeClassificationRegulations: lastQuery.IncludeClassificationRegulations
        };
    }

    function serializeQuery(queryObject) {
        return _.chain(queryObject)
            .toPairs()
            .filter(function(pair) {
                return pair[0] !== 'rawQuery' && !!pair[1];
            })
            .map(function(pair) {
                return encodeURIComponent(pair[0]) + '=' + encodeURIComponent(pair[1]); 
            })
            .join('&')
            .value();
    }

    function parseQuery(rawQueryString) {
        var queryObject = {};
        var words = [];
        var importMatches = ['import', 'inport', 'imp', 'invoer'];
        var exportMatches = ['export', 'exp', 'uitvoer'];

        common.auth.check();

        queryObject.rawQuery = rawQueryString;

        var numberPattern = /^\s*(?:[0-9]{2}[\s.]*){1,5}(?:[\s.]?[0-9])?$/;

        if (rawQueryString) {
            words = rawQueryString.toLowerCase().split(' ');
        }

        for (let i = 0; i < importMatches.length; i++) {
            var foundIndex = words.indexOf(importMatches[i]);
            if (foundIndex > -1) {
                queryObject.movement = 'import';
                words.splice(foundIndex, 1);
            }
        }
        for (let i = 0; i < exportMatches.length; i++) {
            let foundIndex = words.indexOf(exportMatches[i]);
            if (foundIndex > -1) {
                queryObject.movement = 'export';
                words.splice(foundIndex, 1);
            }
        }

        // check to see if there are any country codes
        for (let i = 0; i < words.length; i++) {
            var foundCountryCodes = _.filter(countryDataService.ergaOmnesCountries, function (item) { 
                return item.code.toUpperCase() === words[i].toUpperCase();
            });
            if (foundCountryCodes.length > 0) {
                queryObject.country = foundCountryCodes[0].code;
                words.splice(i, 1);
                break;
            }
        }
        // check to see if there are any country names
        for (let i = 0; i < words.length; i++) {
            var foundCountryNames = _.filter(countryDataService.ergaOmnesCountries, function (item) { 
                return item.name.toLowerCase() === words[i].toLowerCase();
            });
            if (foundCountryNames.length > 0) {
                queryObject.country = foundCountryNames[0].code;
                words.splice(i, 1);
                break;
            }
        }

        var foundCode = '';
        for (let i = 0; i < words.length; i++) {
            if (numberPattern.test(words[i])) {
                if (isAllowedCode(words[i])) {
                    foundCode += words[i].replaceAll('.', '');
                    words.splice(i, 1);
                    i--;
                }
            }
        }
        if (foundCode === '') {
            queryObject.commodityCode = null;
        }
        else {
            if (foundCode.length > 10) {
                foundCode = foundCode.substring(0, 10);
            }
            queryObject.commodityCode = common.formatting.removeTrailingZeros(foundCode);
        }

        queryObject.query = words.join(' ');
        return queryObject;
    }

    function isAllowedCode(word) {
        if (word.indexOf('00') === 0 || word.indexOf('77') === 0) {
            return false;
        }
        if (word.indexOf('99') === 0 && state.filter.referenceCountry === 'GB') {
            return false;
        }
        return true;
    }

    return service;
}