module.exports = {
    bindings: {
        goodscode: '<'
    },
    controller: regulationsNewsController,
    templateUrl: 'regulations/components/regulations-news.html'
};

regulationsNewsController.$inject = [
    'notificationsService', 
    'regulationsDataService', 
    'measureTypesDataService',
];

function regulationsNewsController(
    notifications, 
    regulationsData, 
    measureTypesData
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        isLoadingRegulations: true,
        isLoadingGroups: false,
        isLoadingTypes: false,
        filter: {
            goodsCode: null
        },
        measureTypes: {},
        regulations: [],
        regulationTypes: [],
        regulationTypeDescriptions: {},
        regulationGroups: [],
        regulationGroupDescriptions: {},
        loadNews: loadNews
    };

    function attached() {
        vm.filter.goodsCode = vm.goodscode;
        load();
        notifications.subscribe('filter_date_changed', dateChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    function dateChanged() {
        load();
    }

    function load() {
        return loadMeasureTypes()
            .then(function() {
                return loadNews(1);
            });
    }

    function loadMeasureTypes() {
        return measureTypesData
            .getMeasureTypesDictionary()
            .then(function(measureTypes) {
                vm.measureTypes = measureTypes;
            });
    }

    function loadNews(pageNumber) {
        if (!pageNumber) {
            pageNumber = 1;
        }
        vm.isLoadingRegulations = true;
        vm.regulations = [];
        return regulationsData.getRegulationNews(pageNumber - 1, vm.filter)
            .then(function (data) {
                vm.regulations = data;
                vm.isLoadingRegulations = false;
            });
    }

    function loadGroups() {
        vm.isLoadingGroups = true;
        vm.regulationGroups = [];

        return regulationsData.getRegulationGroups()
            .then(function (data) {
                vm.regulationGroups = data;
                vm.regulationGroupDescriptions = _.chain(data).keyBy('id').mapValues('description').value();
                vm.isLoadingGroups = false;
            });
    }

    function loadTypes() {
        vm.isLoadingTypes = true;
        vm.regulationTypes = [];

        return regulationsData.getRegulationTypes()
            .then(function (data) {
                vm.regulationTypes = data;
                vm.regulationTypeDescriptions = _.chain(data).keyBy('id').mapValues('description').value();
                vm.isLoadingTypes = false;
            });
    }


    return vm;

}