module.exports  = [
    'dataContext', 
    'common', 
    'countryDataService',
    'language', 
    'applicationStateService', 
    'notificationsService', 
    btiDataService
];

function btiDataService(
    dataContext, 
    common, 
    countryDataService,
    language, 
    state, 
    notifications
) {

    var activeBtiReference = null;
    var issuingCountryCodes = [
		'AT',
		'BE',
		'BG',
		'CY',
		'CZ',
		'DE',
		'DK',
		'EE',
		'ES',
		'FI',
		'FR',
		'GB',
		'GR',
		'HR',
		'HU',
		'IE',
		'IT',
		'LT',
		'LU',
		'LV',
		'MT',
		'NL',
		'PL',
		'PT',
		'RO',
		'SE',
		'SI',
		'SK',
		'XI'
    ];

    var service = {
        response: {},
        data: {
            activeBti: null,
            isLoading: false,
        },
        display: 'list',
        filter: {
            country: null,
            goodsCode: null,
            issuedSince: null,
            reference: null,
            searchQuery: null,
            pageNumber: 1
        },
        issuingCountries: _.map(issuingCountryCodes, function (countryCode) {
            var countryName = countryDataService.countries[countryCode];
            return { code: countryCode, name: countryName };
        }),
        orderBy: 'CommodityCode-asc',
        loadNewBtis: loadNewBtis,
        loadMoreBtis: loadMoreBtis,
        changeActiveBti: changeActiveBti,
        clearActiveBti: clearActiveBti,
        countBtisByHeading: countBtisByHeading,
        countBtiSearchResults: countBtiSearchResults,
        resetFilter: resetFilter
    };
    return service;

    function resetFilter() {
        service.filter.country = null;
        service.filter.goodsCode = null;
        service.filter.issuedSince = null;
        service.filter.reference = null;
        service.filter.searchQuery = null;
        service.filter.goodsDescriptionQuery = null;
        service.filter.pageNumber = 1;
        service.display = 'list';
    }

    function loadNewBtis() {
        service.data.activeBti = null;
        service.filter.pageNumber = 1;
        return loadBtis(service.filter);
    }

    function loadMoreBtis() {
        return loadBtis(service.filter);
    }

    function loadBtis(filter) {

        var sort = service.orderBy.split('-')[0];
        var order = service.orderBy.split('-')[1];

        if (sort === 'KeywordRank' && !filter.searchQuery) {
            if (filter.goodsDescriptionQuery) {
                sort = 'GoodsDescriptionRank';
                service.orderBy = 'GoodsDescriptionRank-desc';
            }
            else {
                sort = 'CommodityCode';
                order = 'asc';
                service.orderBy = 'CommodityCode-asc'
            }
        }
        if (sort === 'GoodsDescriptionRank' && !filter.goodsDescriptionQuery) {
            if (filter.searchQuery) {
                sort = 'KeywordRank';
                service.orderBy = 'KeywordRank-desc';
            }
            else {
                sort = 'CommodityCode';
                order = 'asc';
                service.orderBy = 'CommodityCode-asc'
            }
        }

        var url = common.config.apiHosts.generic + '/bti/search' +
            '?goodscode=' + (filter.goodsCode || '') +
            '&pindex=' + (filter.pageNumber - 1) +
            '&psize=20' +
            '&lang=' + language.get() +
            '&date=' + state.filter.date +
            '&sort=' + sort +
            '&order=' + order;
        if (filter.searchQuery)
            url += '&keyword-query=' + filter.searchQuery;
        if (filter.goodsDescriptionQuery)
            url += '&goods-description-query=' + filter.goodsDescriptionQuery;
        if (filter.reference)
            url += '&reference=' + makeSafeBtiReference(filter.reference);
        if (filter.country)
            url += '&country=' + filter.country;
        if (service.display === 'images') {
            url += '&imagesearch=1';
        }
        if (filter.issuedSince) {
            url += '&issuedsince=' + filter.issuedSince;
        }

        service.data.isLoading = true;
        return dataContext.get(url).then(function (paginatedBtis) {
            service.data.isLoading = false;
            service.response = paginatedBtis;
            if (service.response.Results.length > 0) {
                if (!activeBtiReference) {
                    changeActiveBti(service.response.Results[0].BtiReference);
                }
            }
        });
    }

    function clearActiveBti() {
        service.data.activeBti = null;
    }

    function changeActiveBti(btiReference) {
        var url = common.config.apiHosts.generic + '/bti/' + makeSafeBtiReference(btiReference.trim()) + '/';
        service.data.activeBti = null;
        return dataContext.get(url).then(function (activeBti) {
            service.data.activeBti = activeBti;
            notifications.publish('change_active_bti', activeBti);
            _.forEach(service.response.Results, function (bti) {
                bti.active = false;
                if (bti.BtiReference === btiReference) {
                    bti.active = true;
                }
            });
        });
    }

    function makeSafeBtiReference(btiReference) {
        return btiReference.replace(/\//g, "_");
    }

    function countBtisByHeading(chapterCode) {
        var url = common.config.apiHosts.generic + '/bti/countbyheading/' + chapterCode;
        return dataContext.get(url);
    }

    function countBtiSearchResults(q) {
        var url = common.config.apiHosts.generic + '/bti/count' +
            '?lang=' + common.language.get() +
            '&goodscode=' + q.code +
            '&keyword-query=' + q.query;
        return dataContext.get(url);
    }
}