angular.module('taric.bti', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('btiButton', require('./components/bti-button'))
    .component('btiDetails', require('./components/bti-details'))
    .component('btiForm', require('./components/bti-form'))
    .component('btiImages', require('./components/bti-images'))
    .component('btiList', require('./components/bti-list'))
    .component('btiSearchPage', require('./components/bti-search-page'))
    // Modals
    .factory('btiModal', require('./modals/bti'))
    // Services
    .factory('btiDataService', require('./services/bti-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/bti/search', {
            reloadOnSearch: false,
            showSearchSubNav: true, 
            template: '<bti-search-page></bti-search-page>',
        })
        .when('/bti/home', {
            reloadOnSearch: false,
            showSearchSubNav: false, 
            template: '<bti-search-page></bti-search-page>',
        })
        ;
}
