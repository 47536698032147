module.exports = {
    bindings: {
        commodityCode: '<',
        foundTexts: '<',
        searchQuery: '<'
    },
    controller: foundTextsController,
    templateUrl: 'search/components/found-texts.html'
};

foundTextsController.$inject = [
    'btiModal', 
    'caseLawModal', 
    'classificationRegulationsModal'
];

function foundTextsController(
    btiModal, 
    caseLawModal, 
    classificationRegulationsModal
) {
    var vm = {
        showBti: showBti,
        showCaseLaw: showCaseLaw,
        showClassificationRegulations: showClassificationRegulations,
    };
    return vm;

    function showBti(code, $event) {
        btiModal.show(code, vm.searchQuery);

        $event.preventDefault();
        $event.stopPropagation();
    }

    function showClassificationRegulations(code, $event) {
        classificationRegulationsModal.show(code, vm.searchQuery);

        $event.preventDefault();
        $event.stopPropagation();
    }

    function showCaseLaw(code, $event) {
        caseLawModal.show(code, vm.searchQuery);

        $event.preventDefault();
        $event.stopPropagation();
    }
}