module.exports = {
    bindings: {
        tour: '<'
    },
    controller: documentationButtonController,
    templateUrl: 'documentation/components/documentation-button.html'
};

documentationButtonController.$inject = ['$analytics', 'documentationService'];

function documentationButtonController($analytics, documentationService) {
    var vm = {
        startTour: startTour
    }

    return vm;
    
    function startTour() {
        $analytics.eventTrack('start-tour', { category: 'documentation'});
        var currentTour = documentationService.getCurrentTour();
        // 2021-04-26 The tour of the measures page does not work properly with the if statement
        // because it loses the connection with the dom elements. However, there
        // was some other tour that did not start at the first modal without it, so I'll leave
        // this code here just in case. 
        // if (currentTour.id !== vm.tour.id) {
        //     documentationService.initialize(vm.tour.id);
        // }
        documentationService.initialize(vm.tour.id);
        documentationService.startTour();
    }
}