module.exports = {
    buttions: {
        OpenSummary: 'Open samenvatting',
    },
    CaseLaw: 'Rechtspraak',
    CaseLawFirstLetter: 'R',
    DutchCaseLaw: 'Nederlandse rechtspraak',
    DutchCaseLawJudgment: 'Uitspraak / Conclusie',
    DutchCaseLawSummary: 'Inhoudsindicatie',
    EuropeanCaseLaw: 'Europese rechtspraak',
    EuropeanCaseLawSummary: 'Samenvatting',
    CaseNumber: 'Zaaknummer',
    ECLI: 'ECLI',
    EnterCELEXNumber: 'Voer een CELEX-nummer in',
    EnterECLINumber: 'Voer een ECLI-nummer in',
    EnterCaseNumber: 'Voer een zaaknummer in',
    Subject: 'Onderwerp',
    subjects: {
        AllSubjects: 'Alle onderwerpen',
        dynamic: {
            Excise: 'Accijns',
            CustomsValue: 'Douanewaarde',
            Dumping: 'Dumping',
            ExportRefunds: 'Restituties bij uitvoer',
            ImportOrExportProcedures: 'In- en uitvoerprocedures',
            Classification: 'Indeling',
            ImportExportAdvanceFixingCertificates: 'Invoer-, uitvoer- en prefixatiecertificaten',
            Origin: 'Oorsprong',
        }
    },
    MoreCaseLaw: 'Meer rechtspraak',
    ProcedureTypes: 'Bijzondere kenmerken',
    References: 'Wetsverwijzingen',
    FormalRelations: 'Formele relaties',
    JudicialAuthority: 'Instantie',
    DateOfJudgment: 'Datum uitspraak',
    DateOfPublication: 'Datum publicatie',
    AreasOfLaw: 'Rechtsgebieden',
    OpenJudgment: 'Open volledige uitspraak',
    PublicationLocations: 'Vindplaatsen',
    NoDutchJudgments: 'Geen Nederlandse uitspraken',
    NoEuropeanJudgments: 'Geen Europese uitspraken',
    DutchJudgments: 'Nederlandse uitspraken',
    EuropeanJudgments: 'Europese uitspraken',
    CaseLawFor_goodscode_: 'Rechtspraak voor {{ goodscode | stripTaricCode | formatTaricCode }}',
    CaseLawFor_goodscode_and_searchQuery_: 'Rechtspraak voor code {{ goodscode | stripTaricCode | formatTaricCode }} en zoekopdracht "{{ searchQuery }}"',
    CaseLawFor_searchQuery_: 'Rechtspraak voor {{ searchQuery }}',
    _TotalResults_results: '{{ TotalResults }} resultaten' // NB: TotalResults is a number
};