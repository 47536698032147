module.exports = {
    vatNumber: 'BTW-nummer',
    cdNumber: 'CD-nummer',
    eoriNumber: 'EORI-nummer',
    rexNumber: 'REX-nummer',
    tinNumber: 'TIN-nummer',
    cdChecking: 'Query EU Customs Decision System',
    checkEORINumber: 'Controleer EORI-nummer',
    checkRexNumber: 'Controleer REX-nummer',
    checkViesRegistrationForVatNumber: 'Controleer VIES-registratie voor btw-nummer',
    vatCheck: 'Controleer btw-nummer (VIES)',
    eoriCheck: 'Controleer EORI-nummer',
    rexCheck: 'Controleer REX-nummer',
    cdCheck: 'Query Customs Decision System',
    eoriCheckResultValid: 'Het gecontroleerde EORI-nummer is geldig',
    eoriCheckResultInvalid: 'Het gecontroleerde EORI-nummer is ongeldig',
    rexCheckResultInvalid: 'Het gecontroleerde REX-nummer is ongeldig',
    city: 'Stad',
    companyName: 'Bedrijfsnaam',
    companyAddress: 'Bedrijfsadres',
    countryCode: 'Landcode',
    postalCode: 'Postcode',
    searchResults: 'Zoekresultaten',
    serverError: 'Er is een fout opgetreden bij het verwerken van uw verzoek.', 
    activitynameField: 'Activiteit',
    activityflagField: 'Ja/Nee',
    listOfGoods: 'Lijst van goederen',
    commodityCode: 'Goederencode',
    rexFieldError: 'Vul een geldig REX-nummer in',
    eoriFieldError: 'Vul een geldig EORI-nummer in',
    ValidateBusinessInformation: 'Valideer bedrijfsinformatie',
    ViesWebSiteIntroText: 'Je kunt de VIES-website gebruiken om te controleren of een btw-nummer geldig is voor intra-communautaire levering van goederen of diensten.',
    GoToViesWebsite: 'Ga naar de VIES-website (opent in een nieuw venster)',
    PRODUCTION: 'Productie',
    TRADING: 'Handel',
};