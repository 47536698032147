angular.module('taric.caseLaw', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('caseLawButton', require('./components/case-law-button'))
    .component('euCaseLawSummaryButton', require('./components/eu-case-law-summary-button'))
    .component('caseLawNews', require('./components/case-law-news'))
    .component('caseLawSubnav', require('./components/case-law-subnav'))
    .component('caseLawLinksToNews', require('./components/case-law-links-to-news'))
    .component('euCaseLawListItem', require('./components/eu-case-law-list-item'))
    .component('euCaseLawNewsItem', require('./components/eu-case-law-news-item'))
    .component('nlCaseLawListItem', require('./components/nl-case-law-list-item'))
    .component('nlCaseLawNewsItem', require('./components/nl-case-law-news-item'))
    // Pages
    .component('caseLawNewsPage', require('./components/case-law-news-page'))
    .component('euCaseLawPage', require('./components/eu-case-law-page'))
    .component('nlCaseLawPage', require('./components/nl-case-law-page'))
    // Modals
    .factory('euCaseLawSummaryModal', require('./modals/eu-case-law-summary-modal'))
    .factory('caseLawModal', require('./modals/case-law-modal'))
    .factory('nlCaseLawJudgmentModal', require('./modals/nl-case-law-judgment-modal'))
    // Services
    .factory('caseLawDataService', require('./services/case-law-data'))
    .factory('euCaseLawDataService', require('./services/eu-case-law-data'))
    .factory('nlCaseLawDataService', require('./services/nl-case-law-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/case-law/news', {
            template: '<case-law-news-page></case-law-news-page>'
        })
        .when('/case-law/eu', {
            template: '<eu-case-law-page></eu-case-law-page>'
        })
        .when('/case-law/nl', {
            template: '<nl-case-law-page></nl-case-law-page>'
        })
        ;
}
