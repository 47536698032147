module.exports = {
    abc: {
        intro: 'Met Taric Support ABC kunt u met één druk op de knop artikelstambestanden, order-manifesten en aangiftes controleren en aanvullen met data uit het douanetarief. Wat dagen kostte, kost nu minuten. <br><br>Hoe het werkt? Upload een bestand, kies een controle en druk op start. Seconden later kunt u een bestand downloaden met allerlei nuttige data.  <br><br>Taric Support ABC combineert de kracht van onze API met de flexibiliteit van het up- en downloaden van Excel-bestanden'
    },
    abcNew: {
        file: 'Kies een Excel-bestand om te controleren en te verrijken',
        inputOptions: 'Geef aan in welke kolommen van het Excel-bestand welk type data gevonden kan worden en op welke rij de data begint.',
        outputOptions: 'Kies of u een controle wilt doen voor invoer- of uitvoeraangiftes, wat voor type controle u wilt doen en in welk bestandsformaat u het resultaat wilt hebben.',
        letsGo: 'Starten maar!',
    },
    caseLaw: {
        intro: 'Onder het kopje "Nieuws" vindt u de laatste Nederlandse en Europese uitspraken. <br><br>Onder de kopjes "Nederlandse rechtspraak" en "Europese rechtspraak" vindt u een geavanceerde zoekfunctie om snel de relevante jurisprudentie te vinden.',
    },
    chapter: {
        intro: 'Op deze pagina kunt u door een hoofdstuk bladeren. Daarnaast zijn er een hoop bronnen om u te helpen bij het indelingsproces.',
        heading: 'Klik op een post of onderverdeling om deze in/uit te klappen.<br>Hint: dubbel-klik om ook alle onderverdelingen in/uit te klappen.',
        chapterButtons: 
            'De volgende bronnen zijn beschikbaar - u ziet steeds de informatie de betrekking heeft op het huidige hoofdstuk' +
            '<ul><li>A staat voor Aantekeningen, wettelijk bindend</li>' +
            '<li>T staat voor Toelichtingen (hier vindt u ook de indelingsadviezen van de WCO en de indelingsverordeningen van de EU)</li>' +
            '<li>B staat voor BTIs</li>' +
            '<li>R staat voor Rechtspraak - indelingsvraagstukken worden vaak in de rechtbank opgelost</li>' +
            '<li>W staat voor Wetgeving - hier vindt u de laatste wetgeving en berichten vanuit de EU</li>' +
            '</ul>',
        headingButtons: 
            'Deze bronnen zijn ook beschikbaar voor elke post - en elders in de applicatie. ' + 
            'Om ruimte te besparen, gebruiken we steeds alleen de eerste letter voor een knop. ' +
            'Het went snel maar mocht u vergeten zijn waar zo\'n letter voor staat: als u met uw muis boven een knop hangt, dan ziet u wat de knop doet.'
    },
    chapters: {
        intro: 
            'Dit is de startpagina van de module classificatie en tarief. ' + 
            'Op deze pagina kunt u door de afdelingen en hoofdstukken van de nomenclatuur bladeren, of het tarief doorzoeken via de zoekbox.',
        largeSearchBox: 'Zoek op goederencode of zoekwoord via deze zoekbox of die bovenaan de pagina. <br><br>Hint: om sneller te zoeken, kunt u de woorden "import" en "export" gebruiken om meteen aan te geven of u een zoekopdracht doet voor invoer of uitvoer. U kunt ook meteen een landcode of landnaam invullen.',
        sectionNotesAndExplanatoryNotes: 'NB: om te bepalen of een goed ingedeeld kan worden in een bepaalde afdeling of een bepaald hoofdstuk, ' + 
        'moet u altijd de (wettelijk bindende) aantekeningen bij de afdelingen en hoofdstukken lezen. U vindt deze door te klikken op de knop gemarkeerd met de letter A voor Aantekeningen. <br><br>' +
        'Daarnaast zijn de toelichtingen die gepubliceerd worden door de WCO en de EU, hoewel niet wettelijk bindend, onmisbare hulpmiddelen om te bepalen welke goederen nu wel of niet onder ' +
        'een bepaalde afdeling, hoofdstuk, post of onderverdeling vallen. U kunt ze vinden door te klikken op de knop gemarkeerd met de letter T voor Toelichtingen.',
    },
    correlationTable: {
        intro: 'Zoek de voorlopers van nieuwe goederencodes en de opvolgers van verlopen goederencodes. <br><br>U kunt zoeken op basis van start- of einddatum of op basis van goederencode (u kunt hier ook kiezen voor een hoofdstuk, post of onderverdeling).',
        searchSuccessors: 'Klik hier om opvolgers van verlopen goederencodes te bekijken. <br><br>NB: een opvolger van een verlopen code is niet noodzakelijk een nieuwe code. Het kan ook een al langer bestaande code zijn, waar goederen aan worden toegevoegd.',
        searchOriginators: 'Klik hier om voorlopers van nieuwe goederencodes te bekijken. <br><br>NB: een voorloper van een nieuwe code eindigt niet noodzakelijk. Het kan ook dat een deel van de goederen onder de nieuwe code valt en een deel nog steeds onder de oude code.'
    },
    ebti: {
        intro: 'Organisaties in de EU kunnen een verzoek om een bindend indelingsadvies indienen bij hun nationale douaneautoriteiten. Dit worden BTI\'s genoemd. Ze zijn een uitermate handig hulpmiddel bij het bepalen van de juiste classificatie van uw product. Als u voor hetzelfde product aangifte doet, heeft het immers waarschijnlijk dezelfde classificatie. <br><br>Taric Support helpt u met een totaaloverzicht van alle afgegeven BTI\'s in Europa met bijbehorende foto\'s en tekeningen, inclusief een historie van BTI\'s die niet meer geldig zijn. Deze BTI\'s zijn, net als de rest van de informatie, gemakkelijk te vinden op basis van de relevante goederencode.',
        form:
            'Met het zoekformulier voor BTI\'s kunt u zoeken op goederencode (hoofdstuk, post, onderverdeling), maar ook op zoekwoord of omschrijving van de goederen, bijvoorbeeld. <br><br>' + 
            'NB: u kunt BTIs sorteren op goederencode, maar bijvoorbeeld ook op datum, waardoor u een nieuwsoverzicht krijgt met de laatste BTIs, of het veld "Afgegeven sinds" gebruiken om alleen recente BTI\'s te tonen. <br><br>' +
            'NB: u kunt de resultaten ook bekijken als afbeeldingen. Vaak kan het bekijken van een lijst van plaatjes een snelle manier zijn om te zien of een bepaald stuk van de nomenclatuur toepasselijk is voor het goed dat u aan het indelen bent'
    },
    atr: {
        intro: 'Organisaties in het Verenigd Koninkrijk kunnen een verzoek om een bindend indelingsadvies indienen bij hun nationale douaneautoriteiten. Dit worden ATR\'s (Advance Tariff Rulings) genoemd. Ze zijn een uitermate handig hulpmiddel bij het bepalen van de juiste classificatie van uw product. Als u voor hetzelfde product aangifte doet, heeft het immers waarschijnlijk dezelfde classificatie. <br><br>Taric Support helpt u met een totaaloverzicht van alle afgegeven ATR\'s in het Verenigd Koninkrijk met bijbehorende foto\'s en tekeningen, inclusief een historie van ATR\'s die niet meer geldig zijn. Deze ATR\'s zijn, net als de rest van de informatie, gemakkelijk te vinden op basis van de relevante goederencode.',
        form:
            'Met het zoekformulier voor ATR\'s kunt u zoeken op goederencode (hoofdstuk, post, onderverdeling), maar ook op zoekwoord of omschrijving van de goederen, bijvoorbeeld. <br><br>' + 
            'NB: u kunt ATR\'s sorteren op goederencode, maar bijvoorbeeld ook op datum, waardoor u een nieuwsoverzicht krijgt met de laatste ATR\'s, of het veld "Afgegeven sinds" gebruiken om alleen recente ATR\'s te tonen. <br><br>' +
            'NB: u kunt de resultaten ook bekijken als afbeeldingen. Vaak kan het bekijken van een lijst van plaatjes een snelle manier zijn om te zien of een bepaald stuk van de nomenclatuur toepasselijk is voor het goed dat u aan het indelen bent'
    },
    header: {
        referenceDatePicker: 'Gebruik de datumkiezer om in te stellen voor welke datum u nomenclatuur, tarieven en maatregelen wilt bekijken.',
        tradeMovementPicker: 'Kies import of export om nomenclatuur en maatregelen te bekijken voor invoer of uitvoer.',
        languagePicker: 
            'Kies één van 22 Europese talen. '+ 
            'NB: de ondersteunde talen voor de interface zijn Nederlands en Engels, maar het grootste deel van de informatie is in alle 22 talen beschikbaar. ' +
            'Handig voor als een klant, leverancier of collega een andere moedertaal heeft.',
    },
    landingPage: {
        welcomeTitle: 'Welkom / Welcome',
        welcomeIntro: 
            'Hallo! Welkom bij Taric Support. Bedankt voor het gebruiken van onze applicatie. Laten we even rondkijken. <br><br>' + 
            'Als u liever in een andere taal doorgaat, klik dan op de knop hierboven om één van de 22 mogelijke talen te kiezen. <br><hr><br>' +
            'Hello and welcome to Taric Support. Thank you for using our application. Please let us show you around. <br><br>' +
            'If you\'d prefer to continue in a different language, please click the button above to choose one of 22 possible languages.',
        referenceCountryPicker:
            'De belangrijkste databronnen voor Taric Support zijn een aantal verschillende douanetarieven. Door op één van de vlaggen te klikken kunt u wisselen van douanetarief. <br><br>' + 
            'Deze keuze beïnvloedt zowel de nomenclatuur die u gebruikt voor het classificeren als de maatregelen voor in- en uitvoer die u te zien krijgt. ' +
            'De nomenclatuur voor het UK Global Tariff is bijvoorbeeld niet hetzelfde als de nomenclatuur voor TARIC, het douanetarief van de EU. <br><br>' +
            'NB: u kunt op elk moment wisselen van douanetarief door te klikken op de vlaggen in de menubalk.',
        introButton:
            'Om meer informatie te krijgen over een bepaalde pagina, kunt u klikken op de introknop'
    },
    mainMenu: {
        intro: 'Via het menu krijgt u toegang tot de verschillende modules binnen uw abonnement. <br><br>Tip: gebruik de Enter-toets om door deze introductie te gaan.',
        chapters: 'Blader door de nomenclatuur en het douanetarief. U kunt hier ook direct in zoeken via de zoekbox in de menubalk.',
        news: 'Het laatste douanenieuws',
        ebti: 'De volledige EBTI-database: honderdduizenden voorbeelden van producten die door de douane zijn geclassificeerd',
        abc: 'Controleer en verrijk honderden codes per minuut met Taric Support ABC',
        correlationTable: 'Vind de voorlopers en opvolgers van goederencodes',
        referencePrices: 'Een complete lijst van de dagelijkse forfaitaire invoerwaardes en eenheidsprijzen voor groenten en fruit',
        caseLaw: 'Nederlandse en Europese douanerechtspraak.'
    },
    measures: {
        intro: 
            'Hier ziet u de maatregelen die gelden bij in- of uitvoer van een goed met deze goederencode. <br><br>' +
            'Hierbij maken we gebruik van een aantal verschillende kleuren om u te helpen snel de verschillende types codes te herkennen. ' +
            'Op deze manier kunnen we een veelheid aan informatie op een compacte manier weergeven. De kleuren zijn als volgt: <br><br>' +
            '<ul class="nav nav-pills nav-stacked">' +
            '<li><a class="btn btn-certificate-code">Bescheidcode</a></li>' +
            '<li><a class="btn btn-footnote">Voetnoot</a></li>' +
            '<li><a class="btn btn-addcod">Aanvullende code</a></li>' +
            '<li><a class="btn btn-prefcode">Preferentiecode</a></li>' +
            '</ul>',
        form:
            'Met behulp van deze velden kunt u de maatregelen filteren op landcode, aanvullende codes toepassen en een keuze maken wat betreft het toe te passen invoerrecht. <br><br>' +
            'Hint: het is ook mogelijk om de code of naam van het land meteen in de zoekbox in de bovenbalk te typen.',
        validateDeclaration:
            'In dit scherm kunt u de gegevens voor een douane-aangifteregel controleren. Filter eerst op landcode, pas aanvullende codes toe ' +
            'en maak een keuze voor wat betreft het toe te passen invoerrecht. Vul daarna de douanewaarde en de relevante hoeveelheden in. Kies tot slot de toepasbare ' +
            'codes voor bijzondere bepalingen (Y-codes) en overige bescheidcodes en start de controle.',
        calculateDuty:
            'In dit scherm kunt u de invoerrechten en (voor bepaalde landen) de nationale belastingen berekenen. Filter eerst op landcode, pas aanvullende codes toe ' +
            'en maak een keuze voor wat betreft het toe te passen invoerrecht. Vul daarna de douanewaarde en de relevante hoeveelheden in. Kies tot slot de toepasbare ' +
            'codes voor bijzondere bepalingen (Y-codes) en overige bescheidcodes en druk op de knop "Bereken rechten".',
    },
    news: {
        intro: 
            'Onder "Wetgeving EU" vindt u de laatste regelgeving en aankondigingen van EUR-Lex. ' +
            'Onder "Rechtspraak" vindt u de laatste Nederlandse en Europese uitspraken. <br><br>' + 
            'Onder "Douane voor bedrijven" vindt u nieuwsberichten van de Nederlandse douane'
    },
    ui: {
        done: 'Einde',
        help: 'Help',
        next: 'Volgende',
        previous: 'Vorige',
        startTour: 'Intro'
    },
};