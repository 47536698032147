angular.module('taric.correlationTable', [])
    .config(['$routeProvider', routeConfigurator])
    .component('correlationTableSubnav', require('./components/correlation-table-subnav'))
    .component('correlationTable', require('./components/correlation-table'))
    .component('hs2022Backward', require('./components/hs-2022-backward'))
    .component('hs2022Forward', require('./components/hs-2022-forward'))
    
    .component('correlationTablePage', require('./components/correlation-table-page'))
    .component('correlationTableNewsPage', require('./components/correlation-table-news-page'))
    .component('hs2022BackwardPage', require('./components/hs-2022-backward-page'))
    .component('hs2022ForwardPage', require('./components/hs-2022-forward-page'))
    .factory('correlationDataService', require('./services/correlation-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/correlation-table/hs-2022-forward', {
            template: '<hs-2022-forward-page></hs-2022-forward-page>'
        })
        .when('/correlation-table/hs-2022-backward', {
            template: '<hs-2022-backward-page></hs-2022-backward-page>'
        })
        .when('/correlation-table/:activeSubnav/:code', {
            template: '<correlation-table-page></correlation-table-page>'
        })
        .when('/correlation-table/:activeSubnav', {
            template: '<correlation-table-page></correlation-table-page>'
        })
        .when('/correlation-table', {
            template: '<correlation-table-page></correlation-table-page>'
        })
        ;
}
