module.exports = {
    controller: euCdVatFormController,
    templateUrl: 'validate-business-info/components/validate-business-info-cd-form.html'
}

euCdVatFormController.$inject = [
    'validateBusinessInfoDataService',
];

function euCdVatFormController(
    validateBusinessInfoDataService
) {
    var vm = {
        isLoading: false,
        service : validateBusinessInfoDataService,
        cdCheck: validateBusinessInfoDataService.cdCheck
    };

    return vm;
}