module.exports = ['$window', '$q', '$cookies', 'config', 'countryDataService', 'dataContext',
    'identity', 'language', 'preferenceService', 'applicationStateService', 'favoritesService', preloaderService];

function preloaderService($window, $q, $cookies, config, countryDataService, context, identityService, languageService, preferenceService, state, favoritesService) {
    var service = {
        init: init,
        run: runWhenAuthenticated,
        identity: identityService,
        status: {
            isDone: true
        }
    };

    function init() {
        return identityService
        .init()
        .then(function(isAuthenticated) {
            if (isAuthenticated) {
                return runWhenAuthenticated();
            }
        });
    }

    function loadPermissions() {
        return context
            .get(config.apiHosts.generic + '/me/permission')
            .then(function (data) {
                identityService.permissions = [];
                var hasPermissions = false;
                for (var prop in data) {
                    hasPermissions = true;
                    identityService.permissions = identityService.permissions.concat(data[prop]);
                }
                if (!hasPermissions) {
                    $cookies.remove('authToken');
                    $window.location.reload();
                }
            })
            .catch(function () {
                $cookies.remove('authToken');
                $window.location.reload();
            });
    }

    function runWhenAuthenticated() {
        service.status.isDone = false;
        return loadPermissions()
            .then(function() { return preferenceService.init(); })
            .then(function() { return configureServicesBasedOnPreferences(); })
            .then(function() { return countryDataService.init(); })
            .then(function() { return loadSearchCountryPref(); })
            .then(function() { return favoritesService.init(); })
            .then(function() {
                service.status.isDone = true;
            })
            ;
    }

    function loadSearchCountryPref() {
        var deferred = $q.defer();
        var searchCountryPref = preferenceService.get('searchCountry', 'search');
        if (searchCountryPref) {
            var countryCode = searchCountryPref.value.split(',')[0];
            var countryName = searchCountryPref.value.split(',')[1];
            var searchCountryIsValidForReferenceCountry = _.some(countryDataService.ergaOmnesCountries, function (countryData) {
                return countryData.code === countryCode;
            });
            if (searchCountryIsValidForReferenceCountry) {
                state.filter.countryCode = countryCode;
                state.filter.countryName = countryName;
                state.remember.country = true;
            }
            else {
                preferenceService.remove(searchCountryPref);
            }
        }
        deferred.resolve();
        return $q.promise;
    }

    function configureServicesBasedOnPreferences() {
        var deferred = $q.defer();
        if (preferenceService.get('uccModeEnabled', 'settings') !== null) {
            state.uccModeEnabled = preferenceService.get('uccModeEnabled', 'settings').value;
        }
        if (preferenceService.get('language', 'website') !== null) {
            if (languageService.set) {
                languageService.set(preferenceService.get('language', 'website').value, false);
            }
        }
        var referenceCountryPref = preferenceService.get('referenceCountry', 'search');
        if (referenceCountryPref) {
            state.filter.referenceCountry = referenceCountryPref.value;
            config.currentApi = 'dtv';
        }

        if (preferenceService.get('movement') === null) {
            preferenceService.add({
                name: 'movement',
                category: 'user_preferences',
                value: 'import',
                valueType: 'stringValue'
            });
            state.filter.movement = 'import';
        }
        else {
            state.filter.movement = preferenceService.get('movement').movement;
        }
        deferred.resolve();
        return $q.promise;
    }



    return service;
}