module.exports = {
    bindings: { 
        monetaryValue: '<', 
        twoWay: '<', 
        currencyFixed: '<',
        decimalPlaces: '<',
        onChange: '&'
    },
    controller: monetaryValueInputController,
    templateUrl: 'exchange-rates/components/monetary-value-input.html'
};

monetaryValueInputController.$inject = [
    'currencySelectorModal', 
    'exchangeRateDataService', 
    'notificationsService',
];

function monetaryValueInputController(
    currencySelectorModal, 
    exchangeRateDataService, 
    notificationsService
) {

    var vm = {
        $onChanges: $onChanges,
        $onInit: attached,
        $onDestroy: detached,
        onChangeAmountInForeignCurrency: onChangeAmountInForeignCurrency,
        onChangeAmountInTargetCurrency: onChangeAmountInTargetCurrency,
        showModal: showModal,
        twoWay: false,
        currencies: []
    };
    
    return vm;

    function showModal() {
        exchangeRateDataService.getCurrenciesIncludingTargetCurrency()
            .then(function(currencies) {
                vm.currencies = currencies;
            })
            .then(function() {
                currencySelectorModal
                    .show(vm.currencies, vm.monetaryValue.originalUnit)
                    .result
                    .then(itemSelected)
                    .catch(function (res) {
                        if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                            throw res;
                        }
                    });
            })
    }

    function $onChanges() {
        // debugger;
    }

    function attached() {
        if (!vm.decimalPlaces) {
            vm.decimalPlaces = 5;
        }
        notificationsService.subscribe('filter_date_changed', dateChanged);
    }

    function detached() {
        notificationsService.unsubscribe('filter_date_changed', dateChanged);
    }

    function dateChanged() {
        if (vm.monetaryValue.unit !== vm.monetaryValue.originalUnit) {
            updateExchangeRate(vm.monetaryValue.originalUnit, vm.monetaryValue.unit);
        }
    }

    function updateExchangeRate(currencyA, currencyB) {
        // debugger;
        return exchangeRateDataService.getExchangeRate(currencyA, currencyB)
            .then(function (exchangeRate) {
                vm.monetaryValue.exchangeRate = exchangeRate.APerB;
                vm.monetaryValue.originalUnit = currencyA;
                onChangeAmountInForeignCurrency();
            });
    }

    function itemSelected(currency) {
        if (currency.code === vm.monetaryValue.unit) {
            vm.monetaryValue.originalUnit = currency.code;
            vm.monetaryValue.exchangeRate = 1;
            onChangeAmountInForeignCurrency();
            return;
        }
        return updateExchangeRate(currency.code, vm.monetaryValue.unit);
    }

    function onChangeAmountInForeignCurrency() {
        if (isNumericOrNumber(vm.monetaryValue.originalAmount) && _.isNumber(vm.monetaryValue.exchangeRate)) {
            vm.monetaryValue.amount = parseFloat(vm.monetaryValue.originalAmount) / vm.monetaryValue.exchangeRate;
        }
        else {
            vm.monetaryValue.amount = '';
        }
        if (vm.onChange) {
            vm.onChange(vm.monetaryValue);
        }
    }

    function onChangeAmountInTargetCurrency() {
        if (isNumericOrNumber(vm.monetaryValue.amount) && _.isNumber(vm.monetaryValue.exchangeRate)) {
            vm.monetaryValue.originalAmount = parseFloat(vm.monetaryValue.amount) * vm.monetaryValue.exchangeRate;
        }
        else {
            vm.monetaryValue.originalAmount = '';
        }
        if (vm.onChange) {
            vm.onChange(vm.monetaryValue);
        }
    }

    function isNumericOrNumber(input) {
        return _.isNumber(input) || isNumeric(input);
    }

    function isNumeric(str) {
        if (typeof str != "string") {
            return false;  
        }
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    }
}