module.exports = {
    bindings: { 
        currency: '<', 
        placeholder: '<', 
        possibleCurrencies: '< ', 
        selectionChanged: '&' 
    },
    controller: currencyPickerController,
    templateUrl: 'exchange-rates/components/currency-picker.html'
};

currencyPickerController.$inject = ['currencySelectorModal'];

function currencyPickerController(currencySelectorModal) {
    var vm = {
        $onInit: attached,
        clear: clear,
        currency: null,
        userInput: null,
        possibleCurrencies: null,
        itemSelected: itemSelected,
        onBlur: onBlur,
        onEnter: onEnter,
        showModal: showModal
    };

    return vm;

    function showModal() {
        currencySelectorModal
            .show(vm.possibleCurrencies, vm.currency)
            .result
            .then(itemSelected)
            .catch(function (res) {
                if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                    throw res;
                }
            });
    }

    function attached() {

    }

    function clear(ev) {
        vm.userInput = null;
        clearSelection();
    }

    function clearSelection() {
        vm.currency = null;
        if (vm.selectionChanged)
            vm.selectionChanged({ currency: null });

    }

    function onBlur() {
       
    }

    function onEnter() {
        
    }

    function itemSelected(item, model) {
        vm.currency = item;

        if (vm.selectionChanged)
            vm.selectionChanged({ currency: item });
    }
}

