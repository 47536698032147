module.exports = {
    bindings: {
        exciseCode: '<',
        exciseNomenclature: '<',
        selectionChanged: '&',
        validation: '<' 
    },
    controller: eztExciseCodePickerController,
    templateUrl: 'ezt/components/ezt-excise-code-picker.html'
};

eztExciseCodePickerController.$inject = [
    'eztExciseCodeSelectorModal'
];

function eztExciseCodePickerController(
    eztExciseCodeSelectorModal
) {
    var vm = {
        clear: clear,
        itemSelected: itemSelected,
        onBlur: onBlur,
        onEnter: onEnter,
        showModal: showModal
    }
    return vm;

    function clear(ev) {
        vm.userInput = null;
        clearSelection();
    }

    function clearSelection() {
        vm.exciseCode = null;
        if (vm.selectionChanged)
            vm.selectionChanged({ exciseCode: null });

    }

    function onBlur() {
       
    }

    function onEnter() {
       
    }

    function itemSelected(item) {
        vm.exciseCode = item;
        
        if (vm.selectionChanged)
            vm.selectionChanged({ eztExciseCode: item });
    }

    function showModal() {
        eztExciseCodeSelectorModal
            .show(vm.exciseNomenclature, vm.exciseCode)
            .result
            .then(itemSelected)
            .catch(function (res) {
                if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                    throw res;
                }
            });
    }
}