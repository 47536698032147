﻿module.exports = ['identity', 'preloaderService', preloaderCtrl];

function preloaderCtrl(identityService, preloaderService) {

    var vm = {
        identity: identityService,
        status: preloaderService.status
    };

    preloaderService.init();

    return vm;
}