module.exports = {
    bindings: {},
    templateUrl: 'good/components/reference-date-and-country.html',
    controller: referenceDateAndCountryController
};

referenceDateAndCountryController.$inject = ['applicationStateService'];

function referenceDateAndCountryController(state) {
    var vm = {
        state: state
    };
    return vm;
}