﻿module.exports = {
    controller: sectionsPageController,
    templateUrl: 'nomenclature/components/sections-page.html'
};

sectionsPageController.$inject = [
    '$analytics',
    '$location',
    'common',
    'documentationService',
    'nomenclatureDataService',
    'notificationsService',
    'preferenceService',
    'ecicsDataService',
];

function sectionsPageController(
    $analytics, 
    $location, 
    common, 
    documentationService, 
    nomenclatureDataService, 
    notifications, 
    preferenceService,
    ecicsDataService
) {

    var vm = {
        activateSection: activateSection,
        activeSection: '',
        ecicsCount: {},
        fillChapters: fillSections,
        firstSections: [],
        $onInit: attached,
        $onDestroy: detached,
        isLoading: false,
        loadChapter: loadChapter,
        loadChapters: loadChapters,
        loadRecentCodes: loadRecentCodes,
        numberOfRecentCodes: 10,
        recentCodes: [],
        secondSections: [],
    };



    function activateSection(sectionNumber) {
        if (sectionNumber === null || sectionNumber === undefined || sectionNumber === vm.activeSection)
            vm.activeSection = 0;
        else
            vm.activeSection = sectionNumber;
    }

    function fillSections(sectionsData) {
        var sections = sectionsData.data;
        _.forEach(sections, function (section) {
            if (section.chapters === null || section.chapters.length === 0)
                return;

            section.chapterDescription = common.formatting.padLeft(section.chapters[0].number, 2, '0');

            if (section.chapters.length > 1)
                section.chapterDescription += ' - ' + common.formatting.padLeft(section.chapters[section.chapters.length - 1].number, 2, '0');
        });

        var half = Math.floor(sections.length / 2) + 1;

        vm.firstSections = sections.slice(0, half);
        vm.secondSections = sections.slice(half, sections.length);
        vm.isLoading = false;
        documentationService.addHints();
    }

    function attached() {
        $analytics.pageTrack('/sections');
        common.auth.check();
        loadRecentCodes();
        loadChapters();
        loadEcicsCount();
        notifications.subscribe('filter_date_changed', dateChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    function dateChanged() {
        loadChapters();
    }

    function loadEcicsCount() {
        ecicsDataService.countPerChapter()
            .then(function(ecicsCount) {
                vm.ecicsCount = ecicsCount;
            });
    }

    function loadRecentCodes() {
        return preferenceService.getCategory('recent_codes')
            .then(function (codes) {
                var recentCodes = _.chain(codes)
                    .sortBy(function (code) { return new Date(code.lastUpdate).getTime(); }) // this solves a timezone difference issue for codes that were just added
                    .reverse()
                    .uniqBy('name')
                    .take(vm.numberOfRecentCodes)
                    .value();
                vm.recentCodes = recentCodes;
            });
    }

    function loadChapter(chapter) {
        $location.url('/chapter/' + chapter.code);
    }

    function loadChapters() {
        vm.isLoading = true;
        return nomenclatureDataService.getSections()
            .then(fillSections, function() {});
    }


    return vm;

}