angular.module('taric.ezt', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('eztExciseCodePicker', require('./components/ezt-excise-code-picker'))
    .component('eztNomenclature', require('./components/ezt-nomenclature'))
    .component('eztHinweise', require('./components/ezt-hinweise'))
    .component('eztTextsPage', require('./components/ezt-texts-page'))
    // Controllers
    // Filters
    .filter('eztExciseCodeTypeahead', require('./filters/ezt-excise-code-typeahead-filter'))
    // Modals
    .factory('eztExciseCodeSelectorModal', require('./modals/ezt-excise-code-selector'))
    // Services
    .factory('eztDataService', require('./services/ezt-data'))
    ;


function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/texts-german-customs', {
            template: '<ezt-texts-page></ezt-texts-page>'
        })
        .when('/texts-german-customs/:documentType/:mainDocument', {
            template: '<ezt-texts-page></ezt-texts-page>'
        })
    ;
}
