module.exports = {
    controller: belastingdienstPageController,
    templateUrl: 'belastingdienst/components/belastingdienst-page.html'
};

belastingdienstPageController.$inject = [
    '$analytics', 
    'belastingdienstDataService'
];

function belastingdienstPageController(
    $analytics, 
    belastingdienstData
) {

    var vm = {
        $onInit: attached,
        isLoading: false,
        data: {
            items: []
        }
    };

    function attached() {
        $analytics.pageTrack('/dutch-customs-news');
        loadItems();
    }

    function loadItems() {
        vm.isLoading = true;
        vm.data.items = [];
        belastingdienstData.getNews()
            .then(function (data) {
                vm.data.items = data;
                vm.isLoading = false;
            });

    }

    return vm;
}
