module.exports = {
    controller: transactionValueController,
    templateUrl: 'customs-value/components/transaction-value-calculator-page.html'
};

transactionValueController.$inject = [
    '$analytics', 
    '$window', 
    'transactionValueDataService', 
    'applicationStateService',
];

function transactionValueController(
    $analytics, 
    $window, 
    transactionValueDataService, 
    state
) {

    var vm = {
        referenceCountry: state.filter.referenceCountry,
        $onInit: attached,
        print: print
    };
    
    return vm;

    function attached() {
        $analytics.pageTrack('/transaction-value-calculator');
        transactionValueDataService.initTransactionValue();
    }

    function print() {
        $window.print();
    }
}