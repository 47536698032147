module.exports = {
    bindings: {
        measures: '<',
        settings: '<', // commodityCode, eztExciseCode, eztNationalCommodityCode, referenceCountry
    },
    controller: importMeasuresColumnOneController,
    templateUrl: 'good/components/import-measures-col-1.html'
};

importMeasuresColumnOneController.$inject = ['applicationStateService'];
function importMeasuresColumnOneController(state) {
    var vm = {
        state: state
    };
    return vm;
}