module.exports = {
    ChooseNationalCommodityCodeToFindVatRate: 'Kies hierboven een nationale goederencode om de het juiste btw-tarief te vinden.',
    ChooseExciseCodeToFindExciseRates: 'Kies hierboven een accijnscode om het juiste accijnstarief te vinden.',
    ExciseCode: 'Accijnscode',
    ExplanatoryNotesProvidedByGermanCustoms: 'Toelichtingen beschikbaar gesteld door de Duitse douane',
    ExciseDutyRate: 'Tarief accijns',
    EztExciseCodeFieldPlaceHolder: 'Vul accijnscode in of zoek',
    EztExciseCodeSelectorModalTitle: 'Nomenclatuur accijns',
    GermanExplanatoryNotes: 'Toelichtingen Duitsland',
    Hinweise: 'Hinweise',
    NationalCommodityCode: 'Nationale goederencode',
    NationalNomenclature: 'Nationale nomenclatuur',
    TextsPublishedByGermanCustoms: 'Teksten gepubliceerd door Duitse douane',
    TextsGermanCustoms: 'Teksten Duitse douane'
};