module.exports = {
    controller: tradeStatisticsController,
    templateUrl: 'trade-statistics/components/trade-statistics-page.html'
};


tradeStatisticsController.$inject = [
    '$analytics', 
    'common', 
    'countryDataService',
    'notificationsService',
    'applicationStateService', 
    'tradeStatisticsDataService', 
];

function tradeStatisticsController(
    $analytics, 
    common, 
    countryDataService,
    notifications,
    state,
    tradeStatisticsDataService
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        isLoading: false,
        availableYears: ['2020'],
        countryNames: {},
        partnerCountries: [],
        reportingCountries: [],
        query: {
            commodityCode: null,
            partnerCountry: null,
            reportingCountry: null,
            year: '2020'
        },
        sorting: {
            columnName: 'country',
            sortOrder: 'asc'
        },
        euAverageResult: null,
        result: null,
        tradeMovement: state.filter.movement,
        loadData: loadData,
        reportingCountryChanged: reportingCountryChanged,
        partnerCountryChanged: partnerCountryChanged,
        yearChanged: yearChanged
    };

    function attached() {
        $analytics.pageTrack('/trade-statistics');
        loadAvailableYears()
            .then(function() {
                return common.$q.all([
                    loadData(),
                    countryDataService.getEuCountries({ referenceCountry: 'EU' })
                        .then(function(countries) {
                            vm.reportingCountries = _.filter(countries, function (country) {
                                return country.code !== 'XI';
                            });
                            // TODO: include GB (?)
                            // TODO: include XI (?)
                        }),
                    countryDataService.getCountries({ referenceCountry: 'EU' })
                        .then(function (countries) {
                            vm.partnerCountries = _.filter(countries, function (country) {
                                return country.code !== 'XI';
                            });
                            // TODO: include GB (?)
                            // TODO: include XI (?)
                            
                        })
                ])
            })
            .then(function() {
                var reportingCountryNames = _.chain(vm.reportingCountries).keyBy('code').mapValues('name').value();
                var partnerCountryNames = _.chain(vm.partnerCountries).keyBy('code').mapValues('name').value();
                vm.countryNames = _.merge(vm.countryNames, reportingCountryNames, partnerCountryNames);
                vm.countryNames['RS'] = vm.countryNames['XS'];
                sortResultsBy(vm.sorting.columnName, vm.sorting.sortOrder);
            });
        notifications.subscribe('filter_movement_changed', movementChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_movement_changed', movementChanged);
    }

    function loadAvailableYears() {
        return tradeStatisticsDataService.getAvailableYears()
            .then(function (years) {
                vm.availableYears = years;
                vm.query.year = years[0];              
            });
    }

    function movementChanged(tradeMovement) {
        vm.tradeMovement = tradeMovement;
    }

    function sortResultsBy(columnName, sortOrder) {
        var year = vm.result.years[0];
        if (columnName === 'country') {
            vm.result.rows = _.orderBy(vm.result.rows, function (row) {
                return vm.countryNames[row.country_id];
            }, [ sortOrder ]);
        }
        vm.result.rows = _.orderBy(vm.result.rows, function(row) {
            return row.samples[year][columnName];
        }, [ sortOrder ]);
    }

    function reportingCountryChanged(country) {
        var previousCountry = vm.query.reportingCountry;
        if (country) {
            vm.query.reportingCountry = country.code;
        }
        else {
            vm.query.reportingCountry = null;
        }
        if (vm.query.reportingCountry !== previousCountry) {
            loadData();
        }
    }

    function partnerCountryChanged(country) {
        var previousCountry = vm.query.partnerCountry;
        if (country) {
            vm.query.partnerCountry = country.code;
        }
        else {
            vm.query.partnerCountry = null;
        }
        if (vm.query.partnerCountry !== previousCountry) {
            loadData();
        }
    }

    function yearChanged() {
        loadData();
    }

    function loadData() {
        vm.isLoading = true;
        if (vm.query.partnerCountry) {
            // Load both the detailed statistics (as below) and the EU average
            return common.$q.all([tradeStatisticsDataService.getTradeStatistics(vm.query), tradeStatisticsDataService.getEuAverageTradeStatistics(vm.query)])
                .then(function(results) {
                    vm.result = results[0];
                    vm.euAverageResult = results[1];
                    vm.isLoading = false;
                }, function () {
                    vm.isLoading = false;
                });
        }
        else {
            return tradeStatisticsDataService.getTradeStatistics(vm.query)
                .then(function (result) {
                    vm.result = result;
                    sortResultsBy(vm.sorting.columnName, vm.sorting.sortOrder);
                    vm.isLoading = false;
                }, function () {
                    vm.isLoading = false;
                });
        }
    }
    return vm;
}
