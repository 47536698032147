module.exports = {
    controller: resetPasswordPageController,
    templateUrl: 'account/components/reset-password-page.html'
};

resetPasswordPageController.$inject = [
    '$analytics', 
    '$location', 
    '$routeParams', 
    'userAccountService'
];

function resetPasswordPageController(
    $analytics, 
    $location, 
    $routeParams, 
    userAccount
) {

    var vm = {
        $onInit: attached,
        $postLink: focusInputField,
        checkStrength: checkStrength,
        resetPassword: resetPassword,
        key: null,
        model: {
            password1: null,
            password2: null,
            checks: {
                characters: false,
                lowercase: false,
                uppercase: false,
                nonalpha: false,
                number: false
            }
        },
        navigateTo: navigateTo,
        status: {
            done: false,
            errors: [],
            invalidToken: false,
            loading: false
        }
    };

    function attached() {
        $analytics.pageTrack('/reset-password');
        vm.key = $routeParams.key;

        if (vm.key === undefined || vm.key === null || vm.key === '') {
            vm.status.invalidToken = true;
        }
    }

    function focusInputField() {
        document.getElementById('reset-password1').focus();
    }

    function checkStrength() {
        if (!this.model.password1) {
            this.model.checks.characters = false;
            this.model.checks.lowercase = false;
            this.model.checks.nonalpha = false;
            this.model.checks.number = false;
            this.model.checks.uppercase = false;
        }

        this.model.checks.characters = this.model.password1.length >= 8;
        this.model.checks.lowercase = /[a-z]/.exec(this.model.password1) !== null;
        this.model.checks.nonalpha = /\W/.exec(this.model.password1) !== null;
        this.model.checks.number = /\d/.exec(this.model.password1) !== null;
        this.model.checks.uppercase = /[A-Z]/.exec(this.model.password1) !== null;
    }

    function navigateTo(path) {
        $location.url(path);
    }

    function resetPassword() {
        vm.status.errors = [];

        if (vm.model.password1 === null || vm.model.password1 === '') {
            vm.status.errors = ['account.resetPassword.errors.dynamic.PASSWORD_EMPTY'];
            return;
        }

        if (vm.model.password1 !== vm.model.password2) {
            vm.status.errors = ['account.resetPassword.errors.dynamic.PASSWORDS_NOTEQUAL'];
            return;
        }

        vm.status.loading = true;
        userAccount.confirmPassword(vm.key, vm.model.password1)
            .then(resetSuccess, resetError);

        function resetSuccess(data, status, headers) {
            if (data.IsSuccessful === true) {
                vm.status.loading = false;
                vm.status.done = true;
            } else {
                vm.status.loading = false;
                for (var i = 0; i < data.Codes.length; i++) {
                    data.Codes[i] = 'account.resetPassword.errors.dynamic.' + data.Codes[i];
                }
                vm.status.errors = data.Codes;
            }
        }

        function resetError(error, status) {
            vm.status.loading = false;

            if (error.ExceptionMessage) {
                vm.status.errors = [error.ExceptionMessage];
            } else if (error.Message) {
                vm.status.errors = [error.Message];
            } else {
                vm.status.errors = [JSON.stringify(error)];
            }
        }
    }

    return vm;
}
