module.exports = {
    controller: languageDropdownController,
    templateUrl: 'widgets/components/navbar-language-dropdown.html'
};

languageDropdownController.$inject = ['$timeout', '$window', 'common', 'documentationService', 'preferenceService'];

function languageDropdownController($timeout, $window, common, documentationService, preferences) {

    var vm = {
        onClick: onClick,
        getLanguage: common.language.get,
        setLanguage: setLanguage,
        languageService: common.language
    };

    function onClick() {
        $timeout(function() {
            documentationService.exit();
        }, 0);
    }

    function setLanguage(language) {

        preferences.setLanguage(language).then(function(){
            setTimeout(function() {
                // We set a timeout in order to give listeners
                // to the language changed event a chance to react
                // before reloading. For example, the query string
                // in the search function is cleared.
                $window.location.reload();
            }, 1);
        });
    }

    return vm;

}