﻿module.exports = {
    bindings: {
        nationalNomenclatureId: '<',
        group: '<'
    },
    controller: germanVatMeasuresController,
    templateUrl: 'good/components/german-vat-measures.html'
};

function germanVatMeasuresController() {
    var vm = {
        $onChanges: $onChanges,
        filterMeasuresByNationalCommodityCode: filterMeasuresByNationalCommodityCode
    };

    return vm;

    function $onChanges() {
        vm.applicableMeasures = filterMeasuresByNationalCommodityCode(vm.group, vm.nationalNomenclatureId);
    }

}

function filterMeasuresByNationalCommodityCode(group, nationalNomenclatureId) {
    var filteredMeasures = _.filter(group, function (measure) {
        if (!nationalNomenclatureId) {
            return true;
        }
        if (!measure.german_national_commodity_codes) {
            return true;
        }
        if (measure.german_national_commodity_codes.lenghth === 0) {
            return true;
        }
        if (measure.german_national_commodity_codes[0][10] === '0') {
            return true;
        }
        if (_.includes(measure.german_national_commodity_codes, nationalNomenclatureId)) {
            return true;
        }
        return false;
    });
    return filteredMeasures
}