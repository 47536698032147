﻿module.exports = {
    bindings: {
        isLarge: '<',
        item: '<'
    },
    controller: btiButtonController,
    templateUrl: 'bti/components/bti-button.html'
};

btiButtonController.$inject = ['common', 'btiModal'];

function btiButtonController(common, btiModal) {
    
    var vm = {
        showBtis: showBtis
    };

    function showBtis() {
        var numberPattern = /^\s*(?:\d{2}[\s.]*){1,5}$/;

        var code = common.formatting.removeTrailingZeros(vm.item.code);

        if (numberPattern.test(vm.item.searchQuery))
            btiModal.show(code, '');
        else
            btiModal.show(code, vm.item.searchQuery);
    }


    return vm;

}

