module.exports  = [
    'dataContext', 
    'common', 
    validateBusinessInfoDataService
];

function validateBusinessInfoDataService(
    dataContext, 
    common
) {

    var service = {
        response: {},
        isLoading:false,
        eoriCheck: {
            eoriNumber: null,
            result: null,
            messages : null ,
        },
        rexCheck: {
            eoriNumber: null,
            rexNumber: null,
            tinNumber: null,
            result: null,
            messages : null ,
        },
        cdCheck: {
            eoriNumber: null,
            cdNumber: null,
            result: null,
            messages : null ,
        },
        display: 'list',
        checkEoriNumber: checkEoriNumber,
        clearEoriNumber: clearEoriNumber,
        checkRexNumber: checkRexNumber,
        clearRexNumber: clearRexNumber,
        clearRexEoriNumber: clearRexEoriNumber,
        clearRexTinNumber: clearRexTinNumber,
    };
    return service;

    function clearEoriNumber() {
        service.eoriCheck.eoriNumber = null;
        service.eoriCheck.result = null;
    }

    function clearRexNumber() {
        service.rexCheck.rexNumber = null;
        service.rexCheck.result = null;
    }

    function clearRexEoriNumber() {
        service.rexCheck.eoriNumber = null;
        service.rexCheck.result = null;
    }

    function clearRexTinNumber() {
        service.rexCheck.tinNumber = null;
        service.rexCheck.result = null;
    }

    function checkRexNumber() {
        service.rexCheck.result = null;
        var url = common.config.apiHosts.generic + 
        '/rex-numbers/check/' + 
        '?rex=' + (service.rexCheck.rexNumber || '') +
        '&eori=' + (service.rexCheck.eoriNumber || '') +
        '&tin=' + (service.rexCheck.tinNumber || '');
        return dataContext.get(url).then(function (result) {
            service.rexCheck.messages = result.Messages;
            service.rexCheck.result = result.Result.resultField;
        }).catch(function(error) {
            throw error;
        });
    }

    function checkEoriNumber() {
        service.eoriCheck.result = null;
        var url = common.config.apiHosts.generic + 
        '/eori-numbers/check?eori=' + 
        (service.eoriCheck.eoriNumber || '');

        return dataContext.get(url).then(function (result) {
            service.eoriCheck.result = result.Result;
            service.eoriCheck.messages = result.Messages;
        }).catch(function(error) {
            throw error;
        });
    }
}