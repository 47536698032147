module.exports = ['common', 'applicationStateService', 'dataContext', measureTypesDataService];

function measureTypesDataService(common, state, dataContext) {
    var service = {
        measureTypesDictionary: null,
        getMeasureTypesDictionary: getMeasureTypesDictionary,
    };

    function getMeasureTypesDictionary() {

        var deferred = common.$q.defer();
        if (service.measureTypesDictionary) {
            deferred.resolve(service.measureTypesDictionary);
        }
        else {
            var url = common.config.apiHosts[common.config.currentApi] +
                '/measure_types_dictionary' +
                '?lang=' + common.language.get() +
                '&date=' + state.filter.date;
            dataContext.get(url)
                .then(function(measureTypesDictionary) {
                    service.measureTypesDictionary = measureTypesDictionary;
                    deferred.resolve(measureTypesDictionary);
                });
        }
        return deferred.promise;
    }

    return service;

}
