﻿module.exports = ['common', jobStatusDisplayFilter];

function jobStatusDisplayFilter(common) {

    return function (input) {
        if (input === null || input === undefined)
            return null;

        var currentLanguage = common.language.get();

        if (currentLanguage && currentLanguage === 'nl')
            return dutchResult(input);
        return englishResult(input);
    };

    function dutchResult(input) {
        switch (input) {
            case 1:
                return 'Initialiseren';
            case 2:
                return 'Aangemaakt';
            case 3:
                return 'Klaar voor verwerking';
            case 4:
                return 'Wordt verwerkt';
            case 5:
                return 'Voltooid';
            case 6:
                return 'Geannuleerd';
            case 7:
                return 'Klaar voor uitvoer';
            case 8:
                return 'Klaar voor verwerking';
            case 255:
                return 'Fout';
            default:
                return input;
        }
    }

    function englishResult(input) {
        switch (input) {
            case 1:
                return 'Initializing';
            case 2:
                return 'Created';
            case 3:
                return 'Ready to be processed';
            case 4:
                return 'Being processed';
            case 5:
                return 'Completed';
            case 6:
                return 'Cancelled';
            case 7:
                return 'Ready for export';
            case 8:
                return 'Ready to be proecessed';
            case 255:
                return 'Error';
            default:
                return input;
        }
    }
}
