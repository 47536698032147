module.exports = {
    bindings: {
        goodscodes: '<'
    },	
    controller: goodscodeScrollerController,
    templateUrl: 'widgets/components/goodscode-scroller.html'
};

goodscodeScrollerController.$inject = [
    '$element', 
];

function goodscodeScrollerController(
    $element
) {

    var chapterList, headingList, goodsCodeList;
    var vm = {
        $onInit: attached,
        $postLink: link,
        setCurrentChapter: setCurrentChapter,
        setCurrentHeading: setCurrentHeading
    };
    return vm;

    function attached() {
        
        vm.headings = _.chain(vm.goodscodes)
            .map(function(goodscode) {
                return goodscode.substring(0, 4);
            })
            .uniq()
            .value();
        vm.chapters = _.chain(vm.headings)
            .map(function(heading) {
                return heading.substring(0, 2);
            })
            .uniq()
            .value();

        vm.currentChapter = vm.chapters[0];
        vm.currentHeading = vm.headings[0];
    }
    
    function link() {
        chapterList = $element[0].getElementsByClassName('goodscode-scroller-chapter-list')[0];
        headingList = $element[0].getElementsByClassName('goodscode-scroller-heading-list')[0];
        goodsCodeList = $element[0].getElementsByClassName('goodscode-scroller-goodscode-list')[0];
    }

    function setCurrentChapter(chapter, $event) {
        $event.stopPropagation();
        vm.currentChapter = chapter;
        vm.currentHeading = _.find(vm.headings, function(heading) {
            return heading.substring(0, 2) === chapter;
        });

        var headingListItem = headingList.querySelector('li[data-chapter="' + chapter + '"]');
        headingList.scrollTop = headingListItem.offsetTop - 40;

        var goodsCodeListItem = goodsCodeList.querySelector('li[data-chapter="' + chapter + '"]');
        goodsCodeList.scrollTop = goodsCodeListItem.offsetTop - 40;
    }


    function setCurrentHeading(heading, $event) {
        $event.stopPropagation();
        vm.currentHeading = heading;
        var goodsCodeListItem = goodsCodeList.querySelector('li[data-heading="' + heading + '"]');
        goodsCodeList.scrollTop = goodsCodeListItem.offsetTop - 40;
    }
}