module.exports = {
    bindings: { 
        activeBti: '<' 
    },
    controller: btiDetailsController,
    templateUrl: 'bti/components/bti-details.html'
}

btiDetailsController.$inject = [
    '$filter', 
    'btiDataService', 
    'notificationsService'
];

function btiDetailsController(
    $filter, 
    btiDataService, 
    notifications
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        closeImages: closeImages,
        selectImage: selectImage,
        selectedImage: null,
        translateClassificationJustification: true,
        translateGoodsDescription: true
    };
    
    return vm;

    function attached() {
        notifications.subscribe('change_active_bti', onChangeActiveBti);
    }

    function detached() {
        notifications.unsubscribe('change_active_bti', onChangeActiveBti);
    }

    function onChangeActiveBti() {
        var activeBti = btiDataService.data.activeBti;
        closeImages();
        vm.translateClassificationJustification = true;
        vm.translateGoodsDescription = true;
        if (activeBti && activeBti.IsTranslated) {
            
            if (activeBti.TranslatedClassificationJustification) {
                vm.ClassificationJustificationTranslation = {
                    originalText: $filter('addHyperlinks')(activeBti.ClassificationJustification),
                    translatedText: $filter('addHyperlinks')(activeBti.TranslatedClassificationJustification.Result),
                    showTranslation: true
                }
            }
            if (activeBti.TranslatedGoodsDescription) {
                vm.GoodsDescriptionTranslation = {
                    originalText: $filter('addHyperlinks')(activeBti.GoodsDescription),
                    translatedText: $filter('addHyperlinks')(activeBti.TranslatedGoodsDescription.Result),
                    showTranslation: true
                }
            }
        }

        // { originalText: activeBti.GoodsDescription, translatedText: activeBti.TranslatedGoodsDescription.Result}
    }

    function closeImages() {
        vm.selectedImage = null;
    }

    function selectImage(image) {
        vm.selectedImage = image;
    }
}