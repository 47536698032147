module.exports = ['$uibModal', 'common', 'dataContext', englishClassificationRegulationsModal];

function englishClassificationRegulationsModal($uibModal, common, dataContext) {
    var modal = {
        goodsCode: null,
        searchQuery: null,
        show: showModal
    };

    englishClassificationRegulationsModalController.$inject = [
        '$uibModalInstance', 
        'goodsCode', 
        'language', 
        'searchQuery', 
        'applicationStateService', 
        'searchEnglishClassificationRegulationsService'
    ];

    function englishClassificationRegulationsModalController(
        $uibModalInstance, 
        goodsCode, 
        language, 
        searchQuery, 
        state, 
        searchEnglishClassificationRegulationsService
    ) {
        var vm = {
            cancel: cancel,
            data: {
                classificationRegulationsNew: [],
                classificationRegulationsOld: []
            },
            goodsCode: goodsCode,
            isLoading: false,
            language: language.get(),
            load: load,
            referenceCountry: state.filter.referenceCountry,
            searchQuery: searchQuery
        };

        init();

        return vm;


        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function init() {
            load();
        }

        function load() {
            if (vm.language === 'en') {
                vm.isLoading = true;
                common.$q.all([
                    function() { return loadClassificationRegulationsNew() },
                    function() { return loadClassificationRegulationsOld() }
                ])
                .then(function () {
                    vm.isLoading = false;
                });
            }
        }

        function loadClassificationRegulationsNew() {
            var url = common.config.apiHosts.generic + '/classifications/en-after-2013/search?GoodsCode=' + (vm.goodsCode || '');
            if (vm.searchQuery)
                url += '&Query=' + vm.searchQuery;
            return dataContext.get(url).then(function (classificationRegulations) {
                vm.data.classificationRegulationsNew = classificationRegulations;
            });
        }

        function loadClassificationRegulationsOld() {
            var url = common.config.apiHosts.generic + 'classifications/en-before-2014/search?GoodsCode=' + (vm.goodsCode || '');
            if (vm.searchQuery)
                url += '&Query=' + vm.searchQuery;
            return dataContext.get(url).then(function (classificationRegulations) {
                vm.data.classificationRegulationsOld = classificationRegulations;
            });
        }


    }

    function showModal(goodsCode, searchQuery) {

        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: englishClassificationRegulationsModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                goodsCode: function () {
                    return goodsCode;
                },
                searchQuery: function () {
                    return searchQuery;
                }
            },
            size: 'lg',
            templateUrl: 'classification-regulations/modals/classification-regulations-en.html',
            windowClass: 'modal-classification-regulations'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
