﻿module.exports = {
    errorMessages: {
        ErrorEvaluatingCondition: 'Fout bij controleren maatregelvoorwaarde'
    },
    measureConditions: {
        Action: 'Actie douane',
        Amount: 'Hoeveelheid',
        Condition: 'Voorwaarde', // As in measure condition - conditions that must be met for a measure to be applicable and/or import/export to be allowed
        Description: 'Omschrijving',
        DutyRate: 'Tarief',
        noAction: 'geen/positief',
        noCertificate: 'geen'
    },
    measureSeries: {
        AdditionalDuties: 'Aanvullende invoerrechten',
        AntiDumpingMeasures: 'Anti-dumpingmaatregelen',
        Duties: 'Invoerrechten',
        NationalTaxes: 'Nationale belastingen',
        OtherMeasures: 'Andere maatregelen',
        Prohibitions: 'Verboden',
        ReferencePrices: 'Referentieprijzen',
        Restitutions: 'Restituties',
        RestrictionsAndControls: 'Restricties en controles',
        SupplementaryAmounts: 'Aanvullende bedragen',
        SupplementaryUnit: 'Aanvullende eenheid',
        VatRates: 'Btw-tarieven',
    },
    referenceCountries: {
        BE: 'België',
        CH: 'Zwitserland',
        DE: 'Duitsland',
        EU: 'Europese Unie',
        GB: 'Groot-Brittannië',
        IT: 'Italië',
        NL: 'Nederland'
    },
    tradeMovements: {
        All: 'Alle',
        Export: 'Export',
        Import: 'Import'
    },
    unitTypes: {
        Area: 'Oppervlakte',
        Energy: 'Energie',
        GrossWeight: 'Brutogewicht',
        Items: 'Aantal',
        Length: 'Lengte',
        NetWeight: 'Nettogewicht',
        Pairs: 'Aantal paren',
        Power: 'Vermogen',
        Volume: 'Volume',
        Weight: 'Gewicht'
    },
    AddCertificateCode: 'Bescheidcode toevoegen',
    AdditionalCode: 'Aanvullende code',
    AdditionalCodesValidationError: 'Fout in aanvullende codes',
    AdditionalCodeTypeaheadPlaceholder: 'Zoek op aanvullende code of omschrijving',
    AdditionalCodeModalTitle: 'Kies een aanvullende code',
    AdditionalCodes: 'Aanvullende codes',
    NoAdditionalCode: 'Geen aanvullende code',
    NoAdditionalCodesFound: 'Geen aanvullende codes gevonden',
    AgriculturalComponents: 'Agrarisch element',
    MeursingAdditionalCodePlaceHolder: 'Zoek aanvullende code',
    AutonomousTariffSuspensionsMessage: 'Tip: maak gebruik van de autonome tariefschorsing die voor deze goederencode geldt',
    CalculateDuty: 'Bereken rechten',
    CalculateDutyAndTaxes: 'Bereken rechten',
    Certificate: 'Bescheid',
    CertificateCodeTypeaheadPlaceholder: 'Zoek op bescheidcode of omschrijving',
    CertificateCodeModalTitle: 'Kies een bescheidcode',
    CertificateCodes: 'Bescheidcodes',
    CheckMeasureConditions: 'Controleer maatregelvoorwaarden',
    Condition: 'Voorwaarde',
    consumptionTaxWarning: 'Waarschuwing: verbruiksbelasting kan van toepassing zijn. U moet dan een nationale aanvullende code invullen. Hieronder kunt u zoeken op nationale aanvullende code.',
    CountryOfDestination: 'Land van bestemming',
    CountryOfOrigin: 'Land van oorsprong',
    CountryOrRegion: 'Land of regio',
    CustomsValue: 'Douanewaarde',
    Declaration: 'Aangifte',
    DeclarationCountry: 'Land aangifte',
    DeclarationDate: 'Datum aangifte',
    Destination: 'Bestemming', // As in 'Destination country'
    DutyCalculator: 'Bereken rechten',
    DutyCalculationErrors: {
        DutyMeasureNotUniqueForPreferenceCode: 'Meerdere maatregelen gevonden voor preferentiecode. Kies voor bijzondere bestemming of niet.',
        Header: 'Fout bij berekenen van rechten en belastingen',
        InvalidOrMissingQuantity: 'Foute of missende hoeveelheid',
        UnsupportedDutyExpression: 'Eén van de maatregelen voor deze berekening bevat een tariefsuitdrukking die niet ondersteund wordt.'
    },
    DutyCalculationResult: 'Resultaat berekening rechten en belastingen',
    DutyMeasureType: 'Type invoerrecht',
    EuLegislation: 'Wetgeving EU',
    excl: 'behalve',
    Excises: 'Accijnzen',
    exportRefundNomenclature: 'Restitutie-nomenclatuur',
    ExportMeasures: 'Maatregelen uitvoer',
    Footnote: 'Voetnoot',
    Footnotes: 'Voetnoten',
    GeographicalArea: 'Geografische regio',
    GeographicalAreas: 'Geografische regio\'s',
    ImportMeasures: 'Maatregelen invoer',
    InformationForOrderNumber: 'Informatie voor ordernummer',
    Legislation: 'Wetgeving',
    LegislationFirstLetter: 'W',
    Measure: 'Maatregel',
    Measures: 'Maatregelen',
    MeasuresForExportFrom: 'Maatregelen voor uitvoer vanuit',
    MeasuresForImportInto: 'Maatregelen voor invoer naar',
    MeasuresValidOn: 'Maatregelen geldig op',
    MeasureTypeTypeaheadPlaceholder: 'Kies maatregeltype',
    MeasureTypeModalTitle: 'Kies maatregeltype',
    NationalAdditionalCode: 'Nationale aanvullende code',
    noCertificatesFound: 'Geen bescheidcodes gevonden',
    noFootnotesFound: 'Geen voetnoten gevonden',
    noMeasuresFound: 'Geen maatregelen gevonden',
    noResultsForCommodityCodesInPeriod: 'Geen resultaten voor de gekozen goederencodes in het gekozen tijdvak',
    OptionalNationalAdditionalCode: 'Optionele nationale aanvullende code',
    Origin: 'Oorsprong', // As in 'Country of Origin'
    OtherChoices: 'Overige keuzes',
    PreferenceCode: 'Preferentiecode',
    PreferenceCodeDisplayLabel: 'Weergave preferentiecodes',
    PreferenceCodeDisplayLabelPreUcc: 'Vóór UCC',
    PreferenceCodeDisplayLabelUcc: 'DMS - UCC',
    PreferenceCodeTypeaheadPlaceholder: 'Zoek op preferentiecode of op omschrijving',
    PreferenceCodeModalTitle: 'Kies een preferentiecode',
    EndUseMeasureIsAllowedWithProcedureCode44: 'De maatregel bijzondere bestemming is toegestaan met douaneregeling 44 "Bijzondere bestemming"',
    PreferredDutyTreatment: 'Keuze invoerrecht',
    Quantity: 'Hoeveelheid',
    QuotaOrderNumber: 'Ordernummer contingent',
    QuotaOrderNumberTypeaheadPlaceholder: 'Vul ordernummer tariefcontingent in',
    ReferenceLists: 'Referentielijsten',
    Regions: 'Regio\'s',
    RetailPrice: 'Kleinhandelsprijs',
    SearchByCodeOrDescription: 'Zoek op code of omschrijving',        
    ShowQuotaInformation: 'Toon informatie over tariefcontingent',
    Taxes: 'Belastingen',
    ValidateDeclaration: 'Valideer aangifte',
    Value: 'Waarde',
    VatAndExciseEtcetera: 'Omzetbelasting, accijnzen, voorraadheffing en verbruiksbelasting',
    VAT: 'Omzetbelasting'
};
