﻿angular.module('taric.dashboard', [])
    .config(['$routeProvider', routeConfigurator])
    .component('landingPage', require('./components/landing-page'))
    .factory('firstLoginModal', require('./modals/first-login'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/landing', {
            template: '<landing-page></landing-page>'
        })
        .when('/dashboard', {
            template: '<landing-page></landing-page>'
        })
        ;
}
