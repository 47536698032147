module.exports = {
    bindings: {
        currentCode: '<',
        nomenclature: '<',
        onClickRow: '&',
        validation: '<' 
    },
    controller: eztNomenclatureController,
    templateUrl: 'ezt/components/ezt-nomenclature.html'
};

function eztNomenclatureController() {
    var vm = {
        clickEztNomenclatureLine: clickEztNomenclatureLine
    }

    return vm;

    function clickEztNomenclatureLine(line) {
        if (line.ProdLin === '80' && vm.onClickRow) {
            vm.onClickRow({ commodityCode: line.CommodityCode });
        }
    }
}