module.exports = [
    'common', 
    'dataContext', 
    'applicationStateService',
    'countryDataService',
    taresTariffDataService
];

function taresTariffDataService(
    common, 
    dataContext,
    state,
    countryDataService
) {
    var service = {
        loadTariffData: loadTariffData,
        filterTariffDataByCustomsReliefCode: filterTariffDataByCustomsReliefCode,
        filterTariffDataByCountry: filterTariffDataByCountry,
        filterTariffDataByTradeMovement: filterTariffDataByTradeMovement
    };
    return service;

    function loadTariffData(commodityCode) {
        var url = common.config.apiHosts[common.config.currentApi] + '/tares-measures/' + commodityCode +
            '?movement=' + state.filter.movement +
            '&lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }

        return dataContext.get(url);
    }

    function filterTariffDataByCustomsReliefCode(tariffMasterData, customsReliefCode) {
        tariffMasterData.Result.DutyRates = _.map(tariffMasterData.Result.DutyRates, function(dutyRate) {
            dutyRate.isValidForCustomsReliefCode = (dutyRate.CustomsFavourCode === customsReliefCode);
            return dutyRate;
        });
    }

    function filterTariffDataByCountry(tariffMasterData, country) {
        if (!tariffMasterData) {
            return tariffMasterData;
        }
        tariffMasterData.Result.DutyRates = _.map(tariffMasterData.Result.DutyRates, function (dutyRate) {
            dutyRate.isValidForCountry = dutyRateIsValidForCountry(dutyRate, country);
            return dutyRate;
        });
        tariffMasterData.Result.StatisticalCodes = _.map(tariffMasterData.Result.StatisticalCodes, function(statisticalCode) {
            statisticalCode.AdditionalTaxes = _.map(statisticalCode.AdditionalTaxes, function (measure) {
                measure.isValidForCountry = measureIsValidForCountry(measure, country);
                return measure;
            });
            statisticalCode.HasAdditionalTaxesForCountry = _.some(statisticalCode.AdditionalTaxes, function(measure) {
                return measure.isValidForCountry;
            });
            statisticalCode.NonCustomsLaws = _.map(statisticalCode.NonCustomsLaws, function (measure) {
                measure.isValidForCountry = measureIsValidForCountry(measure, country);
                return measure;
            });
            statisticalCode.HasNonCustomsLawsForCountry = _.some(statisticalCode.NonCustomsLaws, function(measure) {
                return measure.isValidForCountry;
            });
            statisticalCode.Permits = _.map(statisticalCode.Permits, function (measure) {
                measure.isValidForCountry = measureIsValidForCountry(measure, country);
                return measure;
            });
            statisticalCode.HasPermitsForCountry = _.some(statisticalCode.Permits, function(measure) {
                return measure.isValidForCountry;
            });
            return statisticalCode;
        });
    }

    function filterTariffDataByTradeMovement(tariffMasterData, tradeMovement) {
        if (!tariffMasterData) {
            return tariffMasterData;
        }
        if (tradeMovement === 'import') {
            tariffMasterData.Result.StatisticalCodes = _.filter(tariffMasterData.Result.StatisticalCodes, function(statisticalCode) {
                return statisticalCode.ValidForImport;
            });
            return tariffMasterData;
        }
        else if (tradeMovement === 'export') {
            tariffMasterData.Result.StatisticalCodes = _.filter(tariffMasterData.Result.StatisticalCodes, function(statisticalCode) {
                return statisticalCode.ValidForExport;
            });
            tariffMasterData.Result.TareSupplements = [];
            tariffMasterData.Result.CustomsReliefCodes = [];
            tariffMasterData.Result.CustomsFavourHintCodes = [];
            tariffMasterData.Result.DutyRates = [];
            tariffMasterData.Result.VatRates = [];
            return tariffMasterData;
        }
        return tariffMasterData;
    }

    function dutyRateIsValidForCountry(dutyRate, country) {
        if (!country) {
            return true;
        }
        if (!dutyRate.CountryGroupNumber) {
            return true;
        }
        return _.some(countryDataService.regions[dutyRate.CountryGroupNumber], function(countryInRegion) {
            return countryInRegion === country;
        });
    }

    function measureIsValidForCountry(measure, country) {
        if (!country) {
            return true;
        }
        var INCLUDE = 0;
        var EXCLUDE = 1;
        var countryGroupsForCountry = _.filter(measure.CountryGroupAssignment, function(countryGroup) {
            return _.some(countryDataService.regions[countryGroup.CountryGroupId], function(countryInRegion) {
                return countryInRegion === country;
            });
        });
        var countryIsIncluded = _.some(countryGroupsForCountry, function(countryGroup) {
            return countryGroup.AssignmentType == INCLUDE;
        });

        var countryIsExcluded = _.some(countryGroupsForCountry, function(countryGroup) {
            return countryGroup.AssignmentType == EXCLUDE;
        });
        
        return countryIsIncluded && !countryIsExcluded;
    }
}