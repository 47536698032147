module.exports = {
    bindings: { 
        placeholder: '<', 
        preferenceCode: '<', 
        possiblePreferenceCodes: '< ', 
        selectionChanged: '&' 
    },
    controller: preferenceCodePickerController,
    templateUrl: 'preference-codes/components/preference-code-picker.html'
};

preferenceCodePickerController.$inject = ['preferenceCodeSelectorModal'];

function preferenceCodePickerController(preferenceCodeSelectorModal) {
    var vm = {
        clear: clear,
        preferenceCode: null,
        userInput: null,
        possiblePreferenceCodes: null,
        itemSelected: itemSelected,
        onBlur: onBlur,
        onEnter: onEnter,
        showModal: showModal
    };

    return vm;

    function showModal() {
        preferenceCodeSelectorModal
            .show(vm.possiblePreferenceCodes, vm.preferenceCode)
            .result
            .then(itemSelected)
            .catch(function (res) {
                if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                    throw res;
                }
            });
    }

    function clear(ev) {
        vm.userInput = null;
        clearSelection();
    }

    function clearSelection() {
        vm.preferenceCode = null;
        if (vm.selectionChanged)
            vm.selectionChanged({ preferenceCode: null });

    }

    function onBlur() {
    }

    function onEnter() {
    }

    function itemSelected(item, model) {
        vm.preferenceCode = item;

        if (vm.selectionChanged) {
            vm.selectionChanged({ preferenceCode: item });
        }
    }


}
