module.exports = {
    bindings: {},
    controller: loginPageController,
    templateUrl: 'account/components/login-page.html'
};

loginPageController.$inject = [
    '$analytics', 
    '$location', 
    'userAccountService', 
    'preloaderService',
];

function loginPageController(
    $analytics, 
    $location, 
    userAccount, 
    preloader
) {

    var vm = {
        $onInit: attached,
        $postLink: focusInputField,
        login: login,
        model: {
            password: null,
            rememberMe: true,
            username: null
        },
        navigateTo: navigateTo,
        status: {
            error: null,
            loading: false
        }
    };

    function attached() {
        $analytics.pageTrack('/login');
    }

    function focusInputField() {
        document.getElementById('login-username').focus();
    }

    function login() {
        vm.status.loading = true;
        userAccount.login(vm.model, vm.model.rememberMe)
            .then(loginSuccess, loginError);
        
        var nextUrl = '/landing';
        var q = $location.search();
        if (q.redirect) {
            nextUrl = q.redirect;
        }
        function loginSuccess(data, status, headers) {
            preloader.run()
                .then(function() {
                    vm.status.loading = false;
                    $location.url(nextUrl);
                }, function() {
                    vm.status.loading = false;
                    $location.url(nextUrl);
                });
        }

        function loginError(error, status) {
            vm.status.loading = false;
            vm.status.error = error;
        }

    }

    function navigateTo(path) {
        $location.url(path);
    }

    return vm;
}