﻿module.exports = ['$http', '$q', 'config', 'identity', 'language', dataContextService];

function dataContextService($http, $q, config, identity, language) {
    var service = {
        del: function (url) { return requestWithoutData('DELETE', url); },
        get: function (url, id, headers) { return requestWithoutData('GET', url, id, headers); },
        post: function (url, parameters) { return requestWithData('POST', url, parameters); },
        put: function (url, parameters) { return requestWithData('PUT', url, parameters); },
        xhr: function (url, parameters) { return requestXhrWithData('POST', url, parameters); },
        cancel: cancel,
        formEncode: formEncode,
        getHeaders: getHeaders,
        pendingRequests: {}
    };

    function cancel(requestId) {
        if (service.pendingRequests[requestId]) {
            // console.log('Canceling request ' + requestId + ' with url ' + service.pendingRequests[requestId].url);
            service.pendingRequests[requestId].execute();
        }
    }

    function formEncode(obj) {
        var encodedString = '';
        for (var key in obj) {
            if (encodedString.length !== 0) {
                encodedString += '&';
            }

            encodedString += key + '=' + encodeURIComponent(obj[key]);
        }
        return encodedString.replace(/%20/g, '+');
    }

    // NB: request id indicates the type of request, like 'CHAPTER' or 'GOOD', for example
    function requestWithoutData(method, url, requestId, headers) {
        var canceller = $q.defer();
        var deferred = $q.defer();
        url = processUrl(url);

        var requestMetadata = {
            execute: function() {
                canceller.resolve({ isAborted: true });
            },
            deferred: deferred,
            url: url,
        };

        if (requestId) {
            if (service.pendingRequests[requestId]) {
                if (url === service.pendingRequests[requestId].url) {
                    return service.pendingRequests[requestId].deferred.promise;
                }
                cancel(requestId);
            }

            requestMetadata.requestId = requestId;
            service.pendingRequests[requestId] = requestMetadata;
        }

        $http({ method: method, url: url, headers: getHeaders(headers), timeout: canceller.promise })
            .then(
                function(data) { 
                    requestWithoutDataSuccessCallback(data, requestMetadata); }, 
                function(data) {                     
                    requestWithoutDataErrorCallback(data, requestMetadata); });

        return deferred.promise;
    }

    function requestWithoutDataSuccessCallback(data, requestMetadata) {
        requestMetadata.deferred.resolve(data.data);
        if (requestMetadata.requestId && service.pendingRequests[requestMetadata.requestId]) {
            delete service.pendingRequests[requestMetadata.requestId];
        }
    }
    
    function requestWithoutDataErrorCallback(error, requestMetadata) {
        if (error && error.config && error.config.timeout && error.config.timeout.$$state && error.config.timeout.$$state.value && error.config.timeout.$$state.value.isAborted) {
            error.isAborted = true;
        }
        else if (error && error.status <= 0) {
            error.isAborted = true;
        }
        else {
            try {
                Sentry.configureScope(function (scope) {
                    scope.setTag("last-api-error-status", error.status);
                    scope.setTag("last-api-error-url", error.config.url);
                });
            }
            catch (e) {}
        }
        requestMetadata.deferred.reject(error);
        if (requestMetadata.requestId && service.pendingRequests[requestMetadata.requestId]) {
            delete service.pendingRequests[requestMetadata.requestId];
        }
    }

    function requestWithData(method, url, parameters) {
        var deferred = $q.defer();
        var inputData = JSON.stringify(parameters);
        url = processUrl(url);

        var config = { method: method, url: url, headers: getHeaders(), data: inputData, dataType: 'json' };

        $http(config)
            .then(successCallback, errorCallback);

        function successCallback(data, status, headers, cfg) { deferred.resolve(data.data); }
        function errorCallback(error) {
            try {
                Sentry.configureScope(function (scope) {
                    scope.setTag("last-api-error-status", error.status);
                    scope.setTag("last-api-error-url", error.config.url);
                });
            }
            catch (e) {}
            deferred.reject(error);
        }

        return deferred.promise;
    }

    function requestXhrWithData(method, url, parameters) {
        var deferred = $q.defer();
        url = processUrl(url);

        var headers = getHeaders();
        headers["Content-Type"] = undefined;

        var config = { method: method, url: url, headers: headers, data: parameters };
        config.transformRequest = _.identity;

        $http(config)
            .then(successCallback, errorCallback, progressCallback);

        function successCallback(data, status, headers, cfg) { deferred.resolve(data.data); }
        function errorCallback(error) {
            try {
                Sentry.configureScope(function (scope) {
                    scope.setTag("last-api-error-status", error.status);
                    scope.setTag("last-api-error-url", error.config.url);
                });
            }
            catch (e) {}
            deferred.reject(error);
        }
        function progressCallback(progress) { deferred.notify(progress); }

        return deferred.promise;
    }

    // we have to include the Bearer token with each call to the Web API controllers.
    function getHeaders(headers) {
        if (!headers) {
            headers = {};
        }
        if (identity.token && identity.token.tokenValue) {
            headers["Authorization"] = "Bearer " + identity.token.tokenValue;
        }
        return headers;
    }

    function processUrl(url) {
        if (url === null || url === '')
            return url;
        // if (url.toLowerCase().indexOf('http://') !== 0 && url.toLowerCase().indexOf('https://') !== 0)
        //     url = config.apiHost + url;

        if (url.indexOf('?') === -1) {
            url += '?lang=' + language.get();
        }
        else {
            url += '&lang=' + language.get();
        }
        return url;
    }

    return service;

}
