angular.module('taric.regulations', [])
    .config(['$routeProvider', routeConfigurator])
    .component('regulationsNewsPage', require('./components/regulations-news-page'))
    .component('regulationDetailsPage', require('./components/regulation-details-page'))
    .component('regulation', require('./components/regulation'))
    .component('regulationButton', require('./components/regulation-button'))
    .component('regulationsButton', require('./components/regulations-button'))
    .component('regulationsNews', require('./components/regulations-news'))
    .factory('regulationModal', require('./modals/regulation'))
    .factory('regulationsModal', require('./modals/regulations'))
    .factory('regulationsDataService', require('./services/regulations-data'))
    .filter('celexToRegulationNumber', require('./filters/celex-to-regulation-number'));

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/regulations/news', {
            template: '<regulations-news-page></regulations-news-page>'
        })
        .when('/regulation-details/:celex', {
            template: '<regulation-details-page></regulation-details-page>'
        })
        ;
}
