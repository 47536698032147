﻿module.exports = ['common', 'applicationStateService', 'dataContext', quotaDataService];

function quotaDataService(common, state, context) {

    var service = {
        getOrderDetails: getOrderDetails
    };

    function getOrderDetails(orderNumber) {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts[common.config.currentApi] + '/actions/quota/details' +
            '?ordernumber=' + orderNumber +
            '&date=' + state.filter.date;

        context.get(url)
            .then(deferred.resolve, deferred.reject);

        return deferred.promise;
    }

    return service;
}