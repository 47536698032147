module.exports = ['$analytics', '$uibModal', measureTypeSelectorModal];

function measureTypeSelectorModal($analytics, $uibModal) {
    var modal = {
        show: showModal
    };

    measureTypeSelectorModalController.$inject = ['$uibModalInstance', 'possibleMeasureTypes', 'measureType'];

    function measureTypeSelectorModalController($uibModalInstance, possibleMeasureTypes, measureType) {
        var vm = {
            measureType: measureType,
            cancel: cancel,
            nationalMeasureTypes: [],
            possibleMeasureTypes: possibleMeasureTypes,
            ok: ok
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function ok(code) {
            $uibModalInstance.close(code);
        }

        return vm;
    }

    function showModal(possibleMeasureTypes, measureType) {
        $analytics.eventTrack('open-modal-measure-type-selector', { category: 'measure-types' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: measureTypeSelectorModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                measureType: function () {
                    return measureType;
                },
                possibleMeasureTypes: function () {
                    return possibleMeasureTypes;
                }
            },
            size: 'lg',
            templateUrl: 'measure-types/modals/measure-type-selector.html',
            windowClass: 'modal-measure-type-selector'
        });
    }

    return modal;
}
