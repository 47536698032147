module.exports = {
    bindings: { 
        searchHandler: '&', 
        legend: '@' 
    },
    controller: searchFormController,
    templateUrl: 'search/components/search-form.html'
};

searchFormController.$inject = [
    '$location',
    'notificationsService', 
    'language'
];

function searchFormController(
    $location, 
    notificationsService, 
    language
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        language: language.get(),
        query: {
            query: $location.search().query
        },
        validationResult: {},
        search: search
    };

    return vm;

    function attached() {
        notificationsService.subscribe('language_changed', languageChanged);
    }

    function detached() {
        notificationsService.unsubscribe('language_changed', languageChanged);
    }

    function languageChanged() {
        $location.search('query', null).replace();
    }

    function validateForm(query) {
        var result = {
            isValid: true,
            errors: {}
        };
        if (!query.query) {
            result.isValid = false;
            result.query = 'QueryIsRequired';
        }
        
        if (query.query) {
            if (query.query.length < 2) {
                result.isValid = false;
                result.query = 'QueryIsTooShort';
            }
        }
        return result;
    }

    function search(query) {
        vm.validationResult = validateForm(query);
        if (vm.validationResult.isValid) {
            // Note that the search handlers are currently configured to receive only a query string
            vm.searchHandler({ query: query });
        }

    }
}