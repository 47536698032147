module.exports = [toFixedFilter];

function toFixedFilter() {
    return function (input, decimals) {
        try {
            return input.toFixed(decimals);
        }
        catch (ex) {
            return input;
        }
    };
}