module.exports = {
    ExportValue: 'Waarde export',
    ExportWeight: 'Gewicht export',
    ExportValuePerKg: 'Waarde export per kg',
    ImportValue: 'Waarde import',
    ImportWeight: 'Gewicht import',
    ImportValuePerKg: 'Waarde import per kg',
    MoreYearsComingSoon: 'Meer jaren volgen binnenkort',
    NoDataAvailable: 'Geen data beschikbaar',
    ReportingCountry: 'Rapporterend land',
    StatisticsForImportFrom: 'Statistieken voor import uit',
    StatisticsForImportInto: 'Statistieken voor import in',

    StatisticsForImportFrom_CountryName_In_Year_: 'Statistieken voor import uit {{ CountryName }} in {{ Year }}',
    StatisticsForImportInto_CountryName_In_Year_: 'Statistieken voor import in {{ CountryName }} in {{ Year }}',
    StatisticsForImportIntoTheEU_In_Year_: 'Statistieken voor import in de EU in {{ Year }}',

    theEU: 'de EU',
    TradePartner: 'Handelspartner',
    TradeStatistics: 'Handelsstatistieken',
    Year: 'Jaar'
};
