angular.module('taric.tradeStatistics', [])
    .config(['$routeProvider', routeConfigurator])
    .component('tradeStatisticsTable', require('./components/trade-statistics-table'))
    .component('tradeStatisticsPage', require('./components/trade-statistics-page'))
    .factory('tradeStatisticsDataService', require('./services/trade-statistics-data'));

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/trade-statistics', {
            template: '<trade-statistics-page></trade-statistics-page>'
        })
        ;
}
