﻿module.exports = ['common', 'dataContext', 'applicationStateService', footnoteDataService];

function footnoteDataService(common, context, state) {

    var service = {
        getFootnote: getFootnote,
        getFootnotes: getFootnotes,
        getTypes: getTypes
    };

    function getFootnote(footnoteType, footnoteCode) {

        var url = common.config.apiHosts[common.config.currentApi] + '/footnotes/' + footnoteType + '/' + footnoteCode +
            '?lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    function getFootnotes(footnoteType) {

        var url = common.config.apiHosts[common.config.currentApi] + '/footnotes' +
            '?type=' + footnoteType +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    function getTypes() {

        var url = common.config.apiHosts[common.config.currentApi] + '/footnotes/types' +
            '?origin=EU,' + state.filter.referenceCountry +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    return service;

}
