﻿module.exports = {
    bindings: {
        measure: '<'
    },
    controller: financialMeasureController,
    templateUrl: 'good/components/financial-measure.html'
};

financialMeasureController.$inject = ['$window', 'applicationStateService'];

function financialMeasureController($window, state) {
    var vm = {
        state: state,
        toggle: function(measure) {
            var textIsSelected = $window.getSelection().toString().length > 0;
            if (measure.isExpandable && !textIsSelected) {
                measure.expanded = !measure.expanded;
            }
        }
    }
    return vm;
}