module.exports = {
    controller: searchAnnotationsController,
    templateUrl: 'search/components/pages/search-annotations.html'
};

searchAnnotationsController.$inject = [
    '$analytics', 
    '$location',
    'notificationsService', 
    'annotationsDataService'
];

function searchAnnotationsController(
    $analytics, 
    $location,
    notificationsService, 
    annotationsDataService
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        isLoading: false,
        query: {
            QueryString: ''
        },
        results: null,
        scrollToDocument: scrollToDocument,
        search: search
    };

    function attached() {
        vm.query.QueryString = $location.search().query;
        $analytics.pageTrack('/section-and-chapter-notes-search');
        loadSearch();
        notificationsService.subscribe('filter_date_changed', loadSearch);
    }

    function detached() {
        notificationsService.unsubscribe('filter_date_changed', loadSearch);
    }

    function scrollToDocument(result) {
        document
            .querySelector('.EU-Note-' + result.NomenclatureLevel + '-' + result.GoodsCodeOrSectionNumber)
            .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return vm;

    function search(query) {
        $location.path('/search/annotations').search(query);
    }

    function loadSearch() {
        if (vm.query.QueryString && vm.query.QueryString.length > 1) {
            vm.isLoading = true;
            annotationsDataService.searchNotes(vm.query.QueryString)
                .then(function(data) {
                    vm.isLoading = false;
                    vm.results = data;
                });
        }
    }
}