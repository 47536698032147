module.exports = {
    controller: changePasswordPageController,
    templateUrl: 'account/components/change-password-page.html'
}

changePasswordPageController.$inject = [
    '$analytics', 
    '$translate', 
    'userAccountService',
];

function changePasswordPageController(
    $analytics, 
    $translate, 
    userAccountService
) {
    var vm = {
        $onInit: attached,
        $postLink: focusInputField,
        changePassword: changePassword,
        checkStrength: checkStrength,
        model: {
            currentPassword: null,
            newPassword1: null,
            newPassword2: null,
            checks: {
                characters: false,
                lowercase: false,
                uppercase: false,
                nonalpha: false,
                number: false
            },
            passwordChangedSuccesfully: false,
            errorChangingPassword: false
        },
        status: {
            errors: [],
            translations: []
        }
    };

    function attached() {
        $analytics.pageTrack('/change-password');
    }

    function focusInputField() {
        document.getElementById('currentPasswordInput').focus();
    }

    function changePassword() {
        var validation = validate();
        vm.status.errors = [];

        if (validation && validation.length > 0) {
            vm.status.errors = validation;
            return;
        }

        userAccountService.changePassword(vm.model.currentPassword, vm.model.newPassword1)
            .then(function (result) {
                if (result.IsSuccessful) {
                    vm.model.passwordChangedSuccesfully = true;
                } else {
                    for (var i = 0; i < result.Codes.length; i++) {
                        result.Codes[i] = 'account.resetPassword.errors.dynamic.' + result.Codes[i];
                    }
                    vm.status.errors = result.Codes;
                }
            })
            .catch(function (error) {
                if (error && error.data && error.data.Message) {
                    vm.status.errors = [error.data.Message];
                } else {
                    vm.status.errors = [vm.status.translations['account.changePassword.errorChangingPassword']];
                }
            });
    }

    function checkStrength() {
        if (!vm.model.newPassword1) {
            vm.model.checks.characters = false;
            vm.model.checks.lowercase = false;
            vm.model.checks.nonalpha = false;
            vm.model.checks.number = false;
            vm.model.checks.uppercase = false;
        }

        vm.model.checks.characters = vm.model.newPassword1.length >= 8;
        vm.model.checks.lowercase = /[a-z]/.exec(vm.model.newPassword1) !== null;
        vm.model.checks.nonalpha = /\W/.exec(vm.model.newPassword1) !== null;
        vm.model.checks.number = /\d/.exec(vm.model.newPassword1) !== null;
        vm.model.checks.uppercase = /[A-Z]/.exec(vm.model.newPassword1) !== null;
    }

    function loadTranslations() {
        $translate([
            'account.changePassword.errorChangingPassword',
            'account.changePassword.errorCurrentPassword',
            'account.changePassword.errorNewPasswordEmpty',
            'account.changePassword.errorNewPasswordCompare'
        ]).then(function (translations) {
            vm.status.translations = translations;
        });
    }

    function validate() {
        var errors = [];

        if (!vm.model.currentPassword || vm.model.currentPassword === '')
            errors.push(vm.status.translations['account.changePassword.errorCurrentPassword']);

        if (!vm.model.newPassword1 || vm.model.newPassword1 === '')
            errors.push(vm.status.translations['account.changePassword.errorNewPasswordEmpty']);
        else if (vm.model.newPassword1 !== vm.model.newPassword2)
            errors.push(vm.status.translations['account.changePassword.errorNewPasswordCompare']);

        return errors.length > 0 ? errors : null;
    }

    loadTranslations();

    return vm;
}
