module.exports = {
    controller: searchEnglishClassificationRegulationsController,
    templateUrl: 'classification-regulations/components/search-classification-regulations-english-page.html'
};

searchEnglishClassificationRegulationsController.$inject = [
    '$analytics', 
    '$location', 
    'language', 
    'searchEnglishClassificationRegulationsService',
];

function searchEnglishClassificationRegulationsController(
    $analytics, 
    $location, 
    language, 
    searchEnglishClassificationRegulationsService
) {

    var vm = {
        $onInit: attached,
        data: searchEnglishClassificationRegulationsService.data,
        isLoading: false,
        language: language.get(),
        noResults: noResults,
        parsedQuery: null,
        search: search
    };

    return vm;

    function attached() {
        // TODO: parse properly
        vm.query = $location.search().query;
        vm.code = $location.search().commodityCode;
        $analytics.pageTrack('/classification-regulations-search');
        loadSearch();
    }

    function search(query) {
        $location.path('/classification-regulations/en/search').search(query);
    }

    function noResults() {
        return vm.language === 'en'
            && !vm.isLoading
            && vm.data.classificationRegulationsOld.length === 0
            && vm.data.classificationRegulationsNew.length === 0
            && queryIsValid();
    }

    function queryIsValid() {
        return vm.query && vm.query.length >= 2;
    }


    function loadSearch() {

        if (queryIsValid()) {

            if (vm.language === 'en') {
                vm.isLoading = true;
                searchEnglishClassificationRegulationsService.load(vm.code, vm.query)
                    .then(function () {
                        vm.isLoading = false;
                    })
                    .catch(function(){
                        vm.isLoading = false;
                    });
            }
            else if (vm.language === 'nl') {
                $location.path('/classification-regulations/nl/search').replace();
            }
        }
    }
}