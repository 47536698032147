﻿module.exports = {
    buttons: {
        Cancel: 'Cancel',
        Close: 'Close',	
        CollapseAll: 'Collapse All', // As in 'collapsible part of a web page
        Delete: 'Delete',
        Download: 'Download',
        DownloadPDF: 'Download PDF',
        EmptyForm: 'Empty Form',
        ExpandAll: 'Expand All', // As in 'expand collapsible part of a web page'
        No: 'No',
        OK: 'OK',
        OpenPDF: 'Open PDF',
        Print: 'Print',	
        Save: 'Save',
        Search: 'Search',
        Yes: 'Yes'
    },
    calendar: {
        Clear: 'Clear',
        OK: 'OK',
        Today: 'Today'
    },
    placeholders: {
        EnterCommodityCode: 'Enter Commodity Code',
        Search: 'Search',
        SearchByCountryNameOrCode: 'Search by country name or code',
        SearchInText: 'Search in Text',
    },
    translation: {
        ShowOriginal: 'Show Original',
        Translate: 'Translate',
    },
    areYouSureModalTitle: 'Are you sure?',
    
    Ascending: 'Ascending', // as in direction of sort order
    Descending: 'Descending', // as in direction of sort order
    
    Country: 'Country',
    Date: 'Date',
    DateFrom: 'Date From',
    DateTo: 'Date To',
    Description: 'Description',
    favorite: 'Favorite',
    favorites: 'Favorites',
    _count_found: '{{ count }} found', // NB: count is a number
    Images: 'Images', // as in 'pictures, show as images'
    List: 'List', // as in 'show as a list' (as opposed to 'show as images')
    Loading: 'Loading',
    MoreNews: 'More News',
    News: 'News',
    No: 'No',
    NoResults: 'No Results',
    NoWcoAccessMessage: 'For access to the WCO Explanatory Notes and Classification Opinions a separate subscription is necessary. Please contact us for more information',
    OrderBy: 'Order By',
    OrderDirection: 'Direction',
    Query: 'Query',
    Remember: 'Remember', // as in 'remember my choice'
    SearchTerm: 'Search Term',
    ShowResultsAs: 'Show Results As', // show as images or list
    Source: 'Source', // As in 'Source of the information'
    TextSearch: 'Search in Text',
    Total: 'Total', // as in 'total amount of the calculation'
    
    ValidityPeriod: 'Validity Period',
    ValidFrom: 'Valid From',
    ValidTo: 'Valid Until',
    ValidityStartDate: 'Start Date of Validity',
    ValidityEndDate: 'End Date of Validity',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    
    Yes: 'Yes'
};
