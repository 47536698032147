module.exports = ['$q', 'dataContext', 'config', 'language', 'applicationStateService', searchEnglishClassificationRegulations];

function searchEnglishClassificationRegulations($q, dataContext, config, language, state) {
    var service = {
        data: {
            classificationRegulationsNew: [],
            classificationRegulationsOld: []
        },
        load: loadClassificationRegulations
    };
    return service;

    function loadClassificationRegulations(goodsCode, searchQuery) {
        return $q.all([
            loadClassificationRegulationsNew(goodsCode, searchQuery),
            loadClassificationRegulationsOld(goodsCode, searchQuery)
        ]);
    }

    function loadClassificationRegulationsNew(goodsCode, searchQuery) {
        service.data.classificationRegulationsNew = [];
        var url = config.apiHosts.generic + '/classifications/en-after-2013/search?GoodsCode=' + (goodsCode || '') +
            '&date=' + state.filter.date +
            '&lang=' + language.get();
        if (searchQuery)
            url += '&Query=' + searchQuery;
        return dataContext.get(url).then(function (classificationRegulations) {
            service.data.classificationRegulationsNew = classificationRegulations;
        });
    }

    function loadClassificationRegulationsOld(goodsCode, searchQuery) {
        service.data.classificationRegulationsOld = [];
        var url = config.apiHosts.generic + '/classifications/en-before-2014/search?GoodsCode=' + (goodsCode || '') +
            '&date=' + state.filter.date +
            '&lang=' + language.get();
        if (searchQuery)
            url += '&Query=' + searchQuery;
        return dataContext.get(url).then(function (classificationRegulations) {
            service.data.classificationRegulationsOld = classificationRegulations;
        });
    }
}