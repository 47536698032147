﻿module.exports = {
    bindings: {
        recentCode: '<'
    },
    controller: recentCodeButtonController,
    templateUrl: 'widgets/components/recent-code-button.html'
};

recentCodeButtonController.$inject = [
    '$location', 
    'applicationStateService', 
    'preferenceService',
];

function recentCodeButtonController(
    $location, 
    state, 
    preferences
) {
    
    var vm = {
        allDone: false,
        $onInit: attached,
        country: null,
        formDisplay: formDisplay,
        goodsnom: null,
        showRecentCode: showRecentCode,
        source: null
    };

    function attached() {
        formDisplay();
    }

    function showRecentCode() {
        var movement = vm.source || 'import';
        state.filter.movement = movement;
        preferences.replace({
            name: 'movement',
            category: 'user_preferences',
            value: movement,
            valueType: 'stringValue'
        });
        $location.url('/good/' + vm.goodsnom);
    }

    function formDisplay() {
        if (vm.recentCode === undefined || vm.recentCode === null)
            return;
        var displayValues = vm.recentCode.name.split('_');
        vm.goodsnom = displayValues[0];
        if (displayValues.length > 1)
            vm.source = displayValues[1];
        if (displayValues.length > 2)
            vm.country = displayValues[2];
        vm.allDone = true;
    }

    return vm;
}