module.exports = {
    controller: forgotPasswordPageController,
    templateUrl: 'account/components/forgot-password-page.html'
}

forgotPasswordPageController.$inject = [
    '$analytics', 
    '$location', 
    '$translate', 
    'userAccountService',
];

function forgotPasswordPageController(
    $analytics, 
    $location, 
    $translate, 
    userAccount
) {

    var vm = {
        $onInit: attached,
        $postLink: focusInputField,
        resetPassword: resetPassword,
        model: {
            username: null
        },
        navigateTo: navigateTo,
        status: {
            done: false,
            error: null,
            loading: false
        }
    };

    function attached() {
        $analytics.pageTrack('/forgot-password');
    }

    function focusInputField() {
        document.getElementById('login-username').focus();
    }

    function resetPassword() {
        vm.status.loading = true;
        var language = $translate.use();

        userAccount.resetPassword(vm.model.username, language)
            .then(resetSuccess, resetError);

        function resetSuccess(data, status, headers) {
            vm.status.loading = false;
            vm.status.done = true;
        }

        function resetError(error, status) {
            vm.status.loading = false;
            vm.status.error = error;
        }
    }

    function navigateTo(path) {
        $location.url(path);
    }

    return vm;
}
