﻿module.exports = {
    bindings: {
        isLarge: '<',
        item: '<'
    },
    controller: annotationButtonController,
    templateUrl: 'texts/components/annotation-button.html'
};

annotationButtonController.$inject = ['annotationsModal'];

function annotationButtonController(annotationsModal) {

    var vm = {
        showAnnotation: showAnnotation
    };

    function showAnnotation() {
        annotationsModal.show(vm.item.section, vm.item.chapter);
    }


    return vm;

}