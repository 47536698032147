module.exports = ['$analytics', '$uibModal', preferenceCodeSelectorModal];

function preferenceCodeSelectorModal($analytics, $uibModal) {
    var modal = {
        show: showModal
    };

    preferenceCodeSelectorModalController.$inject = ['$uibModalInstance', 'possiblePreferenceCodes', 'preferenceCode'];

    function preferenceCodeSelectorModalController($uibModalInstance, possiblePreferenceCodes, preferenceCode) {
        var vm = {
            preferenceCode: preferenceCode,
            cancel: cancel,
            nationalPreferenceCodes: [],
            possiblePreferenceCodes: possiblePreferenceCodes,
            ok: ok
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function ok(code) {
            $uibModalInstance.close(code);
        }

        return vm;
    }

    function showModal(possiblePreferenceCodes, preferenceCode) {
        $analytics.eventTrack('open-modal-preference-code-selector', { category: 'preference-codes' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: preferenceCodeSelectorModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                preferenceCode: function () {
                    return preferenceCode;
                },
                possiblePreferenceCodes: function () {
                    return possiblePreferenceCodes;
                }
            },
            size: 'lg',
            templateUrl: 'preference-codes/modals/preference-code-selector.html',
            windowClass: 'modal-preference-code-selector'
        });
    }

    return modal;
}
