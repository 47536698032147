module.exports =  {
    bindings: {},
    controller: atrListController,
    templateUrl: 'atr/components/atr-list.html'
}

atrListController.$inject = ['atrDataService'];

function atrListController(atrDataService) {
    var vm = {
        service: atrDataService,
        changeActiveAtr: atrDataService.changeActiveAtr,
        isLoading: false,
        loadMore: loadMore
    };
    return vm;

    function loadMore() {
        vm.isLoading = true;
        atrDataService.loadMoreAtrs().then(function () {
            vm.isLoading = false;
        });
    }
}