﻿angular.module('taric.texts', [])
    .component('annotationButton', require('./components/annotation-button'))
    .component('annotation', require('./components/annotation'))
    .component('cccConclusion', require('./components/ccc-conclusion'))
    .component('explanatoryNoteButton', require('./components/explanatory-note-button'))
    .component('noteSource', require('./components/note-source'))
    .component('wcoClassificationOpinion', require('./components/wco-classification-opinion'))
    .factory('textDataService', require('./services/text-data'))
    .factory('annotationsDataService', require('./services/annotations-data'))
    .factory('annotationsModal', require('./modals/annotations'))
    .factory('explanatoryNoteModal', require('./modals/explanatory-note'))
;