var moment = require('moment');

module.exports = ['common', 'dataContext', 'applicationStateService', 'nomenclatureTreeService', nomenclatureDataService];

function nomenclatureDataService(common, context, state, nomenclatureTreeService) {
    var service = {
        chapters: {},
        sections: [],
        browseChapter: browseChapter,
        browseHeading: browseHeading,
        getChapter: getChapter,
        getSections: getSections,
        getSection: getSection,
        getSectionForCommodityCode: getSectionForCommodityCode,
        getSubchapterNumber: getSubchapterNumber,
        isDeclarable: isDeclarable,
        loadDescription: loadDescription,
        loadNomenclatureGroups: loadNomenclatureGroups,
        searchHeadings: searchHeadings,
        searchTree: searchTree
    };
    return service;

    function getSections() {
        var maxNumberOfCachedItems = 10;
        var deferred = common.$q.defer();

        var language = common.language.get();
        var referenceCountry = state.filter.referenceCountry;
        var referenceDate = state.filter.date;
        var cacheDate = moment().format('YYYY-MM-DD');

        var cachedSections = _.find(service.sections, function(item) {
            return item.language === language &&
            item.referenceCountry === referenceCountry &&
            item.referenceDate === referenceDate &&
            item.cacheDate === cacheDate;
        });
        if (cachedSections) {
            cachedSections.lastUsedAt = Date.now();
            deferred.resolve(cachedSections);
        }
        else {
            loadSections(language, referenceCountry, referenceDate)
                .then(function (data) {
                    var sections = {
                        cacheDate: cacheDate,
                        language: language,
                        referenceCountry: referenceCountry,
                        referenceDate: referenceDate,
                        data: data,
                        lastUsedAt: Date.now()
                    };
                    service.sections = _.concat(service.sections, sections);
                    if (service.sections.length > maxNumberOfCachedItems) {
                        service.sections = _.chain(service.sections)
                            .sortBy(function(item) {
                                return item.lastUsedAt;
                            })
                            .drop()
                            .value();
                    }
                    deferred.resolve(sections);
                }, deferred.reject);
        }

        return deferred.promise;
    }

    function loadSections(language, referenceCountry, referenceDate) {
        var url = common.config.apiHosts[common.config.currentApi] + '/sections' +
            '?lang=' + language +
            '&referencecountry=' + referenceCountry +
            '&date=' + referenceDate;
        return context.get(url, 'SECTIONS');
    }

    function getSection(sectionNumber) {
        return getSections()
            .then(function (sections) {
                return _.find(sections.data, function (section) {
                    return section.number === sectionNumber;
                });
            })
    }

    function getSectionForCommodityCode(goodsCode) {
        return getSections()
            .then(function (sections) {
                var chapterCode = goodsCode.substring(0, 2) + '00000000';
                return _.find(sections.data, function (section) {
                    return _.some(section.chapters, function (chapter) {
                        return chapter.code === chapterCode;
                    });
                });
            })
    }

    function loadNomenclatureGroups(goodscode) {
        var url = common.config.apiHosts[common.config.currentApi] + '/nomenclature/' + goodscode + '/nomenclature-groups' +
            '?lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }
        return context.get(url, 'NOMENCLATURE_GROUPS');
    }

    // TODO: add a method for getting this data from the chapter
    // tree of the chapter of the goodscode. This should be possible if the chapter has been loaded previously.
    function loadDescription(goodscode) {
        var url = common.config.apiHosts[common.config.currentApi] + '/nomenclature/' + goodscode + '/description' +
        '?lang=' + common.language.get()  +
        '&movement=' + state.filter.movement +
        '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }
        return context.get(url);
    }

    function isDeclarable(commodityCode) {
        var url = common.config.apiHosts[common.config.currentApi] + '/nomenclature/' + commodityCode + '/is-declarable' +
        '?lang=' + common.language.get()  +
        '&movement=' + state.filter.movement +
        '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }
        return context.get(url);
    }

    function browseHeading(q) {
        var url = common.config.apiHosts.generic + '/nomenclature/browse-heading' + 
            '?goodscode=' + q.commodityCode +
            '&movement=' + state.filter.movement  +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;
        return context.get(url, 'SEARCH');
    }

    function browseChapter(q) {
        var url = common.config.apiHosts.generic + '/nomenclature/browse-chapter' + 
            '?goodscode=' + q.commodityCode +
            '&movement=' + state.filter.movement  +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;
        return context.get(url, 'SEARCH');
    }

    function searchTree(q) {
        var url = common.config.apiHosts.generic + '/nomenclature/search-tree-combined' + 
            '?goodscode=' + q.commodityCode +
            '&q=' + q.query +
            '&includeBtis=' + q.includeBtis +
            '&includeCaseLaw=' + q.includeCaseLaw +
            '&includeClassificationRegulations=' + q.includeClassificationRegulations +
            '&movement=' + state.filter.movement  +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;
        return context.get(url, 'SEARCH');
    }

    function searchHeadings(q) {
        var url = common.config.apiHosts.generic + '/nomenclature/search-combined' +
            '?q=' + q.query +
            '&includeBtis=' + q.includeBtis +
            '&includeCaseLaw=' + q.includeCaseLaw +
            '&includeClassificationRegulations=' + q.includeClassificationRegulations +
            '&movement=' + state.filter.movement +
            '&lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date +
            '&orderby=' + q.orderBy;
        if (q.commodityCode) {
            url += "&goodscode=" + q.commodityCode;
        }
        return context.get(url, 'SEARCH');
    }

    function getChapter(chapterNumber, movement, date) {
        var deferred = common.$q.defer();

        var chapterCode = common.formatting.padRight(common.formatting.padLeft(chapterNumber + '', 2), 10);
        var key = chapterCode + '_' + movement + '_' + date;

        if (service.chapters[key] === undefined) {

            browseChapter({ commodityCode: chapterCode})
                .then(function (data) {
                    nomenclatureTreeService.setCodeFromAndCodeTo(data.tree);
                    service.chapters[key] = data;
                    deferred.resolve(data);
                });
        }
        else
            deferred.resolve(service.chapters[key]);

        return deferred.promise;
    }

    function getSubchapterNumber(chapterNumber, subchapterCode) {
        if (subchapterCode.length === 4) {
            subchapterCode += '000000';
        }
        return getChapter(chapterNumber, state.filter.movement, state.filter.date)
            .then(function (chapterInfo) {
                var subchapters = subchapter = _.filter(chapterInfo.tree.children, function (subchapterInfo) {
                    return subchapterInfo.value.isSubchapter;
                });
                var subchapter = _.find(subchapters, function (subchapterInfo) {
                    return subchapterInfo.value.commodityCode === subchapterCode;
                });
                if (!subchapter) {
                    return null;
                }
                return subchapters.indexOf(subchapter) + 1;
            }, function(err) {
               
            });
    }
}