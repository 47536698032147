module.exports = {
    controller: validateBusinessInfoEoriFormController,
    templateUrl: 'validate-business-info/components/validate-business-info-eori-form.html'
}

validateBusinessInfoEoriFormController.$inject = [
    '$timeout',
    'validateBusinessInfoDataService',
];

function validateBusinessInfoEoriFormController(
    $timeout,
    validateBusinessInfoDataService
) {
    var vm = {
        isLoading: false,
        noResult: false,
        showWarning: false,
        showError: false,
        errorMessage: null,
        service : validateBusinessInfoDataService,
        eoriCheck: validateBusinessInfoDataService.eoriCheck,
        checkEoriNumber: checkEoriNumber,
        clearEoriNumber: clearEoriNumber,
    };

    function clearEoriNumber() {
        validateBusinessInfoDataService.clearEoriNumber();
    }

    function checkEoriNumber() {
        vm.eoriCheck.result = null;
        var eoriRegex = /[A-Za-z][A-Za-z]?[0-9]{1,15}$/;
        if(!validateBusinessInfoDataService.eoriCheck.eoriNumber) {
            vm.showWarning = true;
            return $timeout(function() { 
                vm.showWarning = false;
            }, 5000);
        }

        if(!eoriRegex.test(validateBusinessInfoDataService.eoriCheck.eoriNumber)) {
            vm.showWarning = true;
            return $timeout(function() { 
                vm.showWarning = false;
                vm.errorMessage = null;
            }, 5000);
        }
        vm.isLoading = true;
        return validateBusinessInfoDataService.checkEoriNumber()
        .then(function () {
            vm.isLoading = false;
            if(validateBusinessInfoDataService.eoriCheck.messages.length > 0) {
                vm.showError = true;
                return $timeout(function() { 
                        vm.showError = false;
                    }, 5000);
            }
        }).catch(function(error) {
            vm.isLoading = false;
            if(validateBusinessInfoDataService.eoriCheck.messages.length > 0) {
                vm.showError = true;
                return $timeout(function() { 
                    vm.showError = false;
                }, 5000);
            }
        });
    }

    return vm;
}