module.exports = {
    controller: eurLexLinkController,
    templateUrl: 'links/components/eur-lex-link-page.html'
};

eurLexLinkController.$inject = [
    '$location',
    '$routeParams',
];

function eurLexLinkController(
    $location,
    $routeParams
) {
    var vm = {
        $onInit: attached
    };
    
    return vm;

    function attached() {
        switch ($routeParams.idType) {
            case 'celex':
                $location.url('regulation-details/' + $routeParams.id).replace();
                break;
            default:
                $location.url('news/regulations').replace();
                break;
        }
    }
}