module.exports = ['common', 'dataContext', 'applicationStateService', 'serializationService', dutyDataService];

function dutyDataService(common, context, state, serializationService) {

    var service = {
        getUnits: getUnits,
        calculateDuty: calculateDuty
    };

    function getUnits(commodityCode, additionalCodeObjects, preferenceCode, germanExciseCode, uccModeEnabled) {
        var additionalCodes;

        var url = common.config.apiHosts[common.config.currentApi] + '/goods/' + commodityCode + '/units' +
            '?lang=' + common.language.get() +
            '&country=' + (state.filter.countryCode || '') +
            '&movement=' + state.filter.movement +
            '&german_excise_code=' + (germanExciseCode || '') +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date +
            '&ucc=' + (uccModeEnabled || '');
        if (additionalCodeObjects && additionalCodeObjects.length > 0) {
            additionalCodes = _.map(additionalCodeObjects, function(additionalCode) {
                return '' + additionalCode.type + additionalCode.code;
            });
            url += '&additionalcodes=' + additionalCodes.join(',');
        }
        if (preferenceCode) {
            url += "&preferencecode=" + preferenceCode.code;
        }
        return context.get(url);
    }

    function calculateDuty(query) {
        var quantities = serializationService.serializeQuantities(query.quantities);
        var additionalCodes = _.map(query.additionalCodes, function(additionalCode) {
            return '' + additionalCode.type + additionalCode.code;
        });
        var certificateCodes = _.map(query.certificateCodes, function(certificateCode) {
            if (certificateCode.origin === 'IT') {
                return '' + certificateCode.code + certificateCode.type;
            }
            return '' + certificateCode.type + certificateCode.code;
        });
        var preferenceCode = query.preferenceCode ? query.preferenceCode.code : null;
        var customsValue = serializationService.serializeCustomsValue(query.customsValue);

        var url = common.config.apiHosts[common.config.currentApi] + '/duties/calculate' +
            '?lang=' + common.language.get() +
            '&goodscode=' + query.commodityCode +
            '&additionalcodes=' + additionalCodes.join(',') +
            '&certificatecodes=' + certificateCodes.join(',') +
            '&currencycode=' + (query.currencyCode || '') +
            '&preferencecode=' + (preferenceCode || '') +
            '&customsvalue=' + customsValue +
            '&dutymeasuretypeid=' + (query.dutyMeasureTypeId || '') +
            '&ordernumber=' + (query.orderNumber || '') +
            '&quantities=' + quantities.join(',') +
            '&country=' + (query.countryOfOrigin || '') +
            '&german_national_commodity_code=' + (query.germanNationalCommodityCode || '') +
            '&german_excise_code=' + (query.germanExciseCode || '') +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date +
            '&ucc=' + (query.uccModeEnabled || '');
        return context.get(url);
    }

    return service;

}
