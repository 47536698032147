module.exports = ['$timeout', 'notificationsService', scrollToTopWhenDirective];

function scrollToTopWhenDirective($timeout, notifications) {
    return function (scope, element, attrs) {
        var eventNames = attrs.scrollToTopWhen.split(/,\s?/)
        _.forEach(eventNames, function (eventName) {
          notifications.subscribe(eventName, function() {
            $timeout(function () {
              element[0].scrollTop = 0;
            });
          });
        });
    };
}