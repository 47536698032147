﻿module.exports = {
    controller: certificateCodesPageController,
    templateUrl: 'certificate-codes/components/certificate-codes-page.html'
};

certificateCodesPageController.$inject = [
    '$analytics', 
    '$timeout',
    'notificationsService', 
    'certificateCodesDataService', 
    'applicationStateService'
];

function certificateCodesPageController(
    $analytics, 
    $timeout,
    notifications, 
    certificateCodesData, 
    state
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        isLoading: false,
        filter: "",
        filterType: "",
        isLoadingTypes: false,
        filteredCertificateCodes: [],
        certificateCodes: [],
        certificateCodeTypes: [],
        filteredCertificateCodeTypes: [],
        selectedType: null,
        referenceCountry: state.filter.referenceCountry,
        loadcertificateCodes: loadCertificateCodes,
        clearFilter: clearFilter,
        changeFilter: changeFilter,
        clearFilterType: clearFilterType,
        changeFilterType: changeFilterType,
        focusInputTypeField: focusInputTypeField,
        focusInputField: focusInputField
    };

    function focusInputTypeField() {
        $timeout(function(){
            var inputEL = angular.element(document.querySelector('#inputFilterType'));
            if (inputEL) {
                inputEL[0].focus();
            }
        }, 0);
    }

    function focusInputField() {
        $timeout(function(){
            var inputEL = angular.element(document.querySelector('#inputFilter'));
            if (inputEL) {
                inputEL[0].focus();
            }
        }, 0);
    }

    function attached() {
        $analytics.pageTrack('/reference/certificate-codes');
        loadTypes();
        notifications.subscribe('filter_date_changed', dateChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    function clearFilter() {
        vm.filter = '';
        vm.filteredCertificateCodes = vm.certificateCodes;
        focusInputField();
    }

    function changeFilter() {
        vm.filteredCertificateCodes = vm.certificateCodes.filter(function (el) {
            return (el.type.toLowerCase() + el.code.toLowerCase()).includes(vm.filter.toLowerCase()) || 
                el.description.toLowerCase().includes(vm.filter.toLowerCase());
        });
    }

    function clearFilterType() {
        clearFilter();
        vm.filterType = "";
        vm.filteredCertificateCodeTypes = vm.certificateCodeTypes;
        vm.certificateCodes = [];
        vm.filteredCertificateCodes = [];
        vm.selectedType = null;
        focusInputTypeField();
    }

    function changeFilterType() {
        vm.filteredCertificateCodeTypes = vm.certificateCodeTypes.filter(function (el) {
            return el.code.toLowerCase().includes(vm.filterType.toLowerCase()) || el.description.toLowerCase().includes(vm.filterType.toLowerCase());
        });
        if(vm.filteredCertificateCodeTypes.length === 1) {
            loadCertificateCodes(vm.filteredCertificateCodeTypes[0]);
        }
        if(vm.filteredCertificateCodeTypes.length === 0) {
            vm.certificateCodes = [];
            vm.filteredCertificateCodes = [];
            vm.selectedType = null;
            vm.filter = "";
        }
    }

    function dateChanged() {
        loadTypes();
    }

    function loadCertificateCodes(type) {
        if (vm.referenceCountry === 'CH') {
            return;
        }
        vm.isLoading = true;
        vm.selectedType = type.code;
        vm.filter = "";
        
        certificateCodesData.getByType(type.code)
            .then(function (data) {
                vm.filteredCertificateCodes = data
                vm.certificateCodes = data;
                vm.isLoading = false;
                focusInputField();
            });
    }

    function loadTypes() {
        if (vm.referenceCountry === 'CH') {
            return;
        }
        vm.isLoadingTypes = true;
        vm.certificateCodes = [];
        vm.selectedType = null;

        certificateCodesData.getTypes()
            .then(function (data) {
                vm.certificateCodeTypes = data;
                vm.filteredCertificateCodeTypes = data;
                vm.isLoadingTypes = false;
                focusInputTypeField();
            });
    }

    return vm;

}