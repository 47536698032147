﻿var jsep = require('jsep');

module.exports = ['common', 'ngIfDirective', hasPermissionDirective];

function hasPermissionDirective(common, ngIfDirective) {
    var ngIf = ngIfDirective[0];

    return {
        transclude: ngIf.transclude,
        priority: ngIf.priority - 1,
        terminal: ngIf.terminal,
        restrict: ngIf.restrict,
        link: function(scope, element, attributes) {
            // find the initial ng-if attribute
            var initialNgIf = attributes.ngIf, ifEvaluator;
            // if it exists, evaluates ngIf && ifAuthenticated
            if (initialNgIf) {
                ifEvaluator = function () {
                    return scope.$eval(initialNgIf) && shouldShowElement(attributes.hasPermission);
                }
            } else { // if there's no ng-if, process normally
                ifEvaluator = function () {
                    return shouldShowElement(attributes.hasPermission);
                }
            }
            attributes.ngIf = ifEvaluator;
            ngIf.link.apply(ngIf, arguments);
        }
    };


    // return {
    //     link: function (scope, element, attrs) {
    //         var show = false;
    //         if (attrs && attrs.hasPermission) {
    //             // JSEP interprets a hyphen - as an operator, so we replace it by the string 'HYPHEN'
    //             var expression = attrs.hasPermission.replace(/-/g, 'HYPHEN');
    //             show = evaluatePermissionExpressionTree(jsep(expression));
    //         }

    //         element.removeClass('ng-hide');
    //         if (!show)
    //             element.addClass('ng-hide');
    //     }
    // };

    function shouldShowElement(hasPermission) {
        var expression = hasPermission.replace(/-/g, 'HYPHEN');
        return evaluatePermissionExpressionTree(jsep(expression));
    }

    function evaluatePermissionExpressionTree(tree) {
        if (tree.type === 'Literal') {
            return tree.value;
        }
        if (tree.type == 'BinaryExpression') {
            if (tree.operator === '&&') {
                return evaluatePermissionExpressionTree(tree['left']) && evaluatePermissionExpressionTree(tree['right']);
            }
            else if (tree.operator === '||') {
                return evaluatePermissionExpressionTree(tree['left']) || evaluatePermissionExpressionTree(tree['right']);
            }
        }
        if (tree.type === 'UnaryExpression') {
            if (tree.operator === '!') {
                return !evaluatePermissionExpressionTree(tree.argument);
            }
        }
        if (tree.type === 'Identifier') {
            return common.identity.hasPermission(tree.name) || common.identity.hasPermission(tree.name.replace(/HYPHEN/g, '-'));
        }
        // Unexpected node parsed (TODO: send error to Sentry)
        return false;
    }

}
