module.exports = ['common', 'dataContext', 'applicationStateService', exchangeRateDataService];

function exchangeRateDataService(common, context, state) {
    var service = {
        cachedExchangeRates: {},
        getCurrencies: getCurrencies,
        getCurrenciesIncludingTargetCurrency: getCurrenciesIncludingTargetCurrency,
        getExchangeRate: getExchangeRate,
        getTargetCurrency: getTargetCurrency
    };

    function getCurrencies(targetCurrency) {
        var url = common.config.apiHosts.dtv + '/exchange-rate-currencies/' + targetCurrency +
                '?lang=' + common.language.get() +
                '&referencecountry=' + state.filter.referenceCountry +
                '&date=' + state.filter.date;
        
        return context.get(url)
            .then(function (currencies) {
                return _.filter(currencies, function(currency) {
                    return currency.code !== targetCurrency;
                })
            });
    }

    function getTargetCurrency(referenceCountry) {
        var gbp = {
            code: 'GBP',
            description: 'Pound'
        };
        var eur = {
            code: 'EUR',
            description: 'Euro'
        };
        var sek = {
            code: 'SEK',
            description: 'Swedish Krona'
        };
        if (referenceCountry === 'GB') {
            return gbp;
        }
        // else if (referenceCountry === 'SE') {
        //     return sek;
        // }
        else {
            return eur;
        }
    }

    function getCurrenciesIncludingTargetCurrency() {
        
        var targetCurrency = getTargetCurrency(state.filter.referenceCountry);
        return getCurrencies(targetCurrency.code)
            .then(function (currencies) {
                return _.concat([targetCurrency], currencies);
            });
    }

    var memoized = _.memoize(getExchangeRateForReferenceCountryAtDate, function(currencyA, currencyB, referenceCountry, referenceDate) {
        return currencyA + '|' + currencyB + '|' + referenceCountry + '|' + referenceDate;
    });

    function getExchangeRate(currencyA, currencyB) {
        return memoized(currencyA, currencyB, state.filter.referenceCountry, state.filter.date);
    }

    function getExchangeRateForReferenceCountryAtDate(currencyA, currencyB, referenceCountry, referenceDate) {
        var url = common.config.apiHosts.dtv + '/exchange-rates/' + currencyA + '/' + currencyB +
                '?referencecountry=' + referenceCountry +
                '&date=' + referenceDate;
        
        return context.get(url);
    }

    return service;
}