﻿angular.module('taric.good', [])
    .config(['$routeProvider', routeConfigurator])
    
    .component('antiDumpingFinancialMeasures', require('./components/anti-dumping-financial-measures'))
    .component('customsValueCheckResult', require('./components/customs-value-check-result'))
    .component('dutyCalculationResult', require('./components/duty-calculation-result'))
    .component('exportMeasures', require('./components/export-measures'))
    .component('favoriteButton', require('./components/favorite-button'))
    .component('financialMeasure', require('./components/financial-measure'))
    .component('generalMeasure', require('./components/general-measure'))
    .component('groupedFinancialMeasures', require('./components/grouped-financial-measures'))
    .component('groupedGeneralMeasures', require('./components/grouped-general-measures'))
    .component('groupedVatMeasures', require('./components/grouped-vat-measures'))
    .component('germanExciseMeasures', require('./components/german-excise-measures'))
    .component('germanVatMeasures', require('./components/german-vat-measures'))
    .component('importMeasuresColumnOne', require('./components/import-measures-col-1'))
    .component('importMeasuresColumnTwo', require('./components/import-measures-col-2'))
    .component('measureConditionButtons', require('./components/measure-condition-buttons'))
    .component('measureConditionGroupsExpanded', require('./components/measure-condition-groups-expanded'))
    .component('measureManyConditionButtons', require('./components/measure-many-condition-buttons'))
    .component('measurePreferenceCodeButtons', require('./components/measure-preference-code-buttons'))
    .component('referenceDateAndCountry', require('./components/reference-date-and-country'))
    
    .controller('goodController', require('./controllers/good'))
    
    .directive('measureFiltersExportSmall', require('./directives/form/measure-filters-export-small'))
    .directive('measureFiltersImport', require('./directives/form/measure-filters-import'))
    .directive('measureFiltersImportSmall', require('./directives/form/measure-filters-import-small'))
    .directive('valueQuantityImport', require('./directives/form/value-quantity-import'))
    
    .factory('meursingModal', require('./modals/meursing-code'))
    .factory('ukCertificateGuidanceModal', require('./modals/uk-certificate-guidance'))
    .factory('goodModals', require('./modals/modals'))
    .factory('goodDataService', require('./services/good-data'))
    .factory('goodDisplayService', require('./services/good-display'))
    .factory('goodModelsService', require('./services/good-models'))
    .factory('measureGroupingService', require('./services/measure-grouping'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/good/:goodsnom', {
            templateUrl: 'good/controllers/good.html'
        })
        ;
}
