﻿module.exports = [stripTaricCodeFilter];

function stripTaricCodeFilter() {
    return function (input) {
        if (input === undefined || input === null)
            return;
        if (input.length <= 2)
            return input;

        while (input.substring(input.length - 2) === '00' && input.length > 4) {
            input = input.substring(0, input.length - 2);
        }

        return input;
    };
}