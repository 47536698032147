module.exports = ['$analytics', '$uibModal', ukCertificateGuidanceModal];

function ukCertificateGuidanceModal($analytics, $uibModal) {
    var modal = {
        show: showModal
    };

    ukCertificateGuidanceModalController.$inject = ['$uibModalInstance', 'certificates'];

    function ukCertificateGuidanceModalController($uibModalInstance, certificates) {
        var vm = {
            certificates: certificates,
            cancel: cancel,
            ok: ok
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function ok(code) {
            $uibModalInstance.close(code);
        }

        return vm;
    }

    function showModal(certificates) {
        $analytics.eventTrack('open-modal-uk-certificate-guidance');
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: ukCertificateGuidanceModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                certificates: function () {
                    return certificates;
                }
            },
            size: 'lg',
            templateUrl: 'good/modals/uk-certificate-guidance.html',
            windowClass: 'modal-uk-certificate-guidance'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
