module.exports = [
    '$injector',
    '$location', 
    '$q',
    'applicationStateService',
    errorHandlingService
];

function errorHandlingService(
    $injector,
    $location,
    $q,
    state
) {

    var service = {
        responseError: handleHttpResponseError
    };

    return service;

    function handleHttpResponseError(rejection) {
        var preferenceService = $injector.get('preferenceService');
        var identityService = $injector.get('identity');
        if (rejection.status === 403 && rejection.data === 'No access to trade tariff') {
            var allowedTradeTariffs = identityService.getAllowedTradeTariffs();
            
            var referenceCountry;
            if (_.includes(allowedTradeTariffs, 'EU')) {
                referenceCountry = 'EU';    
            }
            else {
                referenceCountry = allowedTradeTariffs[0];
            }
            state.filter.referenceCountry = referenceCountry;
            preferenceService.setReferenceCountry(referenceCountry).then(function () {
                $location.url('/landing');
            });
            return $q.resolve({ data: null });
        }
        else {
            return $q.reject(rejection);
        }
    }
}