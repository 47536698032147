﻿module.exports = {
    bindings: {
        measure: '<'
    },
    controller: generalMeasureController,
    templateUrl: 'good/components/general-measure.html'
};

generalMeasureController.$inject = ['$window'];

function generalMeasureController($window) {
    var vm = {
        toggle: function(measure) {
            var textIsSelected = $window.getSelection().toString().length > 0;
            if (measure.isExpandable && !textIsSelected) {
                measure.expanded = !measure.expanded;
            }
        }
    }
    return vm;
}