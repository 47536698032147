﻿module.exports = [taricDashesFilter];

function taricDashesFilter() {
    return function (input) {
        if (!input) {
            return '';
        }
        var numeric = parseInt(input);
        return Array(numeric + 1).join('&ndash;&nbsp;');
    }
}