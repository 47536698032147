var moment = require('moment');

module.exports = {
    bindings: {
        updateQueryString: '<'
    },
    controller: atrFormController,
    templateUrl: 'atr/components/atr-form.html'
}

atrFormController.$inject = [
    '$analytics', 
    '$location', 
    'atrDataService', 
    'notificationsService'
];

function atrFormController(
    $analytics, 
    $location, 
    atrDataService, 
    notificationsService
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        changeGoodsDescriptionQueryField: changeGoodsDescriptionQueryField,
        changeSearchQueryField: changeSearchQueryField,
        clearCommodityCode: clearCommodityCode,
        clearAtrReference: clearAtrReference,
        clearSearchQuery: clearSearchQuery,
        clearGoodsDescriptionQuery: clearGoodsDescriptionQuery,
        filter: atrDataService.filter,
        service: atrDataService,
        isLoading: false,
        issuedSinceDatepicker: {
            date: moment(atrDataService.filter.issuedSince, "YYYY-MM-DD").toDate(),
            dateChanged: issuedSinceDateChanged,
            blur: issuedSinceBlur,
            opened: false,
            open: openIssuedSinceDatePicker,
            dateOptions: {}
        },
        loadNew: loadNew,
    };

    function attached() {
        notificationsService.subscribe('language_changed', languageChanged);
        if (atrDataService.filter.searchQuery) {
            atrDataService.orderBy = 'KeywordRank-desc';
        }
    }

    function detached() {
        notificationsService.unsubscribe('language_changed', languageChanged);
    }
    
    function clearAtrReference() {
        atrDataService.filter.reference = '';
    }

    function clearCommodityCode() {
        atrDataService.filter.goodsCode = '';
    }


    function changeGoodsDescriptionQueryField() {
        if (atrDataService.filter.goodsDescriptionQuery && atrDataService.orderBy !== 'GoodsDescriptionRank-desc' ) {
            if (atrDataService.filter.searchQuery && atrDataService.orderBy === 'KeywordRank-desc') {
                // If the user has entered a keyword search query and we're ordering by that, don't overrid the ordering.
            }
            else {
                atrDataService.orderBy = 'GoodsDescriptionRank-desc';
            }
        }
    }

    function clearGoodsDescriptionQuery() {
        atrDataService.filter.goodsDescriptionQuery = '';
    }

    function changeSearchQueryField() {
        if (atrDataService.filter.searchQuery && atrDataService.orderBy !== 'KeywordRank-desc') {
            if (atrDataService.filter.goodsDescriptionQuery && atrDataService.orderBy === 'GoodsDescriptionRank-desc') {
                // If the user has entered a goods description search query and we're ordering by that, don't overrid the ordering.
            }
            else {
                atrDataService.orderBy = 'KeywordRank-desc';
            }
        }
    }

    function clearSearchQuery() {
        atrDataService.filter.searchQuery = '';
    }

    function languageChanged() {
        $location.search('query', null).replace();
    }

    function issuedSinceBlur() {
        if (!vm.issuedSinceDatepicker.date) {
            vm.filter.issuedSince = null;
        }
    }

    function issuedSinceDateChanged() {
        if (vm.issuedSinceDatepicker.date) {
            var newDate = moment(vm.issuedSinceDatepicker.date).format("YYYY-MM-DD");
            if (newDate !== vm.filter.issuedSince) {
                vm.filter.issuedSince = newDate;
            }
        }
        else {
            vm.filter.issuedSince = null;
        }
    }

    function openIssuedSinceDatePicker() {
        vm.issuedSinceDatepicker.opened = true;
    }

    if (vm.updateQueryString) {
        updateSearchQuery();
    }

    return vm;

    function loadNew() {
        $analytics.pageTrack('/atr-search');
        if (vm.updateQueryString) {
            updateSearchQuery();
        }
        vm.isLoading = true;
        atrDataService.loadNewAtrs()
            .then(function () {
                vm.isLoading = false;
            });
    }

    function updateSearchQuery() {
        $location.search('query', vm.filter.searchQuery);
        $location.search('commodityCode', vm.filter.goodsCode);
    }
}