module.exports = {
    Annotations: 'Notes',
    AnnotationsFirstLetter: 'N',

    Chapter: 'Chapter',
    Chapters: 'Chapters',
    Chapter_code_: 'Chapter {{ code }}',
    Chapter_GoodsCodeOrSectionNumber_: 'Chapter {{ GoodsCodeOrSectionNumber }}',

    CombinedNomenclature: 'Combined Nomenclature',

    CommodityClassification: 'Commodity Classification',
    CommodityCode: 'Commodity Code',
    CommodityCodes: 'Commodity Codes',
    CommodityCodesFoundInText: 'Commodity Codes Found in Text',
    

    Heading: 'Heading',
    Heading_code_: 'Heading {{ code }}',
    Heading_GoodsCodeOrSectionNumber_: 'Heading {{ GoodsCodeOrSectionNumber }}',
    Headings: 'Headings',

    Section: 'Section',
    Section_number_: 'Section {{ number }}',
    Section_GoodsCodeOrSectionNumber_: 'Section {{ GoodsCodeOrSectionNumber }}',
    Sections: 'Sections',

    SubChapter_GoodsCodeOrSectionNumber_: 'Subchapter {{ GoodsCodeOrSectionNumber }}',

    Subheading: 'Subheading',

    WcoClassificationOpinions: 'WCO Classification Opinions',
    Classifications: 'Classifications',
    ExplanatoryNotes: 'Explanatory Notes',
    ExplanatoryNotesFirstLetter: 'E',
    ExplanatoryNotesCn: 'CN Explanatory Notes - EU',
    ExplanatoryNotesHs: 'HS Explanatory Notes - WCO',
    Nomenclature: 'Nomenclature',
    NomenclatureGroups: 'Nomenclature Groups',
    NomenclatureHeadings: 'Nomenclature - Headings',
    NomenclatureHeading_code_: 'Nomenclature - Heading {{ code }}',

    SectionAndChapterNotes: 'Section and Chapter Notes',
};