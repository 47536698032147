module.exports = {
    bindings: {
        countryCode: '<',
        currentCode: '<',
        hinweise: '<'
    },
    controller: eztHinweiseController,
    templateUrl: 'ezt/components/ezt-hinweise.html'
};

eztHinweiseController.$inject = ['countryDataService'];

function eztHinweiseController(countryDataService) {
    var vm = {
        getRelevantHinweise: getRelevantHinweise
    }
    return vm;

    function getRelevantHinweise() {
        if (!vm.hinweise) {
            return vm.hinweise;
        }
        return _.chain(vm.hinweise)
            .filter(hinweisIsValidForNationalGoodsNomenclature)
            .filter(hinweisIsValidForCountryCode)
            .value();
    }

    function hinweisIsValidForNationalGoodsNomenclature(hinweis) {
        if (_.endsWith(hinweis.NationalGoodsNomenclatureId, '0')) {
            return true;
        }
        return hinweis.NationalGoodsNomenclatureId === vm.currentCode;
    }

    function hinweisIsValidForCountryCode(hinweis) {
        if (!vm.countryCode) {
            return true;
        }
        if (!hinweis.GeographicalAreaId) {
            return true;
        }
        if (hinweis.GeographicalAreaId === vm.countryCode) {
            return true;
        }
        var regionMembers = countryDataService.regions[hinweis.GeographicalAreaId];
        if (regionMembers && _.includes(regionMembers, vm.countryCode)) {
            return true;
        }
        return false;
    }
}