module.exports = {
    bindings: {
        item: '<'
    },
    controller: nlCaseLawListItemController,
    templateUrl: 'case-law/components/nl-case-law-list-item.html'
};

nlCaseLawListItemController.$inject = ['nlCaseLawJudgmentModal'];

function nlCaseLawListItemController(nlCaseLawJudgmentModal) {
    var vm = {
        showJudgment: showJudgment,
    };

    function showJudgment(ecli) {
        nlCaseLawJudgmentModal.show(ecli);
    }

    return vm;
}