angular.module('taric.nomenclature', [])
    .config(['$routeProvider', routeConfigurator])
    //Components
    .component('commodityCodeDescription', require('./components/commodity-code-description'))
    .component('exportRefundNomenclature', require('./components/export-refund-nomenclature'))
    .component('nomenclatureSection', require('./components/nomenclature-section'))
    .component('nomenclatureChapter', require('./components/nomenclature-chapter'))
    .component('nomenclatureHeading', require('./components/nomenclature-heading'))
    .component('nomenclatureGroups', require('./components/nomenclature-groups'))
    .component('taricCodeRow', require('./components/taric-code-row'))
    .component('taresStatisticalKeyRow', require('./components/tares-statistical-key-row'))
    // Pages
    .component('chapterPage', require('./components/chapter-page'))
    .component('sectionsPage', require('./components/sections-page'))
    // Directives
    // .directive('taricCodeRow', require('./directives/taric-code-row'))
    // Services
    .factory('nomenclatureDataService', require('./services/nomenclature-data'))
    .factory('nomenclatureTreeService', require('./services/nomenclature-tree'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/sections', {
            template: '<sections-page></sections-page>'
        })
        .when('/chapter/:chapterNumber', {
            template: '<chapter-page></chapter-page>'
        })
        ;
}

