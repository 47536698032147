module.exports  = ['dataContext', 'common', 'language', ecicsDataService];

function ecicsDataService(dataContext, common, language) {

    var activeCus = null;

    var service = {
        characteristics: null,
        nomenclatures: null,
        response: {},
        data: {
            activeItem: null,
            isLoading: false,
            isLoadingDetails: false,
        },
        display: 'list',
        filter: {
            casRn: null,
            characteristic: '',
            cus: null,
            ecNumber: null,
            goodsCode: null,
            inChICode: null,
            inChIKey: null,
            name: null,
            nomenclature: '',
            pageNumber: 1,
            unNumber: 0
        },
        orderBy: 'Cus-asc',
        countPerChapter: countPerChapter,
        countPerHeading: countPerHeading,
        countForGoodsCode: countForGoodsCode,
        countForGoodsCodes: countForGoodsCodes,
        init: init,
        loadCharacteristics: loadCharacteristics,
        loadMoreItems: loadMoreItems,
        loadNewItems: loadNewItems,
        loadNomenclatures: loadNomenclatures,
        changeActiveItem: changeActiveItem,
        clearActiveItem: clearActiveItem,
        resetFilter: resetFilter
    };
    return service;

    function init() {
        if (!service.characteristics) {
            loadCharacteristics()
                .then(function(characteristics) {
                    service.characteristics = characteristics;
                })
        }
        if (!service.nomenclatures) {
            loadNomenclatures()
                .then(function(nomenclatures) {
                    service.nomenclatures = nomenclatures;
                })
        }
    }

    function resetFilter() {
        service.filter.casRn = null;
        service.filter.cus = null;
        service.filter.characteristic = '';
        service.filter.ecNumber = null;
        service.filter.inChICode = null;
        service.filter.inChIKey = null;
        service.filter.name = null;
        service.filter.nomenclature = '';
        service.filter.unNumber = null;
        service.filter.goodsCode = null;
        service.filter.pageNumber = 1;
        service.display = 'list';
    }

    function loadNewItems() {
        service.data.activeItem = null;
        service.filter.pageNumber = 1;
        return loadItems(service.filter);
    }

    function loadMoreItems() {
        return loadItems(service.filter);
    }

    function loadItems(filter) {

        var sort = service.orderBy.split('-')[0];
        var order = service.orderBy.split('-')[1];

        var url = common.config.apiHosts.generic + '/ecics/search' +
            '?goodscode=' + (filter.goodsCode || '') +
            '&casrn=' + (filter.casRn || '') +
            '&characteristic=' + (filter.characteristic || '') +
            '&cus=' + (filter.cus || '') +
            '&ecnumber=' + (filter.ecNumber || '') +
            '&inchicode=' + (filter.inChICode || '') +
            '&inchikey=' + (filter.inChIKey || '') +
            '&name=' + (filter.name || '') +
            '&ecicsnomenclature=' + (filter.nomenclature || '') +
            '&unnumber=' + (filter.unNumber || '') +
            '&PageIndex=' + (filter.pageNumber - 1) +
            '&PageSize=10' +
            '&lang=' + language.get() +
            // '&date=' + state.filter.date + // TODO: we could keep track of the history, in which case we'll need to pass a date
            '&Sort=' + sort +
            '&Order=' + order;

        // TODO: we could pass this parameter and not load the images on the server if we don't pass it
        // if (service.display === 'images') {
        //     url += '&imagesearch=1';
        // }

        service.data.isLoading = true;
        return dataContext.get(url).then(function (paginatedItems) {
            service.data.isLoading = false;
            service.response = paginatedItems;
            if (service.response.Results.length > 0) {
                if (!activeCus) {
                    changeActiveItem(service.response.Results[0].Cus);
                }
            }
        });
    }

    function loadCharacteristics() {
        var url = common.config.apiHosts.generic + '/ecics/characteristics';
        return dataContext.get(url);
    }

    function loadNomenclatures() {
        var url = common.config.apiHosts.generic + '/ecics/nomenclatures';
        return dataContext.get(url);
    }

    function clearActiveItem() {
        service.data.activeItem = null;
    }

    function changeActiveItem(cus) {
        var url = common.config.apiHosts.generic + '/ecics/details/' + cus.trim() + '/';
        service.data.activeItem = null;
        service.data.isLoadingDetails = true;
        return dataContext.get(url).then(function (activeItem) {
            service.data.activeItem = activeItem;
            service.data.isLoadingDetails = false;
            _.forEach(service.response.Results, function (item) {
                item.active = false;
                if (item.Cus === cus) {
                    item.active = true;
                }
            });
        });
    }

    function countPerChapter() {
        var url = common.config.apiHosts.generic + '/ecics/count-per-chapter/';
        return dataContext.get(url);
    }

    function countPerHeading(chapterNumber) {
        var url = common.config.apiHosts.generic + '/ecics/count-per-heading/' + chapterNumber.trim() + '/';
        return dataContext.get(url);
    }

    function countForGoodsCode(goodsCode) {
        var url = common.config.apiHosts.generic + '/ecics/count/' + goodsCode.trim() + '/';
        return dataContext.get(url);
    }

    function countForGoodsCodes(goodsCodes) {
        var goodsCodesQueryString = goodsCodes.join(',');
        var url = common.config.apiHosts.generic + '/ecics/count-for-goodscodes?goodscodes=' + goodsCodesQueryString;
        return dataContext.get(url);
    }
}