﻿module.exports = ['$q', '$window', '$cookies', 'config', 'dataContext', 'language', preferenceService];

function preferenceService($q, $window, $cookies, config, dataContext, language) {

    var service = {
        preferences: [],
        add: addPreference,
        get: getPreference,
        getAny: getAnyPreference,
        getCategory: getPreferencesByCategory,
        init: reload,
        initialized: false,
        reload: reload,
        remove: removePreference,
        replace: replacePreference,
        update: updatePreference,
        
        saveCountryPreference: saveCountryPreference,
        clearCountryPreference: clearCountryPreference,
        setLanguage: setLanguage,
        setReferenceCountry: setReferenceCountry
    };

    function replacePreference(preference) {
        var existingPreference = getPreference(preference.name, preference.category);
        if (existingPreference) {
            preference.id = existingPreference.id;
            updatePreference(preference);
        }
        else {
            addPreference(preference);
        }
    }

    function addPreference(preference) {
        if (preference === null)
            return;

        var deferred = $q.defer();

        var obj = toModel(preference);
        var req = dataContext.post(config.apiHosts.generic + '/preference', obj, true)
            .then(function (data) {
                service.preferences.push(fromModel(data));
                deferred.resolve(fromModel(data));
            });

        return deferred.promise;
    }

    function getPreference(name, category) {
        var result = _.find(service.preferences, function (preference) {
            if (category !== undefined) {
                return preference.name === name && preference.category === category;
            }
            return preference.name === name;
        });

        if (result === undefined)
            return null;

        return result;
    }

    function getAnyPreference(name) {
        var result = _.filter(service.preferences, function (preference) {
            return preference.name === name;
        });

        if (result === undefined || result === null)
            return [];
        return result;
    }

    function getPreferencesByCategory(name) {
        var deferred = $q.defer();

        if (service.initialized === true) {
            var result = [];

            for (var i = 0; i < service.preferences.length; i++) {
                if (service.preferences[i].category !== undefined && service.preferences[i].category !== null && service.preferences[i].category.toLowerCase() === name.toLowerCase()) {
                    result.push(service.preferences[i]);
                }
            }

            deferred.resolve(result);
        }
        else {
            dataContext.get(config.apiHosts.generic + '/preference')
                .then(function (data) {
                    var result = [];
                    data = _.map(data, fromModel);

                    for (var i = 0; i < data.length; i++) {
                        if (data[i].category !== undefined && data[i].category !== null && data[i].category.toLowerCase() === name.toLowerCase()) {
                            result.push(data[i]);
                        }
                    }

                    deferred.resolve(result);
                });
        }
        return deferred.promise;
    }

    function fromModel(item) {
        var result = {
            id: item.id,
            category: item.category,
            lastUpdate: item.lastUpdate,
            name: item.name,
            sortOrder: item.sortOrder,
            valueType: item.valueType
        };

        switch (item.valueType) {
            case 'booleanValue':
                result[item.name] = item.booleanValue;
                result.value = item.booleanValue;
                break;
            case 'dateTimeValue':
                result[item.name] = item.dateTimeValue;
                result.value = item.dateTimeValue;
                break;
            case 'numericValue':
                result[item.name] = item.numericValue;
                result.value = item.numericValue;
                break;
            default:
                result[item.name] = item.stringValue;
                result.value = item.stringValue;
                break;
        }

        return result;
    }

    function reload() {
        var deferred = $q.defer();

        dataContext.get(config.apiHosts.generic + '/preference')
            .then(function (data) {
                data = _.map(data, fromModel);
                service.preferences = data;
                service.initialized = true;
                deferred.resolve(data);
            }, function(error) {
                if (error && error.status === 401) {
                    $cookies.remove('authToken');
                    $window.location.reload();
                }
                deferred.reject();
            });
        return deferred.promise;
    }

    function removePreference(preference) {
        if (preference === null || preference.id === undefined || preference.id === null)
            return;

        var obj = toModel(preference);
        service.preferences.splice(_.findIndex(service.preferences, function (prefToFind) { return prefToFind.id === obj.id; }), 1);
        return dataContext.del(config.apiHosts.generic + '/preference/' + preference.id);
    }

    function toModel(preference) {
        var originalPreference = null;
        if (preference.id !== undefined && preference.id !== null)
            originalPreference = _.find(service.preferences, function (prefToFind) { return prefToFind.id === preference.id; });

        var obj = {
            id: preference.id !== undefined && preference.id !== null ? preference.id : -1,
            lastUpdate: new Date(),
            name: preference.name !== undefined && preference.name !== null ? preference.name : (originalPreference !== undefined && originalPreference !== null && originalPreference.name !== undefined && originalPreference.name !== null ? originalPreference.name : null),
            category: preference.category !== undefined && preference.category !== null ? preference.category : (originalPreference !== undefined && originalPreference !== null && originalPreference.category !== undefined && originalPreference.category !== null ? originalPreference.category : null),
            sortOrder: preference.sortOrder !== undefined && preference.sortOrder !== null ? preference.sortOrder : (originalPreference !== undefined && originalPreference !== null && originalPreference.sortOrder !== undefined && originalPreference.sortOrder !== null ? originalPreference.sortOrder : null),
            valueType: preference.valueType !== undefined && preference.valueType !== null ? preference.valueType : (originalPreference !== undefined && originalPreference !== null && originalPreference.valueType !== undefined && originalPreference.valueType !== null ? originalPreference.valueType : null)
        };

        if (obj.name === null)
            return null;
        if (obj.valueType === null)
            obj.valueType = 'stringValue';

        switch (obj.valueType) {
            case 'booleanValue':
                obj.booleanValue = preference.value;
                break;
            case 'dateTimeValue':
                obj.dateTimeValue = preference.value;
                break;
            case 'numericValue':
                obj.numericValue = preference.value;
                break;
            default:
                obj.stringValue = preference.value;
                break;
        }

        return obj;
    }

    function updatePreference(preference) {
        if (preference === null)
            return;

        var obj = toModel(preference);
        var index = _.findIndex(service.preferences, function (prefToFind) { return prefToFind.id === obj.id; });
        if (index > -1)
            service.preferences[index] = fromModel(obj);

        return dataContext.put(config.apiHosts.generic + '/preference/' + obj.id, obj, true);
    }

    function setLanguage(lang) {
        language.set(lang);
        var currentPref = service.get('language', 'website');
        if (currentPref) {
            currentPref.value = lang;
            return service.update(currentPref);
        }
        else {
            return service.add({
                category: 'website',
                name: 'language',
                valueType: 'stringValue',
                value: lang
            });
        }
    }

    function setReferenceCountry(referenceCountry) {
        var currentPref = service.get('referenceCountry', 'user_preferences');
        if (referenceCountry === 'GB') {
            setLanguage('en');
        }
        if (currentPref) {
            currentPref.value = referenceCountry;
            return service.update(currentPref);
        }
        else {
            return service.add({
                category: 'search',
                name: 'referenceCountry',
                valueType: 'stringValue',
                value: referenceCountry
            });
        }
    }

    function saveCountryPreference(country) {
        service.add({ name: 'searchCountry', category: 'search', value: country.code + ',' + country.name });
    }

    function clearCountryPreference() {
        try {
            var pref = service.get('searchCountry', 'search');
            if (pref) {
                service.remove(pref);
            }
        } catch (err) { }
    }

    return service;
}
