module.exports = {
    Annotations: 'Note',
    AnnotationsFirstLetter: 'N',

    Chapter: 'Capitolo',
    Chapters: 'Capitoli',
    Chapter_code_: 'Capitolo {{ code }}',
    Chapter_GoodsCodeOrSectionNumber_: 'Capitolo {{ GoodsCodeOrSectionNumber }}',

    CombinedNomenclature: 'Nomenclatura combinata',

    CommodityClassification: 'Classificazione delle merci', // Check if this is correct
    CommodityCode: 'Codice nomenclatura', // Check if this is correct, I have also seen codice delle merci, codice merceologico, codice doganale and codide nomenclatura
    CommodityCodes: 'Codici nomenclatura', // Check if this is correct
    CommodityCodesFoundInText: 'Codici nomenclatura trovati nel testo', // Check if this is correct (trovati/presenti, not sure which one is better)

    Heading: 'Voce',
    Heading_code_: 'Voce {{ code }}',
    Heading_GoodsCodeOrSectionNumber_: 'Voce {{ GoodsCodeOrSectionNumber }}',
    Headings: 'Voci',

    Section: 'Sezione',
    Section_number_: 'Sezione {{ number }}',
    Section_GoodsCodeOrSectionNumber_: 'Sezione {{ GoodsCodeOrSectionNumber }}',
    Sections: 'Sezioni',

    SubChapter_GoodsCodeOrSectionNumber_: 'Sottocapitolo {{ GoodsCodeOrSectionNumber }}',
    
    Subheading: 'Sottovoce',

    WcoClassificationOpinions: 'Pareri di classifica dell\'OMD',
    Classifications: 'Classificazioni',
    ExplanatoryNotesFirstLetter: 'E',
    ExplanatoryNotes: 'Note Esplicative',
    ExplanatoryNotesCn: 'Note Esplicative della NC dell\'UE',
    ExplanatoryNotesHs: 'Note Esplicative del SA dell\'OMD',
    Nomenclature: 'Nomenclatura',
    NomenclatureGroups: 'Gruppi di nomenclatura',
    NomenclatureHeadings: 'Nomenclatura - Voci',
    NomenclatureHeading_code_: 'Nomenclatura - Voce {{ code }}',

    SectionAndChapterNotes: 'Note di sezioni e di capitoli',
};