var moment = require('moment');

module.exports = [
    'common', 
    'dataContext', 
    newsDataService
];

function newsDataService(
    common, 
    dataContext
) {
    var service = {
        getNewsOverview: getNewsOverview
    };
    
    return service;
    
    function getNewsOverview(startDate, endDate) {
        var startDateString = moment(startDate).format('YYYY-MM-DD');
        var endDateString = moment(endDate).format('YYYY-MM-DD')
        var url = common.config.apiHosts.generic +
            '/news/overview/' +
            '?lang=' + common.language.get() +
            '&startdate=' + startDateString +
            '&enddate=' + endDateString;

        return dataContext.get(url);
    }

}
