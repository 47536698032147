module.exports = {
    bindings: {
        active: '@'
    },
    controller: newsSubnavController,
    templateUrl: 'news/components/news-subnav.html'
};

newsSubnavController.$inject = ['applicationStateService'];

function newsSubnavController(state) {
    var vm = {
        referenceCountry: state.filter.referenceCountry
    };
    return vm;
}