﻿angular.module('taric.referencePrices', [])
    .config(['$routeProvider', routeConfigurator])
    .component('referencePricesOverviewPage', require('./components/overview-page'))
    .factory('referencePricesDataService', require('./services/referenceprices-data'));

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/referenceprices', {
            template: '<reference-prices-overview-page></reference-prices-overview-page>'
        })
        ;
}
