module.exports = ['common', 'applicationStateService', 'dataContext', 'measureTypesDataService', regulationsDataService];

function regulationsDataService(common, state, dataContext, measureTypesData) {
    var service = {
        getMeasureOverview: getMeasureOverview,
        getRegulation: getRegulation,
        getRegulationDetails: getRegulationDetails,
        getRegulationNews: getRegulationNews,
        getRegulationTypes: getRegulationTypes,
        getRegulationGroups: getRegulationGroups
    };

    function getRegulation(dtvRegulationId) {
        var url = common.config.apiHosts[common.config.currentApi] +
            '/regulations/byDtvRegulationId/' + dtvRegulationId +
            '?lang=' + common.language.get() +
            '&date=' + state.filter.date;

        return dataContext.get(url);
    }

    function getMeasureOverview(celex, pageIndex) {
        var url = common.config.apiHosts[common.config.currentApi] +
            '/regulations/' + celex + '/measure_overview' +
            '?lang=' + common.language.get() +
            '&date=' + state.filter.date +
            '&pindex=' + pageIndex +
            '&psize=10';

        return dataContext.get(url);
    }


    function getRegulationDetails(celex) {

        var url = common.config.apiHosts[common.config.currentApi] +
            '/regulations/detailsByCelex/' + celex +
            '?lang=' + common.language.get() +
            '&date=' + state.filter.date;

        return dataContext.get(url);
    }

    function getRegulationNews(pageNumber, filter) {

        var url = common.config.apiHosts[common.config.currentApi] +
            '/regulations/news' +
            '?lang=' + common.language.get() +
            '&date=' + state.filter.date +
            '&pindex=' + pageNumber +
            '&psize=10';

        if (filter && filter.goodsCode) {
            url += '&goodscode=' + filter.goodsCode;
        }

        return dataContext.get(url);
    }

    function getRegulationTypes() {

        var url = common.config.apiHosts[common.config.currentApi] +
            '/regulations/types' +
            '?lang=' + common.language.get() +
            '&date=' + state.filter.date;

        return dataContext.get(url);
    }

    function getRegulationGroups() {

        var url = common.config.apiHosts[common.config.currentApi] +
            '/regulations/groups' +
            '?lang=' + common.language.get() +
            '&date=' + state.filter.date;

        return dataContext.get(url);
    }

    return service;

}
