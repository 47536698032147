﻿// See https://app.taricsupport.com/#!/sections
module.exports = {
    exampleText: 'per esempio: <em>22030009</em> o <em>6911100090 import cn</em>',
    // landingPage: {
    //     dynamic: {
    //         DE: 'To the German Customs Tariff (EZT)',
    //         EU: 'To the European Customs Tariff (TARIC)',
    //         BE: 'To the Belgian Customs Tariff (TARBEL)',
    //         NL: 'To the Dutch Customs Tariff (DTV)',
    //         CH: 'To the Swiss Customs Tariff (TARES)',
    //         GB: 'To the UK Global Tariff (UKGT)',
    //         SE: 'To the Swedish Customs Tariff',
    //         IT: 'To the Italian Customs Tariff'
    //     }
    // },
    // RecentChanges: 'Recent Changes', // recent changes to the application
    RecentCodes: 'Codici recenti' // recent commodity codes
};
