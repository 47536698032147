module.exports = {
    controller: additionalCodesPageController,
    templateUrl: 'additional-codes/components/additional-codes-page.html'
};

additionalCodesPageController.$inject = [
    '$analytics', 
    '$timeout',
    'notificationsService', 
    'additionalCodesDataService', 
    'applicationStateService',
];

function additionalCodesPageController(
    $analytics, 
    $timeout,
    notifications, 
    additionalCodesData, 
    state
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        filter: '',
        filterType: "",
        isLoading: false,
        isLoadingTypes: false,
        filteredAdditionalCodes: [],
        fileterdAdditionalCodeTypes: [],
        additionalCodes: [],
        additionalCodeTypes: [],
        referenceCountry: state.filter.referenceCountry,
        selectedType: null,
        loadAdditionalCode: loadAdditionalCode,
        clearFilter: clearFilter,
        changeFilter: changeFilter,
        clearFilterType: clearFilterType,
        changeFilterType: changeFilterType,
        focusInputTypeField: focusInputTypeField,
        focusInputField: focusInputField
    };

    function focusInputTypeField() {
        $timeout(function(){
            var inputEL = angular.element(document.querySelector('#inputFilterType'));
            if (inputEL) {
                inputEL[0].focus();
            }
        }, 0);
    }

    function focusInputField() {
        $timeout(function(){
            var inputEL = angular.element(document.querySelector('#inputFilter'));
            if (inputEL) {
                inputEL[0].focus();
            }
        }, 0);
    }

    function attached() {
        $analytics.pageTrack('/reference/additional-codes');
        loadTypes();
        notifications.subscribe('filter_date_changed', dateChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    function dateChanged() {
        loadTypes();
    }

    function clearFilter() {
        vm.filter = '';
        vm.filteredAdditionalCodes = vm.additionalCodes;
        focusInputField();
    }

    function changeFilter() {
        vm.filteredAdditionalCodes = vm.additionalCodes.filter(function (el) {
            return (el.type.toLowerCase() + el.code.toLowerCase()).includes(vm.filter.toLowerCase()) || 
                el.description.toLowerCase().includes(vm.filter.toLowerCase());
        });
    }

    
    function clearFilterType() {
        clearFilter();
        vm.filterType = "";
        vm.fileterdAdditionalCodeTypes = vm.additionalCodeTypes;
        vm.additionalCodes = [];
        vm.filteredAdditionalCodes = [];
        vm.selectedType = null;
        focusInputTypeField();
    }

    function changeFilterType() {
        vm.fileterdAdditionalCodeTypes = vm.additionalCodeTypes.filter(function (el) {
            return el.code.toLowerCase().includes(vm.filterType.toLowerCase()) || el.description.toLowerCase().includes(vm.filterType.toLowerCase());
        });
        if(vm.fileterdAdditionalCodeTypes.length === 1) {
            loadAdditionalCode(vm.fileterdAdditionalCodeTypes[0]);
        }
        if(vm.fileterdAdditionalCodeTypes.length === 0) {
            vm.additionalCodes = [];
            vm.filteredAdditionalCodes = [];
            vm.selectedType = null;
            vm.filter = "";
        }
    }

    function loadAdditionalCode(type) {
        vm.isLoading = true;
        vm.selectedType = type.code;
        vm.filter = "";
        additionalCodesData.getByType(type.code)
            .then(function (data) {
                vm.filteredAdditionalCodes = data;
                vm.additionalCodes = data;
                vm.isLoading = false;
                focusInputField();
            });
    }

    function loadTypes() {
        if (vm.referenceCountry == 'CH') {
            return;
        }
        vm.isLoadingTypes = true;
        vm.additionalCodes = [];
        vm.selectedType = null;
        
        additionalCodesData.getTypes()
            .then(function (data) {
                vm.additionalCodeTypes = data;
                vm.fileterdAdditionalCodeTypes = data;
                vm.isLoadingTypes = false;
                focusInputTypeField();
            });
    }

    return vm;
}
