﻿module.exports = ['common', 'dataContext', 'applicationStateService', 'identity', textDataService];

function textDataService(common, context, state, identity) {

    var service = {
        getCccConclusions: getCccConclusions,
        getClassificationOther: getClassificationOther,
        getSectionsAndChaptersWithExplanatoryNotes: getSectionsAndChaptersWithExplanatoryNotes,
        getSubChaptersAndHeadingsWithExplanatoryNotes: getSubChaptersAndHeadingsWithExplanatoryNotes,
        getEuExplanatoryNotes: getEuExplanatoryNotes,
        getGermanExplanatoryNotes: getGermanExplanatoryNotes,
        getWcoExplanatoryNotes: getWcoExplanatoryNotes,
        getNewEnglishClassifications: getNewEnglishClassifications,
        getOldEnglishClassifications: getOldEnglishClassifications,
        getTariferingen: getTariferingen,
        getWcoClassificationOpinions: getWcoClassificationOpinions,
    };

    function formUrl(api, url) {
        var separator = url.indexOf('?') === -1 ? '?' : '&';
        return common.config.apiHosts[api] + url + separator +
            'lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;
    }

    function getClassificationOther(code) {
        var deferred = common.$q.defer();

        var req = context.get(formUrl('generic', '/classifications/other/' + code))
            .then(deferred.resolve, deferred.reject);

        return deferred.promise;
    }

    // TODO: if we cache these, add more metadata (see nomenclature-data.js)
    var itemsWithExplanatoryNotes = {
        sectionsAndChapters: null,
        subchaptersAndHeadings: {}
    };

    function getWcoExplanatoryNotes(nomenclatureLevel, goodsCodeOrSectionNumber) {
        nomenclatureLevel = nomenclatureLevel.toUpperCase();
        var url = formUrl('generic', '/wco-explanatory-notes-for-goodscode/' + nomenclatureLevel + '/' + goodsCodeOrSectionNumber);
        return context.get(url);
    }

    function getEuExplanatoryNotes(nomenclatureLevel, goodsCodeOrSectionNumber) {
        nomenclatureLevel = nomenclatureLevel.toUpperCase();
        var url = formUrl('generic', '/eu-explanatory-notes-for-goodscode/' + nomenclatureLevel + '/' + goodsCodeOrSectionNumber);
        return context.get(url);
    }

    function getGermanExplanatoryNotes(nomenclatureLevel, goodsCodeOrSectionNumber) {
        nomenclatureLevel = nomenclatureLevel.toUpperCase();
        var url = formUrl('dtv', '/ezt/explanatory-notes/' + nomenclatureLevel + '/' + goodsCodeOrSectionNumber);
        return context.get(url);
    }

    function getSectionsAndChaptersWithExplanatoryNotes() {
        var deferred = common.$q.defer();
        if (itemsWithExplanatoryNotes.sectionsAndChapters) {
            deferred.resolve(itemsWithExplanatoryNotes.sectionsAndChapters);
        }
        else {
            var url = formUrl('generic', '/explanatory-notes/sections-and-chapters-with-notes/');
            context.get(url).then(function(response) {
                itemsWithExplanatoryNotes.sectionsAndChapters = response;
                deferred.resolve(response);
            });
        }
        return deferred.promise;
    }

    function getSubChaptersAndHeadingsWithExplanatoryNotes(chapterId) {
        var deferred = common.$q.defer();
        if (itemsWithExplanatoryNotes.subchaptersAndHeadings[chapterId]) {
            deferred.resolve(itemsWithExplanatoryNotes.subchaptersAndHeadings[chapterId]);
        }
        else {
            var url = formUrl('generic', '/explanatory-notes/subchapters-and-headings-with-notes/' + chapterId);
            context.get(url).then(function(response) {
                itemsWithExplanatoryNotes.subchaptersAndHeadings[chapterId] = response;
                deferred.resolve(response);
            });
        }
        return deferred.promise;
    }

    function getTariferingen(code) {
        var deferred = common.$q.defer();

        var req = context.get(formUrl('generic', '/classifications/tariferingen/' + code))
            .then(deferred.resolve, deferred.reject);

        return deferred.promise;
    }

    function getNewEnglishClassifications(code) {
        var url = formUrl('generic', '/classifications/en-after-2013/' + code);
        return context.get(url);
    }

    function getOldEnglishClassifications(code) {
        var url = formUrl('generic', '/classifications/en-before-2014/' + code);
        return context.get(url);
    }

    function getWcoClassificationOpinions(code) {
        var deferred = common.$q.defer();
        if (!identity.hasPermission('toelichtingen_wco')) {
            deferred.reject();
        }
        else {
            context.get(formUrl('generic', '/classifications/wco/' + code))
                .then(deferred.resolve, deferred.reject);
        }
        return deferred.promise;
    }

    function getCccConclusions(commodityCode, query) {
        var url = common.config.apiHosts.generic + '/ccc-conclusions/search?lang=en';
        if(commodityCode) {
            url = url + '&goodscode=' + commodityCode;
        }
        if(query) {
            url = url + '&q=' + query;
        }
        return context.get(url)
    }

    return service;

}