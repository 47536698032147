module.exports = [toCurrencySignFilter];

function toCurrencySignFilter() {
    return function (input) {
        switch (input) {
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return input;
        }
    };
}