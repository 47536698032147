// See https://app.taricsupport.com/#!/exchange-rates
module.exports = {
    Currency: 'Valuta',
    CurrencyCode: 'Codice valuta',
    CurrencyCodeModalTitle: 'Scegli codice valuta',
    CurrencyCodeTypeaheadPlaceholder: 'Cerca per codice o nome di valuta',
    CurrencyConversionResults: 'Risultati della conversione di valuta',
    CurrencyName: 'Nome valuta',
    ExchangeRates: 'Tassi di cambio',
    NumberOfDecimalPlaces: 'Numero di decimali',
    UseTheFieldsBelowToConvertBetween_and_: 'Utilizza i campi sottostanti per convertire tra {{ inverseMonetaryValue.unit }} e {{ monetaryValue.unit }}.',
};
