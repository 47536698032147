module.exports = {
    vatNumber: 'VAT number',
    cdNumber: 'CD number',
    eoriNumber: 'EORI number',
    rexNumber: 'REX number',
    tinNumber: 'TIN number',
    cdChecking: 'Query EU Customs Decision System',
    checkEORINumber: 'Check EORI number',
    checkRexNumber: 'Check REX number',
    checkViesRegistrationForVatNumber: 'Check VIES registration for VAT number',
    vatCheck: 'Check VAT number (VIES)',
    eoriCheck: 'Check EORI number',
    rexCheck: 'Check REX number',
    cdCheck: 'Query Customs Decision System',
    eoriCheckResultValid: 'The checked EORI number is valid',
    eoriCheckResultInvalid: 'The checked EORI number is invalid',
    rexCheckResultInvalid: 'The checked REX-nummer is invalid',
    city: 'City',
    companyName: 'Company name',
    companyAddress: 'Company address',
    countryCode: 'Country code',
    postalCode: 'Postal Code',
    searchResults: 'Search results',
    serverError: 'An error occurred while processing your request', 
    activitynameField: 'Activity',
    activityflagField: 'Flag',
    listOfGoods: 'List of goods',
    commodityCode: 'Commodity code',
    rexFieldError: 'Please enter a valid REX number',
    eoriFieldError: 'Please enter an valid EORI number',
    ValidateBusinessInformation: 'Validate Business Information',
    ViesWebSiteIntroText: 'You can use the EU\'s VIES website to check if VAT numbers are valid for intra-community supply of goods or services.',
    GoToViesWebsite: 'Go To VIES Website (opens in new window)',
    PRODUCTION: 'Production',
    TRADING: 'Trading',
};