module.exports = {
    bindings: {
        updateQueryString: '<'
    },
    controller: ecicsFormController,
    templateUrl: 'ecics/components/ecics-form.html'
};

ecicsFormController.$inject = [
    '$location',
    'ecicsDataService',
];

function ecicsFormController(
    $location,
    ecicsDataService
) {
    var vm = {
        $onInit: attached,
        filter: ecicsDataService.filter,
        service: ecicsDataService,
        loadNew: loadNew
    };


    return vm;

    function attached() {
        if (vm.updateQueryString) {
            updateSearchQuery();
        }
        ecicsDataService.init();
    }

    function loadNew() {
        if (vm.updateQueryString) {
            updateSearchQuery();
        }
        ecicsDataService.loadNewItems();
    }

    function updateSearchQuery() {
        $location.search('query', vm.filter.name);
        $location.search('commodityCode', vm.filter.goodsCode);
    }
}