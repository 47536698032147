﻿module.exports = ['$analytics', '$uibModal', footnoteModal];

function footnoteModal($analytics, $uibModal) {
    var modal = {
        footnotes: [],
        show: showModal
    };

    footnoteModalController.$inject = ['$uibModalInstance', 'footnote', 'footnotes'];

    function footnoteModalController($uibModalInstance, footnote, footnotes) {

        var ctrlFootnoteModal = {
            cancel: cancel,
            footnote: footnote,
            footnotes: footnotes
        };

        function cancel() {
//            $(".page-dashboard, .taric-page-content").removeClass("noprint");
            $uibModalInstance.dismiss('cancel');
        }

        return ctrlFootnoteModal;
    }

    function showModal(footnote, footnotes) {
        $analytics.eventTrack('open-modal-footnotes', { category: 'footnotes' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            controller: footnoteModalController,
            controllerAs: 'ctrlFootnoteModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                footnote: function () {
                    return footnote;
                },
                footnotes: function () {
                    return footnotes;
                }
            },
            size: 'lg',
            templateUrl: 'footnotes/modals/footnote.html',
            windowClass: 'modal-footnote'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
