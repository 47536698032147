module.exports = ['config', 'dataContext', 'applicationStateService', taricStatusService];

function taricStatusService(config, context, state) {
    var service = {
        loadLastSuccessfulDatabase: loadLastSuccessfulDatabase,
        status: null,
        versionNumber: config.version
    };

    function loadLastSuccessfulDatabase() {
        return context.get(config.apiHosts[config.currentApi] + '/status?referencecountry=' + state.filter.referenceCountry)
            .then(function (result) {
                service.lastSuccessfulDatabase = result;
            });
    }

    return service;
}

