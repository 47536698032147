﻿// See https://app.taricsupport.com/#!/search/heading?orderBy=relevance&includeBtis=true&includeClassificationRegulations=true
module.exports = {
    AlphabeticalIndex: 'Alphabetical Index',
    AlsoSearchIn: 'Also search in',
    AlphabeticalIndexNotAvailableInChosenLanguage: 'Alphabetical Index Search is available in English and German. Please switch to one of these languages in order to continue your search.',
    CaseLawNoAccessMessage: 'Contact Taric Support for Access to the Case Law Module',
    CommodityCodeDescriptions: 'Commodity code descriptions',
    AdditionalDataSources: 'Additional data sources',
    SearchCccConclusions: 'Search CCC Conclusions', // CCC = Customs Code Committee
    Conclusion_Nav: 'CCC Conclusions',
    Documents: 'Documents',
    GoToChapter_code_: 'Go to Chapter {{ code }}',
    BrowseChapter_chapterCode_: 'Browse chapter {{ chapterCode }}',
    NoSearchResultsFor_QueryString_FoundInHeading_Heading_: 'No results for "{{ QueryString }}" found in heading {{ Heading }}',
    SearchResultsFor_QueryString_FoundInHeading_Heading_: 'Results for "{{ QueryString }}" found in heading {{ Heading }}',
    _commodityCode_isNotAValidCommodityCode: '{{ commodityCode }} is not a valid commodity code',
    SearchForSuccessors: 'Search for successors',
    SearchForOriginators: 'Search for originators',

    noResult: 'No results found for your search query',
    ClosestExistingParentMessage: 'However, commodity code {{ closestExistingParentCommodityCode | stripTaricCode | formatTaricCode }}, shown below, does exist.',
    
    NoResultsFoundForYourSearchQuery_QueryString_: 'No results found for your search query "{{ QueryString }}"',
    NoResultsFoundForYourSearchQuery_QueryString_FoundInChapter_Code_: 'No results found for your search query "{{ QueryString }}" found in chapter {{ Code }}.',
    
    Opinion_Number_: 'Opinion {{ Number }}', // Short For WCO Classification Opinion
    
    SearchResultsFor_QueryString_: 'Search results for "{{ QueryString }}"',
    SearchResultsFor_QueryString_FoundInAlphabeticalIndex: 'Search results for "{{ QueryString }}" found in the Alphabetical Index',
    SearchResultsFor_QueryString_FoundInCode: 'Search results for "{{ QueryString }}" found under code',
    SearchResultsFor_QueryString_FoundInHeading: 'Search results for "{{ QueryString }}" found in heading',
    SearchResultsFor_QueryString_FoundInHeadings: 'Search results for "{{ QueryString }}" found in the following headings',
    SearchResultsFor_QueryString_FoundInHeadingsInChapter_Code_: 'Search results for "{{ QueryString }}" found in the following headings in chapter {{ Code }}',
    SearchResultsFor_QueryString_FoundInAnnotations: 'Search results for "{{ QueryString }}" found in the following Section and Chapter Notes',
    SearchResultsFor_QueryString_FoundInEuExplanatoryNotes: 'Search results for "{{ QueryString }}" found in the following Explanatory Notes to the EU\'s Combined Nomenclature',
    SearchResultsFor_QueryString_FoundInExplanatoryNotesGermany: 'Search results for "{{ QueryString }}" found in the following Explanatory Notes published by German Customs',
    SearchResultsFor_QueryString_FoundInTextsGermanCustoms: 'Search results for "{{ QueryString }}" found in the following texts published by German Customs',
    SearchResultsFor_QueryString_FoundInWcoExplanatoryNotes: 'Search results for "{{ QueryString }}" found in the following Explanatory Notes to the WCO\'s Harmonised System',
    SearchResultsFor_QueryString_FoundInWcoClassificationOpinions: 'Search results for "{{ QueryString }}" found in the following WCO Classification Opinions',

    SearchNomenclature: 'Search Nomenclature',
    SearchAlphabeticalIndex: 'Search Alphabetical Index',
    SearchEUClassificationRegulations: 'Search EU Classification Regulations',
    SearchExplanatoryNotesGermany: 'Search Explanatory Notes Germany',
    SearchTextsGermanCustoms: 'Search Texts German Customs',
    SearchSectionAndChapterNotes: 'Search Section and Chapter Notes',
    SearchEUExplanatoryNotes: 'Search EU Explanatory Notes',
    SearchWCOExplanatoryNotes: 'Search HS Explanatory Notes (WCO)',
    SearchWCOClassificationOpinions: 'Search WCO Classification Opinions',
    TryOneOfTheFollowingActions: 'You may try one of the following actions',
    WcoExplanatoryNotesNotAvailableInChosenLanguage: 'The WCO Explanatory Notes are available in English and French. Please switch to one of these languages in order to continue your search.',
    WcoClassificationOpinionsNotAvailableInChosenLanguage: 'The WCO Classification Opinions are available in English and French. Please switch to one of these languages in order to continue your search.'
};
