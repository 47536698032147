module.exports = {
    CombinedNomenclatureExportIntraStat: 'Gecombineerde Nomenclatuur &mdash; export/intrastat',
    CommodityCodeChanges: 'Veranderingen goederencodes',
    CorrelationTable: 'Transponeringstabel',
    DerivedFrom: 'Afgeleid van',
    ExpiredCode: 'Verlopen code',
    ExpiredCodes: 'Verlopen codes',
    HarmonizedSystem2022: 'Geharmoniseerd Systeem 2022',
    HS2022Forward: 'Transponering HS2017 naar HS2022',
    HS2022Backward: 'Transponering HS2022 naar HS2017',
    MaxOneYearInterval: 'Het verschil tussen start- en einddatum kan niet meer dan een jaar zijn',
    NewCode: 'Nieuwe code',
    NewCodes: 'Nieuwe codes',
    Nomenclature: 'Nomenclatuur',
    RecentChanges: 'Recente veranderingen',
    RecentCommodityCodeChanges: 'Recente veranderingen goederencodes',
    ReplacedBy: 'Vervangen door',
    Remarks: 'Opmerkingen',
    SearchCorrelationTable: 'Zoeken in transponeringstabel',
    TaricImport: 'TARIC &mdash; import',
    UpcomingChanges: 'Aankomende veranderingen',
    UpcomingCommodityCodeChanges: 'Aankomende veranderingen goederencodes'
};