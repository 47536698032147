module.exports = {
    controller: exchangeRatesController,
    templateUrl: 'exchange-rates/components/exchange-rates-page.html'
};

exchangeRatesController.$inject = [
    '$analytics', 
    '$timeout',
    'notificationsService', 
    'exchangeRateDataService', 
    'applicationStateService',
];

function exchangeRatesController(
    $analytics,
    $timeout, 
    notifications, 
    exchangeRateData, 
    state
) {

    var monetaryValuePrototype = {
        amount: 1,
        unit: 'EUR',
        originalAmount: 1,
        originalUnit: 'EUR'
    };

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        filter: "",
        isLoading: false,
        isLoadingCurrencies: false,
        exchangeRate: null,
        currencies: [],
        filteredCurrencies: [],
        selectedCurrency: null,
        monetaryValue: _.clone(monetaryValuePrototype),
        inverseMonetaryValue: _.clone(monetaryValuePrototype),
        referenceCountry: state.filter.referenceCountry,
        referenceDate: state.filter.date,
        decimalPlaces: 4,
        loadExchangeRate: loadExchangeRate,
        clearFilter: clearFilter,
        changeFilter: changeFilter,
        focusExchangeFilter: focusExchangeFilter,
        focusMonetaryFilter: focusMonetaryFilter
    };

    function attached() {
        $analytics.pageTrack('/reference/exchange-rates');
        if (state.filter.referenceCountry === 'CH') {
            return;
        }
        var targetCurrency = exchangeRateData.getTargetCurrency(state.filter.referenceCountry);
        loadCurrencies(targetCurrency.code);
        notifications.subscribe('filter_date_changed', dateChanged);
        vm.monetaryValue.unit = targetCurrency.code;
        vm.monetaryValue.originalUnit = targetCurrency.code;
        vm.inverseMonetaryValue.unit = targetCurrency.code;
        vm.inverseMonetaryValue.originalUnit = targetCurrency.code;
        focusExchangeFilter();
    }

    function changeFilter() {
        vm.filteredCurrencies = vm.currencies.filter(function (el) {
            return el.code.toLowerCase().includes(vm.filter.toLowerCase()) || el.description.toLowerCase().includes(vm.filter.toLowerCase());
        });
        if(vm.filteredCurrencies.length === 1) {
            loadExchangeRate(vm.filteredCurrencies[0]);
        }
    }

    function clearFilter() {
        vm.filter = "";
        vm.filteredCurrencies = vm.currencies;
        focusExchangeFilter();
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    function dateChanged() {
        vm.referenceDate = state.filter.date;
        var targetCurrency = exchangeRateData.getTargetCurrency(state.filter.referenceCountry);
        loadCurrencies(targetCurrency.code);
        focusExchangeFilter();
    }

    function loadExchangeRate(currency) {
        vm.isLoading = true;
        vm.selectedCurrency = currency.code;

        var targetCurrency = exchangeRateData.getTargetCurrency(state.filter.referenceCountry);
        
        exchangeRateData.getExchangeRate(currency.code, targetCurrency.code)
            .then(function (data) {
                vm.exchangeRate = data;
                vm.monetaryValue.originalUnit = currency.code;
                vm.monetaryValue.exchangeRate = data.APerB;
                vm.monetaryValue.amount = vm.monetaryValue.originalAmount / data.APerB;
                
                vm.inverseMonetaryValue.unit = currency.code;
                vm.inverseMonetaryValue.exchangeRate = 1.0 / data.APerB;
                vm.inverseMonetaryValue.amount = vm.inverseMonetaryValue.originalAmount * data.APerB;
                vm.isLoading = false;
            });
    }

    function loadCurrencies(targetCurrencyCode) {
        vm.isLoadingCurrencies = true;
        vm.exchangeRate = null;
        vm.selectedCurrency = null;
        exchangeRateData.getCurrencies(targetCurrencyCode)
            .then(function (data) {
                vm.currencies = data;
                vm.filteredCurrencies = data;
                vm.isLoadingCurrencies = false;
                if (vm.currencies && vm.currencies.length) {
                    loadExchangeRate(vm.currencies[0]);
                }
            });

    }

    function focusExchangeFilter() {
        $timeout(function() {
            document.querySelector('.exchange-filter').focus();
        },0);
    }

    
    function focusMonetaryFilter() {
        $timeout(function() {
            document.querySelector('.monetary-filter:nth-child(1) input').focus();
        },0);
    }

    return vm;

}
