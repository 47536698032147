module.exports = {
    Currency: 'Munteenheid',
    CurrencyCode: 'Code munteenheid',
    CurrencyCodeModalTitle: 'Kies een munteenheid',
    CurrencyCodeTypeaheadPlaceholder: 'Zoek op code of naam munteenheid',
    CurrencyConversionResults: 'Resultaat van omrekening valuta',
    CurrencyName: 'Naam munteenheid',
    ExchangeRates: 'Wisselkoersen',
    NumberOfDecimalPlaces: 'Aantal cijfers achter de komma',
    UseTheFieldsBelowToConvertBetween_and_: 'Gebruik de velden hieronder om bedragen om te rekenen tussen {{ inverseMonetaryValue.unit }} en {{ monetaryValue.unit }}',
};
