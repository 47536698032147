module.exports = ['$analytics', '$uibModal', certificateCodeSelectorModal];

function certificateCodeSelectorModal($analytics, $uibModal) {
    var modal = {
        show: showModal
    };

    certificateCodeSelectorModalController.$inject = ['$uibModalInstance', 'possibleCertificateCodes', 'certificateCode'];

    function certificateCodeSelectorModalController($uibModalInstance, possibleCertificateCodes, certificateCode) {
        var vm = {
            certificateCode: certificateCode,
            cancel: cancel,
            possibleCertificateCodes: possibleCertificateCodes,
            ok: ok
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function ok(code) {
            $uibModalInstance.close(code);
        }

        return vm;
    }

    function showModal(possibleCertificateCodes, certificateCode) {
        $analytics.eventTrack('open-modal-certificate-selector', { category: 'certificate-codes' });
        var modalInstance = $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: certificateCodeSelectorModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                certificateCode: function () {
                    return certificateCode;
                },
                possibleCertificateCodes: function () {
                    return possibleCertificateCodes;
                }
            },
            size: 'lg',
            templateUrl: 'certificate-codes/modals/certificate-code-selector.html',
            windowClass: 'modal-certificate-code-selector'
        });

        modalInstance.rendered.then(function() {
            var elementToFocusOnInModal = document.querySelector('.modal.in .focus-on-open-modal');
            if (elementToFocusOnInModal) {
                elementToFocusOnInModal.focus();
            }
        });

        return modalInstance;
    }

    return modal;
}
