﻿module.exports = ['$uibModal', areYouSureModal];

function areYouSureModal($uibModal) {
    var modal = {
        show: showModal
    };

    areYouSureModal.$inject = ['$uibModalInstance', 'modalBody'];

    function areYouSureModalController($uibModalInstance, modalBody) {

        var ctrlAreYouSureModal = {
            cancel: cancel,
            confirm: confirm,
            body: modalBody
        };

        function cancel() {
            //            $(".page-dashboard, .taric-page-content").removeClass("noprint");
            $uibModalInstance.dismiss('cancel');
        }

        function confirm() {
            $uibModalInstance.dismiss(true);
        }

        return ctrlAreYouSureModal;
    }

    function showModal(modalBody) {

        return $uibModal.open({
            animation: true,
            backdrop: true,
            controller: areYouSureModalController,
            controllerAs: 'ctrlAreYouSureModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                modalBody: function () {
                    return modalBody;
                }
            },
            templateUrl: 'widgets/modals/are-you-sure.html',
            windowClass: 'modal-are-you-sure'
        });
    }

    return modal;
}
