module.exports = {
    controller: searchAlphabeticalIndexController,
    templateUrl: 'search/components/pages/search-alphabetical-index.html'
};

searchAlphabeticalIndexController.$inject = [
    '$analytics', 
    '$location', 
    'dataContext', 
    'language', 
    'config', 
    'applicationStateService'
];

function searchAlphabeticalIndexController(
    $analytics, 
    $location, 
    dataContext, 
    language, 
    config, 
    state
) {

    var vm = {
        $onInit: attached,
        isLoading: false,
        language: language.get(),
        query: {
            QueryString: ''
        },
        results: [],
        search: search,

    };

    return vm;
    
    function attached() {
        $analytics.pageTrack('/alphabetical-index-search');
        loadSearch();
    }

    function search(query) {
        $location.path('/search/alphabetical-index').search(query);
    }


    function loadSearch() {
        vm.query.QueryString = $location.search().query;
        var url = config.apiHosts.generic + '/alphabetical-index/search' +
            '?lang=' + vm.language +
            '&q=' + vm.query.QueryString +
            '&date=' + state.filter.date;
        if (vm.query.QueryString && vm.query.QueryString.length > 1) {
            vm.language = language.get();
            vm.isLoading = true;
            if (vm.language === 'en' || vm.language === 'de') {
                dataContext.get(url)
                    .then(function (data) {
                        vm.isLoading = false;
                        vm.results = data;
                    });
            }
        }
    }
}