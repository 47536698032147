var moment = require('moment');

module.exports = ['$location', '$routeParams', 'common', 'dataContext', 'identity', nlCaseLawDataService];

function nlCaseLawDataService($location, $routeParams, common, context, identity) {
    var service = {
        clear: clear,
        isLoading: false,
        judgment: {},
        loadCaseLawNews: loadCaseLawNews,
        loadCaseLawSearchResults: loadCaseLawSearchResults,
        loadJudgment: loadJudgment,
        query: {
            Ecli: $routeParams.ecli || null,
            EndDate: null,
            GoodsCode: null,
            Query: null,
            StartDate: null,
            Tag: null,
            Zaaknummer: null,
            PageNumber: 1
        },
        response: {},
    };

    function clear() {
        service.query.Ecli = null;
        service.query.EndDate = null;
        service.query.GoodsCode = null;
        service.query.Query = null;
        service.query.StartDate = null;
        service.query.Tag = null;
        service.query.Zaaknummer = null;
        service.query.PageNumber = 1;
        $location.search('ecli', null);
    }

    function loadCaseLawNews(pageNumber) {
        clear();
        service.query.PageNumber = pageNumber;
        service.response = {};
        if (identity.hasPermission('case-law-nl')) {
            service.isLoadingNL = true;
            var dutchCaseLawUrl = common.config.apiHosts.generic + '/caselaw/nl/documents/search';

            dutchCaseLawUrl += '?size=10&page=' + service.query.PageNumber;

            // TODO: in production, it should not be possible to view unpublished items at all
            // Hence, it should be possible to remove this line by now
            dutchCaseLawUrl += '&published=True';
            return context.get(dutchCaseLawUrl)
                .then(function (data) {
                    if (data) {
                        service.response = data;
                    }
                    service.isLoading = false;
                }, function (data, status, jqXhr) {
                    if (status === 401) {
                        service.isLoading = false;
                        service.noAccess = true;
                    }
                });
        }
    }

    function loadCaseLawSearchResults() {
        if (identity.hasPermission('case-law-nl')) {
            service.isLoading = true;
            service.response = {};
            var dutchCaseLawUrl = common.config.apiHosts.generic + "/caselaw/nl/documents/search";
            if (service.query.Ecli) {
                dutchCaseLawUrl += '?fields=ecli&q=' + service.query.Ecli;
            }
            else if (service.query.Zaaknummer) {
                dutchCaseLawUrl += '?fields=zaaknummer&q=' + service.query.Zaaknummer;
            }
            else {
                dutchCaseLawUrl += '?page=' + service.query.PageNumber;
                if (service.query.GoodsCode) {
                    dutchCaseLawUrl += "&goodscode=" + service.query.GoodsCode;
                }
                if (service.query.Query) {
                    dutchCaseLawUrl += "&fields=inhoudsindicatie,document&q=" + service.query.Query;
                }
                // TODO: implementatie van zoeken op publicatiedatum
                if (service.query.StartDate) {
                    dutchCaseLawUrl += '&uitspraakDatumStart=' + moment(service.query.StartDate).format('YYYY-MM-DD');
                }
                if (service.query.EndDate) {
                    dutchCaseLawUrl += '&uitspraakDatumEinde=' + moment(service.query.EndDate).format('YYYY-MM-DD');
                }
            }
            // TODO: it should be possible to remove the next line
            dutchCaseLawUrl += '&published=True';
            return context.get(dutchCaseLawUrl)
                .then(function (data) {
                    if (data) {
                        service.response = data;
                    }
                    service.isLoading = false;
                }, function (data, status, jqXhr) {
                    if (status === 401) {
                        service.isLoading = false;
                        service.noAccess = true;
                    }
                });
        }
    }

    function loadJudgment(ecli) {
        if (identity.hasPermission('case-law-nl')) {
            service.isLoading = true;
            service.judgment.pdfUrl = 'https://uitspraken.rechtspraak.nl/InzienDocument/GetPdf?ecli=' + ecli;
            var dutchCaseLawUrl = common.config.apiHosts.generic + "/caselaw/nl/documents/" + ecli + "/document";
            context.get(dutchCaseLawUrl)
                .then(function (data) {
                    if (data) {
                        service.judgment.response = data;
                    }
                    service.isLoading = false;
                }, function (data, status, jqXhr) {
                    if (status === 401) {
                        service.isLoading = false;
                        service.noAccess = true;
                    }
                });
        }
    }
    return service;
}