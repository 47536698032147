module.exports = {
    bindings: {
        description: '<',
        onClickDescriptionLine: '&'
    },
    controller: commodityCodeDescriptionExportController,
    templateUrl: 'nomenclature/components/commodity-code-description.html'
}

commodityCodeDescriptionExportController.$inject = ['goodDisplayService', 'applicationStateService'];

function commodityCodeDescriptionExportController(goodDisplayService, state) {
    var vm = {
        state: state,
        copyDescription: function() {
            goodDisplayService.copyDescription(vm.description.lines, state.filter.movement);
        }
    };
    return vm;
}