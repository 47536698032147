﻿module.exports = {
    controller: chapterController,
    templateUrl: 'nomenclature/components/chapter-page.html'
};

chapterController.$inject = [
    '$analytics', 
    '$routeParams', 
    'atrDataService',
    'btiDataService',
    'caseLawDataService',
    'notificationsService', 
    'nomenclatureDataService', 
    'nomenclatureTreeService',
    'common', 
    'applicationStateService',
    'ecicsDataService',
];

function chapterController(
    $analytics, 
    $routeParams, 
    atrDataService,
    btiDataService,
    caseLawDataService,
    notifications, 
    nomenclatureDataService, 
    nomenclatureTreeService,
    common, 
    state,
    ecicsDataService
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        chapter: {},
        chapterNumber: '',
        ecicsCount: {},
        numberOfEcicsItems: 0,
        clickRow: clickRow,
        doubleClickRow: doubleClickRow,
        deselectLine: deselectLine,
        goHome: goHome,
        hasCaseLaw: hasCaseLaw,
        isLoading: false,
        isAuthorized: true,
        state: state,
    };

    function dateChanged() {
        loadChapter($routeParams.chapterNumber);
    }

    function attached() {
        $analytics.pageTrack('/chapter');
        common.auth.check();

        if ($routeParams.chapterNumber !== null && state.filter.movement !== null) {
            loadChapter($routeParams.chapterNumber);
            loadEcicsCount($routeParams.chapterNumber);
        }

        notifications.subscribe('filter_movement_changed', movementChanged);
        notifications.subscribe('filter_date_changed', dateChanged);
    }

    function movementChanged() {
        loadChapter($routeParams.chapterNumber);
    }

    function loadEcicsCount(chapterNumber) {
        ecicsDataService.countPerHeading(chapterNumber)
            .then(function(ecicsCount) {
                vm.ecicsCount = ecicsCount;
                vm.numberOfEcicsItems = _.chain(vm.ecicsCount).values().sum().value();
            });
    }

    function detached() {
        notifications.unsubscribe('filter_movement_changed', movementChanged);
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    // TODO: this exact function is in controllers/search.js. Can we move this (or part of it) to the nomenclature tree service?
    function clickRow(heading) {
        state.currentCode = heading.value.commodityCode;
        state.currentIndentation = heading.value.indentation;
        if (!heading.hasChildren) {
            common.$location.url('good/' + heading.value.commodityCode);
        }
        else {
            heading.metadata.isExpanded = !heading.metadata.isExpanded;
        }
    }

    // TODO: this exact function is in controllers/search.js. Can we move this (or part of it) to the nomenclature tree service?
    function doubleClickRow(heading) {
        state.currentCode = heading.value.commodityCode;
        state.currentIndentation = heading.value.indentation;
        if (!heading.hasChildren) {
            common.$location.url('good/' + heading.value.commodityCode);
        }
        else if (heading.metadata.isExpanded) {
            nomenclatureTreeService.collapseAll(heading);
        }
        else {
            nomenclatureTreeService.expandAll(heading);
        }
        common.interaction.clearSelection();
    }

    function scrollHeadingIntoView() {
        setTimeout(function() {
            var activeHeading = document.querySelector('.taric-box.active');
            if (activeHeading) {
                activeHeading.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 1);
    }

    function hasCaseLaw(heading) {
        return caseLawDataService.euCaseLawItemsPerHeading[heading] || caseLawDataService.dutchCaseLawItemsPerHeading[heading];
    }

    function goHome() {
        common.$location.url('');
    }

    function loadChapter(chapterNumber) {
        vm.isLoading = true;
        vm.chapterNumber = chapterNumber;

        caseLawDataService.getCaseLawInfo(chapterNumber);
        chapterNumber = common.formatting.padLeft(chapterNumber, 2, '0');
        chapterNumber = common.formatting.padRight(chapterNumber, 10, '0');
        atrDataService.countAtrsByHeading(chapterNumber)
            .then(function (atrCount) {
                vm.atrCount = atrCount;
            });
        btiDataService.countBtisByHeading(chapterNumber)
            .then(function (btiCount) {
                vm.btiCount = btiCount;
            });

        nomenclatureDataService.getChapter(chapterNumber, state.filter.movement, state.filter.date)
            .then(function(data) {
                vm.chapterNumber = data.metadata.chapterNumber;
                vm.sectionNumber = data.metadata.sectionNumber;
                vm.chapter = data.tree;
                scrollHeadingIntoView();
                nomenclatureDataService
                    .getSection(data.metadata.sectionNumber)
                    .then(function (section) {
                        vm.section = section;
                        vm.isLoading = false;
                    })
            }, function () { 
                vm.isLoading = false;
            });
    }

    function deselectLine() {
        vm.state.currentCode = null;
        vm.state.currentIndentation = null;
    }

    return vm;

}