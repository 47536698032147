module.exports = {
    bindings: {
        activeItem: '<'
    },
    controller: ecicsDetailsController,
    templateUrl: 'ecics/components/ecics-details.html'
}

ecicsDetailsController.$inject = [
    'ecicsDataService', 
    'language'
];

function ecicsDetailsController(
    ecicsDataService, 
    language
) {
    var vm = {
        service: ecicsDataService,
        language: language.get()
    };

    return vm;
}