﻿module.exports = {
    controller: complianceDetailsController,
    templateUrl: 'compliance/components/compliance-details-page.html'
};

complianceDetailsController.$inject = [
    '$analytics', 
    '$routeParams', 
    'complianceDataService'
];

function complianceDetailsController(
    $analytics, 
    $routeParams, 
    complianceData
) {

    var vm = {
        $onInit: attached,
        canLoadMore: false,
        hasErrorCode: hasErrorCode,
        hideLinesWithoutRemarks: false,
        job: null,
        jobDetails: [],
        jobId: null,
        loadMore: loadMore,
        pageIndex: 0,
        pageSize: 100
    };

    function attached() {
        $analytics.pageTrack('/abc-details');
        if ($routeParams.jobId) {
            vm.jobDetails = [];
            vm.jobId = parseInt($routeParams.jobId);
            vm.pageIndex = 0;
            loadJob();
        }
    }

    function hasErrorCode(errorCodes, line) {
        for (var i = 0; i < errorCodes.length; i++) {
            if (_.some(line.ErrorCodes, function (ec) { return ec.Result === errorCodes[i]; })) {
                return true;
            }
        }
        return false;
    }

    function loadJob() {
        // TODO: Get the job info in a nice way, directly, but the API has to be changed to support this
        // For now we do the superfluous call to get everything and pick the job from there
        complianceData.loadJobs()
            .then(function (result) {
                vm.job = _.find(result, function (j) { return j.JobId === vm.jobId; });
            });

        complianceData.getJobDetails(vm.jobId, vm.pageIndex, vm.pageSize)
            .then(function (data) {
                vm.jobDetails = vm.jobDetails.concat(data);
                vm.canLoadMore = vm.pageSize === data.length;
            }, function (error) {

            });
    }

    function loadMore() {
        vm.pageIndex++;
        loadJob();
    }

    return vm;
}
