module.exports = [additionalCodeTypeaheadFilter];

// Takes care of ordering the results of the additional code typeahed. The logic is as follows:
// First, show the matches on the additional code, ordered by code
// Next, show the matches on the beginning of the additional code description, ordered by description
// Finally, show the matches anywhere else in the description, ordered by description
function additionalCodeTypeaheadFilter() {

    return function (additionalCodes, query) {
        var result;
        if (query === null || query === undefined || query === '') {
            result = _.sortBy(additionalCodes, function(additionalCode) {
                return additionalCode.type + additionalCode.code;
            });
        }
        else {
            var additionalCodeMatchPartition = _.partition(additionalCodes, function(additionalCode) {
                return (additionalCode.type + additionalCode.code).toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) >= 0;
            });
            var additionalCodeMatchStartPartition = _.partition(additionalCodeMatchPartition[0], function(additionalCode) {
                return (additionalCode.type + additionalCode.code).toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) === 0;
            });

            var additionalCodeStartMatches = _.sortBy(additionalCodeMatchStartPartition[0], function(additionalCode) {
                return additionalCode.type + additionalCode.code;
            });
            var additionalCodeOtherMatches = _.sortBy(additionalCodeMatchStartPartition[1], function(additionalCode) {
                return additionalCode.type + additionalCode.code;
            });

            var otherMatches = _.sortBy(additionalCodeMatchPartition[1], 'description');

            var descriptionStartMatchPartition = _.partition(otherMatches, function(additionalCode) {
                return additionalCode.description && additionalCode.description.toLowerCase().indexOf(query.toLowerCase()) === 0;
            });

            var otherDescriptionMatches = _.filter(descriptionStartMatchPartition[1], function(additionalCode) {
                return additionalCode.description && additionalCode.description.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });

            result = _.concat(additionalCodeStartMatches, additionalCodeOtherMatches, descriptionStartMatchPartition[0], otherDescriptionMatches);
        }

        return putAntidumpingDefaultCodesFirst(result);
    }
}

function isAntidumpingDefaultCode(additionalCode) {
    return (additionalCode.type === '8' && additionalCode.code === '900') ||
        (additionalCode.type === 'A' && additionalCode.code === '999') ||
        (additionalCode.type === 'B' && additionalCode.code === '999') ||
        (additionalCode.type === 'C' && additionalCode.code === '999');
}

function putAntidumpingDefaultCodesFirst(additionalCodes) {
    var partition = _.partition(additionalCodes, function(additionalCode) {
        return isAntidumpingDefaultCode(additionalCode);
    });
    return _.concat(partition[0], partition[1]);
}
