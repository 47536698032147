﻿angular
    .module('taric.account', [])
    .config(['$routeProvider', routeConfigurator])
    .component('changePasswordPage', require('./components/change-password-page'))
    .component('forgotPasswordPage', require('./components/forgot-password-page'))
    .component('loginPage', require('./components/login-page'))
    .component('logoutPage', require('./components/logout-page'))
    .component('resetPasswordPage', require('./components/reset-password-page'))
    .factory('favoritesService', require('./services/favorites'))
    .factory('userAccountService', require('./services/user-account'));

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/account/change-password', {
            template: '<change-password-page><change-password-page>'
        })
        .when('/account/forgot-password', {
            template: '<forgot-password-page></forgot-password-page>'
        })
        .when('/account/login', {
            template: '<login-page></login-page>'
        })
        .when('/account/logout', {
            template: '<logout-page></logout-page>'
        })
        .when('/account/reset-password', {
            template: '<reset-password-page></reset-password-page>'
        })
        ;
}
