module.exports = {
    bindings: {
        item: '<'
    },
    controller: nlCaseLawNewsItemController,
    templateUrl: 'case-law/components/nl-case-law-news-item.html'
};

nlCaseLawNewsItemController.$inject = ['nlCaseLawJudgmentModal'];

function nlCaseLawNewsItemController(nlCaseLawJudgmentModal) {
    var vm = {
        showJudgment: showJudgment,
    };

    function showJudgment(ecli) {
        nlCaseLawJudgmentModal.show(ecli);
    }

    return vm;
}