module.exports = {
    controller: regulationDetailsController,
    templateUrl: 'regulations/components/regulation-details-page.html'
};

regulationDetailsController.$inject = [
    '$analytics', 
    '$routeParams',
    'common', 
    'notificationsService',
    'regulationsDataService', 
    'measureTypesDataService',
];

function regulationDetailsController(
    $analytics, 
    $routeParams,
    common, 
    notificationsService,
    regulationsData, 
    measureTypesData
) {

    var vm = {
        celex: '',
        isLoading: false,
        languageId: common.language.get(),
        measureTypes: {},
        regulation: {},
        $onInit: attached,
        $onDestroy: detached,
    };

    return vm;

    function attached() {
        $analytics.pageTrack('/regulation-details');
        common.auth.check();
        if ($routeParams.celex) {
            loadRegulationDetails($routeParams.celex);
        }
        notificationsService.subscribe('language_changed', languageChanged);
        notificationsService.subscribe('filter_date_changed', dateChanged);
    }

    function dateChanged() {
        if ($routeParams.celex) {
            loadRegulationDetails($routeParams.celex);
        }
    }

    function loadMeasureTypes() {
        return measureTypesData
            .getMeasureTypesDictionary()
            .then(function(measureTypes) {
                vm.measureTypes = measureTypes;
            });
    }

    function languageChanged() {
        vm.languageId = common.language.get();
        if ($routeParams.celex) {
            loadRegulationDetails($routeParams.celex);
        }
    }

    function loadRegulationDetails(celex) {
        vm.celex = $routeParams.celex;
        vm.isLoading = true;
        return loadMeasureTypes()
            .then(function() {
                return regulationsData.getRegulationDetails(celex);
            })
            .then(function(regulation) {
                vm.regulation = regulation;
                vm.isLoading = false;
            });
    }

    function detached() {
        notificationsService.unsubscribe('language_changed', languageChanged);
        notificationsService.unsubscribe('filter_date_changed', dateChanged);
    }
}
