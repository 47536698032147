module.exports = [preferenceCodeTypeaheadFilter];

// Takes care of ordering the results of the preference code typeahead. The logic is as follows:
// First, show the matches on the preference code, ordered by code
// Next, show the matches on the beginning of the preference code description, ordered by description
// Finally, show the matches anywhere else in the description, ordered by description
function preferenceCodeTypeaheadFilter() {


    return function (preferenceCodes, query) {
        if (query === null || query === undefined)
            return preferenceCodes;

        var preferenceCodeMatchPartition = _.partition(preferenceCodes, function(preferenceCode) {
            return (preferenceCode.code).toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) === 0;
        });
        var preferenceCodeMatches = _.sortBy(preferenceCodeMatchPartition[0], function(preferenceCode) {
            return preferenceCode.code;
        });
        var otherMatches = _.sortBy(preferenceCodeMatchPartition[1], 'description');

        var descriptionStartMatchPartition = _.partition(otherMatches, function(preferenceCode) {
            return preferenceCode.description && preferenceCode.description.toLowerCase().indexOf(query.toLowerCase()) === 0;
        });

        var otherDescriptionMatches = _.filter(descriptionStartMatchPartition[1], function(preferenceCode) {
            return preferenceCode.description && preferenceCode.description.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });

        var result = _.concat(preferenceCodeMatches, descriptionStartMatchPartition[0], otherDescriptionMatches);
        return result;
    }
}
