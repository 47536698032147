module.exports = {
    controller: caseLawLinkController,
    templateUrl: 'links/components/case-law-link-page.html'
};

caseLawLinkController.$inject = [
    '$location',
    '$routeParams',
    'euCaseLawDataService',
    'nlCaseLawDataService'
];

function caseLawLinkController(
    $location,
    $routeParams,
    euCaseLawDataService,
    nlCaseLawDataService
) {
    var vm = {
        $onInit: attached
    };
    
    return vm;

    function attached() {
        switch ($routeParams.origin.toUpperCase()) {
            case 'EU':
                followEuCaseLawLink();
                break;
            case 'NL':
                followDutchCaseLawLink();
                break;
        }
    }

    function followEuCaseLawLink() {
        switch ($routeParams.idType) {
            case 'celex':
                euCaseLawDataService.query.Celex = $routeParams.id;
                break;
            case 'ecli':
                euCaseLawDataService.query.Ecli = $routeParams.id;
                break;
        }
        $location.url('case-law/eu').replace();
    }

    function followDutchCaseLawLink() {
        switch ($routeParams.idType) {
            case 'ecli':
                nlCaseLawDataService.query.Ecli = $routeParams.id;
                break;
        }
        $location.url('case-law/nl').replace();
    }


}