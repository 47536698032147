﻿module.exports = {
    bindings: { 
        region: '<' 
    },
    controller: regionDisplayController,
    templateUrl: 'geography/components/region-display.html'
};

regionDisplayController.$inject = ['countryModals'];

function regionDisplayController(countryModals) {

    return {
        showRegion: function (region) {
            countryModals.region.show(region);
        }
    };

}
