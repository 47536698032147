// See https://app.taricsupport.com/#!/exchange-rates
module.exports = {
    Currency: 'Currency',
    CurrencyCode: 'Currency Code',
    CurrencyCodeModalTitle: 'Select a currency code',
    CurrencyCodeTypeaheadPlaceholder: 'Search by currency code or name',
    CurrencyConversionResults: 'Currency Conversion Results',
    CurrencyName: 'Currency Name',
    ExchangeRates: 'Exchange Rates',
    NumberOfDecimalPlaces: 'Number of decimal places',
    UseTheFieldsBelowToConvertBetween_and_: 'Use the fields below to convert between {{ inverseMonetaryValue.unit }} and {{ monetaryValue.unit }}',
};
