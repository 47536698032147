﻿module.exports = ['meursingModal', goodModalsService];

function goodModalsService(meursingModal) {

    var service = {
        meursing: meursingModal,
    };

    return service;

}
