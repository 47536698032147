﻿module.exports = ['$filter', 'common', 'dataContext', 'applicationStateService', 'serializationService', goodDataService];

function goodDataService($filter, common, context, state, serializationService) {
    var service = {
        data: null,
        loadGood: loadGood,
        loadMeasures: loadMeasures,
        evaluateMeasureConditions: evaluateMeasureConditions
    };

    function evaluateMeasureConditions(query) {
        var quantities = serializationService.serializeQuantities(query.quantities);

        var additionalCodes = _.map(query.additionalCodes, function(additionalCode) {
            return '' + additionalCode.type + additionalCode.code;
        });
        var certificateCodes = _.map(query.certificateCodes, function(certificateCode) {
            if (certificateCode.origin === 'IT') {
                return '' + certificateCode.code + certificateCode.type;    
            }	
            return '' + certificateCode.type + certificateCode.code;
        });
        var preferenceCode = query.preferenceCode ? query.preferenceCode.code : null;
        var customsValue = serializationService.serializeCustomsValue(query.customsValue);

        var url = common.config.apiHosts[common.config.currentApi] +
            '/goods/' + query.commodityCode + '/evaluate_measure_conditions' +
            '?lang=' + common.language.get() +
            '&additionalcodes=' + additionalCodes.join(',') +
            '&certificatecodes=' + certificateCodes.join(',') +
            '&preferencecode=' + (preferenceCode || '') +
            '&customsvalue=' + customsValue +
            '&dutymeasuretypeid=' + (query.dutyMeasureTypeId || '') +
            '&movement=' + query.tradeMovement +
            '&ordernumber=' + (query.orderNumber || '') +
            '&quantities=' + quantities.join(',') +
            '&country=' + (query.countryCode || '') +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date +
            '&ucc=' + (query.uccModeEnabled || '');
        return context.get(url);
    }

    function loadGood(goodsnom, nationalAdditionalCode, meursingAdditionalCode, currency, uccModeEnabled) {
        var deferred = common.$q.defer();

        loadGoodRaw(goodsnom, nationalAdditionalCode, meursingAdditionalCode, currency, uccModeEnabled)
            .then(function (data) {

                service.data = data;
                if (data.good && data.good.code) {
                    if (state.filter.referenceCountry === 'GB') {
                        if (data.good.result_valid_on && data.good.result_valid_on.substr(0, 10) !== state.filter.date) {
                            data.good.not_up_to_date = true;
                        }
                    }
                }
                deferred.resolve(data);
            }, function(err) {
                return deferred.reject(err);
            });

        return deferred.promise;
    }

    // Loads the raw data for a good, based on its goodsnom
    function loadGoodRaw(goodsnom, nationalAdditionalCode, meursingAdditionalCode, currency, uccModeEnabled) {

        var url = common.config.apiHosts[common.config.currentApi] + '/goods/' + goodsnom +
            '?movement=' + state.filter.movement +
            '&lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry +
            '&ucc=' + (uccModeEnabled || '');

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }
        if (meursingAdditionalCode) {
            if (meursingAdditionalCode.length === 3) {
                meursingAdditionalCode = '7' + meursingAdditionalCode;
            }
            url += '&meursingcode=' + meursingAdditionalCode;
        }
        if (nationalAdditionalCode) {
            url += '&nac=' + nationalAdditionalCode;
        }
        if (currency) {
            url += '&currencycode=' + currency.code;
        }

        return context.get(url, 'GOOD');
    }

    function loadMeasures(countryCode) {
        var deferred = common.$q.defer();
        var measures = $filter('hasCountry')(service.data.good.measures, countryCode);
        if (state.filter.referenceCountry === 'NL') {
            var someMeasuresHaveAnOptionalNationalAdditionalCode = _.some(measures, function (measure) {
                return measure.additional_code && measure.additional_code.type === 'V';
            });
            if (someMeasuresHaveAnOptionalNationalAdditionalCode) {
                measures = _.filter(measures, function (measure) {
                    if (measure.series !== 'P') {
                        return true;
                    }
                    if (measure.additional_code === null) {
                        return false;
                    }
                    if (measure.additional_code.type !== 'V') {
                        return false;
                    }
                    return true;
                });
            }
        }
        var result = {
            has_meursing: _.some(measures, function (measure) {
                return measure.has_meursing;
            }),
            measures: measures
        }
        deferred.resolve(result);

        return deferred.promise;
    }

    return service;
}