module.exports = {
    bindings: {
        preferenceCode: '<',
        useUccEndUseProcedureCode44: '<',
    },
    controller: preferenceCodeButtonController,
    templateUrl: 'preference-codes/components/preference-code-button.html'
};

preferenceCodeButtonController.$inject = ['preferenceCodeModal'];

function preferenceCodeButtonController(preferenceCodeModal) {
    var vm = {
        selectPreferenceCode: selectPreferenceCode
    };
    return vm;

    function selectPreferenceCode(code, useUccEndUseProcedureCode44) {
        preferenceCodeModal.show(code, useUccEndUseProcedureCode44);
    }
}
