module.exports = [taricCodeToChapterNumber];

function taricCodeToChapterNumber() {
    return function (input) {
        if (input === undefined || input === null)
            return input;
        if (input.length <= 2)
            return input;

        while (input.substring(input.length - 2) === '00' && input.length > 2) {
            input = input.substring(0, input.length - 2);
        }

        if (input.substring(0, 1) === '0')
            input = input.substring(1);

        return input;
    };
}