// See https://xtares.admin.ch/tares/login/loginFormFiller.do
// Choose Swiss flag in Taric Support application
// See https://app.taricsupport.com/#!/tares-measures/2203001000
// See https://app.taricsupport.com/#!/tares-measures/3002510000
module.exports = {
    Code: 'Code',
    CustomsFavourHintCodes: 'Customs Favour Hint Codes',
    CustomsReliefCode: 'Customs relief code',
    CustomsReliefCodes: 'Customs relief codes',
    Description: 'Description',
    DutyRates: 'Duty Rates',
    NoCustomsReliefCode: 'No Customs relief code',
    NormalDutyRate: 'Normal duty rate',
    ReliefCodeOnTariffLine: 'Relief code on tariff line',
    StatisticalKeys: 'Statistical Keys',
    Type: 'Type', // Type of code
    ValidForCustomsReliefCode: 'Valid for customs relief code',
    AssessmentCode: 'Assessment code',
    CountryGroup: 'Country group',
    CountryGroups: 'Country groups',
    ValidForCountry: 'Valid for country',
    CountryGroupDescription: 'Country group description',
    Value: 'Value',	
    DutyExpression: 'Duty expression',
    Text: 'Text', // Duty Rate Texts
    DuringFullSupplyPeriod: 'during full supply period (cf. "Remarks", "Tariff quotas", "reduced out-quota rate")',
    VatRates: 'VAT Rates',
    AdditionalTare: 'Additional tare',
    OfTheNetWeight: 'of the net weight',
    ChooseStatisticalKey: 'Choose a statistical key to see more information',
    StatisticalKey: 'Statistical key',
    AdditionalCharges: 'Additional charges',
    Minimum: 'Minimum',	
    Maximum: 'Maximum',
    excluding: 'excluding', // {{ region }} excluding {{ excludedRegion }}
    Key: 'Key', // AdditionalTax.Key
    TypeDescription: 'Type description',
    KeyDescription: 'Key description',
    AssignmentText: 'Assignment text', // AdditionalTax.AssignmentText
    Rate: 'Rate',
    Optional: 'Optional', // Is optional (yes/no)
    NonCustomsLaws: 'Non-customs laws',
    Permits: 'Permits',
    Tolerance: 'Tolerance', // permit tolerance - no permit allowed below this weight
    ToleranceCode: 'Tolerance code',
    PermitAuthority: 'Permit authority',
    PermitAuthorityDescription: 'Permit authority description',
    RequiredQuantitiesAndLimits: 'Required quantities and limits',
    RequiredQuantities: 'Required quantities',
    NetWeight: 'Net weight',
    InKgWithThreeDecimals: 'in kg with 3 decimals',
    SupplementaryUnit: 'Supplementary unit',
    MeanValueLimits: 'Mean value limits',
    MinimumValuePerKgNetWeight: 'Minimum value per kg (net weight)',
    MaximumValuePerKgNetWeight: 'Maximum value per kg (net weight)',
    MinimumValuePerSupplementaryUnit: 'Minimum value per supplementary unit',
    MaximumValuePerSupplementaryUnit: 'Maximum value per supplementary unit',
    WeightLimits: 'Weight limits',
    MinimumWeight: 'Minimum weight',
    MaximumWeight: 'Maximum weight',
    MinimumWeightPerSupplementaryUnit: 'Minimum weight per supplementary unit',
    MaximumWeightPerSupplementaryUnit: 'Maximum weight per supplementary unit',
    ProcessingCodes: 'Processing codes',
};