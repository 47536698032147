module.exports = {
    bindings: {
        commodityCode: '<',
        tradeMovement: '<'
    },
    controller: favoriteButtonController,
    templateUrl: 'good/components/favorite-button.html'
};

favoriteButtonController.$inject = ['favoritesService'];

function favoriteButtonController(favoritesService) {
    var vm = {
        $onInit: $onInit,
        isFavorite: false,
        setFavorite: setFavorite
    };
    return vm;

    function $onInit() {
        vm.isFavorite = favoritesService.isFavorite(vm.commodityCode, vm.tradeMovement);
    }

    function setFavorite() {
        var add = vm.isFavorite === false;

        if (add) {
            favoritesService.add(
                vm.commodityCode,
                vm.tradeMovement,
                false, //vm.countryFilter !== null ? vm.countryFilter.code : null,
                'dtv'//taricVars.applicationSet
            );
            vm.isFavorite = true;
        }
        else {
            favoritesService.remove(vm.commodityCode, vm.tradeMovement);
            vm.isFavorite = false;
        }
    }
}