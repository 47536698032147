module.exports = [
    '$analytics', 
    '$uibModal', 
    nlCaseLawSummaryModal
];

function nlCaseLawSummaryModal(
    $analytics, 
    $uibModal
) {

    var modal = {
        show: showModal
    };

    nlCaseLawJudgmentModalController.$inject = [
        '$uibModalInstance', 
        'nlCaseLawDataService', 
        'ecli',
    ];

    function nlCaseLawJudgmentModalController(
        $uibModalInstance, 
        nlCaseLawDataService, 
        ecli
    ) {
        
        var vm = {
            cancel: cancel,
            ecli: ecli,
            $onInit: attached,
            judgment: nlCaseLawDataService.judgment,
            // title
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function attached() {
            nlCaseLawDataService.loadJudgment(vm.ecli);
        }

        return vm;
    }



    function showModal(ecli) {
        $analytics.eventTrack('open-modal-nl-case-law-judgment', { category: 'case-law' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: nlCaseLawJudgmentModalController,
            controllerAs: 'ctrlNlCaseLawJudgmentModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                ecli: function() {
                    return ecli;
                }
            },
            size: 'lg',
            templateUrl: 'case-law/modals/nl-case-law-judgment-modal.html',
            windowClass: 'modal-nl-case-law-judgment'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}