module.exports = {
    Annotations: 'Aantekeningen',
    AnnotationsFirstLetter: 'A',

    Chapter: 'Hoofdstuk',
    Chapters: 'Hoofdstukken',
    Chapter_code_: 'Hoofdstuk {{ code }}',
    Chapter_GoodsCodeOrSectionNumber_: 'Hoofdstuk {{ GoodsCodeOrSectionNumber }}',
    
    CombinedNomenclature: 'Gecombineerde nomenclatuur',

    CommodityClassification: 'Classificatie goederen',
    CommodityCode: 'Goederencode',
    CommodityCodes: 'Goederencodes',
    CommodityCodesFoundInText: 'Goederencodes gevonden in tekst',

    Heading: 'Post',
    Heading_code_: 'Post {{ code }}',
    Heading_GoodsCodeOrSectionNumber_: 'Post {{ GoodsCodeOrSectionNumber }}',
    Headings: 'Posten',

    Section: 'Afdeling',
    Section_number_: 'Afdeling {{ number }}',	
    Section_GoodsCodeOrSectionNumber_: 'Afdeling {{ GoodsCodeOrSectionNumber }}',	
    Sections: 'Afdelingen',

    SubChapter_GoodsCodeOrSectionNumber_: 'Sub-hoofdstuk {{ GoodsCodeOrSectionNumber }}',

    Subheading: 'Onderverdeling',

    WcoClassificationOpinions: 'WCO Classification Opinions',
    Classifications: 'Tariferingen',
    ExplanatoryNotesFirstLetter: 'T',
    ExplanatoryNotes: 'Toelichtingen',
    ExplanatoryNotesCn: 'GN-toelichtingen - EU',
    ExplanatoryNotesHs: 'GS-toelichtingen - WCO',
    Nomenclature: 'Nomenclatuur',
    NomenclatureGroups: 'Nomenclatuurgroepen',
    NomenclatureHeadings: 'Nomenclatuur - Posten',
    NomenclatureHeading_code_: 'Nomenclatuur - Post {{ code }}',

    SectionAndChapterNotes: 'Aantekeningen nomenclatuur',
};