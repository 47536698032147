module.exports = ['$timeout', '$translate', 'ngIntroService', 'preferenceService', documentationService];

function documentationService($timeout, $translate, ngIntroService, preferences) {

    var currentTour = {
        id: null,
        running: false
    };

    var service = {
        addHints: addHints,
        exit: exit,
        getCurrentTour: getCurrentTour,
        next: next,
        startTour: startTour,
        initialize: initalizeTour
    };

    function getCurrentTour() {
        return currentTour;
    }

    ngIntroService.onExit(function() {
        currentTour.running = false;
    });

    var defaultIntroOptions = {
        disableInteraction: true,
        showStepNumbers: false,
        showBullets: false,
        exitOnOverlayClick: false,
        exitOnEsc: true,
        helperElementPadding: 0
    };

    var defaultTourConfig = {
        repeatAdNauseum: false, // TODO: set to false in production (it is good for debugging though)
        autoStart: false // TODO: set to false in production (it is good for debugging though)
    };

    var config = getTourConfig();

    return service;

    function exit() {
        ngIntroService.exit();
    }

    function next() {
        if (currentTour.running) {
            ngIntroService.next();
        }
    }

    function startTour() {
        currentTour.running = true;
        $timeout(function() {
            ngIntroService.start();
        }, 0);
    }

    function initalizeTour(id) {
        var tourConfig = _.find(config, { 'id': id });
        if (tourConfig) {
            $translate(tourConfig.translations)
                .then(function(translations) {
                    tourConfig = _.defaults(tourConfig, defaultTourConfig);
                    currentTour.id = id;
                    var options = tourConfig.getOptions(translations);
                    ngIntroService.setOptions(options);
                    ngIntroService.onExit(function() {
                        markTourAsExited(id);
                    });
                    if ((tourConfig.autoStart && isNewTour(id)) || tourConfig.repeatAdNauseum) {
                        $timeout(function() {
                            ngIntroService.start();
                        }, 0);
                    }
                });
        }
    }

    function addHints() {
        $timeout(function() {
            ngIntroService.addHints();
            ngIntroService.showHints();
        }, 0);
    }

    function isNewTour(id) {
        // return true; // good for debugging: all tours with autoStart: true will always auto-start
        var tourPreference = preferences.get(id, 'tourHasExited');
        return tourPreference === null;
    }

    function markTourAsExited(id) {
        preferences.replace({
            category: 'tourHasExited',
            name: id,
            value: true,
            valueType: 'booleanValue'
        });
    }

    function getTourConfig() {
        var config = [
            {
                id: 'abc',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.abc.intro']
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.abc.intro',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'abc-new',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                element: document.querySelector('.introjs-abc-new-file'),
                                intro: translations['documentation.abcNew.file']
                            },
                            {
                                element: document.querySelector('.introjs-abc-new-input-options'),
                                intro: translations['documentation.abcNew.inputOptions']
                            },
                            {
                                element: document.querySelector('.introjs-abc-new-output-options'),
                                intro: translations['documentation.abcNew.outputOptions']
                            },
                            {
                                element: document.querySelector('.introjs-abc-new-submit'),
                                intro: translations['documentation.abcNew.letsGo'],
                                position: 'left'
                            }
                            
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.abcNew.file',
                    'documentation.abcNew.inputOptions',
                    'documentation.abcNew.outputOptions',
                    'documentation.abcNew.letsGo',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'case-law',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.caseLaw.intro']
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.caseLaw.intro',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'chapter',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.chapter.intro']
                            },
                            {
                                element: document.querySelector('.introjs-heading'),
                                intro: translations['documentation.chapter.heading']
                            },
                            {
                                element: document.querySelector('.introjs-chapter-buttons'),
                                intro: translations['documentation.chapter.chapterButtons']
                            },
                            {
                                element: document.querySelector('.introjs-heading-buttons'),
                                intro: translations['documentation.chapter.headingButtons'],
                                position: 'left'
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.chapter.intro',
                    'documentation.chapter.heading',
                    'documentation.chapter.chapterButtons',
                    'documentation.chapter.headingButtons',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'chapters',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.chapters.intro']
                            },
                            {
                                element: '#large-search-box',
                                intro: translations['documentation.chapters.largeSearchBox']
                            },
                            {
                                element: '#introjs-reference-date-picker',
                                intro: translations['documentation.header.referenceDatePicker'],
                                position: 'bottom-right-aligned'
                            },
                            {
                                element: '#introjs-import-export-toggle',
                                intro: translations['documentation.header.tradeMovementPicker'],
                                position: 'bottom-right-aligned'
                            },
                            {
                                element: '#introjs-navbar-language-dropdown',
                                intro: translations['documentation.header.languagePicker'],
                                position: 'bottom-right-aligned'
                            },
                            {
                                element: document.querySelector('.introjs-nomenclature-section'),
                                intro: translations['documentation.chapters.sectionNotesAndExplanatoryNotes']
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.chapters.intro',
                    'documentation.chapters.largeSearchBox',
                    'documentation.chapters.sectionNotesAndExplanatoryNotes',
                    'documentation.header.referenceDatePicker',
                    'documentation.header.tradeMovementPicker',
                    'documentation.header.languagePicker',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'correlation-table',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.correlationTable.intro']
                            },
                            {
                                element: '#correlation-table-search-successors',
                                intro: translations['documentation.correlationTable.searchSuccessors']
                            },
                            {
                                element: '#correlation-table-search-originators',
                                intro: translations['documentation.correlationTable.searchOriginators']
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.correlationTable.intro',
                    'documentation.correlationTable.searchSuccessors',
                    'documentation.correlationTable.searchOriginators',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'ebti',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.ebti.intro']
                            },
                            {
                                element: document.querySelector('.introjs-ebti-form'),
                                intro: translations['documentation.ebti.form']
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.ebti.intro',
                    'documentation.ebti.form',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'atr',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.atr.intro']
                            },
                            {
                                element: document.querySelector('.introjs-atr-form'),
                                intro: translations['documentation.atr.form']
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.atr.intro',
                    'documentation.atr.form',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'landing',
                autoStart: false,
                repeatAdNauseum: false, // TODO: for debugging only
                getOptions: function(translations) {
                    var introOptions = {
                        // disableInteraction: false,
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                element: '#introjs-navbar-language-dropdown',
                                title: translations['documentation.landingPage.welcomeTitle'],
                                intro: translations['documentation.landingPage.welcomeIntro']
                            },
                            // {
                            //     element: document.querySelector('.introjs-language-dropdown'),
                            //     intro: translations['documentation.header.languagePicker'],
                            //     position: 'left'
                            // },
                            {
                                element: document.querySelector('.introjs-pick-trade-tariff'),
                                intro: translations['documentation.landingPage.referenceCountryPicker']
                            },
                            {
                                element: document.querySelector('.btn-help'),
                                intro: translations['documentation.landingPage.introButton']
                            },
                            
                            // {
                            //     element: '#introjs-reference-date-picker',
                            //     intro: translations['documentation.landingPage.referenceDatePicker']
                            // },
                            // {
                            //     element: '#introjs-import-export-toggle',
                            //     intro: translations['documentation.landingPage.tradeMovementPicker']
                            // },
                            // {
                            //     element: '#introjs-navbar-language-dropdown',
                            //     intro: translations['documentation.landingPage.languagePicker']
                            // }
                        ]
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.landingPage.welcomeTitle',
                    'documentation.landingPage.welcomeIntro',
                    'documentation.landingPage.referenceCountryPicker',
                    'documentation.landingPage.introButton',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'show-measures',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.measures.intro']
                            },
                            {
                                element: document.querySelector('.show-measures-additional-search-options'),
                                intro: translations['documentation.measures.form'],
                                position: 'left'
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.measures.intro',
                    'documentation.measures.form',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'validate-declaration',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.measures.intro']
                            },
                            {
                                element: document.querySelector('.additional-search-options'),
                                intro: translations['documentation.measures.validateDeclaration'],
                                position: 'left'
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.measures.intro',
                    'documentation.measures.validateDeclaration',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'calculate-duty',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.measures.intro']
                            },
                            {
                                element: document.querySelector('.additional-search-options'),
                                intro: translations['documentation.measures.calculateDuty'],
                                position: 'left'
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.measures.intro',
                    'documentation.measures.calculateDuty',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'menu',
                // autoStart: true,
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.mainMenu.intro'],
                            },
                            {
                                element: '#side-menu-chapters',
                                intro: translations['documentation.mainMenu.chapters'],
                                position: 'right'
                            },
                            {
                                element: '#side-menu-news',
                                intro: translations['documentation.mainMenu.news'],
                                position: 'right'
                            },
                            {
                                element: '#side-menu-ebti',
                                intro: translations['documentation.mainMenu.ebti'],
                                position: 'right'
                            },
                            {
                                element: '#side-menu-atr',
                                intro: translations['documentation.mainMenu.atr'],
                                position: 'right'
                            },
                            {
                                element: '#side-menu-abc',
                                intro: translations['documentation.mainMenu.abc'],
                                position: 'right'
                            },
                            {
                                element: '#side-menu-correlation-table',
                                intro: translations['documentation.mainMenu.correlationTable'],
                                position: 'right'
                            },
                            {
                                element: '#side-menu-reference-prices',
                                intro: translations['documentation.mainMenu.referencePrices'],
                                position: 'right'
                            },
                            {
                                element: '#side-menu-case-law',
                                intro: translations['documentation.mainMenu.caseLaw'],
                                position: 'right'
                            },
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.mainMenu.intro',
                    'documentation.mainMenu.chapters',
                    'documentation.mainMenu.news',
                    'documentation.mainMenu.ebti',
                    'documentation.mainMenu.abc',
                    'documentation.mainMenu.correlationTable',
                    'documentation.mainMenu.referencePrices',
                    'documentation.mainMenu.caseLaw',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
            {
                id: 'news',
                getOptions: function(translations) {
                    var introOptions = {
                        doneLabel: translations['documentation.ui.done'],
                        nextLabel: translations['documentation.ui.next'],
                        prevLabel: translations['documentation.ui.previous'],
                        steps: [
                            {
                                intro: translations['documentation.news.intro']
                            }
                        ],
                    };
                    return _.defaults(introOptions, defaultIntroOptions);
                },
                translations: [
                    'documentation.news.intro',
                    'documentation.ui.done',
                    'documentation.ui.previous',
                    'documentation.ui.next',
                ]
            },
        ];

        return config;
    }
}