﻿module.exports = [trimFilter];

function trimFilter() {
    return function (input) {
        if (input === null || input === undefined)
            return input;

        return input.trim();
    };
}
