module.exports = ['config', 'dataContext', 'identity', 'widgetModals', authCheckService];

function authCheckService(config, context, identity, widgetModals) {

    var service = {
        check: checkUserStatus
    };

    function checkUserStatus() {
        if (!identity.isAuthenticated) {
            return;
        }

        context.get(config.apiHosts.auth + '/auth/me')
            .then(function (result) {
                identity.updateCookie();
                try {
                    Sentry.configureScope(function (scope) {
                        scope.setUser({ "email": result.EmailAddress });
                    });
                }
                catch (e) {}
            })
            .catch(function (error) {
                if (error && error.status === 401) {
                    identity.set(null);
                    var modalRef = widgetModals.loggingOut.show();
                    window.setTimeout(function () {
                        modalRef.dismiss();
                        window.location.href = '/#!/account/logout';
                    }, 5000);
                }
                else if (error && error.status === 403) {
                    // console.log('403', error);
                }
            });
    }

    return service;

}