﻿module.exports = ['common', 'dataContext', 'applicationStateService', regionDataService];

function regionDataService(common, context, state) {

    var service = {
        get: get,
        getByCode: getByCode
    };

    function get() {

        var url = common.config.apiHosts[common.config.currentApi] + '/regions' +
            '?origin=EU,' + state.filter.referenceCountry +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    function getByCode(code) {

        var url = common.config.apiHosts[common.config.currentApi] + '/countries' +
            '?region=' + code +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    return service;
}
