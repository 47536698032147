angular.module('taric.measureTypes', [])
    .config(['$routeProvider', routeConfigurator])
    .component('measureTypePicker', require('./components/measure-type-picker'))
    .filter('measureTypeTypeahead', require('./filters/measure-type-typeahead-filter'))
    .factory('measureTypeSelectorModal', require('./modals/measure-type-selector'))
    .factory('measureTypesDataService', require('./services/measure-types-data'));

function routeConfigurator($routeProvider) {
    $routeProvider
        // .when('/regulations', {
        //     templateUrl: 'regulations/controllers/regulations-news.html'
        // })
        ;
}