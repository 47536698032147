module.exports = {
    controller: nlCaseLawPageController,
    templateUrl: 'case-law/components/nl-case-law-page.html'
};

nlCaseLawPageController.$inject = [
    '$analytics', 
    '$routeParams', 
    '$location', 
    'nlCaseLawDataService',
];

function nlCaseLawPageController(
    $analytics, 
    $routeParams, 
    $location, 
    nlCaseLawDataService
) {

    if ($routeParams.ecli && $routeParams.ecli.match(/ecli:eu/i)) {
        $location.url('/case-law-nl');
    }

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        data: nlCaseLawDataService,
        clear: clear,
        dateOptions: {},
        format: 'dd-MM-yyyy',
        open1: open1,
        open2: open2,
        pageChanged: pageChanged,
        popup1: {
            opened: false,
        },
        popup2: {
            opened: false,
        },
        search: search,
    };

    function attached() {
        $analytics.pageTrack('/case-law/nl');
        nlCaseLawDataService.loadCaseLawSearchResults();
    }

    function detached() {
        clear();
    }

    function clear() {
        nlCaseLawDataService.clear();
    }

    function open1() {
        vm.popup1.opened = true;
    }

    function open2() {
        vm.popup2.opened = true;
    }

    function pageChanged() {
        nlCaseLawDataService.loadCaseLawSearchResults();
        var height = document.getElementsByClassName('rechtspraak-search-buttons')[0].offsetTop;
        var element = document.getElementById('content');
        element.scrollTo(0, height);
    }

    function search() {
        nlCaseLawDataService.loadCaseLawSearchResults();
    }

    return vm;
}