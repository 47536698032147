module.exports = {
    CommodityCode_Ascending: 'Goederencode - oplopend',
    CommodityCode_Descending: 'Goederencode - aflopend',
    CUS_Ascending: 'CUS - oplopend',
    CUS_Descending: 'CUS - aflopend',
    ValidFrom_Ascending: 'Geldig vanaf - oplopend',
    ValidFrom_Descending: 'Geldig vanaf - aflopend',
    Relevance_Keywords: 'Relevantie - Sleutelwoorden',
    Relevance_GoodsDescription: 'Relevantie - Goederenomschrijving',
    Relevance: 'Relevantie',
};