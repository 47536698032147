module.exports = [
    'common', 
    'dataContext', 
    'applicationStateService',
    eztDataService
];

function eztDataService(
    common, 
    dataContext,
    state
) {
    var service = {
        loadEztExciseNomenclature: loadEztExciseNomenclature,
        loadEztNomenclature: loadEztNomenclature,
        loadEztHinweise: loadEztHinweise,
        loadEztDocuments: loadEztDocuments,
        loadEztFootnote: loadEztFootnote,
        loadEztTextMainIndex: loadEztTextMainIndex
    };
    return service;

    function loadEztDocuments(documentType, mainDocument) {
        var url = common.config.apiHosts[common.config.currentApi] + '/ezt/texts/' + documentType + '/' + mainDocument +
            '?referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;
        
        return dataContext.get(url);
    }

    function loadEztTextMainIndex() {
        var url = common.config.apiHosts[common.config.currentApi] + '/ezt/texts' +
            '?referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;
        
        return dataContext.get(url);
    }

    function loadEztNomenclature(commodityCode) {
        var url = common.config.apiHosts[common.config.currentApi] + '/ezt/nomenclature/' + commodityCode +
            '?movement=' + state.filter.movement +
            '&lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }

        return dataContext.get(url);
    }

    function loadEztHinweise(commodityCode) {
        var url = common.config.apiHosts[common.config.currentApi] + '/ezt/hinweise/' + commodityCode +
            '?movement=' + state.filter.movement +
            '&lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }

        return dataContext.get(url);
    }

    function loadEztFootnote(footnoteType, footnoteCode) {
        var url = common.config.apiHosts[common.config.currentApi] + '/ezt/footnotes/' + footnoteType + '/' + footnoteCode +
            '?movement=' + state.filter.movement +
            '&lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }

        return dataContext.get(url);
    }

    function loadEztExciseNomenclature(exciseTypeId) {
        var url = common.config.apiHosts[common.config.currentApi] + '/ezt/excise-nomenclature/' + exciseTypeId +
            '?movement=' + state.filter.movement +
            '&lang=' + common.language.get()  +
            '&referencecountry=' + state.filter.referenceCountry;

        if (state.filter.date !== null) {
            url += '&date=' + state.filter.date;
        }

        return dataContext.get(url);
    }
}