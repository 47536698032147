﻿module.exports = ['common', 'dataContext', userAccountService];

function userAccountService(common, context) {

    var service = {
        changePassword: changePassword,
        confirmPassword: confirmPassword,
        resetPassword: resetPassword,
        login:login
    };

    function changePassword(oldPassword, newPassword) {
        var deferred = common.$q.defer();

        context.post(common.config.apiHosts.auth + '/auth/changepassword', { current_password: oldPassword, new_password: newPassword})
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function confirmPassword(key, newPassword) {
        var deferred = common.$q.defer();

        //context.get(common.config.apiHosts.auth + '/auth/permissions')
        context.post(common.config.apiHosts.auth + '/auth/confirmpassword', { token: key, newPassword: newPassword })
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function resetPassword(username, language) {
        var deferred = common.$q.defer();
        if (language === undefined || language === null) {
            language = 'en';
        }

        context.post(common.config.apiHosts.auth + '/auth/resetpassword', { username: username, language: language })
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function login(userData, rememberMe) {
        var tokenUrl = common.config.apiHosts.auth + '/auth/token';
        if (!userData.grant_type) {
            userData.grant_type = 'password';
        }
        userData.application_key = common.config.applicationKey;
        userData.application_secret = common.config.applicationSecret;

        var deferred = common.$q.defer();
        context.post(tokenUrl, userData)
            .then(function (data) {
                common.identity.set(userData.username, data, rememberMe);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        return deferred.promise;
    }

    return service;

}