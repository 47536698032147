module.exports = [valueQuantityImportDirective];

function valueQuantityImportDirective() {
    var directive = {
        restrict: 'E',
        templateUrl: 'good/directives/form/value-quantity-import.html'
    };

    return directive;
}
