module.exports = {
    bindings: {
        isLarge: '<',
        item: '<'
    },
    controller: ecicsButtonController,
    templateUrl: 'ecics/components/ecics-button.html'
};

ecicsButtonController.$inject = [
    'common', 
    'ecicsModal'
];

function ecicsButtonController(
    common, 
    ecicsModal
) {

    var vm = {
        showEcics: showEcics
    };

    function showEcics() {
        var numberPattern = /^\s*(?:\d{2}[\s.]*){1,5}$/;

        var code = common.formatting.removeTrailingZeros(vm.item.code);

        if (numberPattern.test(vm.item.searchQuery))
            ecicsModal.show(code, '');
        else
            ecicsModal.show(code, vm.item.searchQuery);
    }


    return vm;

}
