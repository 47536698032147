module.exports = {
    controller: complianceOverviewController,
    templateUrl: 'compliance/components/compliance-overview-page.html'
};

complianceOverviewController.$inject = [
    '$analytics', 
    '$location', 
    '$timeout', 
    '$translate', 
    'common', 
    'complianceDataService', 
    'complianceModalsService', 
    'notificationsService', 
    'widgetModals'
];

function complianceOverviewController(
    $analytics, 
    $location, 
    $timeout, 
    $translate, 
    common, 
    complianceData, 
    complianceModalsService, 
    notifications,
    widgetModals
) {

    var loadJobStatusTimeOut;
    var loadJobStatusTimeOutHasBeenCancelled;

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        cancelJob: cancelJob,
        downloadFile: downloadFile,
        jobs: [],
        loading: false,
        query: {
            PageNumber: 0
        },
        loadJobs: loadJobs,
        newJob: newJob,
        pageChanged: pageChanged,
        showDetails: showDetails,
        showErrors: showErrors,
        showWarnings: showWarnings,
        translations: null
    };

    function attached() {
        $analytics.pageTrack('/abc-overview');
        common.auth.check();

        if (common.identity.hasPermission('bestandscontrole')) {
            loadJobs(1);
        }

        $translate(['compliance.status.cancel_task_areyousure']).then(function (result) {
            vm.translations = result;
        });

        loadJobStatusTimeOutHasBeenCancelled = false;
    }

    function detached() {
        loadJobStatusTimeOutHasBeenCancelled = true;
        $timeout.cancel(loadJobStatusTimeOut);
    }

    function pageChanged() {
        notifications.publish('abc_page_changed');
        $timeout.cancel(loadJobStatusTimeOut);
        loadJobs(vm.query.PageNumber);
    }

    function cancelJob(jobId) {
        var modalRef = widgetModals.areYouSure.show(vm.translations['compliance.status.cancel_task_areyousure']);

        modalRef.result
            .then(function (modalResult) {
                if (modalResult === true) {
                    complianceData.cancelJob(jobId)
                        .then(function (result) {
                            loadJobs(1);
                        });
                }
            })
            .catch(function (error) {
                if (error === true) {
                    // this is a false positive. Don't know why but it's built in like this at the moment
                    complianceData.cancelJob(jobId)
                        .then(function (result) {
                            loadJobs(1);
                        });
                }
            });
    }

    function downloadFile(jobId) {
        window.open(common.config.apiHosts['generic'] + '/compliance/job/' + jobId + '/download?token=' + encodeURIComponent(common.identity.token.tokenValue));
    }

    function loadJobs(pageNumber) {
        if (vm.loading)
            return;

        vm.loading = true;
        complianceData.loadJobs(pageNumber)
            .then(function (result) {
                if (!loadJobStatusTimeOutHasBeenCancelled) {
                    loadJobStatusTimeOut = $timeout(function() {
                        loadJobs(pageNumber);
                    }, 10000);
                }
                vm.jobs = result
                vm.loading = false;
            });
    }

    function newJob() {

        $location.url('/compliance-check/new');
    }

    function showDetails(jobId) {
        $location.url('/compliance-check/' + jobId);
    }

    function showErrors(jobId) {
        complianceModalsService.complianceInfo.show(jobId).result
            .then(function (result) { }, function (error) { });
    }

    function showWarnings(jobId) {
        complianceModalsService.complianceInfo.show(jobId).result
            .then(function (result) { }, function (error) { });
    }

    return vm;

}