﻿module.exports = ['footnoteModal', footnoteModalsService];

function footnoteModalsService(footnoteModal) {

    var service = {
        footnote: footnoteModal
    };

    return service;

}