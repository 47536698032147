﻿module.exports = ['$analytics', '$uibModal', meursingModal];

function meursingModal($analytics, $uibModal) {
    var modal = {
        show: showModal
    };

    meursingModalController.$inject = ['$uibModalInstance', 'common', 'dataContext'];

    function meursingModalController($uibModalInstance, common, dataContext) {
        var vm = {
            cancel: cancel,
            updateFilters: updateFilters,
            filterMeursingMelk: filterMeursingMelk,
            filterMeursingZetmeel: filterMeursingZetmeel,
            invalidCombination: false,
            isLoadingTypes: false,
            loadMeursingHeaders: loadMeursingHeaders,
            meursingAdditionalCode: null,
            meursingFilter: {
                melkvet: null,
                melkproteinen: null,
                zetmeel: null,
                saccharose: null
            },
            meursingHeaders: {
                melkvet: ['a', 'b'],
                melkproteinen: [],
                filteredProteinen: [],
                zetmeel: [],
                saccharose: [],
                filteredSaccharose: [],
                original: []
            },
            ok: ok
        };

        function updateFilters() {
            vm.invalidCombination = false;
            vm.meursingHeaders.filteredProteinen = _.filter(vm.meursingHeaders.melkproteinen, filterMeursingMelk);
            vm.meursingHeaders.filteredSaccharose = _.filter(vm.meursingHeaders.saccharose, filterMeursingZetmeel);
            if (!vm.meursingFilter.melkproteinen && vm.meursingHeaders.filteredProteinen) {
                vm.meursingFilter.melkproteinen = vm.meursingHeaders.filteredProteinen[0];
            }
            if (!vm.meursingFilter.saccharose && vm.meursingHeaders.filteredSaccharose) {
                vm.meursingFilter.saccharose = vm.meursingHeaders.filteredSaccharose[0];
            }
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function filterMeursingMelk(selectedItem) {
            var originalMelkvet = _.find(vm.meursingHeaders.original, function (header) { return header.numb === '010' && header.row_col_id === '0'; });
            var subheader = _.filter(originalMelkvet.subheaders, function (subheader) { return subheader.name === vm.meursingFilter.melkvet; });
            var subheaderSeqNumbs = _.map(subheader, function (subheader) { return subheader.seq_numb });

            return subheaderSeqNumbs.indexOf(selectedItem.seq_numb) > -1;
        }

        function filterMeursingZetmeel(selectedItem) {
            var originalZetmeel = _.find(vm.meursingHeaders.original, function (header) { return header.numb === '010' && header.row_col_id === '1'; });
            var subheader = _.filter(originalZetmeel.subheaders, function (subheader) { return subheader.name === vm.meursingFilter.zetmeel; });
            var subheaderSeqNumbs = _.map(subheader, function (subheader) { return subheader.seq_numb });

            return subheaderSeqNumbs.indexOf(selectedItem.seq_numb) > -1;
        }

        function loadMeursingAdditionalCode() {
            var url = common.config.apiHosts[common.config.currentApi] + '/meursing/additionalcode';
            var headers = _.cloneDeep(vm.meursingHeaders.original);

            for (var i = 0; i < headers.length; i++) {
                var meursingHeader = headers[i];

                if (meursingHeader.numb === '010' && meursingHeader.row_col_id === '0') {
                    meursingHeader.subheaders = [_.find(meursingHeader.subheaders, function (subheader) { return subheader.seq_numb === vm.meursingFilter.melkproteinen.seq_numb })];
                }
                else if (meursingHeader.numb === '010' && meursingHeader.row_col_id === '1') {
                    meursingHeader.subheaders = [_.find(meursingHeader.subheaders, function (subheader) { return subheader.seq_numb === vm.meursingFilter.saccharose.seq_numb })];
                }
                else if (meursingHeader.numb === '020' && meursingHeader.row_col_id === '0') {
                    meursingHeader.subheaders = [_.find(meursingHeader.subheaders, function (subheader) { return subheader.seq_numb === vm.meursingFilter.melkproteinen.seq_numb })];
                }
                else if (meursingHeader.numb === '020' && meursingHeader.row_col_id === '1') {
                    meursingHeader.subheaders = [_.find(meursingHeader.subheaders, function (subheader) { return subheader.seq_numb === vm.meursingFilter.saccharose.seq_numb })];
                }
            }

            var args = { headers: headers };

            return dataContext.post(url, args);
        }

        function loadMeursingHeaders() {
            dataContext
                .get(common.config.apiHosts[common.config.currentApi] + '/meursing')
                .then(function (data) {
                    vm.meursingHeaders.original = data.headers;
                    _.forEach(data.headers, function (meursingHeader) {
                        if (meursingHeader.numb === '010' && meursingHeader.row_col_id === '0') {
                            vm.meursingHeaders.melkvet = _.uniq(_.map(meursingHeader.subheaders, function (sub) { return sub.name; }));
                            vm.meursingFilter.melkvet = vm.meursingHeaders.melkvet[0];
                        }
                        else if (meursingHeader.numb === '010' && meursingHeader.row_col_id === '1') {
                            vm.meursingHeaders.zetmeel = _.uniq(_.map(meursingHeader.subheaders, function (sub) { return sub.name; }));
                            vm.meursingFilter.zetmeel = vm.meursingHeaders.zetmeel[0];
                        }
                        else if (meursingHeader.numb === '020' && meursingHeader.row_col_id === '0') {
                            vm.meursingHeaders.melkproteinen = meursingHeader.subheaders;
                            vm.meursingFilter.melkproteinen = vm.meursingHeaders.melkproteinen[0];
                        }
                        else if (meursingHeader.numb === '020' && meursingHeader.row_col_id === '1') {
                            vm.meursingHeaders.saccharose = meursingHeader.subheaders;
                            vm.meursingFilter.saccharose = vm.meursingHeaders.saccharose[0];
                        }
                    });
                    updateFilters();
                });
        }

        function ok() {
            loadMeursingAdditionalCode().then(function(meursingAdditionalCode) {
                if (meursingAdditionalCode === null) {
                    vm.invalidCombination = true;
                }
                else {
                    $uibModalInstance.close(meursingAdditionalCode);
                }
            });
        }

        loadMeursingHeaders();

        return vm;
    }

    function showModal() {
        $analytics.eventTrack('open-modal-meursing');
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: meursingModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            size: 'lg',
            templateUrl: 'good/modals/meursing-code.html',
            windowClass: 'modal-meursing-code'
        });
    }

    return modal;
}
