﻿module.exports = ['$analytics', '$uibModal', regulationsModal];

function regulationsModal($analytics, $uibModal) {
    var modal = {
        goodscode: null,
        show: showModal
    };

    regulationsModalController.$inject = ['$uibModalInstance', 'goodscode'];

    function regulationsModalController($uibModalInstance, goodscode) {
        var vm = {
            cancel: cancel,
            goodscode: goodscode
        };

        function cancel() {
//            $(".page-dashboard, .taric-page-content").removeClass("noprint");
            $uibModalInstance.dismiss('cancel');
        }

        return vm;
    }

    function showModal(goodscode) {
        $analytics.eventTrack('open-modal-regulations', { category: 'regulations' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: regulationsModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                goodscode: function() {
                    return goodscode;
                }
            },
            size: 'lg',
            templateUrl: 'regulations/modals/regulations.html',
            windowClass: 'modal-wide'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
