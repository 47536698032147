module.exports = {
    controller: EztTextsController,
    templateUrl: 'ezt/components/ezt-texts-page.html'
};

EztTextsController.$inject = [
    '$q', 
    '$routeParams', 
    'eztDataService'
];

function EztTextsController(
    $q, 
    $routeParams, 
    eztDataService
) {
    var vm = {
        $onInit: attached,
        scrollToDocument: scrollToDocument
    };

    return vm;

    function attached() {
        var promises = [loadIndex()];
        if ($routeParams.documentType && $routeParams.mainDocument) {
            promises.push(loadDocuments($routeParams.documentType, $routeParams.mainDocument));
        }
        $q.all(promises);
    }

    function scrollToDocument(eztDocument) {
        document
            .querySelector('.' + eztDocument.HtmlClassName)
            .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    
    function loadIndex() {
        vm.isLoadingIndex = true;
        return eztDataService.loadEztTextMainIndex()
            .then(function (index) {
                vm.isLoadingIndex = false;
                vm.mainIndex = index;
            });
    }

    function loadDocuments(documentType, mainDocument) {
        vm.isLoadingDocuments = true;
        return eztDataService.loadEztDocuments(documentType, mainDocument)
            .then(function (documents) {
                vm.isLoadingDocuments = false;
                vm.documents = documents;
            })
    }
}