module.exports = [
    'common',
    'countryDataService', 
    'dataContext', 
    'applicationStateService',
    vatRatesDataService
];

function vatRatesDataService(
    common, 
    countryDataService,
    dataContext,
    state
) {
    var service = {
        getVatRatesCountries: getVatRatesCountries,
        getVatRates: getVatRates
    };
    return service;

    function getVatRatesCountries() {
        var url = common.config.apiHosts.generic + '/vat-rates-countries';
        return dataContext.get(url)
            .then(function (countryCodes) {
                return _.map(countryCodes, function (countryCode) {
                    if (countryCode === 'EL') {
                        return {
                            code: countryCode,
                            name: countryDataService.countries['GR']
                        }
                    }
                    return {
                        code: countryCode,
                        name: countryDataService.countries[countryCode]
                    };
                });
            });
    }

    function getVatRates(commodityCode, memberState) {
        var url = common.config.apiHosts.generic + '/vat-rates/' + commodityCode +
            '?referenceCountry=' + memberState +
            '&lang=' + common.language.get() +
            '&date=' + state.filter.date;
        return dataContext.get(url);
    }
}