module.exports = {
    CAS_RN: 'CAS RN',
    ChemicalsSearch: 'Zoek chemische stoffen',
    Characteristic: 'Kenmerk',
    Characteristics: 'Kenmerken',
    Comment: 'Opmerking',
    CUS: 'CUS',
    EC_Number: 'EC-nummer',
    Ecics: 'ECICS',
    EcicsDetails: 'ECICS-details',
    EcicsLinkText: 'Klik hier om dit item te openen in de ECICS-database.',
    InChI: 'InChI',
    InChIKey: 'InChI-sleutel',
    LanguageCode: 'Taalcode',
    Level: 'Niveau',
    Name: 'Naam',
    Names: 'Namen',
    Nomenclature: 'Nomenclatuur',
    Order: 'Volgorde',
    SearchEcicsDatabase: 'Zoek in ECICS-database',
    UN_Number: 'UN-Nummer',
};