﻿angular.module('taric.geography', [])
    .config(['$routeProvider', routeConfigurator])
    .component('regionOverviewPage', require('./components/region-overview-page'))
    .component('countryPicker', require('./components/country-picker'))
    .component('regionDisplay', require('./components/region-display'))
    .factory('countryDataService', require('./services/country-data'))
    .factory('countryModals', require('./modals/modals'))
    .factory('regionDataService', require('./services/region-data'))
    .factory('regionModal', require('./modals/region'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/regions', {
            template: '<region-overview-page></region-overview-page>'
        })
        ;
}