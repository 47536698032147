﻿module.exports = ['common', 'dataContext', 'applicationStateService', complianceDataService];

function complianceDataService(common, dataContext, state) {

    var service = {
        result: {},
        cancelJob: cancelJob,
        createJob: createJob,
        createJobFromTemplate: createJobFromTemplate,
        finalizeJob: finalizeJob,
        getJobDetails: getJobDetails,
        getJobErrors: getJobErrors,
        getJobLogs: getJobLogs,
        getJobTemplates: getJobTemplates,
        getJobTypes: getJobTypes,
        getJobWarnings: getJobWarnings,
        loadJobs: loadJobs,
        uploadFile: uploadFile
    };

    function cancelJob(jobId) {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/job/' + jobId + '/cancel';

        dataContext.post(url, {})
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function createJob(job) {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/job';

        dataContext.post(url, job)
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function createJobFromTemplate(templateId) {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/job?template=' + templateId;

        dataContext.post(url, {})
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function finalizeJob(jobId) {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/job/' + jobId + '/finalize';

        dataContext.post(url, {})
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getJobDetails(jobId, pageIndex, pageSize) {
        var deferred = common.$q.defer();
        var url = common.config.apiHosts.generic + '/compliance/job/' + jobId + '/details?pindex=' + pageIndex + '&psize=' + pageSize;

        dataContext.get(url)
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getJobErrors(jobId) {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/job/' + jobId + '/error';

        dataContext.get(url)
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getJobLogs(jobId) {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/job/' + jobId + '/log';

        dataContext.get(url)
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getJobTemplates() {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/template';

        dataContext.get(url)
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getJobTypes() {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/jobtypes?referencecountry=' + state.filter.referenceCountry;

        dataContext.get(url)
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getJobWarnings(jobId) {
        var deferred = common.$q.defer();

        var url = common.config.apiHosts.generic + '/compliance/job/' + jobId + '/warning';

        dataContext.get(url)
            .then(function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadJobs(pageNumber) {
        var deferred = common.$q.defer();
        var pageIndex = pageNumber - 1;
        var url = common.config.apiHosts.generic + '/compliance/job/status?psize=20&pindex=' + pageIndex;

        dataContext.get(url)
            .then(function (result) {
                service.result = result;
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function uploadFile(jobId, file, goodColumnIndex, originColumnIndex, startingLineIndex) {
        var deferred = common.$q.defer();

        var formData = new FormData();
        formData.append("items[]", file.filedata);

        var urlArgs = ['docType=Excel', 'goodColumn=' + goodColumnIndex, 'countryColumn=' + originColumnIndex, 'startingLine=' + startingLineIndex];

        dataContext.xhr(common.config.apiHosts.generic + '/compliance/job/' + jobId + '/document?' + urlArgs.join('&'), formData)
            .then(function (result) {
                deferred.resolve(result);
            });

        return deferred.promise;
    }

    return service;
}
