var moment = require('moment');

module.exports =  {
    bindings: {},
    controller: btiImagesController,
    templateUrl: 'bti/components/bti-images.html'
}

btiImagesController.$inject = ['btiDataService', 'applicationStateService'];

function btiImagesController(btiDataService, state) {
    var vm = {
        service: btiDataService,
        changeActiveBti: btiDataService.changeActiveBti,
        isLoading: false,
        loadMore: loadMore,
        referenceDate: moment(state.filter.date, 'YYYY-MM-DD').format('D-M-YY')
    };
    return vm;

    function loadMore() {
        vm.isLoading = true;
        btiDataService.loadMoreBtis().then(function () {
            vm.isLoading = false;
        });
    }
}