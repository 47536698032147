angular.module('taric.customsValue', [])
    .config(['$routeProvider', routeConfigurator])
    .component('transactionValueCalculatorPage', require('./components/transaction-value-calculator-page'))
    .component('transactionValueCalculator', require('./components/transaction-value-calculator'))
    .component('transactionValueComponent', require('./components/transaction-value-component'))
    .factory('transactionValueCalculatorModal', require('./modals/transaction-value-calculator'))
    .factory('transactionValueDataService', require('./services/transaction-value-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/transaction-value-calculator', {
            template: '<transaction-value-calculator-page></transaction-value-calculator-page>'
        })
        ;
}
