module.exports = [celexToRegulationNumberFilter];

function celexToRegulationNumberFilter() {


    return function (celex) {
        if (celex === null || celex === undefined) {
            return celex;
        }
        var year = celex.substr(1, 4);
        if (_.startsWith(year, '19')) {
            year = year.substr(2, 2);
        }
        var number = _.trimStart(celex.substr(6, 4), '0');
        
        return number + '/' + year;
    };
}