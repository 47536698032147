angular.module('taric.validateBusinessInfo', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('validateBusinessInfoSubnav', require('./components/validate-business-info-subnav'))
    .component('validateBusinessInfoCdForm', require('./components/validate-business-info-cd-form'))
    .component('validateBusinessInfoEoriForm', require('./components/validate-business-info-eori-form'))
    .component('validateBusinessInfoRexForm', require('./components/validate-business-info-rex-form'))
    .component('validateBusinessInfoVatForm', require('./components/validate-business-info-vat-form'))
    // Modals
    .factory('validateBusinessInfoRexModal', require('./modals/validate-business-info-rex-modal'))
    // Services
    .factory('validateBusinessInfoDataService', require('./services/validate-business-info-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/validate-business-info/home', {
            template: '<validate-business-info-eori-form></validate-business-info-eori-form>',
        })
        .when('/validate-business-info/check-customs-decision', {
            template: '<validate-business-info-cd-form></validate-business-info-cd-form>'
        })
        .when('/validate-business-info/check-eori-number', {
            template: '<validate-business-info-eori-form></validate-business-info-eori-form>'
        })
        .when('/validate-business-info/check-rex-number', {
            template: '<validate-business-info-rex-form></validate-business-info-rex-form>'
        })
        .when('/validate-business-info/vies-check-vat-number', {
            template: '<validate-business-info-vat-form></validate-business-info-vat-form>'
        })

        ;
}