module.exports = ['$uibModal', 'common', classificationRegulationsModal];

function classificationRegulationsModal($uibModal, common) {
    var modal = {
        goodsCode: null,
        searchQuery: null,
        show: showModal
    };

    classificationRegulationsModalController.$inject = [
        '$uibModalInstance', 
        'goodsCode', 
        'language', 
        'searchQuery', 
        'applicationStateService', 
        'searchClassificationRegulationsService'
    ];

    function classificationRegulationsModalController(
        $uibModalInstance, 
        goodsCode, 
        language, 
        searchQuery, 
        state, 
        searchClassificationRegulationsService
    ) {
        var vm = {
            cancel: cancel,
            data: searchClassificationRegulationsService.data,
            goodsCode: goodsCode,
            isLoading: false,
            language: language.get(),
            load: load,
            referenceCountry: state.filter.referenceCountry,
            searchQuery: searchQuery
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function init() {
            load();
        }

        function load() {
            if (vm.language === 'nl') {
                vm.isLoading = true;
                searchClassificationRegulationsService.load(goodsCode, searchQuery)
                    .then(function () {
                        vm.isLoading = false;
                    });
            }
        }

        init();

        return vm;
    }

    function showModal(goodsCode, searchQuery) {

        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: classificationRegulationsModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                goodsCode: function () {
                    return goodsCode;
                },
                searchQuery: function () {
                    return searchQuery;
                }
            },
            size: 'lg',
            templateUrl: 'classification-regulations/modals/classification-regulations.html',
            windowClass: 'modal-classification-regulations'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
