﻿module.exports = {  
    bindings: { 
        autoFocus: '<',
        countryCode: '<',
        possibleCountries: '<',
        rememberCountry: '<',
        selectionChanged: '&',
        showRememberCountryToggle: '<', 
        validation: '<' 
    },
    controller: countryPickerController,
    templateUrl: 'geography/components/country-picker.html'
};

countryPickerController.$inject = ['$element', '$timeout'];

function countryPickerController($element, $timeout) {
    var vm = {
        $onInit: attached,
        $postLink: postLink,
        clear: clear,
        country: null,
        itemSelected: itemSelected,
        onBlur: onBlur,
        onEnter: onEnter,
        toggleRememberCountry: toggleRememberCountry
    };

    return vm;

    function attached() {
        if (vm.countryCode) {
            vm.country = _.find( vm.possibleCountries, function(country) {
                return country.code === vm.countryCode;
            });
        }
    }

    function postLink() {
        if (vm.autoFocus) {
            $timeout(function(){
                var rootEl = $element[0];
                var inputEl = rootEl.getElementsByClassName('js-autofocus')[0];
                inputEl.focus();
            }, 0);
        }
    }

    function clear() {
        vm.country = null;
        clearSelection();
    }

    function clearSelection() {
        if (vm.selectionChanged)
            vm.selectionChanged({ country: null, rememberCountry: vm.rememberCountry });            
    }

    function onBlur() {
        if (!vm.country) {
            clearSelection();
        }
    }

    function onEnter() {
        if (!vm.country) {
            clearSelection();
        }
    }

    function itemSelected(item, model) {
        if (vm.selectionChanged)
            vm.selectionChanged({ country: item, rememberCountry: vm.rememberCountry });
    }

    function toggleRememberCountry() {
        vm.rememberCountry = !vm.rememberCountry;
        if (vm.selectionChanged) {
            vm.selectionChanged({ country: vm.country, rememberCountry: vm.rememberCountry });
        }
    }
}
