module.exports = ['applicationStateService', 'notificationsService', 'preferenceService', tradeMovementService];

function tradeMovementService(state, notifications, preferences) {
    
    var service = {
        set: setTradeMovement
    };
    
    return service;

    function setTradeMovement(tradeMovement) {
        if ((tradeMovement !== 'import' && tradeMovement !== 'export') || tradeMovement === state.filter.movement) {
            return;
        }
        state.filter.movement = tradeMovement;
        notifications.publish('filter_movement_changed', tradeMovement);
        preferences.replace({
            name: 'movement',
            category: 'user_preferences',
            value: tradeMovement,
            valueType: 'stringValue'
        });
}
}