// Various ways of ordering search results
module.exports = {
    CommodityCode_Ascending: 'Commodity Code - Ascending',
    CommodityCode_Descending: 'Commodity Code - Descending',
    CUS_Ascending: 'CUS - Ascending',
    CUS_Descending: 'CUS - Descending',
    ValidFrom_Ascending: 'Valid From - Ascending',
    ValidFrom_Descending: 'Valid From - Descending',
    Relevance_Keywords: 'Relevance - Keywords',
    Relevance_GoodsDescription: 'Relevance - Goods Description',
    Relevance: 'Relevance',
};