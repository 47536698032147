module.exports = {
    bindings: {
        dutyCalculationResult: '<',
        dutyCalculationErrors: '<'
    },
    controller: dutyCalculationResultController,
    templateUrl: 'good/components/duty-calculation-result.html'
};

dutyCalculationResultController.$inject = ['applicationStateService'];

function dutyCalculationResultController(state) {
    var vm = {
        state: state
    }
    return vm;
}