module.exports = {
    bindings: { 
        placeholder: '<', 
        orderNumber: '<', 
        possibleOrderNumbers: '< ', 
        selectionChanged: '&' 
    },
    controller: orderNumberPickerController,
    templateUrl: 'quota/components/order-number-picker.html'
};

function orderNumberPickerController() {
    
    var vm = {
        clear: clear,
        orderNumber: null,
        userInput: null,
        possibleOrderNumbers: null,
        itemSelected: itemSelected,
        onBlur: onBlur,
        onEnter: onEnter
    };
    
    return vm;

    function clear(ev) {
        vm.userInput = null;
        clearSelection();
    }
    
    function clearSelection() {
        vm.orderNumber = null;
        if (vm.selectionChanged)
            vm.selectionChanged({ orderNumber: null });

    }

    function onBlur() {
    }

    function onEnter() {
    }

    function itemSelected(item, model) {
        vm.orderNumber = item;

        if (vm.selectionChanged) {
            vm.selectionChanged({ orderNumber: item });
        }
    }   
}