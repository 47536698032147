module.exports = {
    controller: ecicsImagesController,
    templateUrl: 'ecics/components/ecics-images.html'
};

ecicsImagesController.$inject = ['ecicsDataService'];

function ecicsImagesController(ecicsDataService) {
    var vm = {
        service: ecicsDataService,
        changeActiveItem: ecicsDataService.changeActiveItem,
        isLoading: false,
        loadMore: loadMore
    };
    return vm;

    function loadMore() {
        vm.isLoading = true;
        ecicsDataService.loadMoreItems().then(function () {
            vm.isLoading = false;
        });
    }
}