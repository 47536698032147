module.exports = ['dataContext', 'config', 'language', 'applicationStateService', searchClassificationRegulations];

function searchClassificationRegulations(dataContext, config, language, state) {
    var service = {
        data: {
            classificationRegulations: []
        },
        load: loadClassificationRegulations,
        loadTarifering: loadTarifering
    };
    return service;

    function loadClassificationRegulations(goodsCode, searchQuery) {
        service.data.classificationRegulations = [];
        var url = config.apiHosts.generic + '/classifications/search?GoodsCode=' + (goodsCode || '') +
            '&date=' + state.filter.date +
            '&lang=' + language.get();
        if (searchQuery) {
            url += '&Query=' + searchQuery;
        }
        return dataContext.get(url).then(function (classificationRegulations) {
            service.data.classificationRegulations = classificationRegulations;
        });
    }

    function loadTarifering(heading, tariferingNumber) {
        var url = config.apiHosts.generic + '/classifications/tariferingen/' + heading + '/' + tariferingNumber;
        return dataContext.get(url);
    }
}