﻿// See https://app.taricsupport.com/#!/news/regulations
module.exports = {
    ActiveTaricMeasures: 'Active TARIC Measures',
    BaseRegulation: 'Base Regulation',
    BaseRegulations: 'Base Regulations',
    ChangedBy: 'Changed by',
    ClassificationReference: 'Reference to Classification of Heading',
    ClassificationInLegalActsDirectory: 'Classification in Legal Acts Directory',
    CountryRegion: 'Country/Region',
    DocumentNotFound: 'Document Not Found',
    GoBackToTheOverview: 'Go Back to the Overview',
    HereAreAFewThings: 'Here are a few things you may want to do.',
    InForce: 'In Force',
    ModificationRegulation: 'Modification Regulation',
    ModificationRegulations: 'Modification Regulations',
    Name: 'Name',
    NoModificationRegulations: 'No Modification Regulations',
    OfficialJournal: 'Official Journal',
    OJ: 'OJ',
    OpenDetails: 'Open Details',
    OpenDocumentOnEurLex: 'Open document on EUR-Lex',
    Note: 'Note',
    Note_CELEX_: 'Note {{ CELEX }}',
    Page: 'Page',
    PubDate: 'Publ. Date',
    PublicationDate: 'Publication Date',
    _count_publicationsFound: '{{ count }} Publications Found',
    PubNo: 'Pub No.',
    PubPage: 'Pub. Page',
    Regulation: 'Regulation',
    Regulation_CELEX_: 'Regulation {{ CELEX }}',
    Regulations: 'Regulations',
    ResourceType: 'Document Type',
    SearchEurLexFor: 'Search EUR-Lex for',
    ThereIsNoDocument_celex_: 'There is no document in our application with CELEX equal to {{ celex }}',
    ModalTitle: 'Regulations',
    consolidated: 'consolidated into the above text', // "consolidated" means "incorporated into the above text
    unconsolidated: 'not consolidated into the above text', // "consolidated" means "incorporated into the above text
};
