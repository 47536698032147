﻿module.exports = ['$analytics', '$uibModal', regionModal];

function regionModal($analytics, $uibModal) {
    var modal = {
        region: null,
        show: showModal
    };

    regionModalController.$inject = ['$uibModalInstance', 'countryDataService', 'region'];

    function regionModalController($uibModalInstance, countryDataService, region) {
        var vm = {
            cancel: cancel,
            countries: [],
            region: region,
            searchParameter: ''
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        var countryCodes = countryDataService.regions[region.code];
        vm.countries = _.map(countryCodes, function(countryCode) {
            return {
                code: countryCode,
                name: countryDataService.countries[countryCode]
            };
        });

        vm.countries = vm.countries.sort(function (a, b) {
            if (a.name > b.name)
                return 1;
            else if (a.name < b.name)
                return -1;
            return 0;
        });

        return vm;
    }

    function showModal(region) {
        $analytics.eventTrack('open-modal-region-members', { category: 'geography' });
        var modalInstance = $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: regionModalController,
            controllerAs: 'ctrlRegionModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                region: function () {
                    return region;
                }
            },
            size: 'lg',
            templateUrl: 'geography/modals/region.html',
            windowClass: 'modal-region'
        });

        modalInstance.rendered.then(function() {
            var elementToFocusOnInModal = document.querySelector('.modal.in .focus-on-open-modal');
            if (elementToFocusOnInModal) {
                elementToFocusOnInModal.focus();
            }
        });

        return modalInstance.result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
