module.exports = [certificateCodeTypeaheadFilter];

// Takes care of ordering the results of the certificate code typeahed. The logic is as follows:
// First, show the matches on the certificate code, ordered by code
// Next, show the matches on the beginning of the certificate code description, ordered by description
// Finally, show the matches anywhere else in the description, ordered by description
function certificateCodeTypeaheadFilter() {

    return function (certificateCodes, query) {
        var result;
        if (query === null || query === undefined || query === '') {
            result = _.sortBy(certificateCodes, function(certificateCode) {
                return certificateCode.type + certificateCode.code;
            });
        }
        else {
            var certificateCodeMatchPartition = _.partition(certificateCodes, function(certificateCode) {
                return (certificateCode.type + certificateCode.code).toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) >= 0;
            });
            var certificateCodeMatchStartPartition = _.partition(certificateCodeMatchPartition[0], function(certificateCode) {
                return (certificateCode.type + certificateCode.code).toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) === 0;
            });

            var certificateCodeStartMatches = _.sortBy(certificateCodeMatchStartPartition[0], function(certificateCode) {
                return certificateCode.type + certificateCode.code;
            });
            var certificateCodeOtherMatches = _.sortBy(certificateCodeMatchStartPartition[1], function(certificateCode) {
                return certificateCode.type + certificateCode.code;
            });

            var otherMatches = _.sortBy(certificateCodeMatchPartition[1], 'description');

            var descriptionStartMatchPartition = _.partition(otherMatches, function(certificateCode) {
                return certificateCode.description && certificateCode.description.toLowerCase().indexOf(query.toLowerCase()) === 0;
            });

            var otherDescriptionMatches = _.filter(descriptionStartMatchPartition[1], function(certificateCode) {
                return certificateCode.description && certificateCode.description.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });

            result = _.concat(certificateCodeStartMatches, certificateCodeOtherMatches, descriptionStartMatchPartition[0], otherDescriptionMatches);
        }

        return result;
    }
}

