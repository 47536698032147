﻿module.exports = {
    bindings: {
        currentKey: '<',
        keyNode: '<',
        onClickRow: '&'
    },
    controller: taresStatisticalKeyRowController,
    templateUrl: 'nomenclature/components/tares-statistical-key-row.html'
};

taresStatisticalKeyRowController.$inject = [];

function taresStatisticalKeyRowController() {
    var vm = {
        clickRow: clickRow
    };

    function clickRow(keyNode) {
        if (keyNode.value.prodlin === 80 && vm.onClickRow) {
            vm.onClickRow({ key: keyNode.value.key });
        }
    }

    return vm;
}