﻿module.exports = {
    tabs: {
        Measures: 'Maatregelen',
        ValidateDeclaration: 'Valideer aangifte',
        CalculateDuty: 'Bereken rechten'
    },
    form: {
        legendValue: 'Waarde',
        radioMethodLabel: 'Methode',	
        radioMethodOptionCalculateValue: 'Waarde berekenen',
        radioMethodOptonEnterValue: 'Waarde invoeren'
    },
    financialConditions: {
        E: {
            title: 'De aangegeven hoeveelheid of prijs per eenheid, naar gelang het geval, is gelijk aan of minder dan het aangeduide maximum, anders overlegging van het vereiste document',
            headers: {
                col1: 'Hoeveelheid of prijs per eenheid',
                col2: 'Tarief'
            }
        },
        F: {
            title: 'Tarief op basis van netto prijs franco grens, vóór inklaring',
            headers: {
                col1: 'Netto prijs franco grens, vóór inklaring',
                col2: 'Tarief'
            }
        },
        L: {
            title: 'De CIF-prijs moet hoger zijn dan de minimumprijs',
            headers: {
                col1: 'CIF-prijs',
                col2: 'Tarief'
            }
        },
        M: {
            title: 'De invoerprijs moet gelijk zijn aan of hoger dan de minimumprijs/referentieprijs',
            headers: {
                col1: 'Invoerprijs',
                col2: 'Tarief'
            }
        },
        V: {
            title: 'De invoerprijs dient gelijk te zijn of hoger dan de ingangprijs',
            headers: {
                col1: 'Invoerprijs',
                col2: 'Tarief'
            }
        }
    },
    meursing: {
        calculateDescription: 'Vul onderstaande gegevens in om de aanvullende code voor het berekenen van het agrarisch element op te vragen.',
        calculateTitle: 'Aanvullende code agrarisch element berekenen',
        impossibleCombination: 'Onmogelijke combinatie van ingredienten (totaal gewicht boven 100%)',
        milkfat: 'Melkvet (% gewicht)',
        milkproteins: 'Melkproteïnen (% gewicht)',
        saccharin: 'Saccharose/invertsuiker/isoglucose (% gewicht)',
        starchGlucose: 'Zetmeel/glucose (% gewicht)',
    }
};
