﻿module.exports = ['$analytics', '$uibModal', 'language', regulationModal];

function regulationModal($analytics, $uibModal, language) {
    var modal = {
        measures: null,
        show: showModal
    };

    regulationModalController.$inject = ['$uibModalInstance', 'measures', 'applicationStateService'];

    function regulationModalController($uibModalInstance, measures, state) {
        var ctrlRegulationModal = {
            cancel: cancel,
            measures: measures,
            referenceCountry: state.filter.referenceCountry
        };

        function cancel() {
//            $(".page-dashboard, .taric-page-content").removeClass("noprint");
            $uibModalInstance.dismiss('cancel');
        }

        return ctrlRegulationModal;
    }

    function showModal(measures) {
        $analytics.eventTrack('open-modal-regulation', { category: 'regulation' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: regulationModalController,
            controllerAs: 'ctrlRegulationModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                measures: function () {
                    return _.map(measures, function(measure) {
                        if (measure.regulation) {
                            measure.regulation.oj_url = measure.regulation.url;
                            if (measure.regulation.reg_journal_numb && measure.regulation.reg_journal_numb !== '1' && measure.regulation.reg_pubdate && measure.regulation.reg_journal_page) {
                                measure.regulation.url =
                                    'http://eur-lex.europa.eu/search.html?whOJ=NO_OJ%3D' + measure.regulation.reg_journal_numb.substring(2) +
                                    ',YEAR_OJ%3D' + measure.regulation.reg_pubdate.substring(0, 4) +
                                    ',PAGE_FIRST%3D' + _.padStart(measure.regulation.reg_journal_page, 4, '0') +
                                    '&DB_COLL_OJ=oj-l&type=advanced&lang=' + language.get();
                            }
                        }
                        return measure;
                    });
                }
            },
            size: 'lg',
            templateUrl: 'regulations/modals/regulation.html',
            windowClass: 'modal-regulation'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
