module.exports = {
    controller: euCaseLawPageController,
    templateUrl: 'case-law/components/eu-case-law-page.html'
};

euCaseLawPageController.$inject =['$analytics', 'euCaseLawDataService'];

function euCaseLawPageController($analytics, euCaseLawDataService) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        data: euCaseLawDataService,
        clear: clearForm,
        dateOptions: {},
        format: 'dd-MM-yyyy',
        open1: open1,
        open2: open2,
        pageChanged: pageChanged,
        popup1: {
            opened: false,
        },
        popup2: {
            opened: false,
        },
        search: search,
    };

    function attached() {
        $analytics.pageTrack('/case-law-eu');
        euCaseLawDataService.loadTags();
        euCaseLawDataService.loadCaseLaw();
    }

    function detached() {
        euCaseLawDataService.clear();
    }

    function clearForm() {
        euCaseLawDataService.clear();
        euCaseLawDataService.loadCaseLaw();
    }

    function open1() {
        vm.popup1.opened = true;
    }

    function open2() {
        vm.popup2.opened = true;
    }

    function pageChanged() {
        euCaseLawDataService.loadCaseLaw();
        var height = document.getElementsByClassName('rechtspraak-search-buttons')[0].offsetTop;
        var element = document.getElementById('content');
        element.scrollTo(0, height);
    }

    function search() {
        euCaseLawDataService.loadCaseLaw();
    }

    return vm;
}