﻿module.exports = [highlightFilter];

function highlightFilter() {
    return function (text, filterPhrase, splitWords) {
        if (text && filterPhrase) {
            var tag_re = /(<\/?[^>]+>)/g;
            text = text.split(tag_re).map(function (substring) {
                var result = substring;
                if (!result || result.match(tag_re)) {
                    return result;
                } else {
                    var words = splitWords ? filterPhrase.split(' ') : [filterPhrase];
                    _.forEach(words, function (word) {
                        var filter_re = new RegExp(_.escapeRegExp(word), 'gi');
                        result = result.replace(filter_re,
                            function (str) { return '<mark>' + str + '</mark>'; });
                    });
                    return result;
                }
            }).join('');
        }
        return text;
    };
}