module.exports = {
    controller: regulationsNewsController,
    templateUrl: 'regulations/components/regulations-news-page.html'
};

regulationsNewsController.$inject = ['$analytics'];

function regulationsNewsController($analytics) {
    var vm = {
        $onInit: attached
    };
    return vm;

    function attached() {
        $analytics.pageTrack('/regulations-news');
    }
}