﻿module.exports = {
    bindings: {
        measure: '<'
    },
    controller: measurePreferenceCodeButtonsController,
    templateUrl: 'good/components/measure-preference-code-buttons.html'
};

measurePreferenceCodeButtonsController.$inject = ['applicationStateService']

function measurePreferenceCodeButtonsController(state) {
    var vm = {
        state: state
    };
    return vm;
}