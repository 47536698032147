module.exports = {
    controller: hs2022backwardController,
    templateUrl: 'correlation-table/components/hs-2022-backward-page.html'
};

hs2022backwardController.$inject = ['$analytics'];

function hs2022backwardController($analytics) {

    var vm = {
        $onInit: attached
    };

    function attached() {
        $analytics.pageTrack('/correlation-table/hs2022');
    }
    
    return vm;
}
