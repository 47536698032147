module.exports = {
    controller: caseLawNewsPageController,
    templateUrl: 'case-law/components/case-law-news-page.html'
};

caseLawNewsPageController.$inject = ['$analytics'];

function caseLawNewsPageController($analytics) {
    var vm = {
        $onInit: attached
    };
    return vm;

    function attached() {
        $analytics.pageTrack('/case-law-news');
    }
}