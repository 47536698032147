﻿// See https://app.taricsupport.com/#!/bti/home
// See https://ec.europa.eu/taxation_customs/dds2/ebti/ebti_home.jsp?Lang=en
module.exports = {
    BindingTariffInformation: 'Binding Tariff Information',
    BtiFirstLetter: 'B',
    Btis: 'BTIs',
    BtiReference: 'BTI Reference',
    copyDescription: 'This information is a copy of the information in the EU EBTI-database.',
    countryOfOrigin: 'Issuing Country / Language',
    dateAndPlaceOfIssue: 'Date and Country of Issue',
    details: 'BTI Details',
    ebtiLinkText: 'Click here to see the up-to-date version in this database.',
    EuropeanBindingTariffInformation: 'European Binding Tariff Information',
    goodsDescription: 'Goods Description',
    issuedSince: 'Issued Since',
    Keywords: 'Keywords',
    motivation: 'Grounds for classification',
    nationalKeywords: 'National Keywords',
    NOIMAGE: 'NO IMAGE', // Shown instead of an image when no image is available
    noLongerValid: 'This BTI is no longer valid and can therefore no longer be found in the EU EBTI-database.',
    notValidOn_referenceDate_: 'not valid on {{ referenceDate | date: \'dd-MM-yy\' }}',
    possiblyInvalid: 'Note: in some cases, such as the publication of a classification regulation, BTIs may be revoked before their normal period of validity has expired. Hence, it is possible that this BTI is no longer valid.',
    PageTitleSearchBtis: 'Search BTIs',
    _btiCount_bindingTariffInformationDecisionsFound: '{{ btiCount }} binding tariff information decision(s) found',
    _StartDate_until_EndDate_: '{{ StartDate | date: \'dd-MM-yyyy\' }} until {{ EndDate | date: \'dd-MM-yyyy\' }}'
};
