window._ = require('lodash');
window.angular = require('angular');

angular.module('taric', [
    'ngCookies',
    'ngLocale',
    'ngRoute',
    'ngSanitize',
    // 3rd party modules
    'angulartics',
    'angulartics.google.analytics',
    'ngFileUpload',
    'chart.js',
    'pascalprecht.translate',
    'ui.bootstrap',
    'ui.mask',
    'ui.select',
    'angular-intro',
    'toastr',
    'tmh.dynamicLocale',
    // Internal modules
    'taric.core', // all core functionality
    'taric.account', // all account related functionality
    'taric.additionalcodes',
    'taric.belastingdienst',
    'taric.bti',
    'taric.atr',
    'taric.caseLaw',
    'taric.certificateCodes',
    'taric.classificationRegulations',
    'taric.compliance', // all compliance related functionality
    'taric.correlationTable',
    'taric.customsValue',
    'taric.dashboard',
    'taric.documentation', // interactive tour
    'taric.duty',
    'taric.exchangeRates',
    'taric.ezt',
    'taric.ecics',
    'taric.footnotes', // all footnotes related functionality
    'taric.geography', // all country/region related functionality
    'taric.good', // all good related functionality
    'taric.links',
    'taric.measureTypes',
    'taric.news',
    'taric.nomenclature', // all nomenclature related functionality
    'taric.patterns',
    'taric.preferenceCodes',
    'taric.quota', // all text quota related functionality
    'taric.referencePrices',
    'taric.regulations',
    'taric.search',
    'taric.referenceDate',
    'taric.tares', // The Swiss trade tariff
    'taric.texts', // all text related functionality, e.g. explanatory notes, bti's, annotations, footnotes, etc.
    'taric.tradeMovement',
    'taric.tradeStatistics',
    'taric.units', // measurement units
    'taric.validateBusinessInfo',
    'taric.vatRates',
    'taric.widgets', // all shared interface functionality, e.g. general modals
]);

try {
    angular.module(['ngSentry']);
}
catch (e) {}

require('angulartics');
require('angulartics-google-analytics');
require('angular-chart.js');
require('angular-cookies');
require('angular-dynamic-locale');
require('angular-intro.js');
require('angular-sanitize');
require('angular-route');
require('angular-toastr');
require('angular-dynamic-locale');
require('angular-translate');
require('angular-ui-bootstrap');
require('angular-ui-mask');
require('chart.js');
require('intro.js');
require('ng-file-upload');
require('ui-select');

require('./translations/en/_index');
require('./translations/it/_index');
require('./translations/nl/_index');

require('./config');
require('./core/_module');
require('./account/_module');
require('./additional-codes/_module');
require('./belastingdienst/_module');
require('./bti/_module');
require('./atr/_module');
require('./case-law/_module');
require('./certificate-codes/_module');
require('./classification-regulations/_module');
require('./compliance/_module');
require('./correlation-table/_module');
require('./customs-value/_module');
require('./dashboard/_module');
require('./documentation/_module');
require('./duty/_module');
require('./exchange-rates/_module');
require('./ezt/_module');
require('./ecics/_module');
require('./footnotes/_module');
require('./geography/_module');
require('./good/_module');
require('./links/_module');
require('./measure-types/_module');
require('./news/_module');
require('./nomenclature/_module');
require('./patterns/_module');
require('./preference-codes/_module');
require('./quota/_module');
require('./reference-date/_module');
require('./referenceprices/_module');
require('./regulations/_module');
require('./search/_module');
require('./tares/_module');
require('./texts/_module');
require('./trade-movement/_module');
require('./trade-statistics/_module');
require('./units/_module');
require('./validate-business-info/_module');
require('./vat-rates/_module');
require('./widgets/_module');

