module.exports = {
    bindings: { 
        settings: '<' 
    },
    controller: searchSubNavController,
    templateUrl: 'search/components/search-sub-nav.html'
};

searchSubNavController.$inject = [
    '$scope', 
    '$location', 
    'caseLawModal', 
    'language', 
    'searchService', 
    'applicationStateService', 
    'formatting',
];

function searchSubNavController(
    $scope, 
    $location, 
    caseLawModal, 
    language, 
    searchService, 
    state, 
    formatting
) {

    var vm = {
        $onInit: attached,
        language: language.get(),
        referenceCountry: state.filter.referenceCountry,
        searchAlphabeticalIndex: searchAlphabeticalIndex,
        searchAnnotations: searchAnnotations,
        searchBtis: searchBtis,
        searchChemicals: searchChemicals,
        searchClassificationRegulations: searchClassificationRegulations,
        searchCccConclusions: searchCccConclusions,
        searchEuExplanatoryNotes: searchEuExplanatoryNotes,
        searchGermanExplanatoryNotes: searchGermanExplanatoryNotes,
        searchTextsGermanCustoms: searchTextsGermanCustoms,
        searchHeadings: searchHeadings,
        searchWcoClassificationOpinions: searchWcoClassificationOpinions,
        searchWcoExplanatoryNotes: searchWcoExplanatoryNotes,
        showCaseLaw: showCaseLaw,
        showHeadingSubNavItem: showHeadingSubNavItem,
        showSubHeadingSearch: showSubHeadingSearch,
        searchAtrs: searchAtrs,
    };
    
    return vm;
    
    function attached() {
        vm.q = searchService.parseLocationSearchObject($location.search());
    
        $scope.$on('$locationChangeSuccess', function () {
            vm.q = searchService.parseLocationSearchObject($location.search());
        });
    }

    function searchAnnotations() {
        $location.path('/search/annotations');
    }

    function searchEuExplanatoryNotes() {
        $location.path('/search/explanatory-notes/eu');
    }

    function searchGermanExplanatoryNotes() {
        $location.path('/search/explanatory-notes/germany');
    }

    function searchTextsGermanCustoms() {
        $location.path('/search/texts/germany');
    }

    function searchHeadings() {
        var search  = $location.search();
        $location.search('commodityCode', '');
        if (search.orderBy !== 'commoditycode' && search.orderBy !== 'relevance') {
            $location.search('orderBy', 'relevance');
        }
        $location.path('/search/heading');
    }
    
    function searchWcoExplanatoryNotes() {
        $location.path('/search/explanatory-notes/wco');
    }

    function searchWcoClassificationOpinions() {
        $location.path('/search/classifications/wco/');
    }

    function searchBtis() {
        $location.path('/bti/search');
    }
    
    function searchAlphabeticalIndex() {
        $location.path('/search/alphabetical-index');
    }

        
    function searchAtrs() {
        $location.path('/atr/search');
    }

    function searchChemicals() {
        $location.path('/ecics/search');
    }

    function searchCccConclusions() {
        $location.path('/search/ccc-conclusions');
    }
    
    function searchClassificationRegulations() {
        $location.path('/classification-regulations/' + vm.language + '/search');
    }

    function showSubHeadingSearch() {
        $location.path('/search/tree').search({ 
            commodityCode: formatting.removeTrailingZeros(vm.q.commodityCode),
            query: vm.q.query 
        });
    }

    function showHeadingSubNavItem() {
        return vm.q.commodityCode && formatting.isHeadingCode(vm.q.commodityCode);
    }

    function showCaseLaw() {
        caseLawModal.show(vm.q.commodityCode, vm.q.query);
    }
}