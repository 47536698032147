﻿angular.module('taric.search', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('foundTexts', require('./components/found-texts'))
    .component('headingSearchResults', require('./components/heading-search-results'))
    .component('headerSearchBox', require('./components/header-search-box'))
    .component('largeSearchBox', require('./components/large-search-box'))
    .component('nomenclatureSearchForm', require('./components/nomenclature-search-form'))
    .component('searchForm', require('./components/search-form'))
    .component('searchSubNav', require('./components/search-sub-nav'))
    // Controllers
    .controller('searchBoxController', require('./controllers/search-box'))
    // Pages
    .component('searchAlphabeticalIndexPage', require('./components/pages/search-alphabetical-index'))
    .component('searchCccConclusionsPage', require('./components/pages/search-ccc-conclusions'))
    .component('searchAnnotationsPage', require('./components/pages/search-annotations'))
    .component('searchHeadingPage', require('./components/pages/search-heading'))
    .component('searchEuExplanatoryNotesPage', require('./components/pages/search-eu-explanatory-notes'))
    .component('searchExplanatoryNotesGermanyPage', require('./components/pages/search-explanatory-notes-germany'))
    .component('searchTextsGermanyPage', require('./components/pages/search-texts-germany'))
    .component('searchWcoClassificationOpinionsPage', require('./components/pages/search-wco-classification-opinions'))
    .component('searchWcoExplanatoryNotesPage', require('./components/pages/search-wco-explanatory-notes'))
    .component('searchPage', require('./components/pages/search'))
    // Services
    .factory('searchService', require('./services/search'))
    ;


function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/search/heading', {
            template: '<search-heading-page></search-heading-page>'
        })
        .when('/search/tree', {
            template: '<search-page></search-page>'
        })    
        .when('/search/annotations', {
            template: '<search-annotations-page></search-annotations-page>'
        })
        .when('/search/explanatory-notes/eu', {
            template: '<search-eu-explanatory-notes-page></search-eu-explanatory-notes-page>'
        })
        .when('/search/explanatory-notes/germany', {
            template: '<search-explanatory-notes-germany-page></search-explanatory-notes-germany-page>'
        })
        .when('/search/texts/germany', {
            template: '<search-texts-germany-page></search-texts-germany-page>'
        })
        .when('/search/explanatory-notes/wco', {
            template: '<search-wco-explanatory-notes-page></search-wco-explanatory-notes-page>'
        })
        .when('/search/classifications/wco', {
            template: '<search-wco-classification-opinions-page></search-wco-classification-opinions-page>'
        })
        .when('/search/alphabetical-index', {
            template: '<search-alphabetical-index-page></search-alphabetical-index-page>'
        })
        .when('/search/atr-index.page', {
            template: '<search-atr-index-page></search-atr-index-page>'
        })
        .when('/search/ccc-conclusions', {
            template: '<search-ccc-conclusions-page></search-ccc-conclusions-page>'
        })
        .when('/search/:searchquery', {
            template: '<search-page></search-page>'
        })
    ;
}
