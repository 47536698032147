﻿module.exports = {
    NoAccess: '<h2>Voor toegang tot deze data is een extra abonnement vereist.</h2> ' +
    '<p>In deze module vindt u een compleet overzicht van de referentieprijzen voor landbouwproducten. ' +
    'U vind hier elke dag de laatste dagwaardes, forfaitaire invoerwaarden en eenheidsprijzen. </p>' +
    '<p>Voor toegang is een apart abonnement vereist. Neem contact op met Taric Support als u interesse heeft.</p> ' +
    '<p><em>Hieronder vindt u een voorbeeld van de data die beschikbaar is. NB: dit voorbeeld is geen actuele data.</em></p>',
    overview: {
        EndDate: 'Stopt op',
        NoPrices: 'Er konden op dit moment geen referentieprijzen gevonden worden',
        StandardImportValues: 'Forfaitaire invoerwaardes',
        StartDate: 'Start op',
        Title: 'Referentieprijzen EU',
        UnitPrices: 'Eenheidswaardes'
    }
};