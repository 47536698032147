module.exports = [
    '$analytics',
    '$element',
    '$location',
    '$timeout',
    'applicationStateService',
    'searchService',
    'tradeMovementService',
    searchBoxController];

function searchBoxController($analytics, $element, $location, $timeout, state, searchService, tradeMovement) {

    var vm = {
        $postLink: link,
        filter: state.filter,
        search: search,
        searchTerm: '',
        setMovement: setMovement
    };

    function link() {
        $timeout(function(){
            var formEl = $element[0];
            var inputEl = formEl.getElementsByClassName('js-autofocus')[0];
            if (inputEl) {
                inputEl.focus();
            }
        }, 0);
    }

    function search() {
        var query = vm.searchTerm;
        var queryObject = searchService.parseQuery(query);
        queryObject.includeBtis = true;
        queryObject.includeClassificationRegulations = true;
        if (queryObject.movement) {
            setMovement(queryObject.movement);
        }
        if (queryObject.country) {
            state.filter.countryCode = queryObject.country;
        }
        if (queryObject.query) {
            queryObject.orderBy = 'relevance';
            $analytics.eventTrack('search-text', { value: query });
        }
        else {
            $analytics.eventTrack('search-code', { value: query });
        }
        var queryString = searchService.serializeQuery(queryObject);

        vm.searchTerm = '';
        if (queryObject.commodityCode && queryObject.commodityCode.length >= 4) {
            $location.path('search/tree').search(queryString);
        }
        else {
            $location.path('search/heading').search(queryString);
        }
    }

    function setMovement(movementType) {
        tradeMovement.set(movementType);
    }

    return vm;

}