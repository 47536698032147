module.exports = {
    bindings: { 
        placeholder: '<', 
        measureType: '<', 
        possibleMeasureTypes: '< ', 
        selectionChanged: '&' 
    },
    controller: measureTypePickerController,
    templateUrl: 'measure-types/components/measure-type-picker.html'
};

measureTypePickerController.$inject = ['measureTypeSelectorModal'];

function measureTypePickerController(measureTypeSelectorModal) {
    var vm = {
        $onInit: attached,
        clear: clear,
        measureType: null,
        userInput: null,
        possibleMeasureTypes: null,
        itemSelected: itemSelected,
        onBlur: onBlur,
        onEnter: onEnter,
        showModal: showModal
    };

    return vm;

    function showModal() {
        measureTypeSelectorModal
            .show(vm.possibleMeasureTypes, vm.measureType)
            .result
            .then(itemSelected)
            .catch(function (res) {
                if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                    throw res;
                }
            });
    }

    function attached() {
    }

    function clear(ev) {
        vm.userInput = null;
        clearSelection();
    }

    function clearSelection() {
        vm.measureType = null;
        if (vm.selectionChanged)
            vm.selectionChanged({ measureType: null });

    }

    function onBlur() {
    }

    function onEnter() {
    }

    function itemSelected(item, model) {
        vm.measureType = item;

        if (vm.selectionChanged) {
            vm.selectionChanged({ measureType: item });
        }
    }


}

