﻿module.exports = ['$uibModal', loggingOutModal];

function loggingOutModal($uibModal) {
    var modal = {
        dismiss: dismissModal,
        modalRef: null,
        show: showModal
    };

    loggingOutModal.$inject = ['$uibModalInstance'];

    function loggingOutModalController($uibModalInstance) {

        var ctrlLoggingOutModal = {
        };

        function cancel() {
            //            $(".page-dashboard, .taric-page-content").removeClass("noprint");
            $uibModalInstance.dismiss('cancel');
        }

        function confirm() {
            $uibModalInstance.dismiss(true);
        }

        return ctrlLoggingOutModal;
    }

    function dismissModal() {

    }

    function showModal() {

        return $uibModal.open({
            animation: true,
            backdrop: true,
            controller: loggingOutModalController,
            controllerAs: 'ctrlLoggingOutModal',
            keyboard: true,
            modalFade: true,
            templateUrl: 'widgets/modals/logging-out.html'
        });
    }

    return modal;
}
