module.exports = {
    // tabs: {
    //     Measures: 'Measures',
    //     ValidateDeclaration: 'Validate declaration',
    //     CalculateDuty: 'Calculate duties'
    // },
    form: {
        legendValue: 'Valore',
        radioMethodLabel: 'Metodo',	
        radioMethodOptionCalculateValue: 'Calcolare il valore',
        radioMethodOptonEnterValue: 'Inserire il valore'
    },
    financialConditions: {
        E: {
            title: 'La quantità e il prezzo unitario dichiarato, secondo i casi, è uguale od inferiore  alla quantità massima indicata, o presentazione del documento richiesto',
            headers: {
                col1: 'Quantità o prezzo unitario dichiarato',
                col2: 'Importo'
            }
        },
        F: {
            title: 'Prezzo netto franco frontiera, prima dello sdoganamento, deve essere uguale o superiore al prezzo minimo',
            headers: {
                col1: 'Prezzo netto franco frontiera, prima dello sdoganamento',
                col2: 'Importo'
            }
        },
        L: {
            title: 'Il prezzo CIF deve essere superiore al prezzo minimo',
            headers: {
                col1: 'Prezzo CIF',
                col2: 'Importo'
            }
        },
        M: {
            title: 'Il prezzo dichiarato deve essere uguale o superiore al prezzo minimo/ prezzo di riferimento',
            headers: {
                col1: 'Prezzo dichiarato',
                col2: 'Importo'
            }
        },
        V: {
            title: 'Il prezzo all\'importazione deve essere uguale o superiore al prezzo d\'entrata',
            headers: {
                col1: 'Prezzo all\'importazione',
                col2: 'Importo'
            }
        }
    },
    meursing: {
        calculateDescription: '',
        calculateTitle: 'Ricerca elementi agricoli',
        impossibleCombination: 'Combinazione di ingredienti non valida (peso totale superiore al 100%)',
        milkfat: 'Materie grasse provenienti dal latte (% in peso)',
        milkproteins: 'Proteine del latte (% in peso)',
        saccharin: 'Saccarosio/Zucchero invertito/Isoglucosio (% in peso)',
        starchGlucose: 'Amido-Fecola/Glucosio (% in peso)',
    }
};
