﻿module.exports = ['areYouSureModal', 'loggingOutModal', widgetModalsService];

function widgetModalsService(areYouSureModal, loggingOutModal) {

    var service = {
        areYouSure: areYouSureModal,
        loggingOut: loggingOutModal
    };

    return service;

}
