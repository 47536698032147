﻿// See https://app.taricsupport.com/#!/atr/home
// See https://www.tax.service.gov.uk/search-for-advance-tariff-rulings/search?enableTrackingConsent=true
module.exports = {
    AdvanceTariffRulings: 'Advance Tariff Rulings',
    ATRs: 'ATRs',
    AtrReference: 'ATR Reference',
    attachments: 'Attachments',
    commodityDescription: 'Commodity description',
    copyDescription: 'This information is a copy of the information in the UK ATR database.',
    details: 'ATR Details',
    atrLinkText: 'Click here to see the up-to-date version in this database.',
    goodsDescription: 'Goods Description',
    issuedSince: 'Issued Since',
    Keywords: 'Keywords',
    motivation: 'Grounds for classification',
    NOIMAGE: 'NO IMAGE',
    noLongerValid: 'This ATR is no longer valid and can therefore no longer be found in the UK ATR database.',
    notValidOn_referenceDate_: 'not valid on {{ referenceDate | date: \'dd-MM-yy\' }}',
    possiblyInvalid: 'Note: in some cases, ATRs may be revoked before their normal period of validity has expired. Hence, it is possible that this ATR is no longer valid.', 
    SearchAdvanceTariffRulings: 'Search Advance Tariff Rulings',
    _StartDate_until_ExpiryDate_: '{{ StartDate | date: \'dd-MM-yyyy\' }} until {{ ExpiryDate | date: \'dd-MM-yyyy\' }}'
};
