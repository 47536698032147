﻿module.exports = {
    bindings: {
        isLarge: '<',
        item: '<'
    },
    controller: explanatoryNoteButtonController,
    templateUrl: 'texts/components/explanatory-note-button.html'
};

explanatoryNoteButtonController.$inject = ['common', 'explanatoryNoteModal'];

function explanatoryNoteButtonController(common, explanatoryNoteModal) {

    var vm = {
        getCode: getCode,
        showExplanatoryNote: showExplanatoryNote
    };

    function getCode() {
        if (vm.item.type === 'section')
            return 'a' + common.formatting.padLeft(common.formatting.romanToNumber(vm.item.number), 2, '0');
        else if (vm.item.type === 'chapter' && vm.item.code)
            return 'h' + vm.item.code.substring(0, 2);
        else if (vm.item.type === 'chapter' && vm.item.number)
            return 'h' + common.formatting.padLeft(common.formatting.romanToNumber(vm.item.number), 2, '0');
        else if (vm.item.type === 'subchapter')
            return vm.item.code;
        else
            return vm.item.code.substring(0, 4);
    }

    function showExplanatoryNote() {
        explanatoryNoteModal.show(vm.getCode());
    }


    return vm;

}