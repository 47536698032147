module.exports = {
    bindings: {
        chapter: '<',
        numberOfEcicsItems: '<',
        onClickChapter: '&'
    },
    controller: chapterController,
    templateUrl: 'nomenclature/components/nomenclature-chapter.html'
};

chapterController.$inject = ['language'];

function chapterController(language) {
    var vm = {
        showAtrButton: language.get() === 'en'
    };
    return vm;
}