module.exports = ['common', 'dataContext', 'applicationStateService', belastingdienstDataService];

function belastingdienstDataService(common, context, state) {

    var service = {
        getNews: getNews
    };


    function getNews() {
        var url = common.config.apiHosts.generic + '/belastingdienst/news' +
            '?date=' + state.filter.date;

        return context.get(url);
    }

    return service;

}
