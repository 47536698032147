﻿module.exports = {
    buttons: {
        Cancel: 'Annuleren',
        Close: 'Sluiten',
        CollapseAll: 'alles invouwen',
        Delete: 'Verwijderen',
        Download: 'Download',	
        DownloadPDF: 'Download PDF',
        EmptyForm: 'Formulier leegmaken',
        ExpandAll: 'alles uitvouwen',
        No: 'Nee',	
        OK: 'OK',	
        OpenPDF: 'Open PDF',
        Print: 'Afdrukken',	
        Save: 'Opslaan',
        Search: 'Zoeken',
        Yes: 'Ja'
    },
    calendar: {
        Clear: 'Wissen',
        OK: 'OK',
        Today: 'Vandaag'
    },
    placeholders: {
        EnterCommodityCode: 'Voer een goederencode in',
        Search: 'Zoeken...',
        SearchByCountryNameOrCode: 'Zoek op naam of code land',
        SearchInText: 'Zoek in tekst',
    },
    translation: {
        ShowOriginal: 'Toon origineel',
        Translate: 'Vertaal',
    },
    areYouSureModalTitle: 'Weet u het zeker?',
    
    Ascending: 'Oplopend',
    Descending: 'Aflopend',
    
    
    Country: 'Land',
    Date: 'Datum',
    DateFrom: 'Datum van',
    DateTo: 'Datum tot',
    Description: 'Omschrijving',
    favorite: 'favoriet',
    favorites: 'Favorieten',
    _count_found: '{{ count }} gevonden', // NB: count is a number
    Images: 'Afbeeldingen',
    List: 'Lijst',
    MoreNews: 'Meer nieuws',
    News: 'Nieuws',
    No: 'Nee',
    NoResults: 'Geen resultaten',
    OrderBy: 'Sorteren op',
    OrderDirection: 'Sorteervolgorde',
    Query: 'Zoekopdracht',
    Remember: 'Onthouden',
    SearchTerm: 'Zoekterm',
    ShowResultsAs: 'Toon resultaten als', // show as images or list
    Source: 'Bron', // As in 'Source of the information'
    TextSearch: 'Zoeken in tekst',
    Total: 'Totaal',
    ValidityPeriod: 'Periode geldigheid',
    ValidFrom: 'Geldig vanaf',
    ValidTo: 'Geldig tot',
    ValidityStartDate: 'Startdatum geldigheid',
    ValidityEndDate: 'Einddatum geldigheid',
    StartDate: 'Start Date',
    EndDate: 'Einddatum',
    Yes: 'Ja'
};
