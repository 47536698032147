module.exports = {
    bindings: {
        tarifering: '<',
        query: '<'
    },
    controller: tariferingController,
    templateUrl: 'classification-regulations/components/tarifering.html'
};

tariferingController.$inject = [
    '$element', 
    'searchClassificationRegulationsService', 
    'language',
];

function tariferingController(
    $element, 
    searchClassificationRegulationsService, 
    language
) {
    var vm = {
        $onInit: attached,
        language: language.get()
    };

    function attached() {
        setTimeout(function() {
            var tariferingLinks = $element[0].querySelectorAll('a[data-type="tarifering"]');
            _.forEach(tariferingLinks, function(link) {
                link.addEventListener('click', function() {
                    var heading = this.attributes['data-heading'].value;
                    var tariferingNumber = this.attributes['data-tarifering-number'].value;
                    searchClassificationRegulationsService.loadTarifering(heading, tariferingNumber)
                        .then(function (newTarifering) {
                            newTarifering.isReference = true;
                            vm.referencedTarifering = newTarifering;
                        });
                });
            });
        }, 0);

    }
    return vm;
}
