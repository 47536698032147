angular.module('taric.vatRates', [])
    // .config(['$routeProvider', routeConfigurator])
    .component('vatMeasure', require('./components/vat-measure'))
    .component('vatRates', require('./components/vat-rates'))
    .factory('vatRatesDataService', require('./services/vat-rates-data'));

// function routeConfigurator($routeProvider) {
//     $routeProvider
//         .when('/trade-statistics', {
//             templateUrl: 'trade-statistics/controllers/trade-statistics.html'
//         })
//         ;
// }
