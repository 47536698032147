﻿// See https://app.taricsupport.com/#!/compliance-check
module.exports = {
    errors: {
        select_file_to_check: 'Select a file to check',
        specify_goodsnom_column: 'Specify in which column the commodity code is located',
        invalid_goodsnom_column: 'The commodity code column is invalid',
        invalid_country_code_column: 'The country code column is invalid',
        specify_starting_row: 'Specify the first row containing data to be processed',
        invalid_starting_row: 'The starting row is invalid',
        specify_jobtype: 'Specify what type of check should be performed'
    },
    modal: {
        code: 'Code',
        title: 'ABC Errors and Warnings',
        lineNumber: 'Line',
        message: 'Message',
    },
    details: {
        backToOverview: 'Back to Overview',
    },
    newJob: {
        breadcrumb: 'Process a New File',
        cooColumn: 'Country of Origin Column',
        cooColumnShort: 'CoO column',
        countryOfDestinationColumn: 'Country of Destination Column',
        countryOfDestination: 'County of Destination',
        description: '',
        goodscodeColumn: 'Commodity Code Column',
        goodscode: 'Commodity Code',
        jobTypes: {
            description: 'Type of job'
        },
        labelUseTemplateOrCustomCheck: 'Choose one of the preconfigured ABC jobs below or create a custom ABC job',
        optionCustomCheck: 'Create a custom ABC job',
        measureTypes: 'Measure Types',
        outputFormats: {
            csv: 'CSV',
            description: 'Output Format',
            excel: 'Excel',
            excelAnnotated: 'Excel with Color Indicators',
            plainText: 'Plain Text'
        },
        outputType: 'Please specify the type of output you want to receive',
        readFile: 'Please specify in which rows and columns we can find the relevant data',
        selectFile: 'Select an Excel file to process',
        openFile: 'Open File',
        selectedFile: 'Selected File',
        startCheck: 'Start Validating and Enriching File',
        startsOnRow: 'Starts on Row',
        title: 'ABC - Validate and Enrich a New File',
        uploadFile: 'Upload File'
    },
    overview: {
        breadcrumb: 'Taric Support ABC',
        contactUs: '<a href="http://www.taricsupport.com/contact" target="_blank">Contact us</a> for more information about Taric Support ABC and the possibilities for your organization',
        dateAdded: 'Created At',
        dateCompleted: 'Completed At',
        description: 'Validate large amounts of commodity codes quickly by simply uploading an Excel file. Enrich these files with trade tariff data, like descriptions of the nomenclature, additional codes, import duties, VAT rates and certificate codes.',
        documentType: 'Type',
        downloadFile: 'Download File',
        file: 'File',
        noJobs: 'You haven\'t checked any files yet.',
        numberOfErrors: '# Errors',
        numberOfLines: '# Lines',
        numberOfLogs: '# Messages',
        numberOfWarnings: '# Warnings',
        showDetails: 'Show details',
        startFirst: 'Process your first file',
        startNew: 'Process a new file',
        status: 'Status',
        title: 'Taric Support ABC - Automated Batch Control',
    },
    status: {
        cancel_task_areyousure: 'Are you sure you want to cancel this task?'
    }
};
