angular.module('taric.classificationRegulations', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('classificationRegulation', require('./components/classification-regulation'))
    .component('classificationRegulationNew', require('./components/classification-regulation-new'))
    .component('classificationRegulationOld', require('./components/classification-regulation-old'))
    .component('tarifering', require('./components/tarifering'))
    // Pages
    .component('searchClassificationRegulationsPage', require('./components/search-classification-regulations-page'))
    .component('searchEnglishClassificationRegulationsPage', require('./components/search-classification-regulations-english-page'))
    // Modals
    .factory('classificationRegulationsModal', require('./modals/classification-regulations'))
    .factory('englishClassificationRegulationsModal', require('./modals/classification-regulations-en'))
    // Services
    .factory('searchClassificationRegulationsService', require('./services/search-classification-regulations'))
    .factory('searchEnglishClassificationRegulationsService', require('./services/search-classification-regulations-english'))
    ;


function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/classification-regulations/nl/search/:searchquery', {
            template: '<search-classification-regulations-page></search-classification-regulations-page>'
        })
        .when('/classification-regulations/en/search/:searchquery', {
            template: '<search-english-classification-regulations-page></search-english-classification-regulations-page>'
        })
        .when('/classification-regulations/nl/search', {
            template: '<search-classification-regulations-page></search-classification-regulations-page>'
        })
        .when('/classification-regulations/en/search', {
            template: '<search-english-classification-regulations-page></search-english-classification-regulations-page>'
        })
    ;
}
