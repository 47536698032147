module.exports = {
    bindings: {},
    controller: hs2022ForwardCorrelationsController,
    templateUrl: 'correlation-table/components/hs-2022-forward.html'
};

hs2022ForwardCorrelationsController.$inject = ['correlationDataService'];

function hs2022ForwardCorrelationsController(correlationDataService) {
    var vm = {
        $onInit: attached,
        search: search
    };
    return vm;

    function attached() {
        search();
    }

    function search(commodityCode) {
        vm.isLoading = true;
        return correlationDataService
            .getHs2022ForwardCorrelations(commodityCode)
            .then(function (result) {
                vm.isLoading = false;
                vm.result = result;
            })
    }
}