﻿module.exports = [hasAdditionalCodeFilter];

function hasAdditionalCodeFilter() {
    function measureHasAdditionalCode(measure, additionalCode) {
        var addCodeType = additionalCode.substr(0, 1).toUpperCase();
        var addCode = additionalCode.substr(1);

        return measure.additional_code &&
            measure.additional_code.code && (
                measure.additional_code.type !== addCodeType ||
                (measure.additional_code.code === addCode &&
                    measure.additional_code.type === addCodeType)
            );

    }

    return function (measures, additionalCode) {
        if (!measures || !additionalCode || additionalCode.length !== 4)
            return measures;
        return _.filter(measures, function (measure) {
            return measureHasAdditionalCode(measure, additionalCode);
        });
    };
}
