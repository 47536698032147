﻿module.exports = {
    bindings: {
        isLarge: '<',
        item: '<'
    },
    controller: caseLawButtonController,
    templateUrl: 'case-law/components/case-law-button.html'
}

caseLawButtonController.$inject = ['caseLawModal'];

function caseLawButtonController(caseLawModal) {

    var vm = {
        showCaseLaw: showCaseLaw
    };

    function showCaseLaw() {
        var numberPattern = /^\s*(?:\d{2}[\s.]*){1,5}$/;

        if (numberPattern.test(vm.item.searchQuery))
            caseLawModal.show(vm.item.code, '');
        else
            caseLawModal.show(vm.item.code, vm.item.searchQuery);
    }


    return vm;

}