﻿angular.module('taric.exchangeRates', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('exchangeRatesPage', require('./components/exchange-rates-page'))
    .component('currencyPicker', require('./components/currency-picker'))
    .component('monetaryValueInput', require('./components/monetary-value-input'))
    // // Filters
    .filter('currencyTypeahead', require('./filters/currency-typeahead-filter'))
    // Modals
    .factory('currencySelectorModal', require('./modals/currency-selector'))
    // Services
    .factory('exchangeRateDataService', require('./services/exchange-rate-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/exchange-rates', {
            template: '<exchange-rates-page></exchange-rates-page>'
        })
        ;
}
