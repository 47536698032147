﻿module.exports = {
    changePassword: {
        changePassword: 'Wachtwoord veranderen',
        currentPassword: 'Huidig wachtwoord',
        errorCurrentPassword: 'Het huidige wachtwoord is niet ingevuld',
        errorNewPasswordEmpty: 'Het nieuwe wachtwoord is niet ingevuld',
        errorNewPasswordCompare: 'De nieuwe wachtwoorden komen niet overeen',
        newPassword1: 'Nieuw wachtwoord',
        newPassword2: 'Nieuw wachtwoord bevestigen',
        passwordChangedSuccessfully: 'Wachtwoord veranderd.',
        errorChangingPassword: 'Fout bij het veranderen van het wachtwoord.'
    },
    forgotPassword: {
        backToLogin: 'Terug naar de loginpagina',
        doneMessage: 'We hebben een e-mail verstuurd met instructies verstuurd naar het adres dat verbonden is aan uw account. De mail zou binnen een aantal minuten ontvangen moeten zijn.',
        explanation: 'Vul alstublieft uw gebruikersnaam in en wij sturen u een e-mail met de instructies om een nieuw wachtwoord aan te maken voor uw account',
        forgotPassword: 'Wachtwoord vergeten?',
        resetPassword: 'Wachtwoord resetten'
    },
    login: {
        error: 'Gebruikersnaam of wachtwoord onjuist',
        logIn: 'Inloggen',
        password: 'Wachtwoord',
        rememberMe: 'Houdt mij ingelogd',
        username: 'Gebruikersnaam'
    },
    logout: {
        signedOut: 'U bent uitgelogd',
        signingOut: 'U wordt uitgelogd...',
        title: 'Uitloggen'
    },
    resetPassword: {
        backToLogin: 'Terug naar de inlogpagina',
        doneMessage: 'U hebt een nieuw wachtwoord aangemaakt. U kunt dit wachtwoord nu gebruiken om in te loggen op uw Taric Support account.',
        errors: {
            dynamic: {
                'PASSWORD_INVALID_TOKEN': 'Het verzoek om een nieuw wachtwoord aan te maken is verlopen',
                'PASSWORD_ISCURRENT': 'Het wachtwoord dat u heeft ingevoerd bij \'Huidig wachtwoord\' is niet uw huidige wachtwoord',
                'PASSWORD_MINIMUM_LENGTH': 'Het wachtwoord moet minimaal 8 karakters lang zijn',
                'PASSWORD_NO_LOWERCASE': 'Het wachtwoord bevat geen kleine letters',
                'PASSWORD_NO_NONALPHA': 'Het wachtwoord bevat geen non-alfanumerieke karakters',
                'PASSWORD_NO_NUMBERS': 'Het wachtwoord bevat geen nummers',
                'PASSWORD_NO_UPPERCASE': 'Het wachtwoord heeft geen hoofdletters',
                'PASSWORD_EMPTY': 'Vul alstublieft een wachtwoord in',
                'PASSWORDS_NOTEQUAL': 'De wachtwoorden die u heeft ingevuld komen niet overeen'
            }
        },
        explanation: 'U staat op het punt om een nieuw wachtwoord aan te maken voor uw account. Uw nieuwe wachtwoord moet minimaal 8 karakters lang zijn en moet een combinatie van hoofd- en kleine letters, nummers en non-alfanumerieke karakters bevatten',
        invalidToken: 'Uw link om een nieuw wachtwoord aan te maken is verlopen. Genereer een nieuwe link door het \'wachtwoord vergeten\' formulier opnieuw in te vullen',
        password1: 'Nieuw wachtwoord',
        password2: 'Nogmaals het nieuwe wachtwoord',
        resetPassword: 'Maak een nieuw wachtwoord',
        strength: {
            'characters': '8 karakters',
            'lowercase': 'Minimaal een kleine letter',
            'nonalpha': 'Minimaal een non-alfanumeriek karakter',
            'number': 'Minimaal een getal',
            'oneOfFour': 'Voldoe aan 3 van de volgende 4 voorwaarden:',
            'uppercase': 'Minimaal een hoofdletter',
        }
    }
};