// For the original source, see the following web pages:
// See https://ec.europa.eu/taxation_customs/dds2/ecics/chemicalsubstance_consultation.jsp
// See https://ec.europa.eu/taxation_customs/dds2/ecics/chemicalsubstance_details.jsp?Lang=it&Cus=0010001-6
// In Taric Support, see https://app.taricsupport.com/#!/ecics/home/
module.exports = {
    CAS_RN: 'CAS RN', // do not translate
    ChemicalsSearch: 'Chemicals Search',
    Characteristic: 'Characteristic',
    Characteristics: 'Characteristics',
    Comment: 'Comment',
    CUS: 'CUS',  // do not translate
    EC_Number: 'EC Number',
    Ecics: 'ECICS',  // do not translate
    EcicsDetails: 'ECICS Details',
    EcicsLinkText: 'Click here to open this item in the ECICS database.',
    InChI: 'InChI',  // do not translate
    InChIKey: 'InChI Key',
    LanguageCode: 'Language Code',
    Level: 'Level',
    Name: 'Name',
    Names: 'Names',
    Nomenclature: 'Nomenclature',
    Order: 'Order', // as in the ordering of a list
    SearchEcicsDatabase: 'Search ECICS Database',
    UN_Number: 'UN Number',
};