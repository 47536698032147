﻿module.exports = {
    buttons: {
        Cancel: 'Cancella',
        Close: 'Chiudi',
        CollapseAll: 'Riduci tutti',
        Delete: 'Elimina',
        Download: 'Scarica',
        DownloadPDF: 'Scarica PDF',
        EmptyForm: 'Svuota modulo',
        ExpandAll: 'Espandi tutti',
        No: 'No',
        OK: 'OK',
        OpenPDF: 'Apri PDF',
        Print: 'Stampa',	
        Save: 'Salva',
        Search: 'Cerca',
        Yes: 'Sì',
    },
    calendar: {
        Clear: 'Svuota',
        OK: 'OK',
        Today: 'Oggi'
    },
    placeholders: {
        EnterCommodityCode: 'Inserisci il codice nomenclatura',
        Search: 'Cerca',
        SearchByCountryNameOrCode: 'Cerca per nome o codice del paese',
        SearchInText: 'Cerca nel testo',
    },
    translation: {
        ShowOriginal: 'Mostra originale',
        Translate: 'Traduci',
    },

    Ascending: 'Crescente', // as in direction of sort order
    Descending: 'Descrescente', // as in direction of sort order
    
    Country: 'Paese',
    Date: 'Data',
    DateFrom: 'Data da',
    DateTo: 'Data a',
    Description: 'Descrizione',
    
    favorite: 'Preferito',
    favorites: 'Preferiti',
    
    _count_found: '{{ count }} risultati trovati', // NB: count is a number
    Loading: 'Caricando',
    MoreNews: 'Altre notizie',
    News: 'Notizie',
    NoResults: 'Nessun risultato',
    
    No: 'No', // as in 'false'
    Yes: 'Sì', // as in 'true'

    Query: 'Query',
    Remember: 'Ricorda', // as in 'remember my choice'
    TextSearch: 'Ricerca testo',
    SearchTerm: 'Ricerca',
    
    OrderBy: 'Ordina per',
    OrderDirection: 'Direzione',
    ShowResultsAs: 'Mostra come', // show as images or list
    List: 'Lista', // as in 'show as a list' (as opposed to 'show as images')
    Images: 'Immagini', // as in 'pictures, show as images'
    Source: 'Fonte', // As in 'Source of the information'
    Total: 'Totale', // as in 'total amount of the calculation'
    ValidityPeriod: 'Periodo di validità',
    ValidFrom: 'Valido da',
    ValidTo: 'Valido a',
    ValidityStartDate: 'Data inizio validità',
    ValidityEndDate: 'Data fine validità',
    StartDate: 'Data di inizio',
    EndDate: 'Data di fine',

};
