module.exports = {
    bindings: {
        active: '<',
        ecicsCount: '<',
        section: '<',
        onClickChapter: '&',
        onClickSection: '&'
    },
    controller: sectionController,
    templateUrl: 'nomenclature/components/nomenclature-section.html',
};

function sectionController() {
    var vm = {
        clickChapter: clickChapter,
        clickSection: clickSection
    };
    return vm;

    function clickSection() {
        vm.onClickSection({ section: vm.section });
    }

    function clickChapter(chapter) {
        vm.onClickChapter({ chapter: chapter });
    }
}