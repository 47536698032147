﻿module.exports = ['$locale', 'notificationsService', '$translate', 'tmhDynamicLocale', languageService];

function languageService($locale, notifications, $translate, dynamicLocale) {
    var service = {
        $locale: $locale,
        $translate: $translate,
        currentLanguage: null,
        get: getLanguage,
        set: setLanguage,
        setSupportedLanguages: setSupportedLanguages,
        supportedLanguages: [
            "cs",
            "da",
            "de",
            "et",
            "en",
            "es",
            "fr",
            "hr",
            "it",
            "lv",
            "lt",
            "hu",
            "nl",
            "pl",
            "pt",
            "ro",
            "sk",
            "sl",
            "fi",
            "sv",
            "el",
            "bg"
          ],
        supportedLanguagesWithDescription: [
            {
              "Id": "cs",
              "Description": "Ceština"
            },
            {
              "Id": "da",
              "Description": "Dansk"
            },
            {
              "Id": "de",
              "Description": "Deutsch"
            },
            {
              "Id": "et",
              "Description": "Eesti keel"
            },
            {
              "Id": "en",
              "Description": "English"
            },
            {
              "Id": "es",
              "Description": "Español"
            },
            {
              "Id": "fr",
              "Description": "Français"
            },
            {
              "Id": "hr",
              "Description": "Hrvatski"
            },
            {
              "Id": "it",
              "Description": "Italiano"
            },
            {
              "Id": "lv",
              "Description": "Latviešu valoda"
            },
            {
              "Id": "lt",
              "Description": "Lietuviu kalba"
            },
            {
              "Id": "hu",
              "Description": "Magyar"
            },
            {
              "Id": "nl",
              "Description": "Nederlands"
            },
            {
              "Id": "pl",
              "Description": "Polski"
            },
            {
              "Id": "pt",
              "Description": "Português"
            },
            {
              "Id": "ro",
              "Description": "Româna"
            },
            {
              "Id": "sk",
              "Description": "Slovenčina"
            },
            {
              "Id": "sl",
              "Description": "Slovenščina"
            },
            {
              "Id": "fi",
              "Description": "Suomi"
            },
            {
              "Id": "sv",
              "Description": "Svenska"
            },
            {
              "Id": "el",
              "Description": "ελληνικά"
            },
            {
              "Id": "bg",
              "Description": "български"
            }
          ],
    };

    function setSupportedLanguages(languagesWithDescription) {
        service.supportedLanguagesWithDescription = _.map(languagesWithDescription, function(lang) {
            lang.Id = lang.Id.toLowerCase();
            return lang;
        });
        service.supportedLanguages = _.map(service.supportedLanguagesWithDescription, function(lang) {
            return lang.Id;
        });
    }

    function getLanguage() {
        return $translate.use();
    }

    function setLanguage(language) {
        language = language.toLowerCase();
        if (!language)
            return;

        if (service.supportedLanguages.indexOf(language) > -1) {
            if(language === 'en') {
              dynamicLocale.set('en-nl');
            }
            else {
              dynamicLocale.set(language);
            }
            service.currentLanguage = language;
            $translate.use(language);

            notifications.publish('language_changed', language);
        }
    }

    return service;
}