﻿module.exports = [
    '$analytics', 
    '$uibModal', 
    '$window',
    'language',
    'preferenceService',
    firstLoginModal
];

function firstLoginModal(
    $analytics, 
    $uibModal,
    $window,
    languageService,
    preferences
) {
    var modal = {
        show: showModal
    };
    
    firstLoginModalController.$inject = ['$uibModalInstance'];
    
    function firstLoginModalController($uibModalInstance) {
        
        var vm = {
            cancel: cancel,
            languages: languageService.supportedLanguagesWithDescription,
            setLanguageAndClose: setLanguageAndClose
        };

        return vm;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function setLanguageAndClose(languageId) {
            preferences.setLanguage(languageId).then(function(){
                setTimeout(function() {
                    // We set a timeout in order to give listeners
                    // to the language changed event a chance to react
                    // before reloading. For example, the query string
                    // in the search function is cleared.
                    $window.location.reload();
                }, 1);
            });
        }
    }


    function showModal() {
        $analytics.eventTrack('open-modal-first-login', { category: 'first-login' });
        return $uibModal.open({
            animation: true,
            backdrop: 'static',
            controller: firstLoginModalController,
            controllerAs: 'ctrl',
            keyboard: false,
            modalFade: true,
            resolve: {},
            templateUrl: 'dashboard/modals/first-login.html',
            windowClass: 'modal-light'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
