module.exports = {
    bindings: { 
        activeAtr: '<' 
    },
    controller: atrDetailsController,
    templateUrl: 'atr/components/atr-details.html'
}

atrDetailsController.$inject = [
    '$filter', 
    'atrDataService', 
    'notificationsService'
];

function atrDetailsController(
    $filter, 
    atrDataService, 
    notifications
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        closeImages: closeImages,
        selectImage: selectImage,
        selectedImage: null,
        translateClassificationJustification: true,
        translateGoodsDescription: true
    };
    
    return vm;

    function attached() {
        notifications.subscribe('change_active_atr', onChangeActiveatr);
    }

    function detached() {
        notifications.unsubscribe('change_active_atr', onChangeActiveatr);
    }

    function onChangeActiveatr() {
        var activeAtr = atrDataService.data.activeAtr;
        closeImages();
        // vm.translateClassificationJustification = true;
        // vm.translateGoodsDescription = true;
        // if (activeAtr && activeAtr.IsTranslated) {
            
        //     if (activeAtr.TranslatedClassificationJustification) {
        //         vm.ClassificationJustificationTranslation = {
        //             originalText: $filter('addHyperlinks')(activeAtr.ClassificationJustification),
        //             translatedText: $filter('addHyperlinks')(activeAtr.TranslatedClassificationJustification.Result),
        //             showTranslation: true
        //         }
        //     }
        //     if (activeAtr.TranslatedGoodsDescription) {
        //         vm.GoodsDescriptionTranslation = {
        //             originalText: $filter('addHyperlinks')(activeAtr.GoodsDescription),
        //             translatedText: $filter('addHyperlinks')(activeAtr.TranslatedGoodsDescription.Result),
        //             showTranslation: true
        //         }
        //     }
        // }

        // { originalText: activeAtr.GoodsDescription, translatedText: activeAtr.TranslatedGoodsDescription.Result}
    }

    function closeImages() {
        vm.selectedImage = null;
    }

    function selectImage(image) {
        vm.selectedImage = image;
    }
}