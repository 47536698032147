﻿angular.module('taric.core', [])
    // controllers
    .controller('preloaderController', require('./controllers/preloader'))
    // directives
    .directive('convertToNumber', require('./directives/convert-to-number'))
    .directive('hasPermission', require('./directives/has-permission'))
    .directive('ngEnter', require('./directives/ng-enter'))
    .directive('repeatDelimiter', require('./directives/repeat-delimiter'))
    .directive('scrollToTopWhen', require('./directives/scroll-to-top-when'))
    // filters
    // services.common
    .factory('common', require('./services.common/common'))
    .factory('authCheckService', require('./services.common/auth-check'))
    .factory('formatting', require('./services.common/formatting'))
    .factory('identity', require('./services.common/identity'))
    .factory('interactionService', require('./services.common/interaction'))
    .factory('language', require('./services.common/language'))
    .factory('notificationsService', require('./services.common/notifications'))
    .factory('preloaderService', require('./services.common/preloader'))
    .factory('versionHistoryService', require('./services.common/version-history'))
    // services.data
    .factory('dataContext', require('./services.data/data-context'))
    .factory('preferenceService', require('./services.data/preferences'))
    .factory('serializationService', require('./services.data/serialization'))
    .factory('applicationStateService', require('./services.state/application-state'))
    
    // error handling
    .factory('errorHandlingService', require('./services.common/error-handling'))
    .config(['$httpProvider', function($httpProvider) {
        $httpProvider.interceptors.push('errorHandlingService');
    }])
;
