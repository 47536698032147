// Choose German trade tariff (EZT - Elektronischer Zolltarif).
// See https://app.taricsupport.com/#!/good/2203000100
// See https://app.taricsupport.com/#!/texts-german-customs
// See https://auskunft.ezt-online.de/ezto/Welcome.do
module.exports = {
    ChooseNationalCommodityCodeToFindVatRate: 'Choose a national commodity code above to find the correct VAT rate.',
    ChooseExciseCodeToFindExciseRates: 'Choose an excise code above to find the correct excise rate.',
    ExciseCode: 'Excise code',
    ExplanatoryNotesProvidedByGermanCustoms: 'Explanatory Notes provided by German Customs',
    ExciseDutyRate: 'Excise duty rate',
    EztExciseCodeFieldPlaceHolder: 'Enter or search for excise code',
    EztExciseCodeSelectorModalTitle: 'Excise Nomenclature',
    GermanExplanatoryNotes: 'Explanatory Notes Germany',
    Hinweise: 'Hinweise',
    NationalCommodityCode: 'National Commodity Code',
    NationalNomenclature: 'National Nomenclature',
    TextsPublishedByGermanCustoms: 'Texts published by German Customs',
    TextsGermanCustoms: 'Texts German Customs',

};