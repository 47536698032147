﻿module.exports = ['common', 'dataContext', 'applicationStateService', certificateCodesDataService];

function certificateCodesDataService(common, context, state) {

    var service = {
        getByType: getByType,
        getTypes: getTypes
    };

    function getByType(certificateType) {

        var url = common.config.apiHosts[common.config.currentApi] + '/certificates' +
            '?type=' + certificateType +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    function getTypes() {

        var url = common.config.apiHosts[common.config.currentApi] + '/certificates/types' +
            '?origin=EU,' + state.filter.referenceCountry +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    return service;

}