module.exports = {
    controller: validateBusinessInfoRexFormController,
    templateUrl: 'validate-business-info/components/validate-business-info-rex-form.html'
}

validateBusinessInfoRexFormController.$inject = [
    '$timeout',
    '$sce',
    'validateBusinessInfoDataService',
    'validateBusinessInfoRexModal',
];

function validateBusinessInfoRexFormController(
    $timeout,
    $sce,
    validateBusinessInfoDataService,
    validateBusinessInfoRexModal
) {
    var vm = {
        isLoading: false,
        showError: false,
        showWarning: false,
        errorMessage: null,
        init: true,
        service : validateBusinessInfoDataService,
        rexCheck: validateBusinessInfoDataService.rexCheck,
        checkRexNumber: checkRexNumber,
        clearRexNumber: clearRexNumber,
        clearEoriNumber: clearEoriNumber,
        clearTinNumber: clearTinNumber,
        showModalGoodsActivities: showModalGoodsActivities,
        showIcon: showIcon,
    };

    function showIcon(field) {
        if(field) {
            str = "<i class='fa fa-check-square' style='color: green;'></i>";
        } else {
            str =  "<i class='fa fa-window-close' style='color:red;'></i>";
        }
        return $sce.trustAsHtml(str);
    } 

    function showModalGoodsActivities(data) {
        validateBusinessInfoRexModal.show(data);
    }

    function clearRexNumber() {
        validateBusinessInfoDataService.clearRexNumber();

    }

    function clearEoriNumber() {
        validateBusinessInfoDataService.clearRexEoriNumber();
    }

    function clearTinNumber() {
        validateBusinessInfoDataService.clearRexTinNumber();
    }

    function checkRexNumber() {
        if(!validateBusinessInfoDataService.rexCheck.eoriNumber
            && !validateBusinessInfoDataService.rexCheck.rexNumber
            && !validateBusinessInfoDataService.rexCheck.tinNumber) {
            vm.showWarning = true;
            return $timeout(function() { 
                vm.showWarning = false;
            }, 5000);
        }

        // if(!validateBusinessInfoDataService.rexCheck.rexNumber) {
        //     vm.showWarning = true;
        //     return $timeout(function() { 
        //         vm.showWarning = false;
        //         vm.errorMessage = null;
        //     }, 5000);
        // }

        var rexRegex = /[A-Za-z][A-Za-z]REX?[A-Z0-9]{1,50}$/;
        if(validateBusinessInfoDataService.rexCheck.rexNumber && !rexRegex.test(validateBusinessInfoDataService.rexCheck.rexNumber)) {
            vm.showWarning = true;
            return $timeout(function() { 
                vm.showWarning = false;
                vm.errorMessage = null;
            }, 5000);
        }
        vm.isLoading = true;
        vm.noResult = false;
        vm.init = false;
        return validateBusinessInfoDataService.checkRexNumber()
        .then(function () {
            vm.isLoading = false;
            if(!validateBusinessInfoDataService.rexCheck.result) {
                vm.noResult = true;
                return $timeout(function() { 
                        vm.noResult = false;
                    }, 8000);
            }
            if(validateBusinessInfoDataService.rexCheck.messages.length > 0) {
                vm.showError = true;
                return $timeout(function() { 
                        vm.showError = false;
                    }, 5000);
            }
        }).catch(function(error) {
            vm.isLoading = false;
            if(validateBusinessInfoDataService.rexCheck.messages.length > 0) {
                vm.showError = true;
                return $timeout(function() { 
                        vm.showError = false;
                    }, 5000);
            }
        });
    }

    return vm;
}