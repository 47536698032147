module.exports = [measureFiltersDirective];

function measureFiltersDirective() {
    var directive = {
        restrict: 'E',
        templateUrl: 'good/directives/form/measure-filters-export-small.html'
    };

    return directive;
}
