module.exports = [
    '$analytics', 
    '$uibModal', 
    atrModal
];

function atrModal(
    $analytics, 
    $uibModal
) {

    var modal = {
        goodsCode: null,
        searchQuery: null,
        show: showModal
    };

    atrModalController.$inject = [
        '$uibModalInstance', 
        'atrDataService', 
        'goodsCode', 
        'searchQuery',
    ];

    function atrModalController(
        $uibModalInstance, 
        atrDataService, 
        goodsCode, 
        searchQuery
    ) {

        var vm = {
            $onInit: attached,
            $onDestroy: detached,
            cancel: cancel,
            changeActiveAtr: changeActiveAtr,
            service: atrDataService,
        };

        var refresh;
        var opacity = 1;

        return vm;

        function attached() {
            // The following is a hack, introduced in October 2020. The reason for it is that
            // Chrome would not render the atr images properly but a redraw would fix it.
            refresh = setInterval(function() {
                opacity = (opacity === 1 ? 0.99 : 1);
                document.body.style.opacity = opacity;
            }, 40);
            init();
        }

        function detached() {
            document.body.style.opacity = 1;
            clearInterval(refresh);
        }



        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function changeActiveAtr(atrReference) {
            atrDataService.changeActiveAtr(atrReference);
        }

        function init() {
            atrDataService.clearActiveAtr();
            atrDataService.resetFilter();
            atrDataService.filter.goodsCode = goodsCode;
            atrDataService.filter.searchQuery = searchQuery;
            atrDataService.loadNewAtrs();
        }


    }

    function showModal(goodsCode, searchQuery) {
        $analytics.eventTrack('open-modal-atr', { category: 'atr' });
        if (goodsCode)
            modal.goodsCode = goodsCode;
        if (searchQuery)
            modal.searchQuery = searchQuery;

        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: atrModalController,
            controllerAs: 'ctrlAtrModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                goodsCode: function () {
                    return modal.goodsCode;
                },
                searchQuery: function () {
                    return modal.searchQuery;
                }
            },
            size: 'lg',
            templateUrl: 'atr/modals/atr.html',
            windowClass: 'modal-bti'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}