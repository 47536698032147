module.exports = {
    controller: btiSearchController,
    templateUrl: 'bti/components/bti-search-page.html'
};

btiSearchController.$inject = [
    '$analytics', 
    '$location', 
    '$route', 
    'btiDataService', 
    'searchService',
];

function btiSearchController(
    $analytics, 
    $location, 
    $route, 
    btiDataService, 
    searchService
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        showSearchSubNav: $route.current.$$route.showSearchSubNav,
        service: btiDataService,
        isLoading: false,
        loadNew: loadNew
    };

    var refresh;
    var opacity = 1;

    return vm;

    function attached() {
        $analytics.pageTrack('/bti-search');
        // The following is a hack, introduced in October 2020. The reason for it is that
        // Chrome would not render the BTI images properly but a redraw would fix it.
        refresh = setInterval(function() {
            opacity = (opacity === 1 ? 0.99 : 1);
            document.querySelector('.bti-image-search-results').style.opacity = opacity;
            document.querySelector('.bti-detail').style.opacity = opacity;
        }, 40);
        init();
    }

    function detached() {
        document.querySelector('.bti-image-search-results').style.opacity = 1;
        document.querySelector('.bti-detail').style.opacity = 1;
        clearInterval(refresh);
    }

    function loadNew() {
        vm.isLoading = true;
        btiDataService.loadNewBtis()
            .then(function () {
                vm.isLoading = false;
            });
    }

    function init() {
        btiDataService.resetFilter();
        var q = searchService.parseLocationSearchObject($location.search());
        btiDataService.filter.goodsCode = q.commodityCode;
        btiDataService.filter.searchQuery = q.query;
        loadNew();
    }

}