module.exports = {
    bindings: {
        active: '@'
    },
    controller: validateBusinessInfoSubnavController,
    templateUrl: 'validate-business-info/components/validate-business-info-subnav.html'
};

validateBusinessInfoSubnavController.$inject = ['applicationStateService'];

function validateBusinessInfoSubnavController(state) {
    var vm = {
        referenceCountry: state.filter.referenceCountry
    };
    return vm;
}