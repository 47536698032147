﻿module.exports = ['complianceInfoModal', complianceModalsService];

function complianceModalsService(complianceInfoModal) {

    var service = {
        complianceInfo: complianceInfoModal
    };

    return service;

}
