angular.module('taric.belastingdienst', [])
    .config(['$routeProvider', routeConfigurator])
    .component('belastingdienstPage', require('./components/belastingdienst-page'))
    .factory('belastingdienstDataService', require('./services/belastingdienst-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        // .when('/nieuws-douane-voor-bedrijven', {
        //     templateUrl: 'belastingdienst/components/belastingdienst-page.html'
        // })
        ;
}
