﻿module.exports = [countryTypeaheadFilter];

// Takes care of ordering the results of the country typeahed. The logic is as follows:
// First, show the matches on the country code (first letter or both), ordered by code
// Next, show the matches on the beginning of the country name, ordered by name
// Finally, show the matches anywhere else in the name, ordered by name
// NB: this needs to be used in combination with a regular filter, since it does not take
// care of removing results which don't match the query at all
function countryTypeaheadFilter() {


    return function (countries, query) {
        if (query === null || query === undefined)
            return countries;

        var countryCodeMatchPartition = _.partition(countries, function(country) {
            return country.code && country.code.toLowerCase().indexOf(query.toLowerCase()) === 0;
        });
        var countryCodeMatches = _.sortBy(countryCodeMatchPartition[0], 'code');
        var otherMatches = _.sortBy(countryCodeMatchPartition[1], 'name');

        var nameMatchPartition = _.partition(otherMatches, function(country) {
            return country.name && country.name.toLowerCase().indexOf(query.toLowerCase()) === 0;
        });

        var result = _.concat(countryCodeMatches, nameMatchPartition[0], nameMatchPartition[1]);
        return result;
    }
}
