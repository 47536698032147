module.exports = {
    bindings: {
        query: '<',
        headingResult: '<'
    },
    controller: headingSearchResultsController,
    templateUrl: 'search/components/heading-search-results.html'
};

headingSearchResultsController.$inject = [
    '$location', 
    'btiModal', 
    'caseLawModal', 
    'classificationRegulationsModal',
    'formatting',  
    'searchService', 
];

function headingSearchResultsController(
    $location, 
    btiModal, 
    caseLawModal, 
    classificationRegulationsModal, 
    formatting, 
    searchService
) {
    var vm = {
        searchCode: searchCode,
        showBti: showBti,
        showCaseLaw: showCaseLaw,
        showClassificationRegulations: showClassificationRegulations,
    };

    return vm;

    function searchCode(code) {
        var q = searchService.createQueryFromLastQuery(vm.query);
        q.commodityCode = formatting.removeTrailingZeros(code);
        var queryString = searchService.serializeQuery(q);
        $location.path('/search/tree').search(queryString);
    }

    function showBti(code, $event) {
        btiModal.show(code, vm.query.QueryString);

        $event.preventDefault();
        $event.stopPropagation();
    }

    function showClassificationRegulations(code, $event) {
        classificationRegulationsModal.show(code, vm.query.QueryString);

        $event.preventDefault();
        $event.stopPropagation();
    }

    function showCaseLaw(code, $event) {
        caseLawModal.show(code, vm.query.QueryString);

        $event.preventDefault();
        $event.stopPropagation();
    }
}