angular.module('taric.tares', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('taresMeasuresPage', require('./components/tares-measures-page'))
    // Services
    .factory('taresStatisticalKeysDataService', require('./services/tares-statistical-keys-data'))
    .factory('taresTariffDataService', require('./services/tares-tariff-data'))
    ;


function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/tares-measures/:commodityCode', {
            template: '<tares-measures-page></tares-measures-page>'
        })
    ;
}
