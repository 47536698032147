module.exports = [
    '$analytics', 
    '$uibModal', 
    validateBusinessInfoRexModal
];

function validateBusinessInfoRexModal(
    $analytics, 
    $uibModal
) {
    var modal = {
        tableData: null,
        show: showModal
    };

    validateBusinessInfoRexModalController.$inject = [
        '$uibModalInstance', 
        'tableData',
    ];

    function validateBusinessInfoRexModalController(
        $uibModalInstance, 
        tableData
    ) {

        var vm = {
            $onInit: attached,
            $onDestroy: detached,
            cancel: cancel,
            tableData: tableData
        };

        var refresh;
        var opacity = 1;

        return vm;

        function attached() {
        }

        function detached() {
            document.body.style.opacity = 1;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

    }


    function showModal(tableData) {
        $analytics.eventTrack('open-modal-rex', { category: 'rex' });
        if (tableData)
            modal.tableData = tableData;
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: validateBusinessInfoRexModalController,
            controllerAs: 'ctrleuRexModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                tableData: function () {
                    return modal.tableData;
                }
            },
            templateUrl: 'validate-business-info/modals/validate-business-info-rex-modal.html',
            windowClass: 'modal-rex'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });

    }

    return modal;

}