module.exports = [measureTypeTypeaheadFilter];

// Takes care of ordering the results of the order number typeahead.
// It returns the matched order numbers, sorted
function measureTypeTypeaheadFilter() {


    return function (measureTypes, query) {
        if (query === null || query === undefined)
            return measureTypes;

        var measureTypeMatchPartition = _.partition(measureTypes, function(measureType) {
            return measureType.description.toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) === 0;
        });
        var startMatches = _.sortBy(measureTypeMatchPartition[0], function(measureType) {
            return measureType;
        });

        var otherMatchesPartition = _.partition(measureTypeMatchPartition[1], function(measureType) {
            return measureType.description.toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) > 0;
        });

        var otherMatches = _.sortBy(otherMatchesPartition[0], function(measureType) {
            return measureType;
        });


        var result = _.concat(startMatches, otherMatches);


        return result;
    }
}
