﻿module.exports = ['common', 'preferenceService', favoritesService];

function favoritesService(common, preferences) {

    var service = {
        favorites: [],
        add: add,
        isFavorite: isFavorite,
        init: load,
        load: load,
        remove: remove
    };

    function add(goodsCode, movement, countryCode, applicationSet) {
        var dfr = common.$q.defer();

        preferences.add({
            name: goodsCode + '_' + movement,
            category: 'favorite_codes',
            value: 'goodsnom=' + goodsCode + '&source=' + applicationSet + '&type=' + movement + '&country=' + (countryCode ? countryCode : null),
            valueType: 'stringValue'
        }).then(function (result) {
            load();
            dfr.resolve(result);
        });

        return dfr.promise;
    }

    function isFavorite(goodsCode, movement) {
        var preference = preferences.get(goodsCode + '_' + movement, 'favorite_codes');
        return preference !== null;
    }

    function load() {
        var deferred = common.$q.defer();
        preferences.getCategory('favorite_codes')
            .then(function (codes) {
                for (var i = 0; i < codes.length; i++) {
                    var goodscode = codes[i].name;
                    var movement = '';
                    if (goodscode.indexOf('_') > -1) {
                        var parts = goodscode.split('_');
                        goodscode = parts[0];
                        movement = parts[1];

                    }
                    codes[i].goodscode = goodscode;
                    codes[i].movement = movement;
                }
                service.favorites = _.sortBy(codes, 'name');
                deferred.resolve(service.favorites);
            });
        return deferred.promise;
    }

    function remove(goodsCode, movement) {
        var preference = preferences.get(goodsCode + '_' + movement, 'favorite_codes');

        // for old favorites which were saved without movement
        if (preference === null) {
            preference = preferences.get(goodsCode, 'favorite_codes');
        }
        preferences.remove(preference);
        load();
    }

    return service;
}
