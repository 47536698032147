module.exports = ['$filter', 'common', 'dataContext', 'serializationService', regulationsDataService];

function regulationsDataService($filter, common, dataContext, serializationService) {
    var service = {
        checkCustomsValue: checkCustomsValue,
        getAvailableYears: getAvailableYears,
        getTradeStatistics: getTradeStatistics,
        getEuAverageTradeStatistics: getEuAverageTradeStatistics
    };

    return service;

    function getAvailableYears() {
        var url = common.config.apiHosts.generic +
            '/trade-statistics/available-years';

        return dataContext.get(url);
    }

    function getEuAverageTradeStatistics(tradeStatisticsQuery) {
        var url = common.config.apiHosts.generic +
            '/trade-statistics/eu-average' +
            '?lang=' + common.language.get();
        if (tradeStatisticsQuery.partnerCountry) {
            url += '&country=' + tradeStatisticsQuery.partnerCountry;
        }
        if (tradeStatisticsQuery.commodityCode) {
            url += '&goodscode=' + tradeStatisticsQuery.commodityCode;
        }
        if (tradeStatisticsQuery.year) {
            url += '&year=' + tradeStatisticsQuery.year;
        }

        return dataContext.get(url);
    }

    function getTradeStatistics(tradeStatisticsQuery) {
        var url = common.config.apiHosts.generic +
            '/trade-statistics' +
            '?lang=' + common.language.get();
        if (tradeStatisticsQuery.reportingCountry) {
            url += '&referencecountry=' + tradeStatisticsQuery.reportingCountry;
        }
        if (tradeStatisticsQuery.partnerCountry) {
            url += '&country=' + tradeStatisticsQuery.partnerCountry;
        }
        if (tradeStatisticsQuery.commodityCode) {
            url += '&goodscode=' + tradeStatisticsQuery.commodityCode;
        }
        if (tradeStatisticsQuery.year) {
            url += '&year=' + tradeStatisticsQuery.year;
        }

        return dataContext.get(url);
    }

    function checkCustomsValue(checkCustomsValueQuery) {
        var url = common.config.apiHosts.generic +
            '/trade-statistics/customs-value-check' +
            '?lang=' + common.language.get() +
            '&referencecountry=' + checkCustomsValueQuery.reportingCountry +
            '&country=' + checkCustomsValueQuery.partnerCountry +
            '&goodscode=' + checkCustomsValueQuery.commodityCode +
            '&customsvalue=' + serializationService.serializeCustomsValue(checkCustomsValueQuery.customsValue) +
            '&quantities=' + serializationService.serializeQuantities(checkCustomsValueQuery.quantities) +
            '&date=' + checkCustomsValueQuery.date;

        return dataContext
            .get(url)
            .then(function (result) {
                result.CurrentCustomsValuePerUnitOfWeightFormatted = 
                    createFormattedValuePerUnitOfWeight(result.CustomsValuePerUnitOfWeight);

                if (result.AverageCustomsValuePerUnitOfWeight) {
                    result.AverageCustomsValuePerUnitOfWeightFormatted = 
                        createFormattedValuePerUnitOfWeight(result.AverageCustomsValuePerUnitOfWeight);
                }

                result.Percentage = $filter('number')(result.ValueComparedToAverage * 100, 2) + '%';
                return result;
            });
    }

    function createFormattedValuePerUnitOfWeight(valuePerUnit) {
        return $filter('number')(valuePerUnit.Value.Amount, 2) + ' ' +
            $filter('toCurrencySign')(valuePerUnit.Value.MonetaryUnit.code) + ' / ' +
            (valuePerUnit.Unit.MeasurementUnit.code === 'KGM' ? 'kg' : valuePerUnit.Unit.MeasurementUnit.code)
    }
}
