// See https://app.taricsupport.com/#!/trade-statistics
// See https://trade.ec.europa.eu/access-to-markets/en/statistics
module.exports = {
    ExportValue: 'Export value',
    ExportWeight: 'Export weight',
    ExportValuePerKg: 'Export value per kg',
    ImportValue: 'Import value',
    ImportWeight: 'Import weight',
    ImportValuePerKg: 'Import value per kg',
    MoreYearsComingSoon: 'More years coming soon',
    NoDataAvailable: 'No data available',
    ReportingCountry: 'Reporting country',
    StatisticsForImportFrom: 'Statistics for import from',
    StatisticsForImportInto: 'Statistics for import into',
    
    StatisticsForImportFrom_CountryName_In_Year_: 'Statistics for import from {{ CountryName }} in {{ Year }}',
    StatisticsForImportInto_CountryName_In_Year_: 'Statistics for import into {{ CountryName }} in {{ Year }}',
    StatisticsForImportIntoTheEU_In_Year_: 'Statistics for import into the EU in {{ Year }}',

    theEU: 'the EU',
    TradePartner: 'Trade partner',
    TradeStatistics: 'Trade statistics',
    Year: 'Year'
};
