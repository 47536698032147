module.exports = [
    'common', 
    'dataContext', 
    'applicationStateService',
    taresStatisticalKeysDataService
];

function taresStatisticalKeysDataService(
    common, 
    dataContext,
    state
) {
    var service = {
        loadStatisticalKeys: loadStatisticalKeys
    };
    return service;

    function loadStatisticalKeys(commodityCode) {
        var deferred = common.$q.defer();
        if (state.filter.referenceCountry === 'CH') {
            var url = common.config.apiHosts[common.config.currentApi] + '/tares-statistical-keys' +
                '?goodscode=' + commodityCode +
                '&movement=' + state.filter.movement +
                '&lang=' + common.language.get()  +
                '&referencecountry=' + state.filter.referenceCountry +
                '&date=' + state.filter.date;
    
            dataContext.get(url)
                .then(function(data) {
                    deferred.resolve(data);
                });
        }
        else {
            deferred.resolve([]);
        }
        return deferred.promise;
    }
}