﻿module.exports = {
    controller: complianceNewController,
    templateUrl: 'compliance/components/compliance-new-page.html'
};

complianceNewController.$inject = [
    '$analytics', 
    '$translate', 
    'common', 
    'complianceDataService', 
    'language', 
    'applicationStateService', 
    'Upload'
];

function complianceNewController(
    $analytics, 
    $translate, 
    common, 
    complianceData, 
    languageService, 
    state, 
    fileUpload
) {

    var vm = {
        $onInit: attached,
        errors: [],
        appStateFilter: state.filter,
        getFileDetails: getFileDetails,
        isUploading: false,
        jobTemplates: [],
        jobTypes: [],
        newJob: {
            file: null,
            goodsnomColumn: 'A',
            jobType: '',
            movement: '0',
            name: null,
            originColumn: 'B',
            outputType: '2',
            startRow: 2
        },
        save: saveJob,
        selectedTemplate: null,
        translations: []
    };

    var chars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

    function attached() {
        common.auth.check();
        $analytics.pageTrack('/abc-new');
        $translate([
            'compliance.errors.select_file_to_check', 
            'compliance.errors.specify_goodsnom_column', 
            'compliance.errors.invalid_goodsnom_column', 
            'compliance.errors.invalid_country_code_column', 
            'compliance.errors.specify_starting_row', 
            'compliance.errors.invalid_starting_row', 
            'compliance.errors.specify_jobtype'
        ])
            .then(function (result) {
                vm.translations = result;
            });

        getJobTemplates();
        getJobTypes();
    }

    function getFileDetails(e) {
        for (var i = 0; i < e.length; i++) {
            vm.newJob.name = e[i].name;
        }
    }

    function getJobTemplates() {
        complianceData.getJobTemplates()
            .then(function (data) {
                vm.jobTemplates = data;
                if (vm.jobTemplates.length > 0) {
                    vm.selectedTemplate = vm.jobTemplates[0].Id;
                }
            });
    }

    function getJobTypes() {
        complianceData.getJobTypes()
            .then(function (data) {
                vm.jobTypes = data;
                if (vm.jobTypes && vm.jobTypes.length > 0)
                    vm.newJob.jobType = vm.jobTypes[0].JobType;
            });
    }

    function validate(fromTemplate) {
        vm.errors = [];
        if (!vm.newJob.file) {
            vm.errors.push(vm.translations['compliance.errors.select_file_to_check']);
        }

        if (!fromTemplate) {
            if (!vm.newJob.goodsnomColumn) {
                vm.errors.push(vm.translations['compliance.errors.specify_goodsnom_column']);
            }
            else if (!vm.newJob.goodsnomColumn.match(/^[a-zA-z]+$/)) {
                vm.errors.push(vm.translations['compliance.errors.invalid_goodsnom_column']);
            }

            if (!!vm.newJob.originColumn && !vm.newJob.originColumn.match(/^[a-zA-z]+$/)) {
                vm.errors.push(vm.translations['compliance.errors.invalid_country_code_column']);
            }
    
            if (!vm.newJob.startRow) {
                vm.errors.push(vm.translations['compliance.errors.specify_starting_row']);
            }
            else if (!('' + vm.newJob.startRow).match(/^[1-9][0-9]*$/)) {
                vm.errors.push(vm.translations['compliance.errors.invalid_starting_row']);
            }
    
            if (!vm.newJob.jobType) {
                vm.errors.push(vm.translations['compliance.errors.specify_jobtype']);
            }
        }


        return vm.errors.length == 0;
    }

    function saveJob() {
        if (validate(vm.selectedTemplate !== null) === false)
            return;

        vm.isUploading = true;

        if (vm.selectedTemplate) {
            complianceData.createJobFromTemplate(vm.selectedTemplate)
                .then(function (jobResult) {
                    fileUpload.upload({
                        data: { file: vm.newJob.file },
                        headers: { 'Authorization': 'Bearer ' + common.identity.token.tokenValue },
                        url: common.config.apiHosts.generic + '/compliance/job/' + jobResult.Id + '/document?template=' + vm.selectedTemplate
                    })
                        .then(function (fileResult) {
                            // At last, finalize the job
                            complianceData.finalizeJob(jobResult.Id)
                                .then(function (finalizeResult) {
                                    common.$location.url('/compliance-check');
                                });
                        });
                });
        }
        else {
            var goodsColumnIndex = excelColumnToIndex(vm.newJob.goodsnomColumn) - 1;
            var originColumnIndex = excelColumnToIndex(vm.newJob.originColumn) - 1;


            if (goodsColumnIndex == -1 || originColumnIndex == -1)
                return;

            var job = {
                jobType: vm.newJob.jobType,
                language: languageService.get(),
                movement: vm.newJob.movement,
                outputType: parseInt(vm.newJob.outputType),
                referenceCountry: state.filter.referenceCountry
            };

            // First create the job
            complianceData.createJob(job)
                .then(function (jobResult) {

                    var urlArgs = [
                        'docType=Excel',
                        'goodColumn=' + goodsColumnIndex,
                        'countryColumn=' + originColumnIndex,
                        'startingLine=' + (vm.newJob.startRow - 1)
                    ];
                    //Then upload the file
                    fileUpload.upload({
                        data: { file: vm.newJob.file },
                        headers: { 'Authorization': 'Bearer ' + common.identity.token.tokenValue },
                        url: common.config.apiHosts.generic + '/compliance/job/' + jobResult.Id + '/document?' + urlArgs.join('&')
                    })
                        .then(function (fileResult) {
                            // At last, finalize the job
                            complianceData.finalizeJob(jobResult.Id)
                                .then(function (finalizeResult) {
                                    common.$location.url('/compliance-check');
                                });
                        });
                });
        }
    }

    function excelColumnToIndex(val) {
        var base = 'abcdefghijklmnopqrstuvwxyz', i, j, result = 0;
        val = val.toLowerCase();

        for (i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
            result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
        }

        return result;
    }

    return vm;

}
