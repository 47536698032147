﻿module.exports = ['regionModal', countryModalsService];

function countryModalsService(regionModal) {

    var service = {
        region: regionModal
    };

    return service;

}
