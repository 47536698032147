module.exports = {
    bindings: {
        goodscode: '<',
        searchQuery: '<'
    },
    controller: caseLawNewsController,
    templateUrl: 'case-law/components/case-law-news.html'
};

caseLawNewsController.$inject = [
    'euCaseLawDataService', 
    'nlCaseLawDataService', 
    'nlCaseLawJudgmentModal', 
    'language', 
    'identity',
];

function caseLawNewsController(
    euCaseLawDataService, 
    nlCaseLawDataService, 
    nlCaseLawJudgmentModal, 
    language, 
    identity
) {

    var vm = {
        $onInit: attached,
        dataEU: euCaseLawDataService,
        dataNL: nlCaseLawDataService,
        identity: identity,
        isLoading: isLoading,
        language: language.get(),
        openUitspraak: openUitspraak,
        pageChangedEU: pageChangedEU,
        pageChangedNL: pageChangedNL
    };

    return vm;

    function attached() {

        vm.dataEU.query.GoodsCode = vm.goodscode;
        vm.dataEU.query.Query = vm.searchQuery;
        vm.dataEU.query.Page = 1;

        vm.dataNL.query.GoodsCode = vm.goodscode;
        vm.dataNL.query.Query = vm.searchQuery;
        vm.dataNL.query.Page = 1;

        if (vm.identity.hasPermission('case-law-nl')) {
            loadLatestCaseLawNL();
        }
        if (vm.identity.hasPermission('case-law-eu')) {
            loadLatestCaseLawEU();
        }
    }

    function isLoading() {
        return vm.isLoadingNL || vm.isLoadingEU;
    }

    function pageChangedEU() {
        loadLatestCaseLawEU();
        var modal = document.getElementsByClassName('modal-case-law')[0];
        if (modal) {
            modal.scrollTo(0, 0);
        }
        else {
            document.getElementById("content").scrollTo(0, 0);
        }
    }


    function pageChangedNL() {
        loadLatestCaseLawNL();
        var modal = document.getElementsByClassName('modal-case-law')[0];
        if (modal) {
            modal.scrollTo(0, 0);
        }
        else {
            document.getElementById("content").scrollTo(0, 0);
        }
    }

    function loadLatestCaseLawEU() {
        vm.isLoadingEU = true;
        if (vm.identity.hasPermission('case-law-eu')) {
            euCaseLawDataService.loadCaseLaw()
            .then(function() {
                vm.isLoadingEU = false;
            });
        }
    }

    function loadLatestCaseLawNL() {
        vm.isLoadingNL = true;
        if (vm.identity.hasPermission('case-law-nl')) {
            nlCaseLawDataService.loadCaseLawSearchResults()
            .then(function() {
                vm.isLoadingNL = false;
            });
        }
    }

    function openUitspraak(ecli) {
        nlCaseLawJudgmentModal.show(ecli);
    }
}
