﻿
module.exports = {
    controller: landingPageController,
    templateUrl: 'dashboard/components/landing-page.html'
};

landingPageController.$inject = [
    '$analytics', 
    '$window', 
    'common', 
    'applicationStateService', 
    'documentationService', 
    'firstLoginModal',
    'versionHistoryService', 
    'preferenceService',
];

function landingPageController(
    $analytics, 
    $window, 
    common, 
    state, 
    documentationService, 
    firstLoginModal,
    versionHistoryService, 
    preferences
) {

    var vm = {
        $onInit: attached,
        common: common,
        setReferenceCountry: setReferenceCountry,
        toggleReferenceCountryNotAvailableMessage: toggleReferenceCountryNotAvailableMessage,
        allowedReferenceCountries: [],
        disallowedReferenceCountries: [],
        showReferenceCountryNotAvailableMessageFor: null,
        versionHistory: null
    };

    function attached() {
        $analytics.pageTrack('/landing');
        common.auth.check();
        loadVersionHistory();
        documentationService.initialize('landing');
        vm.allowedReferenceCountries = common.identity.getAllowedTradeTariffs();
        vm.disallowedReferenceCountries = common.identity.getDisallowedTradeTariffs();
        var languagePreference = preferences.get('language', 'website');
        if (languagePreference === null) {
            firstLoginModal.show();
        }
    }

    function toggleReferenceCountryNotAvailableMessage(referenceCountry) {
        if (vm.showReferenceCountryNotAvailableMessageFor === referenceCountry) {
            vm.showReferenceCountryNotAvailableMessageFor = null;
        }
        else {
            vm.showReferenceCountryNotAvailableMessageFor = referenceCountry;
        }
    }

    function loadVersionHistory() {
        versionHistoryService.get().then(function (result) {
            vm.versionHistory = _.sortBy(result.data.history, 'releaseDate').reverse()[0];
        }, function (error) { } );
    }

    function setReferenceCountry(referenceCountry) {
        state.filter.referenceCountry = referenceCountry;
        preferences.setReferenceCountry(referenceCountry).then(function () {
            $window.location.reload();
        });
        documentationService.exit();
        common.$location.url('/');
    }

    return vm;
}
