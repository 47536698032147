﻿var moment = require('moment');

module.exports = ['common', 'applicationStateService', 'dataContext', referencePricesDataService];

function referencePricesDataService(common, state, dataContext) {
    var service = {
        getReferencePrices: getReferencePrices,
        getSampleReferencePrices: getSampleReferencePrices,
        getStreamXml: getStreamXml
    };

    function getReferencePrices(language, date) {
        var apiDate = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

        var url = common.config.apiHosts[common.config.currentApi] + '/representative-prices/json?lang=' + language + 
            '&date=' + apiDate +
            '&referenceCountry=' + state.filter.referenceCountry;

        return dataContext.get(url);
    }

    function getSampleReferencePrices(language) {
        
        var url = common.config.apiHosts[common.config.currentApi] + '/representative-prices/sample?lang=' + language + 
            '&referenceCountry=' + state.filter.referenceCountry;

        return dataContext.get(url);
    }

    function getStreamXml(date) {
        var apiDate = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        var url = common.config.apiHosts[common.config.currentApi] + '/representative-prices/stream' + 
            '?date=' + apiDate +
            '&referenceCountry=' + state.filter.referenceCountry;
        var requestId = 'REFERENCE_PRICES_XML';
        var headers = { 'Accept': 'application/xml' };
        return dataContext.get(url, requestId, headers);
    }

    return service;

}
