﻿module.exports = [goodModelsService];

function goodModelsService() {
    var service = {
        measuresDisplayModel: measuresDisplayModel
    };

    function measuresDisplayModel() {
        return {
            import: {
                additionalDuties: [],
                antidumping: {
                    financieel: [],
                    nietFinancieel: []
                },
                conditions: [],
                tariffs: {
                    accijns: [],
                    contingenten: [],
                    drd: [],
                    preferenties: [],
                    schorsingen: [],
                    other: []
                },
                referencePrices: [],
                prohibitions: [],
                supplementaryAmount: [],
                toezichtAchteraf: [],
                vat: [],
                other: []
            },
            export: {
                additionalDuties: [],
                conditions: [],
                prohibitions: [],
                restitutions: [],
                toezichtAchteraf: [],
                other: []
            },
            general: {
                conditions: [],
                other: [],
                units: []
            }
        };
    }

    return service;
}