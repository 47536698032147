// See https://app.taricsupport.com/#!/correlation-table/expired-codes-search
module.exports = {
    CombinedNomenclatureExportIntraStat: 'Combined Nomenclature &mdash; export/intrastat',
    CommodityCodeChanges: 'Commodity Code Changes',
    CorrelationTable: 'Correlation Table',
    DerivedFrom: 'Derived from',
    ExpiredCode: 'Expired Code',
    ExpiredCodes: 'Expired Codes',
    HarmonizedSystem2022: 'Harmonized System 2022',
    HS2022Forward: 'HS2017 to HS2022 Correlations',
    HS2022Backward: 'HS2022 to HS2017 Correlations',
    MaxOneYearInterval: 'You cannot specify a period of more than a year',
    NewCode: 'New Code',
    NewCodes: 'New Codes',
    Nomenclature: 'Nomenclature',
    RecentChanges: 'Recent changes',
    RecentCommodityCodeChanges: 'Recent commodity code changes',
    Remarks: 'Remarks',
    ReplacedBy: 'Replaced by',
    SearchCorrelationTable: 'Search correlation table',
    TaricImport: 'TARIC &mdash; import',
    UpcomingChanges: 'Upcoming changes',
    UpcomingCommodityCodeChanges: 'Upcoming commodity code changes'
};