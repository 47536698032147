﻿module.exports = {
    AlsoSearchIn: 'Zoek ook in',
    AlphabeticalIndex: 'Alfabetische Index',
    AlphabeticalIndexNotAvailableInChosenLanguage: 'Zoeken in de alfabetische index is mogelijk in het Engels en in het Duits. Kies één van deze talen om deze te doorzoeken.',
    CaseLawNoAccessMessage: 'Neem contact op met Taric Support voor toegang tot de module rechtspraak',
    CommodityCodeDescriptions: 'Omschrijvingen goederencodes',
    AdditionalDataSources: 'Additionele databronnen',
    SearchCccConclusions: 'Zoek conclusies CDW',
    Conclusion_Nav: 'Conclusies CDW',
    Documents: 'Documenten',
    BrowseChapter_chapterCode_: 'Blader in hoofdstuk {{ chapterCode }}',
    GoToChapter_code_: 'Ga naar hoofdstuk {{ code }}',
    NoSearchResultsFor_QueryString_FoundInHeading_Heading_: 'Geen zoekresultaten voor "{{ QueryString }}" gevonden in post {{ Heading }}',
    SearchResultsFor_QueryString_FoundInHeading_Heading_: 'Zoekresultaten voor "{{ QueryString }}" gevonden in post {{ Heading }}',
    _commodityCode_isNotAValidCommodityCode: '{{ commodityCode }} is geen geldige goederencode',
    SearchForSuccessors: 'Zoek opvolgers',
    SearchForOriginators: 'Zoek voorlopers',
    
    noResult: 'Er is geen resultaat gevonden voor uw zoekopdracht',
    ClosestExistingParentMessage: 'Goederencode {{ closestExistingParentCommodityCode | stripTaricCode | formatTaricCode }} &ndash; zie onder &ndash; bestaat wel.',
    
    NoResultsFoundForYourSearchQuery_QueryString_: 'Er is geen resultaat gevonden voor uw zoekopdracht "{{ QueryString }}"',
    NoResultsFoundForYourSearchQuery_QueryString_FoundInChapter_Code_: 'Er is geen resultaat gevonden voor uw zoekopdracht "{{ QueryString }}" in hoofdstuk {{ Code }}.',

    Opinion_Number_: 'Advies {{ Number }}', // Short For WCO Classification Opinion
    
    SearchResultsFor_QueryString_: 'Zoekresultaten voor "{{ QueryString }}"',
    SearchResultsFor_QueryString_FoundInAlphabeticalIndex: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de alfabetische index',
    SearchResultsFor_QueryString_FoundInCode: 'Zoekresultaten voor "{{ QueryString }}" gevonden onder code',
    SearchResultsFor_QueryString_FoundInHeading: 'Zoekresultaten voor "{{ QueryString }}" gevonden in post',
    SearchResultsFor_QueryString_FoundInHeadings: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de volgende posten',
    SearchResultsFor_QueryString_FoundInHeadingsInChapter_Code_: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de volgende posten in hoofdstuk {{ Code }}',
    SearchResultsFor_QueryString_FoundInAnnotations: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de volgende aantekeningen',
    SearchResultsFor_QueryString_FoundInEuExplanatoryNotes: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de volgende toelichtingen van de EU',
    SearchResultsFor_QueryString_FoundInExplanatoryNotesGermany: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de volgende toelichtingen gepubliceerd door de Duitse douane',
    SearchResultsFor_QueryString_FoundInTextsGermanCustoms: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de volgende teksten gepubliceerd door de Duitse douane',
    SearchResultsFor_QueryString_FoundInWcoExplanatoryNotes: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de volgende toelichtingen bij het Geharmoniseerd Systeem van de WDO',
    SearchResultsFor_QueryString_FoundInWcoClassificationOpinions: 'Zoekresultaten voor "{{ QueryString }}" gevonden in de volgende classification opinions van de WDO',
    
    SearchNomenclature: 'Zoek in nomenclatuur',
    SearchAlphabeticalIndex: 'Zoek in alfabetische index',
    SearchEUClassificationRegulations: 'Zoek in indelingsverordeningen EU',
    SearchSectionAndChapterNotes: 'Zoek in aantekeningen bij afdelingen en hoofdstukken',
    SearchEUExplanatoryNotes: 'Zoek in toelichtingen EU',
    SearchExplanatoryNotesGermany: 'Zoek in toelichtingen Duitsland',
    SearchTextsGermanCustoms: 'Zoek in teksten gepubliceerd door de Duitse douane',
    SearchWCOExplanatoryNotes: 'Zoek in HS-toelichtingen WCO',
    SearchWCOClassificationOpinions: 'Zoek in indelingsadviezen WCO',
    TryOneOfTheFollowingActions: 'U kunt één van de volgende acties proberen',
    WcoExplanatoryNotesNotAvailableInChosenLanguage: 'De HS-toelichtingen van de WDO (Werelddouaneorganisatie) zijn beschikbaar in het Engels en het Frans. Kies één van deze talen om ze te doorzoeken.',
    WcoClassificationOpinionsNotAvailableInChosenLanguage: 'De classification opinions van de WDO (Werelddouaneorganisatie) zijn beschikbaar in het Engels en het Frans. Kies één van deze talen om ze te doorzoeken.'
};
