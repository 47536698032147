module.exports = ['formatting', nomenclatureTreeService];

function nomenclatureTreeService(formatting) {
    
    var service = {
        collapseAll: collapseAll,
        expandAll: expandAll,
        findNodeInTree: findNodeInTree,
        hasBti: hasBti,
        isDeclarableCode: isDeclarableCode,
        setCodeFromAndCodeTo: setCodeFromAndCodeTo
        
    };

    return service;

    function expandAll(rootNode) {
        if (rootNode.children && rootNode.children.length > 0) {
            rootNode.metadata.isExpanded = true;
            _.each(rootNode.children, function (node) {
                expandAll(node);
            });
        }
    }

    function collapseAll(rootNode) {
        if (rootNode.children && rootNode.children.length > 0) {
            rootNode.metadata.isExpanded = false;
            _.each(rootNode.children, function (node) {
                collapseAll(node);
            });
        }
    }

    // NB: btiCount is an object with commodity codes as keys and bti counts as values
    // Does the node or any of its childen have btis?
    function hasBti(node, btiCount) {
        if (!btiCount) {
            return false;
        }
        if (btiCount[node.value.commodityCode] > 0) {
            return true;
        }
        return _.some(node.children, function(child) {
            return hasBti(child, btiCount);
        })
    }

    function isDeclarableCode(commodityCode, rootNode) {
        var goodsNomenclatureId = formatting.goodsCodeToGoodsnomId(commodityCode);
        var foundNode = findNodeInTree(goodsNomenclatureId, rootNode);
        if (foundNode === null)
            return false;
        else if (!foundNode.hasChildren)
            return true; // We can navigate to it if it's an end node (doesn't have children)
        else if (foundNode.children === null || foundNode.children.length === 0)
            return false; /// We can't determine the right children (they may exist but not be loaded), so just return false

        // if the commodity code matches but the prodlin of the node is not 80,
        // then the node can have a descendant with the same commodity code and prodlin 80 
        // which is declarable. This is what we check here.
        for (var i = 0; i < foundNode.children.length; i++) {
            var childIsNavigable = isDeclarableCode(goodsNomenclatureId, foundNode.children[i]);
            if (childIsNavigable === true)
                return true;
        }
        return false;
    }

    function findNodeInTree(code, rootNode) {
        if (rootNode === null) {
            return null;
        }
        var i,
            result,
            len = rootNode.children.length;
        if (rootNode.value.commodityCode === code && (!rootNode.value.prodlin || rootNode.value.prodlin === 80)) {
            return rootNode;
        }
        for (i = 0; i < len; i++) {
            if (i < len - 1 && rootNode.children[i + 1].value.commodityCode > rootNode.children[i].value.commodityCode && rootNode.children[i + 1].value.commodityCode <= code) {
                continue;
            }
            result = findNodeInTree(code, rootNode.children[i]);
            if (result !== null && !result.value.isSubchapter && (!result.value.prodlin || result.value.prodlin === 80)) {
                return result;
            }
        }
        return null;
    }

    function setCodeFromAndCodeTo(rootNode) {
        if (rootNode === null) {
            return;
        }
        _.forEach(rootNode.children, setCodeFromAndCodeTo);
        if (rootNode.value.prodlin !== 80) {
            
            // This check is here to support older UK cache, where we didn't store the prodlin
            // Otherwise we'd just need rootNode.code_from = rootNode.code
            if (rootNode.value.prodlin) {
                rootNode.value.codeFrom = rootNode.value.commodityCode;
            }
            else if (rootNode.children && rootNode.children.length > 0 && rootNode.children[0].value.commodityCode === rootNode.value.commodityCode) {
                rootNode.value.codeFrom = rootNode.value.commodityCode;
            }

            if (rootNode.children && rootNode.children.length > 0) {
                var highestSubCode = _.maxBy(rootNode.children, function (node) {
                    if (node.value.prodlin === 80) {
                        return node.value.commodityCode;
                    }
                    // The else if statement is needed to support trees without prodlin 
                    // (where we check on the occurrence of the same commodity code on multipple indentiations)
                    else if (!node.value.prodlin && (!node.children || node.children.length === 0 || node.children[0].value.commodityCode !== node.value.commodityCode)) {
                        return node.value.commodityCode;
                    }
                    else {
                        return node.value.codeTo;
                    }
                });
                
                if (highestSubCode) {
                    if (highestSubCode.value.prodlin === 80) {
                        rootNode.value.codeTo = highestSubCode.value.commodityCode;
                    }
                    // The else if statement is needed to support trees without prodlin 
                    // (where we check on the occurrence of the same commodity code on multipple indentiations)
                    else if (!highestSubCode.value.prodlin && (!highestSubCode.children || highestSubCode.children.length === 0 || highestSubCode.children[0].value.commodityCode !== highestSubCode.value.commodityCode)) {
                        rootNode.value.codeTo = highestSubCode.value.commodityCode;
                    }
                    else {
                        rootNode.value.codeTo = highestSubCode.value.codeTo;
                    }
                }
            }
        }
    }
}