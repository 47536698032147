﻿module.exports = {
    ActiveTaricMeasures: 'Actieve TARIC-maatregelen',
    BaseRegulation: 'Basisverordening',
    BaseRegulations: 'Basisverordeningen',
    ChangedBy: 'Gewijzigd door',
    ClassificationReference: 'Referentie naar tarifering van post',
    ClassificationInLegalActsDirectory: 'Indeling in het repertorium',
    CountryRegion: 'Land/Regio',
    DocumentNotFound: 'Document niet gevonden',
    GoBackToTheOverview: 'Ga terug naar het overzicht',
    HereAreAFewThings: 'Hier zijn een aantal mogelijke vervolgstappen.',
    InForce: 'Van kracht',
    ModificationRegulation: 'Wijzigingsverordening',
    ModificationRegulations: 'Wijzigingsverordeningen',
    Name: 'Naam',
    NoModificationRegulations: 'Geen wijzigingsverordeningen',
    Note: 'Nota',
    Note_CELEX_: 'Nota {{ CELEX }}',
    OfficialJournal: 'Publicatieblad',
    OJ: 'Pb',
    OpenDetails: 'Open details',
    OpenDocumentOnEurLex: 'Open document op EUR-Lex',
    Page: 'Pag.',
    PubNo: 'Pb nr.',
    PubDate: 'Publ. datum',
    PublicationDate: 'Publicatiedatum',
    PubPage: 'Publ. blad',
    _count_publicationsFound: '{{ count }} publicaties gevonden',
    Regulation: 'Verordening',
    Regulation_CELEX_: 'Verordening {{ CELEX }}',
    Regulations: 'Verordeningen',
    ResourceType: 'Type document',
    SearchEurLexFor: 'Zoek op EUR-Lex naar',
    ThereIsNoDocument_celex_: 'Er is geen document in onze application met CELEX gelijk aan {{ celex }}',
    ModalTitle: 'Wet- en regelgeving',
    consolidated: 'verwerkt in bovenstaande tekst',
    unconsolidated: 'niet verwerkt in bovenstaande tekst',
};
