module.exports = ['$httpParamSerializer', '$location', '$routeParams', 'common', 'dataContext', 'identity', euCaseLawDataService];

function euCaseLawDataService($httpParamSerializer, $location, $routeParams, common, context, identity) {
    var service = {
        isLoading: false,
        query: {
            Celex: null,
            Ecli: $routeParams.ecli || null,
            EndDate: null,
            GoodsCode: null,
            Lang: common.language.get(),
            Query: null,
            StartDate: null,
            Tag: null,
            Zaaknummer: null,
            Page: 1
        },
        tags: [],
        response: {},
        summary: {
            html: null,
            metadata: null,
            pdfUrl: null
        },
        clear: clear,
        getSummaryPdfUrl: getSummaryPdfUrl,
        loadCaseLaw: loadCaseLaw,
        loadCaseLawNews: loadCaseLawNews,
        loadSummary: loadSummary,
        loadTags: loadTags,
    };

    function clear() {
        service.query.Celex = null;
        service.query.Ecli = null;
        service.query.EndDate = null;
        service.query.GoodsCode = null;
        service.query.Query = null;
        service.query.StartDate = null;
        service.query.Tag = null;
        service.query.Zaaknummer = null;
        service.query.Page = 1;

        $location.search('ecli', null);
    }

    function loadTags() {
        if (identity.hasPermission('case-law-eu')) {
            context.get(common.config.apiHosts.generic + '/caselaw/eu/tags')
                .then(function (data) {
                    service.tags = data;
                });
        }
    }

    function loadCaseLaw() {
        if (identity.hasPermission('case-law-eu')) {
            service.isLoading = true;
            service.response = {};
            return context.get(common.config.apiHosts.generic + '/caselaw/eu/search?' + $httpParamSerializer(service.query))
                .then(function (data) {
                    service.response = data;
                    service.isLoading = false;
                }, function (data, status, jqXhr) {
                    if (status === 401) {
                        service.isLoading = false;
                        service.noAccess = true;
                    }
                });
        }
    }

    function loadCaseLawNews(pageNumber) {
        clear();
        service.query.Page = pageNumber;
        if (identity.hasPermission('case-law-eu')) {
            service.isLoading = true;
            return context.get(common.config.apiHosts.generic + '/caselaw/eu/search?' + $httpParamSerializer(service.query))
                .then(function (data) {
                    service.response = data;
                    service.isLoading = false;
                }, function (data, status, jqXhr) {
                    if (status === 401) {
                        service.isLoading = false;
                        service.noAccess = true;
                    }
                });
        }
    }

    function loadSummary(workId) {
        if (identity.hasPermission('case-law-eu')) {
            service.summary.html = null;
            service.isLoading = true;
            return context.get(common.config.apiHosts.generic + '/caselaw/eu/summary/' + workId).then(function (html) {
                service.summary.html = html;
                service.isLoading = false;
            });
        }
    }

    function getSummaryPdfUrl(workId) {
        if (identity.hasPermission('case-law-eu')) {
            service.summary.html = null;
            service.isLoading = true;
            var url = common.config.apiHosts.generic +
                '/caselaw/eu/summary/' + workId + '/pdfUrl'
            return context.get(url).then(function (pdfUrl) {
                service.summary.pdfUrl = pdfUrl;
                service.isLoading = false;
            });
        }
    }




    return service;
}