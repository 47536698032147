module.exports = {
    controller: searchClassificationRegulationsController,
    templateUrl: 'classification-regulations/components/search-classification-regulations-page.html'
};

searchClassificationRegulationsController.$inject = [
    '$analytics', 
    '$location', 
    'notificationsService', 
    'language', 
    'searchClassificationRegulationsService',
];

function searchClassificationRegulationsController(
    $analytics, 
    $location, 
    notificationsService, 
    language, 
    searchClassificationRegulationsService
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        data: searchClassificationRegulationsService.data,
        isLoading: false,
        language: language.get(),
        noResults: noResults,
        search: search
    };

    return vm;

    function attached() {
        // TODO: parse properly
        vm.query = $location.search().query;
        vm.code = $location.search().commodityCode;
        $analytics.pageTrack('/classification-regulations-search');

        loadSearch();
        notificationsService.subscribe('filter_date_changed', loadSearch);
    }

    function detached() {
        notificationsService.unsubscribe('filter_date_changed', loadSearch);
    }

    function search(query) {
        $location.path('/classification-regulations/nl/search').search(query);
    }


    function noResults() {
        return vm.language === 'nl' && !vm.isLoading && vm.data.classificationRegulations.length === 0 && queryIsValid();
    }

    function queryIsValid() {
        return vm.query && vm.query.length >= 2;
    }


    function loadSearch() {

        if (queryIsValid()) {
            if (vm.language === 'nl') {
                vm.isLoading = true;
                searchClassificationRegulationsService.load(vm.code, vm.query)
                    .then(function () {
                        vm.isLoading = false;
                    });
            }
            else if (vm.language === 'en') {
                $location.path('/classification-regulations/en/search').search({ code: vm.code, query: vm.query }).replace();
            }
        }
        else {
            searchClassificationRegulationsService.data.classificationRegulations = [];
        }
    }
}