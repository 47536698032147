﻿module.exports = {
    bindings: {
        isLarge: '<',
        goodscode: '<'
    },
    controller: regulationsButtonController,
    templateUrl: 'regulations/components/regulations-button.html'
};

regulationsButtonController.$inject = ['regulationsModal'];

function regulationsButtonController(regulationsModal) {

    var vm = {
        showRegulations: showRegulations
    };

    function showRegulations() {
        regulationsModal.show(vm.goodscode);
    }


    return vm;

}