﻿module.exports = {
    exampleText: 'bijvoorbeeld: <em>22030009</em> of <em>6911100090 import cn</em>',
    landingPage: {
        dynamic: {
            DE: 'Naar het Duitse douanetarief (EZT)',
            EU: 'Naar het Europese douanetarief (TARIC)',
            BE: 'Naar het Belgische douanetarief (TARBEL)',
            NL: 'Naar het Nederlandse douanetarief (DTV)',
            CH: 'Naar het Zwitserse douanetarief (TARES)',
            GB: 'Naar het UK Global Tariff (UKGT)',
            SE: 'Naar het Zweedse douanetarief',
            IT: 'Naar het Italiaanse douanetarief'
        }
    },
    RecentChanges: 'Recente wijzigingen',
    RecentCodes: 'Recente codes'
};
