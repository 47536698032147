﻿// See https://app.taricsupport.com/#!/referenceprices
module.exports = {
    NoAccess: '<h2>An additional subscription is required to access this data.</h2> ' + 
        '<p>This module gives a complete overview of the reference prices for agricultural products. ' +
        'That is, you will find an always up-to-date overview of current Standard Import Values and Unit Prices. ' +
        'You can also download this data for use in an external application. </p> ' +
        '<p>This module requires an additional subscription. Please contact Taric Support for access.</p> ' +
        '<p><em>Below is a sample of the data that is available. Please note that this sample concerns historical data.</em></p> ',
    overview: {
        EndDate: 'Stops on',
        NoPrices: 'No reference prices could be found at this time',
        StandardImportValues: 'Standard Import Values',
        StartDate: 'Starts on',
        Title: 'EU Reference prices',
        UnitPrices: 'Unit Prices'
    }
};