var moment = require('moment');

module.exports = {
    controller: correlationTableController,
    templateUrl: 'correlation-table/components/correlation-table.html'
};
    
correlationTableController.$inject = [
    '$analytics', 
    '$window', 
    '$routeParams', 
    '$translate', 
    'formatting', 
    'correlationDataService', 
    'applicationStateService', 
    'tradeMovementService', 
    'notificationsService',
];

function correlationTableController(
    $analytics, 
    $window, 
    $routeParams, 
    $translate, 
    formatting, 
    correlationData, 
    state, 
    tradeMovement, 
    notifications
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        beforeFirstSearch: true,
        changeDateInterval: changeDateInterval,
        data: {
            newCodes: [],
            oldCodes: []
        },
        error: null,
        filter: {
            dateInterval: 'upcoming-year',
            goodsnom: $routeParams.code,
            endDate: moment().add(1, 'Y').format('D-M-YYYY'),
            endDateFormatted: null,
            orderBy: 'date',
            orderDirection: 'ascending',
            startDate: moment().format('D-M-YYYY'),
            startDateFormatted: null,
            type: 'import'
        },
        isLoading: false,
        changeTradeMovement: changeTradeMovement,
        loadNewCodes: loadNewCodes,
        loadOldCodes: loadOldCodes,
        openOldCodeInNewWindow: openOldCodeInNewWindow,
        openNewCodeInNewWindow: openNewCodeInNewWindow,
        activeSubnav: $routeParams.activeSubnav,
        referenceCountry: state.filter.referenceCountry,
        translations: {}
    };
    
    return vm;

    function openOldCodeInNewWindow(oldCode, newCode) {
        if (oldCode.endDate) {
            if (moment(oldCode.endDate).isBefore(state.filter.referenceDate)) {
                $window.open('/#!/search/tree?commodityCode=' + oldCode.goodsnom + '&date=' + moment(oldCode.endDate).format('YYYY-MM-DD'));
            }
        }
        if (newCode.startDate) {
            if (moment(newCode.startDate).isBefore(state.filter.referenceDate)) {
                $window.open('/#!/search/tree?commodityCode=' + oldCode.goodsnom + '&date=' + moment(newCode.startDate).subtract(1, 'd').format('YYYY-MM-DD'));
            }
        }
        $window.open('/#!/search/' + oldCode.goodsnom);
    }

    function openNewCodeInNewWindow(oldCode, newCode) {
        if (newCode.startDate) {
            if (moment(newCode.startDate).isAfter(state.filter.referenceDate)) {
                $window.open('/#!/search/tree?commodityCode=' + newCode.goodsnom + '&date=' + moment(newCode.startDate).format('YYYY-MM-DD'));
            }
        }
        if (oldCode.endDate) {
            if (moment(oldCode.endDate).isAfter(state.filter.referenceDate)) {
                $window.open('/#!/search/tree?commodityCode=' + newCode.goodsnom + '&date=' + moment(oldCode.endDate).add(1, 'd').format('YYYY-MM-DD'));
            }
        }
        $window.open('/#!/search/tree?commodityCode=' + newCode.goodsnom);
    }

    function movementChanged() {
        vm.filter.type = state.filter.movement;
    }

    function detached() {
        notifications.unsubscribe('filter_movement_changed', movementChanged);
    }

    function attached() {
        notifications.subscribe('filter_movement_changed', movementChanged);

        vm.filter.type = state.filter.movement;
        if (vm.activeSubnav.indexOf('recent') > 0) {
            vm.filter.dateInterval = 'last-year';
            vm.filter.startDate = moment().subtract(1, 'Y').format('D-M-YYYY');
            vm.filter.endDate = moment().format('D-M-YYYY');
            vm.filter.orderBy = 'date';
            vm.filter.orderDirection = 'descending';
        }
        else if (vm.activeSubnav.indexOf('upcoming') > 0) {
            vm.filter.dateInterval = 'upcoming-year';
            vm.filter.startDate = moment().format('D-M-YYYY');
            vm.filter.endDate = moment().add(1, 'Y').format('D-M-YYYY');
            vm.filter.orderBy = 'date';
            vm.filter.orderDirection = 'ascending';
        }
        else if ($routeParams.code) {
            vm.filter.dateInterval = 'choose-dates';
            if (formatting.isChapterCode($routeParams.code)) {
                vm.filter.startDate = moment().subtract(2, 'Y');
            }
            else if (formatting.isHeadingCode($routeParams.code)) {
                vm.filter.startDate = moment().subtract(5, 'Y');
            }
            else {
                vm.filter.startDate = moment().subtract(20, 'Y');
            }
            vm.filter.endDate = moment().add(2, 'M').format('D-M-YYYY');
        }
        else {
            vm.filter.dateInterval = 'choose-dates';
            vm.filter.startDate = moment().subtract(10, 'M').format('D-M-YYYY');
            vm.filter.endDate = moment().add(2, 'M').format('D-M-YYYY');
        }
        loadTranslations();   
        loadCorrelationTable();
    }

    function loadCorrelationTable() {
        if (vm.activeSubnav.indexOf('expired-codes') === 0) {
            loadOldCodes();
        }
        else if (vm.activeSubnav.indexOf('new-codes') === 0) {
            loadNewCodes();
        }
    }

    function changeTradeMovement(newTradeMovement) {
        tradeMovement.set(newTradeMovement);
    }

    function changeDateInterval(newDateInterval) {
        if (newDateInterval === 'upcoming-year') {
            vm.filter.startDate = moment().format('D-M-YYYY');
            vm.filter.endDate = moment().add(1, 'Y').format('D-M-YYYY');
            vm.filter.orderBy = 'date';
            vm.filter.orderDirection = 'ascending';
        }
        else if (newDateInterval === 'last-year') {
            vm.filter.startDate = moment().subtract(1, 'Y').format('D-M-YYYY');
            vm.filter.endDate = moment().format('D-M-YYYY');
            vm.filter.orderBy = 'date';
            vm.filter.orderDirection = 'descending';
        }
        else { // choose dates
            vm.filter.orderBy = 'commodityCode';
            vm.filter.orderDirection = 'ascending';
        }
        loadCorrelationTable();
    }

    function loadOldCodes() {
        $analytics.eventTrack('/load-correlation-table', { category: vm.activeSubnav });
        vm.activeSubnav = vm.activeSubnav.replace('new-codes', 'expired-codes');
        vm.beforeFirstSearch = false;
        vm.error = null;
        vm.isLoading = true;
        setDates();
        correlationData.getOldCodes(vm.filter.startDateFormatted, vm.filter.endDateFormatted, vm.filter.goodsnom, vm.filter.type, vm.filter.orderBy, vm.filter.orderDirection)
            .then(function (result) {
                vm.data.newCodes = [];
                vm.data.oldCodes = result;
                vm.isLoading = false;
            }, handleError);
    }

    function loadNewCodes() {
        $analytics.eventTrack('/load-correlation-table', { category: vm.activeSubnav });
        vm.activeSubnav = vm.activeSubnav.replace('expired-codes', 'new-codes');
        vm.beforeFirstSearch = false;
        vm.error = null;
        vm.isLoading = true;
        setDates();
        correlationData.getNewCodes(vm.filter.startDateFormatted, vm.filter.endDateFormatted, vm.filter.goodsnom, vm.filter.type, vm.filter.orderBy, vm.filter.orderDirection)
            .then(function (result) {
                vm.data.oldCodes = [];
                vm.data.newCodes = result;
                vm.isLoading = false;
            }, handleError);
    }

    function handleError(errorResponse) {
        vm.isLoading = false;
        if (errorResponse && errorResponse.data && errorResponse.data.Message) {
            if (errorResponse.data.Message === 'You cannot specify a period of more than a year') {
                vm.error = vm.translations['correlationTable.MaxOneYearInterval'];
            }
            else
                vm.error = errorResponse.data.Message;
        }
        else
            vm.error = 'An unexpected error occured. Please contact Taric Support.'; // TODO: translate
    }

    function setDates() {
        if (vm.filter.startDate !== null) {
            vm.filter.startDateFormatted = moment(vm.filter.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
            vm.filter.startDate = moment(vm.filter.startDate, 'DD-MM-YYYY').format('D-M-YYYY');
        }
        if (vm.filter.endDate !== null) {
            vm.filter.endDateFormatted = moment(vm.filter.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
            vm.filter.endDate = moment(vm.filter.endDate, 'DD-MM-YYYY').format('D-M-YYYY');
        }
    }

    function loadTranslations() {
        $translate([
            'shared.ui.MaxOneYearInterval'
        ]).then(function (translations) {
            vm.translations = translations;
        });
    }
}