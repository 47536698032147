module.exports = {
    bindings: {
        item: '<'
    },
    controller: euCaseLawNewsItemController,
    templateUrl: 'case-law/components/eu-case-law-news-item.html'
};

euCaseLawNewsItemController.$inject = ['language'];

function euCaseLawNewsItemController(language) {
    var vm = {
        language: language.get()
    };

    return vm;
}