// See https://app.taricsupport.com/#!/case-law/news
// See https://app.taricsupport.com/#!/case-law/nl
// See https://app.taricsupport.com/#!/case-law/eu
// See https://eur-lex.europa.eu/homepage.html
// See https://uitspraken.rechtspraak.nl/resultaat?inhoudsindicatie=zt0&publicatiestatus=ps1&sort=Relevance (in Dutch)
module.exports = {
    buttons: {
        OpenSummary: 'Open Summary',
    },
    CaseLaw: 'Case Law',
    CaseLawFirstLetter: 'C',
    DutchCaseLaw: 'Dutch Case Law',
    DutchCaseLawSummary: 'Summary',
    EuropeanCaseLawSummary: 'Summary',
    DutchCaseLawJudgment: 'Judgment',
    EuropeanCaseLaw: 'European Case Law',
    CaseNumber: 'Case Number',
    ECLI: 'ECLI',
    EnterCELEXNumber: 'Enter CELEX Number',
    EnterECLINumber: 'Enter ECLI Number',
    EnterCaseNumber: 'Enter Case Number',
    Subject: 'Subject',
    subjects: {
        AllSubjects: 'All Subjects',
        dynamic: {
            Excise: 'Excise',
            CustomsValue: 'Customs Value',
            Dumping: 'Dumping',
            ImportOrExportProcedures: 'Import or Export Procedures',
            Classification: 'Classification',
            ImportExportAdvanceFixingCertificates: 'Import and Export licences and Advance-fixing Certificates',
            Origin: 'Origin',
            ExportRefunds: 'Export Refunds',
        }
    },
    MoreCaseLaw: 'More Case Law',
    ProcedureTypes: 'Procedure Types',
    References: 'References',
    FormalRelations: 'Formal Relations',
    JudicialAuthority: 'Judicial Authority',
    DateOfJudgment: 'Date of Judgment',
    DateOfPublication: 'Date of Publication',
    AreasOfLaw: 'Areas of Law',
    PublicationLocations: 'Publication Locations', // Which legal journal has the judgment been published in?
    OpenJudgment: 'Open Judgment',
    NoDutchJudgments: 'No Dutch Judgments',
    NoEuropeanJudgments: 'No European Judgments',
    DutchJudgments: 'Dutch Judgments',
    EuropeanJudgments: 'European Judgments',
    CaseLawFor_goodscode_: 'Case Law for {{ goodscode | stripTaricCode | formatTaricCode }}',
    CaseLawFor_goodscode_and_searchQuery_: 'Case Law for code {{ goodscode | stripTaricCode | formatTaricCode }} and query "{{ searchQuery }}"',
    CaseLawFor_searchQuery_: 'Case Law for {{ searchQuery }}',
    _TotalResults_results: '{{ TotalResults }} results' // NB: TotalResults is a number
};