angular.module('taric.ecics', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('ecicsSearchPage', require('./components/ecics-search-page'))
    .component('ecicsButton', require('./components/ecics-button'))
    .component('ecicsDetails', require('./components/ecics-details'))
    .component('ecicsForm', require('./components/ecics-form'))
    .component('ecicsImages', require('./components/ecics-images'))
    .component('ecicsList', require('./components/ecics-list'))
    // Modals
    .factory('ecicsModal', require('./modals/ecics-modal'))
    // Services
    .factory('ecicsDataService', require('./services/ecics-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/ecics/search/:searchquery', {
            showSearchSubNav: true,
            template: '<ecics-search-page></ecics-search-page>',
        })
        .when('/ecics/search/', {
            showSearchSubNav: true,
            template: '<ecics-search-page></ecics-search-page>',
        })
        .when('/ecics/home/', {
            showSearchSubNav: false,
            template: '<ecics-search-page></ecics-search-page>',
        })
        ;
}
