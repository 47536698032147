module.exports = {
    Chapters: 'Chapters',
    Search: 'Search',
    News: 'News',
    BindingTariffInformation: 'Binding Tariff Information',	
    ChemicalsSearch: 'Chemicals Search',
    ValidateBusinessInformation: 'Validate Business Information',	
    AdvanceTariffRulings: 'Advance Tariff Rulings',
    TaricSupportABC: 'ABC - Check File',
    CorrelationTable: 'Correlation Table',
    TradeStatistics: 'Trade Statistics',
    TransactionValue: 'Transaction Value',
    ReferencePrices: 'EU Reference Prices',
    CaseLaw: 'Case Law',
    TextsByGermanCustoms: 'Texts by German Customs',
    ReferenceLists: 'Reference Lists',
    AdditionalCodes: 'Additional Codes',
    CertificateCodes: 'Certificate Codes',
    Footnotes: 'Footnotes',
    Regions: 'Regions',
    ExchangeRates: 'Exchange Rates',
    ChangePassword: 'Change Password',
    Logout: 'Logout',
    Favorites: 'Favorites',	
};