module.exports = {
    bindings: { 
        placeholder: '<', 
        certificateCodes: '<', 
        possibleCertificateCodes: '< ', 
        selectionChanged: '&' 
    },
    controller: certificateCodePickerController,
    templateUrl: 'certificate-codes/components/certificate-code-picker.html'
};

certificateCodePickerController.$inject = ['certificateCodeSelectorModal'];

function certificateCodePickerController(certificateCodeSelectorModal) {
    var vm = {
        $onInit: attached,
        removeCertificateCode: removeCertificateCode,
        certificateCodes: [],
        possibleCertificateCodes: [],
        availableCertificateCodes: [],
        userInput: null,
        itemSelected: itemSelected,
        onBlur: onBlur,
        onFocus: onFocus,
        onEnter: onEnter,
        showModal: showModal
    };

    return vm;

    function showModal() {
        updateAvailableCertificateCodes();
        certificateCodeSelectorModal
            .show(vm.availableCertificateCodes, vm.certificateCodes)
            .result
            .then(itemSelected)
            .catch(function (res) {
                if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                    throw res;
                }
            });
    }

    function attached() {
        updateAvailableCertificateCodes();
    }

    // available means possible and not already selected
    function updateAvailableCertificateCodes() {
        vm.availableCertificateCodes = _.chain(vm.possibleCertificateCodes)
            .differenceBy(vm.certificateCodes, function (certificateCode) {
                return certificateCode.type + certificateCode.code;
            })
            .map(function (certificateCode) {
                if (certificateCode.origin === 'IT') {
                    certificateCode.displayString = certificateCode.code + ' ' + certificateCode.type;
                }
                else {
                    certificateCode.displayString = certificateCode.type + ' ' + certificateCode.code;
                }
                return certificateCode;
            })
            .value();
    }

    function removeCertificateCode(certificateCode) {
        vm.certificateCodes = _.filter(vm.certificateCodes, function (code) {
            return code.type !== certificateCode.type || code.code !== certificateCode.code;
        });
        updateAvailableCertificateCodes();
        if (vm.selectionChanged)
            vm.selectionChanged({ certificateCodes: vm.certificateCodes });
    }

    function onFocus() {
        updateAvailableCertificateCodes();
    }

    function onBlur() {

    }

    function onEnter() {

    }

    function itemSelected(item, model) {
        vm.userInput = null;
        // console.log('itemSelected', item, model, vm.userInput);
        vm.certificateCodes.push(item);
        updateAvailableCertificateCodes();

        if (vm.selectionChanged)
            vm.selectionChanged({ certificateCodes: vm.certificateCodes });

    }


}
