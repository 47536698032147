﻿module.exports = ['countryDataService', hasCountryFilter];

function hasCountryFilter(countryData) {
    function measureHasCountry(measure, countryCode) {
        if ((measure.region === null || measure.region === undefined) && measure.measures !== undefined && measure.measures.length > 0) { // measure without regions
            for (var i = 0; i < measure.measures.length; i++) {
                if (measureHasCountry(measure.measures[i], countryCode) === true) {
                    return true;
                }
            }
        }
        else if (measure.region.type === "1") { // measures for country groups
            if (measure.excluded_regions !== null && measure.excluded_regions !== undefined && measure.excluded_regions.length > 0) {
                for (var j = 0; j < measure.excluded_regions.length; j++) {
                    if (measure.excluded_regions[j].code.toLowerCase() === countryCode.toLowerCase())
                        return false;
                }
            }

            return countryData.regions[measure.region.code].indexOf(countryCode) > -1;
        }
        else { // measures for countries
            return measure.region.code.toLowerCase() === countryCode.toLowerCase();
        }
    }

    return function (measures, countryCode) {
        if (countryCode === null || countryCode === undefined)
            return measures;

        return _.filter(measures, function (measure) {
            return measureHasCountry(measure, countryCode);
        });
    }
}
