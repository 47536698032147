module.exports = {
    bindings: {
        query: '<', 
        searchHandler: '&'
    },
    controller: nomenclatureSearchFormController,
    templateUrl: 'search/components/nomenclature-search-form.html'
};

nomenclatureSearchFormController.$inject = [
    '$location', 
    'notificationsService',
];

function nomenclatureSearchFormController(
    $location, 
    notificationsService
) {
    
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        query: {},
        validationResult: {},
        search: search
    };

    function attached() {
        notificationsService.subscribe('filter_movement_changed', movementChanged);
        notificationsService.subscribe('filter_date_changed', dateChanged);
        notificationsService.subscribe('language_changed', languageChanged);
    }
    
    function detached() {
        notificationsService.unsubscribe('filter_movement_changed', movementChanged);
        notificationsService.unsubscribe('filter_date_changed', dateChanged);
        notificationsService.unsubscribe('language_changed', languageChanged);
    }

    function languageChanged() {
        $location.search('query', null).replace();
    }

    function movementChanged() {
        vm.search(vm.query);
    }

    function dateChanged() {
        vm.search(vm.query);
    }

    function validateForm(query) {
        var result = {
            isValid: true,
            errors: {}
        };
        if (!query.query) {
            result.isValid = false;
            result.query = 'QueryIsRequired';
        }
        
        if (query.commodityCode) {
            query.commodityCode = query.commodityCode.replaceAll(/[.\s]/g, '');
            if (!/^[0-9]{2,10}$/.test(query.commodityCode)) {
                result.isValid = false;
                result.commodityCode = 'CommodityCodeIsInvalid';
            }
        }
        if (query.query) {
            if (query.query.length < 2) {
                result.isValid = false;
                result.query = 'QueryIsTooShort';
            }
        }
        return result;
    }

    function search(query) {
        vm.validationResult = validateForm(query);
        if (vm.validationResult.isValid) {
            if (vm.searchHandler) {
                vm.searchHandler({ query: query });
            }
        }
    }

    return vm;
}