﻿// The common service exports all services that will be used a lot in different other services and controllers
module.exports = [
    '$location', 
    '$q', 
    '$timeout', 
    'authCheckService', 
    'config', 
    'formatting', 
    'identity', 
    'interactionService', 
    'language', 
    commonService
];

function commonService(
    $location, 
    $q, 
    $timeout, 
    authCheckService, 
    config, 
    formatting, 
    identity, 
    interaction, 
    language
) {
    var service = {
        $location: $location,
        $q: $q,
        $timeout: $timeout,
        auth: authCheckService,
        config: config,
        formatting: formatting,
        identity: identity,
        interaction: interaction,
        language: language
    };

    return service;
}
