module.exports = {
    bindings: {},
    controller: hs2022BackwardCorrelationsController,
    templateUrl: 'correlation-table/components/hs-2022-backward.html'
};

hs2022BackwardCorrelationsController.$inject = ['correlationDataService'];

function hs2022BackwardCorrelationsController(correlationDataService) {
    var vm = {
        $onInit: attached,
        search: search
    };
    return vm;

    function attached() {
        search();
    }

    function search(commodityCode) {
        vm.isLoading = true;
        return correlationDataService
            .getHs2022BackwardCorrelations(commodityCode)
            .then(function (result) {
                vm.isLoading = false;
                vm.result = result;
            });
    }
}