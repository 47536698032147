module.exports = {
    Chapters: 'Hoofdstukken',
    Search: 'Zoeken',
    News: 'Nieuws',
    BindingTariffInformation: 'Bindende tariefinlichtingen',	
    ChemicalsSearch: 'Zoek chemische stoffen',
    ValidateBusinessInformation: 'Valideer bedrijfsinformatie',	
    AdvanceTariffRulings: 'Advance Tariff Rulings',
    TaricSupportABC: 'ABC - Bestandscontrole',
    CorrelationTable: 'Transponeringstabel',
    TradeStatistics: 'Handelsstatistieken',
    TransactionValue: 'Transactiewaarde',
    ReferencePrices: 'Referentieprijzen EU',
    CaseLaw: 'Rechtspraak',
    TextsByGermanCustoms: 'Teksten Duitse douane',
    ReferenceLists: 'Referentielijsten',
    AdditionalCodes: 'Aanvullende codes',
    CertificateCodes: 'Bescheidcodes',
    Footnotes: 'Voetnoten',
    Regions: 'Regio\'s',
    ExchangeRates: 'Wisselkoersen',
    ChangePassword: 'Wachtwoord wijzigen',
    Logout: 'Uitloggen',
    Favorites: 'Favorieten',	
};