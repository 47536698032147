﻿module.exports = {
    bindings: {
        group: '<'
    },
    controller: antiDumpingFinancialMeasureController,
    templateUrl: 'good/components/anti-dumping-financial-measures.html'
};

antiDumpingFinancialMeasureController.$inject = ['goodDisplayService'];

function antiDumpingFinancialMeasureController(goodDisplayService) {

    var vm = {
        group: null,
        setExpanded: setExpanded
    };

    function setExpanded(val) {
        if (val) {
            goodDisplayService.expandMeasures(vm.group.measures);
        }
        else {
            goodDisplayService.collapseMeasures(vm.group.measures);
        }
    }
    return vm;
}