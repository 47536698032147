module.exports = {
    // errorMessages: {
    //     ErrorEvaluatingCondition: 'Error evaluating measure condition'
    // },
    measureConditions: {
        Action: 'Azione',
        Amount: 'Quantità',
        Condition: 'Condizione', // As in measure condition - conditions that must be met for a measure to be applicable and/or import/export to be allowed
        Description: 'Descrizione',
        DutyRate: 'Importo',
        noAction: '-', // 'none/positive',
        noCertificate: '-' // 'none'
    },
    measureSeries: {
        AdditionalDuties: 'Dazi addizionali',
        AntiDumpingMeasures: 'Dazi antidumping o compensativi',
        Duties: 'Dazi applicabili',
        NationalTaxes: 'Imposte nazionali',
        OtherMeasures: 'Altre misure',
        Prohibitions: 'Prohibizioni',
        ReferencePrices: 'Prezzo unitario, valore forfettario, prezzo rappresentativo',
        Restitutions: 'Restituzioni',
        RestrictionsAndControls: 'Restrizioni e controlli',
        SupplementaryAmounts: 'Importo supplementare',
        SupplementaryUnit: 'Unità supplementari',
        VatRates: 'IVA',
    },
    referenceCountries: {
        BE: 'Belgio',
        CH: 'Svizzera',
        DE: 'Germania',
        EU: 'Unione Europea',
        GB: 'Regno Unito',
        IT: 'Italia',
        NL: 'Paesi Bassi'
    },
    tradeMovements: {
        All: 'Tutti',
        Export: 'Esportazione',
        Import: 'Importazione'
    },
    unitTypes: {
        Area: 'Area',
        Energy: 'Energia',
        GrossWeight: 'Peso lordo',
        Items: 'Numero di pezzi',
        Length: 'Lunghezza',
        NetWeight: 'Peso netto',
        Pairs: 'Numero di paia',
        Power: 'Potenza', // As in electrical power
        Volume: 'Volume',
        Weight: 'Peso'
    },
    AddCertificateCode: 'Aggiungi codice di certificato',
    AdditionalCode: 'Codice addizionale',
    AdditionalCodesValidationError: 'Errore in codici addizionali',
    AdditionalCodeTypeaheadPlaceholder: 'Cerca per codice addizionale o descrizione',
    AdditionalCodeModalTitle: 'Scegli codice addizionale',
    AdditionalCodes: 'Codici addizionali',
    // NoAdditionalCode: 'No Additional Code',
    NoAdditionalCodesFound: 'Nessun codice addizionale trovato',
    AgriculturalComponents: 'Elementi agricoli',
    MeursingAdditionalCodePlaceHolder: 'Ricerca codice addizionale',
    // AutonomousTariffSuspensionsMessage: 'Recommendation: make use of the Autonomous Tariff Suspension that applies for this commodity code',
    CalculateDuty: 'Calcola dazi',
    CalculateDutyAndTaxes: 'Calcola dazi e tasse',
    Certificate: 'Certificato',
    CertificateCodeTypeaheadPlaceholder: 'Cerca per codice di certificato o descrizione',
    CertificateCodeModalTitle: 'Scegli codice di certificato',
    CertificateCodes: 'Codici di certificato',
    // CheckMeasureConditions: 'Check Measure Conditions', // check as in verify if all of the conditions have been met
    // consumptionTaxWarning: 'Consumption Tax (Verbruiksbelasting) may apply, in which case a National Additional Code is required. Below you can search for National Additional Codes.',
    CountryOfDestination: 'Paese di destinazione',
    CountryOfOrigin: 'Paese di origine',
    CountryOrRegion: 'Paese o regione',
    CustomsValue: 'Valore doganale',
    Declaration: 'Dichiarazione', // As in 'Customs Declaration'
    DeclarationCountry: 'Paese di dichiarazione', // As in 'Country where the customs declaration is done'
    DeclarationDate: 'Data di dichiarazione', // As in 'Date of customs declaration'
    Destination: 'Destinazione', // As in 'Destination country'
    // DutyCalculator: 'Duty Calculator',
    // DutyCalculationErrors: {
    //     DutyMeasureNotUniqueForPreferenceCode: 'The duty measure is not unique for the preference code. Select duty measure with or without end-use.',
    //     Header: 'Duty Calculation Error',
    //     InvalidOrMissingQuantity: 'Invalid or missing quantity',
    //     UnsupportedDutyExpression: 'One of the measures for this duty calculation contains an unsupported duty expression.'
    // },
    // DutyCalculationResult: 'Duty Calculation Result',
    DutyMeasureType: 'Tipo di misura del dazio',
    // EuLegislation: 'EU Legislation',
    excl: 'escluso', // As in 'measure is valid for all countries excl. country1, country2...'
    Excises: 'Accise',
    // ExportMeasures: 'Export Measures',
    // exportRefundNomenclature: 'Export Refund Nomenclature',
    Footnote: 'Nota',
    Footnotes: 'Note',
    GeographicalArea: 'Area geogragica',
    GeographicalAreas: 'Aree Geografiche',
    // ImportMeasures: 'Import Measures',
    // InformationForOrderNumber: 'Information for Order Number', // Order Number of Tariff Quota
    // Legislation: 'Legislation',
    // LegislationFirstLetter: 'L',
    Measure: 'Misura',
    Measures: 'Misure',
    // MeasuresForExportFrom: 'Measures for export from',
    // MeasuresForImportInto: 'Measures for import into',
    // MeasuresValidOn: 'Measures valid on',
    MeasureTypeTypeaheadPlaceholder: 'Cerca tipo di misura',
    MeasureTypeModalTitle: 'Scegli tipo di misura',
    NationalAdditionalCode: 'Codice addizionale nazionale',
    noCertificatesFound: 'Nessun codice di certificato trovato',
    noFootnotesFound: 'Nessuna nota trovata',
    noMeasuresFound: 'Nessuna misura trovata',
    // noResultsForCommodityCodesInPeriod: 'No Results for the chosen commodity codes in the chosen period',
    // OptionalNationalAdditionalCode: 'Optional national additional code',
    Origin: 'Origine', // As in 'Country of Origin'
    // OtherChoices: 'Other Choices', // Header for print version, showing other choices the user has made to filter measures (e.g. reference date and reference country)
    PreferenceCode: 'Codice preferenza',
    // PreferenceCodeDisplayLabel: 'Preference codes display', // display preference codes that are valid for declarations under UCC or for declarations in the situation before UCC?
    PreferenceCodeDisplayLabelPreUcc: 'Pre-CDU', // UCC as in Union Customs Code
    PreferenceCodeDisplayLabelUcc: 'CDU', // UCC as in Union Customs Code
    PreferenceCodeTypeaheadPlaceholder: 'Cerca per codice o descrizione',
    PreferenceCodeModalTitle: 'Scegli codice preferenza',
    // EndUseMeasureIsAllowedWithProcedureCode44: 'The end-use measure is allowed with customs procedure 44 "End-use"',
    PreferredDutyTreatment: 'Trattamento doganale',
    Quantity: 'Quantità',
    // QuotaOrderNumber: 'Quota Order Number',
    // QuotaOrderNumberTypeaheadPlaceholder: 'Enter quota order number',
    // ReferenceLists: 'Reference lists',
    Regions: 'Regioni',
    // RetailPrice: 'Retail Price',
    // SearchByCodeOrDescription: 'Search by code or description',
    // ShowQuotaInformation: 'Show Quota Information',
    // Taxes: 'Taxes',
    Value: 'Valore',
    // ValidateDeclaration: 'Validate Declaration',
    // VatAndExciseEtcetera: 'VAT, Excise, Storage and Consumption Tax',
    VAT: 'IVA'
};