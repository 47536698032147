angular.module('taric.atr', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('atrButton', require('./components/atr-button'))
    .component('atrDetails', require('./components/atr-details'))
    .component('atrForm', require('./components/atr-form'))
    .component('atrImages', require('./components/atr-images'))
    .component('atrList', require('./components/atr-list'))
    .component('atrSearchPage', require('./components/atr-search-page'))
    // Modals
    .factory('atrModal', require('./modals/atr'))
    // Services
    .factory('atrDataService', require('./services/atr-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/atr/search', {
            reloadOnSearch: false,
            showSearchSubNav: true, 
            template: '<atr-search-page></atr-search-page>',
        })
        .when('/atr/home', {
            reloadOnSearch: false,
            showSearchSubNav: false, 
            template: '<atr-search-page></atr-search-page>',
        })
        ;
}
