﻿module.exports = [formatDutyFilter];

function formatDutyFilter() {
    var i;
    var replacements = [
        ['van de waarde omzetbelasting', '']
    ];

    return function (input) {
        if (input === null || input === undefined)
            return input;

        for (i = 0; i < replacements.length; i++) {
            while (input.indexOf(replacements[i][0]) > -1)
                input = input.replace(replacements[i][0], replacements[i][1]);
        }

        var breakLength = 30;

        if (input.length > breakLength) {
            for (i = 1; i < input.length / breakLength; i++) {
                var breakIndex = input.indexOf(' ', i * breakLength);
                if (breakIndex <= 0)
                    break;
                input = input.substr(0, breakIndex) + '<br/>' + input.substr(breakIndex);
            }
        }

        return input;
    };
}