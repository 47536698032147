﻿module.exports = {
    orderNumberPrefix: 'order:',
    orderNumber: {
        balance: 'Saldo',
        changeDate: 'Datum wijziging',
        critical: 'Kritiek',
        currentVolume: 'Beschikbare hoeveelheid',
        endDate: 'Einddatum',
        initialVolume: 'Oorspronkelijke hoeveelheid',
        measurementUnit: 'Maatstaf',
        noQuota: 'Er is geen contingent gevonden voor dit ordernummer.',
        noStatus: 'Er is geen status-informatie beschikbaar',
        periodStartDate: 'Begindatum periode',
        region: 'Geografisch gebied',
        startDate: 'Ingangsdatum',
        status: 'Status',
    },
    quotaStatus: {
        block: 'Geblokkeerd',
        critical: 'Kritiek',
        exhaustion: 'Uitgeput',
        reopening: 'Heropend',
        resume: 'Hervat',
        start: 'Geopend',
        suspension: 'Geschorst',
        unblocking: 'Blokkering opgeheven',
        unsuspension: 'Schorsing opgeheven'
    }
};
