angular.module('taric.news', [])
    .config(['$routeProvider', routeConfigurator])
    .component('newsOverviewPage', require('./components/news-overview-page'))
    .component('newsSectionCaseLawNewsPage', require('./components/case-law-news-page'))
    .component('newsSubnav', require('./components/news-subnav'))
    .component('caseLawLinks', require('./components/case-law-links'))
    .factory('newsDataService', require('./services/news-data'))
    ;


function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/news/overview', {
            template: '<news-overview-page></news-overview-page>'
        })
        .when('/news/regulations', {
            template: '<regulations-news-page></regulations-news-page>'
        })
        .when('/news/case-law', {
            template: '<news-section-case-law-news-page></news-section-case-law-news-page>'
        })
        .when('/news/nieuws-douane-voor-bedrijven', {
            template: '<belastingdienst-page></belastingdienst-page>'
        })
        .when('/news/correlation-table/:activeSubnav', {
            template: '<correlation-table-news-page></correlation-table-news-page>'
        })
    ;
}
