﻿module.exports = {
    controller: searchHeadingController,
    templateUrl: 'search/components/pages/search-heading.html'
};

searchHeadingController.$inject = [
    '$analytics', 
    'formatting',  
    'applicationStateService', 
    'searchService', 
    '$location', 
    'nomenclatureDataService',
];

function searchHeadingController(
    $analytics, 
    formatting,  
    state, 
    searchService, 
    $location, 
    nomenclatureDataService
) {


    var vm = {
        $onInit: attached,
        chapterFilter: null,
        result: {},
        isLoading: false,
        q: {},
        search: search,
        state: state,
    };

    return vm;


    function attached() {
        $analytics.pageTrack('/nomenclature-search/headings');
        vm.q = searchService.parseLocationSearchObject($location.search());
        if (vm.q.commodityCode || vm.q.query) {
            search(vm.q);
        }
    }

    function search (q) {
        if (!q.commodityCode && !q.query) {
            return;
        }

        var queryString = searchService.serializeQuery(q);
        // NB: the back button will not work properly if we don't do this check
        if (queryString !== searchService.serializeQuery($location.search())) {
            $location.path('/search/heading').search(queryString);
        }

        vm.q = q;

        vm.isLoading = true;
        state.currentIndentation = null;

        if (!state.remember.country && !q.country) {
            state.filter.countryCode = null;
            state.filter.countryName = null;
        }

        // NB: if we'd remove this first if-statement, we could show results for a single heading in a flat format
        if (q.commodityCode && !formatting.isChapterCode(q.commodityCode)) {
            $location.path('/search/tree').replace();
        }
        else if (!q.query && q.commodityCode && formatting.isChapterCode(q.commodityCode)) {
            $location.url('/chapter/' + q.commodityCode.substring(0, 2)).replace();
        }
        else {
            if (q.commodityCode !== null) {
                vm.chapterFilter = formatting.toChapterNumber(q.commodityCode);
            }
            
            nomenclatureDataService.searchHeadings(q)
                .then(function (data) {
                    vm.isLoading = false;
                    vm.result = data;
                });
        }
    }
}