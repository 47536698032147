module.exports = [currencyTypeaheadFilter];

// Takes care of ordering the results of the currency code typeahed. The logic is as follows:
// First, show the matches on the currency code, ordered by code
// Next, show the matches on the beginning of the currency code name, ordered by name
// Finally, show the matches anywhere else in the name, ordered by name
function currencyTypeaheadFilter() {

    return function (currencies, query) {
        var result;
        if (query === null || query === undefined || query === '') {
            result = _.sortBy(currencies, function(currency) {
                return currency.code;
            });
        }
        else {
            var currencyCodeMatchPartition = _.partition(currencies, function(currency) {
                return currency.code.toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) >= 0;
            });
            var currencyCodeMatchStartPartition = _.partition(currencyCodeMatchPartition[0], function(currency) {
                return currency.code.toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) === 0;
            });

            var currencyStartMatches = _.sortBy(currencyCodeMatchStartPartition[0], function(currency) {
                return currency.code;
            });
            var currencyOtherMatches = _.sortBy(currencyCodeMatchStartPartition[1], function(currency) {
                return currency.code;
            });

            var otherMatches = _.sortBy(currencyCodeMatchPartition[1], 'description');

            var currencyNameStartMatchPartition = _.partition(otherMatches, function(currency) {
                return currency.description && currency.description.toLowerCase().indexOf(query.toLowerCase()) === 0;
            });

            var otherCurrencyNameMatches = _.filter(currencyNameStartMatchPartition[1], function(currency) {
                return currency.description && currency.description.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });

            result = _.concat(currencyStartMatches, currencyOtherMatches, currencyNameStartMatchPartition[0], otherCurrencyNameMatches);
        }

        return putDefaultCurrenciesFirst(result);
    }
}

function isDefaultCurrency(currency) {
    return currency.code === 'EUR' || currency.code === 'GBP';
}

function putDefaultCurrenciesFirst(currencies) {
    var partition = _.partition(currencies, function(currency) {
        return isDefaultCurrency(currency);
    });
    return _.concat(partition[0], partition[1]);
}
