﻿module.exports = ['$analytics', '$uibModal', preferenceCodeModal];

function preferenceCodeModal($analytics, $uibModal) {
    var modal = {
        footnotes: [],
        show: showModal
    };

    preferenceCodeModalController.$inject = [
        '$uibModalInstance', 
        'preferenceCode', 
        'useUccEndUseProcedureCode44', 
        'preferenceCodeDataService'
    ];

    function preferenceCodeModalController(
        $uibModalInstance, 
        preferenceCode, 
        useUccEndUseProcedureCode44, 
        preferenceCodeDataService
    ) {

        var vm = {
            cancel: cancel,
            data: preferenceCodeDataService.data,
            preferenceCode: preferenceCode,
            useUccEndUseProcedureCode44: useUccEndUseProcedureCode44
        };

        load(preferenceCode);

        return vm;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function load(preferenceCode) {
            preferenceCodeDataService.getDescription(preferenceCode);
        }
    }


    function showModal(preferenceCode, useUccEndUseProcedureCode44) {
        $analytics.eventTrack('open-modal-preference-code-description', { category: 'preference-codes' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            controller: preferenceCodeModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                preferenceCode: function () {
                    return preferenceCode;
                },
                useUccEndUseProcedureCode44: function () {
                    return useUccEndUseProcedureCode44;
                }
            },
            size: 'lg',
            templateUrl: 'preference-codes/modals/preference-code.html',
            windowClass: 'modal-preference-code'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
