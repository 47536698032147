module.exports = {
    controller: searchWcoClassificationOpinionsController,
    templateUrl: 'search/components/pages/search-wco-classification-opinions.html'
};

searchWcoClassificationOpinionsController.$inject = [
    '$analytics', 
    '$location', 
    'notificationsService', 
    'dataContext', 
    'language', 
    'config', 
    'applicationStateService',
];

function searchWcoClassificationOpinionsController(
    $analytics, 
    $location, 
    notificationsService, 
    dataContext, 
    language, 
    config, 
    state
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        isLoading: false,
        language: language.get(),
        query: {
            QueryString: ''
        },
        results: [],
        scrollToDocument: scrollToDocument,
        search: search
    };

    return vm;

    function attached() {
        vm.query.QueryString = $location.search().query;
        $analytics.pageTrack('/wco-classification-opinions-search');
        loadSearch();
        notificationsService.subscribe('filter_date_changed', loadSearch);
    }

    function detached() {
        notificationsService.unsubscribe('filter_date_changed', loadSearch);
    }

    function search(query) {
        $location.path('/search/classifications/wco').search(query);
    }

    function scrollToDocument(result) {
        var selector = '.classification-opinion-WCO-' + result.HsCode + '-' + result.Number;
        document
            .querySelector(selector)
            .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }


    function loadSearch() {
        var url = config.apiHosts.generic + '/classifications/wco/search' +
            '?lang=' + language.get() +
            '&q=' + vm.query.QueryString +
            '&date=' + state.filter.date;
        if (vm.query.QueryString && vm.query.QueryString.length > 1) {
            vm.language = language.get();
            vm.isLoading = true;
            if (vm.language === 'en' || vm.language === 'fr') {
                dataContext.get(url)
                    .then(function (data) {
                        vm.isLoading = false;
                        vm.results = data;
                    });
            }
        }
    }
}