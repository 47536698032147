module.exports = {
    controller: searchConclusionIndexController,
    templateUrl: 'search/components/pages/search-ccc-conclusions.html'
};

searchConclusionIndexController.$inject = [
    '$analytics', 
    '$location',
    'searchService',
    'textDataService',  
    'common',
];

function searchConclusionIndexController(
    $analytics, 
    $location,
    searchService,
    textDataService,
    common
) {

    var vm = {
        $onInit: attached,
        isLoading: false,
        results: null,
        commodityCode: '',
        query:'',
        search: search,
        clearCommodityCode: clearCommodityCode,
        clearQuery: clearQuery,
    };

    return vm;

    function attached() {
        var q = searchService.parseLocationSearchObject($location.search());
        vm.commodityCode = q.commodityCode || '';
        vm.query = q.query || '';
        search();
    }

    function search() {
        if(vm.commodityCode.length > 0 || vm.query.length > 0) {
            vm.isLoading = true;
            $location.search('query', vm.query);
            $location.search('commodityCode', vm.commodityCode);
            return textDataService.getCccConclusions(vm.commodityCode, vm.query)
                .then(function (results) {
                    vm.isLoading = false;
                    vm.results = results;
                });
        }

    }

    function clearQuery() {
        vm.query = '';
        vm.results = null;
    }

    function clearCommodityCode() {
        vm.commodityCode = '';
        vm.results = null;
    }
}