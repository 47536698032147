module.exports = {
    bindings: {},
    controller: ecicsSearchPageController,
    templateUrl: 'ecics/components/ecics-search-page.html'
}

ecicsSearchPageController.$inject = [
    '$location', 
    '$route', 
    'ecicsDataService',
    'searchService'
];

function ecicsSearchPageController(
    $location, 
    $route, 
    ecicsDataService, 
    searchService
) {
    var vm = {
        $onInit: attached,
        showSearchSubNav: $route.current.$$route.showSearchSubNav,
        service: ecicsDataService,
        isLoading: false,
        loadNew: loadNew
    };

    return vm;
    
    function attached() {
        ecicsDataService.resetFilter();
        var q = searchService.parseLocationSearchObject($location.search());
        ecicsDataService.filter.goodsCode = q.commodityCode;
        ecicsDataService.filter.name = q.query;
        loadNew();
    }

    function loadNew() {
        // vm.isLoading = true;
        ecicsDataService.loadNewItems()
            .then(function () {
                // vm.isLoading = false;
            });
    }

}