﻿module.exports = {
    bindings: {
        code: '<',
        searchQuery: '@'
    },
    controller: taricCodeRowController,
    templateUrl: 'nomenclature/components/taric-code-row.html'
}

taricCodeRowController.$inject = ['common', 'btiModal', 'applicationStateService', 'nomenclatureTreeService'];

function taricCodeRowController(common, btiModal, state, nomenclatureTreeService) {
    var vm = {
        clickRow: clickRow,
        doubleClickRow: doubleClickRow,
        isLoading: false,
        state: state,
        showBti: showBti
    };

    function doubleClickRow(node) {
        state.currentCode = node.value.commodityCode;
        state.currentIndentation = node.value.indentation;
        if (!node.hasChildren) {
            common.$location
                .url('/good/' + node.value.commodityCode);
        }
        else if (node.metadata.isExpanded) {
            nomenclatureTreeService.collapseAll(node);
        }
        else {
            nomenclatureTreeService.expandAll(node);
        }
        common.interaction.clearSelection();
    }

    function clickRow(node) {
        state.currentCode = node.value.commodityCode;
        state.currentIndentation = node.value.indentation;

        if (!node.hasChildren)
            common.$location
                .url('/good/' + node.value.commodityCode);
        else {
            node.metadata.isExpanded = !node.metadata.isExpanded;
        }
    }

    function showBti(code, $event) {
        btiModal.show(code, vm.searchQuery);

        $event.preventDefault();
        $event.stopPropagation();
    }

    return vm;
}