module.exports = {
    controller: atrSearchController,
    templateUrl: 'atr/components/atr-search-page.html'
};

atrSearchController.$inject = [
    '$analytics', 
    '$location', 
    '$route', 
    'atrDataService', 
    'searchService',
];

function atrSearchController(
    $analytics, 
    $location, 
    $route, 
    atrDataService, 
    searchService
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        showSearchSubNav: $route.current.$$route.showSearchSubNav,
        service: atrDataService,
        isLoading: false,
        loadNew: loadNew
    };

    var refresh;
    var opacity = 1;

    return vm;

    function attached() {
        $analytics.pageTrack('/atr-search');
        // The following is a hack, introduced in October 2020. The reason for it is that
        // Chrome would not render the atr images properly but a redraw would fix it.
        refresh = setInterval(function() {
            opacity = (opacity === 1 ? 0.99 : 1);
            document.querySelector('.bti-image-search-results').style.opacity = opacity;
            document.querySelector('.bti-detail').style.opacity = opacity;
        }, 40);
        init();
    }

    function detached() {
        document.querySelector('.bti-image-search-results').style.opacity = 1;
        document.querySelector('.bti-detail').style.opacity = 1;
        clearInterval(refresh);
    }

    function loadNew() {
        vm.isLoading = true;
        atrDataService.loadNewAtrs()
            .then(function () {
                vm.isLoading = false;
            });
    }

    function init() {
        atrDataService.resetFilter();
        var q = searchService.parseLocationSearchObject($location.search());
        atrDataService.filter.goodsCode = q.commodityCode;
        atrDataService.filter.searchQuery = q.query;
        loadNew();
    }

}