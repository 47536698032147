module.exports = {
    controller: feedbackButtonController,
    templateUrl: 'widgets/components/feedback-button.html'
};

feedbackButtonController.$inject = ['language'];

function feedbackButtonController(language) {
    var vm = {
        language: language
    };
    return vm;
}