module.exports = {
    bindings: {
        commodityCode: '<'
    },
    controller: vatRatesController,
    templateUrl: 'vat-rates/components/vat-rates.html'
};

vatRatesController.$inject = [
    'preferenceService', 
    'vatRatesDataService'
];

function vatRatesController(
    preferenceService,
    vatRatesDataService
) {
    var vm = {
        isLoading: false,
        vatRateCountryCode: null,
        measures: [],
        possibleVatRateCountries: [],
        $onInit: attached,
        vatRateCountryChanged: vatRateCountryChanged
    };

    function attached() {
        vatRatesDataService.getVatRatesCountries()
            .then(function (vatRatesCountries) {
                var vatRateCountryCodePreference = preferenceService.get('vat-rate-country');
                var preferredVatRateCountryCode = vatRateCountryCodePreference ? vatRateCountryCodePreference.value : null;
                var preferredVatRateCountry = _.find(vatRatesCountries, function (country) {
                    return country.code == preferredVatRateCountryCode;
                });
                if (preferredVatRateCountry) {
                    vm.vatRateCountryCode = preferredVatRateCountry.code;
                    vatRateCountryChanged(preferredVatRateCountry);
                }
                vm.possibleVatRateCountries = vatRatesCountries
            });
    }

    function vatRateCountryChanged(country) {
        vm.measures = [];
        if (country) {
            vm.isLoading = true;
            vatRatesDataService.getVatRates(vm.commodityCode, country.code)
                .then(function (vatMeasures) {
                    vm.isLoading = false;
                    vm.measures = vatMeasures;
                });
        }
        vm.vatRateCountryCode = country ? country.code : null;
        preferenceService.replace({
            name: 'vat-rate-country',
            category: 'user_preferences',
            value: vm.vatRateCountryCode,
            valueType: 'stringValue'
        });
    }
    return vm;
}