﻿module.exports = {
    bindings: {
        item: '<'
    },
    controller: euCaseLawSummaryButtonController,
    templateUrl: 'case-law/components/eu-case-law-summary-button.html'
};

euCaseLawSummaryButtonController.$inject = ['euCaseLawSummaryModal'];

function euCaseLawSummaryButtonController(euCaseLawSummaryModal) {

    var vm = {
        showSummary: showSummary
    };

    function showSummary(workId) {
        euCaseLawSummaryModal.show(workId);
    }

    return vm;

}