var moment = require('moment');

module.exports =  {
    bindings: {},
    controller: atrImagesController,
    templateUrl: 'atr/components/atr-images.html'
}

atrImagesController.$inject = ['atrDataService', 'applicationStateService'];

function atrImagesController(atrDataService, state) {
    var vm = {
        service: atrDataService,
        changeActiveAtr: atrDataService.changeActiveAtr,
        isLoading: false,
        loadMore: loadMore,
        referenceDate: moment(state.filter.date, 'YYYY-MM-DD').format('D-M-YY')
    };
    return vm;

    function loadMore() {
        vm.isLoading = true;
        atrDataService.loadMoreAtrs().then(function () {
            vm.isLoading = false;
        });
    }
}