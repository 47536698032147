module.exports = {
    controller: logoutPageController,
    templateUrl: 'account/components/logout-page.html'
}

logoutPageController.$inject = [
    '$analytics', 
    '$window', 
    'common'
];

function logoutPageController(
    $analytics, 
    $window, 
    common
) {

    var vm = {
        $onInit: attached,
        loggedOut: false
    };

    function attached() {
        $analytics.pageTrack('/logout');
        logout();
    }

    function logout() {
        common.identity.set(null);
        vm.loggedOut = true;
        
        common.$timeout(function () {
            $window.location.reload();
        }, 1000);
    }
    

    return vm;

}