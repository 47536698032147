﻿var FileSaver = require('file-saver');

module.exports = {
    controller: referencePriceOverviewController,
    templateUrl: 'referenceprices/components/overview-page.html'
};

referencePriceOverviewController.$inject = [
    '$analytics', 
    'common', 
    'identity',
    'notificationsService', 
    'countryDataService', 
    'referencePricesDataService', 
    'applicationStateService'
];

function referencePriceOverviewController(
    $analytics, 
    common, 
    identity,
    notifications, 
    countryDataService, 
    referencePricesDataService, 
    state
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        dateChanged: dateChanged,
        download: download,
        downloadStreamXml: downloadStreamXml,
        loading: false,
        prices: {
            original: null,
            importValues: [],
            unitPrices: []
        },
    };

    function dateChanged() {
        loadData();
    }

    function attached() {
        $analytics.pageTrack('/reference-prices');
        loadData();
        notifications.subscribe('filter_date_changed', dateChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    function loadData() {
        var getReferencePricesPromise;
        if (identity.hasPermission('reference_prices')) {
            getReferencePricesPromise = function() {
                return referencePricesDataService.getReferencePrices(common.language.get(), state.filter.date);
            };
        }
        else {
            getReferencePricesPromise = function() {
                return referencePricesDataService.getSampleReferencePrices(common.language.get());
            };
        }
        vm.loading = true;
        vm.prices.unitPrices = [];
        vm.prices.importValues = [];


        return getReferencePricesPromise()
            .then(function (result) {
                vm.prices.original = result.Result;
                for (var i = 0; i < result.Result.length; i++) {
                    vm.prices.importValues = vm.prices.importValues.concat(
                        _.map(result.Result[i].StandardImportValues, function (item) {
                            if (item.CountryOfOrigin === '1011')
                                item.CountryOfOrigin += ' — Erga Omnes';
                            else {
                                var country = countryDataService.getCountry(item.CountryOfOrigin);
                                if (country)
                                    item.CountryOfOrigin += ' — ' + country;
                            }
                            item.Goodsnom = result.Result[i].Goodsnom;
                            return item;
                        }));
                    vm.prices.unitPrices = vm.prices.unitPrices.concat(
                        _.map(result.Result[i].UnitPrices, function (item) {
                            if (item.Duty.indexOf(' euc') > -1) {
                                item.Duty = '€ ' + item.Duty.replace(' euc', ' ');
                            }
                            if (item.CountryOfOrigin === '1011')
                                item.CountryOfOrigin += ' — Erga Omnes';
                            else {
                                var country = countryDataService.getCountry(item.CountryOfOrigin);
                                if (country)
                                    item.CountryOfOrigin += ' — ' + country;
                            }
                            item.Goodsnom = result.Result[i].Goodsnom;
                            return item;
                        }));
                }
                vm.loading = false;
            }, function () {
                vm.loading = false;
            });
    }

    function downloadStreamXml() {
        var fileName = 'valuationValues.xml';
        referencePricesDataService.getStreamXml(state.filter.date)
            .then(function(result) {
                var fileToSave = new Blob([result], {
                    type: 'application/xml',
                    name: fileName
                });
        
                // Global loaded in FileSaver.min.js
                FileSaver.saveAs(fileToSave, fileName);
            });
    }

    function download() {
        // Create a blob of the data
        var fileName = 'reference-prices.json';
        var json = JSON.stringify({
            result: vm.prices.original,
            referenceDate: state.filter.date
        });
        json = json.replace(/&euro;/g, 'EUR').replace(/€\s?EUR/g, 'EUR');
        json = json.replace(/Goodsnom/g, "goodsnom");
        json = json.replace(/StandardImportValues/g, "standardImportValues");
        json = json.replace(/UnitPrices/g, "unitPrices");
        json = json.replace(/CountryOfOrigin/g, "countryOfOrigin");
        json = json.replace(/Duty/g, "duty");
        json = json.replace(/StartDate/g, "startDate");
        json = json.replace(/EndDate/g, "endDate");
        json = json.replace(/(\d+),(\d+)/g, "$1.$2");
        var fileToSave = new Blob([json], {
            type: 'application/json',
            name: fileName
        });

        // Global loaded in FileSaver.min.js
        FileSaver.saveAs(fileToSave, fileName);

        // Save the file
        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style = "display: none";

        // var url = window.URL.createObjectURL(fileToSave);
        // a.href = url;
        // a.download = fileName;
        // a.click();
        // window.URL.revokeObjectURL(url);

    }

    return vm;
}
