module.exports = {
    bindings: {
        item: '<'
    },
    controller: euCaseLawListItemController,
    templateUrl: 'case-law/components/eu-case-law-list-item.html'
};

euCaseLawListItemController.$inject = ['euCaseLawSummaryModal', 'language'];

function euCaseLawListItemController(euCaseLawSummaryModal, language) {
    var vm = {
        language: language.get(),
        showSummary: showSummary,
    };

    function showSummary(celex) {
        euCaseLawSummaryModal.show(celex);
    }

    return vm;
}