module.exports  = [
    'dataContext', 
    'common', 
    'language', 
    'applicationStateService', 
    'notificationsService', 
    atrDataService
];

function atrDataService(
    dataContext, 
    common, 
    language, 
    state, 
    notifications
) {

    var activeatrReference = null;

    var service = {
        response: {},
        data: {
            activeAtr: null,
            isLoading: false,
        },
        display: 'list',
        filter: {
            goodsCode: null,
            issuedSince: null,
            reference: null,
            searchQuery: null,
            pageNumber: 1
        },
        orderBy: 'CommodityCode-asc',
        loadNewAtrs: loadNewAtrs,
        loadMoreAtrs: loadMoreAtrs,
        changeActiveAtr: changeActiveAtr,
        clearActiveAtr: clearActiveAtr,
        countAtrsByHeading: countAtrsByHeading,
        countAtrSearchResults: countAtrSearchResults,
        resetFilter: resetFilter
    };
    return service;

    function resetFilter() {
        service.filter.goodsCode = null;
        service.filter.issuedSince = null;
        service.filter.reference = null;
        service.filter.searchQuery = null;
        service.filter.goodsDescriptionQuery = null;
        service.filter.pageNumber = 1;
        service.display = 'list';
    }

    function loadNewAtrs() {
        service.data.activeAtr = null;
        service.filter.pageNumber = 1;
        return loadAtrs(service.filter);
    }

    function loadMoreAtrs() {
        return loadAtrs(service.filter);
    }

    function loadAtrs(filter) {

        var sort = service.orderBy.split('-')[0];
        var order = service.orderBy.split('-')[1];

        if (sort === 'KeywordRank' && !filter.searchQuery) {
            if (filter.goodsDescriptionQuery) {
                sort = 'GoodsDescriptionRank';
                service.orderBy = 'GoodsDescriptionRank-desc';
            }
            else {
                sort = 'CommodityCode';
                order = 'asc';
                service.orderBy = 'CommodityCode-asc'
            }
        }
        if (sort === 'GoodsDescriptionRank' && !filter.goodsDescriptionQuery) {
            if (filter.searchQuery) {
                sort = 'KeywordRank';
                service.orderBy = 'KeywordRank-desc';
            }
            else {
                sort = 'CommodityCode';
                order = 'asc';
                service.orderBy = 'CommodityCode-asc'
            }
        }

        var url = common.config.apiHosts.generic + '/atr/search' +
            '?goodscode=' + (filter.goodsCode || '') +
            '&pindex=' + (filter.pageNumber - 1) +
            '&psize=20' +
            '&lang=' + language.get() +
            '&date=' + state.filter.date +
            '&sort=' + sort +
            '&order=' + order;
        if (filter.searchQuery)
            url += '&keyword-query=' + filter.searchQuery;
        if (filter.goodsDescriptionQuery)
            url += '&goods-description-query=' + filter.goodsDescriptionQuery;
        if (filter.reference)
            url += '&reference=' + filter.reference;
        if (service.display === 'images') {
            url += '&imagesearch=1';
        }
        if (filter.issuedSince) {
            url += '&issuedsince=' + filter.issuedSince;
        }

        service.data.isLoading = true;
        return dataContext.get(url).then(function (paginatedatrs) {
            service.data.isLoading = false;
            service.response = paginatedatrs;
            if (service.response.Results.length > 0) {
                if (!activeatrReference) {
                    changeActiveAtr(service.response.Results[0].RulingReference);
                }
            }
        });
    }

    function clearActiveAtr() {
        service.data.activeAtr = null;
    }

    function changeActiveAtr(atrReference) {
        var url = common.config.apiHosts.generic + '/atr/' + atrReference.toString().trim() + '/';
        service.data.activeAtr = null;
        return dataContext.get(url).then(function (activeAtr) {
            service.data.activeAtr = activeAtr;
            notifications.publish('change_active_atr', activeAtr);
            _.forEach(service.response.Results, function (atr) {
                atr.active = false;
                if (atr.RulingReference === atrReference) {
                    atr.active = true;
                }
            });
        });
    }

    function countAtrsByHeading(chapterCode) {
        var url = common.config.apiHosts.generic + '/atr/countbyheading/' + chapterCode;
        return dataContext.get(url);
    }

    function countAtrSearchResults(q) {
        var url = common.config.apiHosts.generic + '/atr/count' +
            '?lang=' + common.language.get() +
            '&goodscode=' + q.code +
            '&keyword-query=' + q.query;
        return dataContext.get(url);
    }
}