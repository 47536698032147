module.exports = {
    controller: transactionValueCalculatorController,
    templateUrl: 'customs-value/components/transaction-value-calculator.html'
};

transactionValueCalculatorController.$inject = ['transactionValueDataService'];

function transactionValueCalculatorController(transactionValueDataService) {
    var vm = {
        $onInit: attached,
        onChangeTransactionValue: onChangeTransactionValue,
        activeTabs: {
            additions: 'delivery-costs',
            deductions: 'delivery-costs'
        },
        calculatorVersion: 'basic',
        data: {}
    };

    return vm;

    function attached() {
        vm.data = transactionValueDataService.data;
    }

    function onChangeTransactionValue() {
        transactionValueDataService.calculateResult();
    }
}
