﻿module.exports = [isRetailPriceFilter];

function isRetailPriceFilter() {
    return function (units) {
        return _.filter(units, function(unit) { 
            return unit.Unit.code === 'RET' || /* GB */ 
                unit.Unit.code === '003' || /* NL */
                unit.Unit.code === '062' || /* DE */
                unit.Unit.code === '063'; /* DE */
        });
    };
}
