﻿module.exports = {
    bindings: {
        group: '<'
    },
    controller: groupedFinancialMeasuresController,
    templateUrl: 'good/components/grouped-financial-measures.html'
};

groupedFinancialMeasuresController.$inject = ['goodDisplayService'];

function groupedFinancialMeasuresController(goodDisplayService) {

    var vm = {
        setExpanded: setExpanded
    }

    return vm;

    function setExpanded(val) {
        if (val) {
            goodDisplayService.expandMeasures(vm.group.measures);
        }
        else {
            goodDisplayService.collapseMeasures(vm.group.measures);
        }
    }
    
}