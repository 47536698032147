module.exports = [
    '$analytics', 
    '$uibModal', 
    euCaseLawSummaryModal
];

function euCaseLawSummaryModal(
    $analytics, 
    $uibModal
) {
    
    var modal = {
        show: showModal
    };

    euCaseLawSummaryModalController.$inject = [
        '$uibModalInstance', 
        'euCaseLawDataService', 
        'workId'
    ];

    function euCaseLawSummaryModalController(
        $uibModalInstance, 
        euCaseLawDataService, 
        workId
    ) {

        var vm = {
            cancel: cancel,
            workId: workId,
            $onInit: attached,
            summary: euCaseLawDataService.summary,
            // title
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function attached() {
            euCaseLawDataService.loadSummary(vm.workId).then(function() {
                var iframe = document.getElementById('eu-case-law-summary-iframe'),
                    iframedoc = iframe.contentDocument || iframe.contentWindow.document;

                iframedoc.body.innerHTML = euCaseLawDataService.summary.html;
            });
            euCaseLawDataService.getSummaryPdfUrl(vm.workId);
        }

        return vm;
    }



    function showModal(workId) {
        $analytics.eventTrack('open-modal-eu-case-law-summary', { category: 'case-law' });
        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: euCaseLawSummaryModalController,
            controllerAs: 'ctrlEuCaseLawSummaryModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                workId: function() {
                    return workId;
                }
            },
            size: 'lg',
            templateUrl: 'case-law/modals/eu-case-law-summary-modal.html',
            windowClass: 'modal-eu-case-law-summary'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}