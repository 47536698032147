module.exports = ['common', 'dataContext', correlationDataService];

function correlationDataService(common, context) {

    var service = {
        getNewCodes: getNewCodes,
        getOldCodes: getOldCodes,
        getHs2022ForwardCorrelations: getHs2022ForwardCorrelations,
        getHs2022BackwardCorrelations: getHs2022BackwardCorrelations,
    };

    function getNewCodes(startDate, endDate, commodityCode, movementType, orderBy, orderDirection) {
        var url = common.config.apiHosts[common.config.currentApi] + '/commodity-code-correlation/originators';
        url += '?startDate=' + startDate + '&endDate=' + endDate;
        url += '&movement=' + movementType;
        url += '&orderby=' + orderBy;
        url += '&orderDirection=' + (orderDirection || 'ascending');
        if (commodityCode) {
            url += '&commodityCode=' + commodityCode;
        }

        return context.get(url);
    }

    function getOldCodes(startDate, endDate, commodityCode, movementType, orderBy, orderDirection) {
        var url = common.config.apiHosts[common.config.currentApi] + '/commodity-code-correlation/successors';
        url += '?startDate=' + startDate + '&endDate=' + endDate;
        url += '&movement=' + movementType;
        url += '&orderby=' + orderBy;
        url += '&orderDirection=' + (orderDirection || 'ascending');
        if (commodityCode) {
            url += '&commodityCode=' + commodityCode;
        }

        return context.get(url);
    }

    function getHs2022ForwardCorrelations(commodityCode) {
        var url = common.config.apiHosts.generic + '/hs-2022/forward-correlations'
        if (commodityCode) {
            url += '?goodscode=' + commodityCode;
        }
        return context.get(url);
    }

    function getHs2022BackwardCorrelations(commodityCode) {
        var url = common.config.apiHosts.generic + '/hs-2022/backward-correlations'
        if (commodityCode) {
            url += '?goodscode=' + commodityCode;
        }
        return context.get(url);
    }

    return service;

}
