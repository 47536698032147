module.exports = ['$analytics', '$uibModal', caseLawModal];

function caseLawModal($analytics, $uibModal) {
    var modal = {
        goodscode: null,
        searchQuery: null,
        show: showModal
    };

    function showModal(goodscode, searchQuery) {
        $analytics.eventTrack('open-modal-case-law', { category: 'case-law' });

        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: caseLawModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                goodscode: function () {
                    return goodscode;
                },
                searchQuery: function () {
                    return searchQuery;
                }
            },
            size: 'lg',
            templateUrl: 'case-law/modals/case-law-modal.html',
            windowClass: 'modal-case-law modal-wide'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    caseLawModalController.$inject = ['$uibModalInstance', 'goodscode', 'searchQuery'];

    function caseLawModalController($uibModalInstance, goodscode, searchQuery) {

        var ctrl = {
            cancel: cancel,
            goodscode: goodscode,
            searchQuery: searchQuery,
        };

        return ctrl;


        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }

    return modal;

}