﻿angular.module('taric.additionalcodes', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('measureAdditionalCodeButtons', require('./components/measure-additional-code-buttons'))
    .component('measureAdditionalCodesExpanded', require('./components/measure-additional-codes-expanded'))
    .component('additionalCodesPage', require('./components/additional-codes-page'))
    .component('additionalCodePicker', require('./components/additional-code-picker'))
    // Filters
    .filter('additionalCodeTypeahead', require('./filters/additional-code-typeahead-filter'))
    // Modals
    .factory('additionalCodeSelectorModal', require('./modals/additional-code-selector'))
    // Services
    .factory('additionalCodesDataService', require('./services/additional-codes-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/additional-codes', {
            template: '<additional-codes-page></additional-codes-page>'
        })
        ;
}
