﻿module.exports = {
    bindings: { 
        ukQuotaInformation: '<' 
    },
    controller: orderNumberButtonController,
    templateUrl: 'quota/components/uk-ordernumber-button.html'
};

orderNumberButtonController.$inject = ['quotaModals'];

function orderNumberButtonController(quotaModals) {
    var vm = {
        showOrderNumber: showOrderNumber
    };

    function showOrderNumber() {
        quotaModals.ukOrderQuota.show(vm.ukQuotaInformation);
    }

    return vm;

}