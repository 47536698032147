module.exports = ['dataContext', 'config', preferenceCodeService];

function preferenceCodeService(dataContext, config) {
    var service = {
        getDescription: getDescription,
        getDescriptions: getDescriptions,
        data: {
            code: null
        }
    };
    return service;

    function getDescription(preferenceCode) {
        var url = config.apiHosts.generic + '/preference-codes/' + preferenceCode;
        return dataContext.get(url)
            .then(function(code) {
                service.data.code = code;
            });
    }

    function getDescriptions() {
        var url = config.apiHosts.generic + '/preference-codes';
        return dataContext.get(url);
    }
}
