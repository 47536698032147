var moment = require('moment');

module.exports = {
    bindings: {
        updateQueryString: '<'
    },
    controller: btiFormController,
    templateUrl: 'bti/components/bti-form.html'
}

btiFormController.$inject = [
    '$analytics', 
    '$location', 
    'btiDataService', 
    'notificationsService'
];

function btiFormController(
    $analytics, 
    $location, 
    btiDataService, 
    notificationsService
) {
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        changeGoodsDescriptionQueryField: changeGoodsDescriptionQueryField,
        changeSearchQueryField: changeSearchQueryField,
        clearCommodityCode: clearCommodityCode,
        clearBtiReference: clearBtiReference,
        clearCountry: clearCountry,
        clearSearchQuery: clearSearchQuery,
        clearGoodsDescriptionQuery: clearGoodsDescriptionQuery,
        filter: btiDataService.filter,
        service: btiDataService,
        isLoading: false,
        issuedSinceDatepicker: {
            date: moment(btiDataService.filter.issuedSince, "YYYY-MM-DD").toDate(),
            dateChanged: issuedSinceDateChanged,
            blur: issuedSinceBlur,
            opened: false,
            open: openIssuedSinceDatePicker,
            dateOptions: {}
        },
        loadNew: loadNew,
        onSelectCountry: onSelectCountry
    };

    function attached() {
        notificationsService.subscribe('language_changed', languageChanged);
        if (btiDataService.filter.searchQuery) {
            btiDataService.orderBy = 'KeywordRank-desc';
        }
        if (vm.updateQueryString) {
            updateSearchQuery();
        }
    }

    function detached() {
        notificationsService.unsubscribe('language_changed', languageChanged);
    }
    
    function clearBtiReference() {
        btiDataService.filter.reference = '';
    }

    function clearCommodityCode() {
        btiDataService.filter.goodsCode = '';
    }

    function clearCountry() {
        btiDataService.filter.country = '';
    }

    function onSelectCountry(country) {
        if (country) {
            btiDataService.filter.country = country.code;
        }
        else {
            btiDataService.filter.country = null;
        }
    }


    function changeGoodsDescriptionQueryField() {
        if (btiDataService.filter.goodsDescriptionQuery && btiDataService.orderBy !== 'GoodsDescriptionRank-desc' ) {
            if (btiDataService.filter.searchQuery && btiDataService.orderBy === 'KeywordRank-desc') {
                // If the user has entered a keyword search query and we're ordering by that, don't overrid the ordering.
            }
            else {
                btiDataService.orderBy = 'GoodsDescriptionRank-desc';
            }
        }
    }

    function clearGoodsDescriptionQuery() {
        btiDataService.filter.goodsDescriptionQuery = '';
    }

    function changeSearchQueryField() {
        if (btiDataService.filter.searchQuery && btiDataService.orderBy !== 'KeywordRank-desc') {
            if (btiDataService.filter.goodsDescriptionQuery && btiDataService.orderBy === 'GoodsDescriptionRank-desc') {
                // If the user has entered a goods description search query and we're ordering by that, don't overrid the ordering.
            }
            else {
                btiDataService.orderBy = 'KeywordRank-desc';
            }
        }
    }

    function clearSearchQuery() {
        btiDataService.filter.searchQuery = '';
    }

    function languageChanged() {
        $location.search('query', null).replace();
    }

    function issuedSinceBlur() {
        if (!vm.issuedSinceDatepicker.date) {
            vm.filter.issuedSince = null;
        }
    }

    function issuedSinceDateChanged() {
        if (vm.issuedSinceDatepicker.date) {
            var newDate = moment(vm.issuedSinceDatepicker.date).format("YYYY-MM-DD");
            if (newDate !== vm.filter.issuedSince) {
                vm.filter.issuedSince = newDate;
            }
        }
        else {
            vm.filter.issuedSince = null;
        }
    }

    function openIssuedSinceDatePicker() {
        vm.issuedSinceDatepicker.opened = true;
    }

    return vm;

    function loadNew() {
        $analytics.pageTrack('/bti-search');
        if (vm.updateQueryString) {
            updateSearchQuery();
        }
        vm.isLoading = true;
        btiDataService.loadNewBtis()
            .then(function () {
                vm.isLoading = false;
            });
    }

    function updateSearchQuery() {
        $location.search('query', vm.filter.searchQuery);
        $location.search('commodityCode', vm.filter.goodsCode);
    }
}