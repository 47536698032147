﻿module.exports = ['orderQuotaModal', 'ukOrderQuotaModal', quotaModalsService];

function quotaModalsService(orderQuotaModal, ukOrderQuotaModal) {

    var service = {
        orderQuota: orderQuotaModal,
        ukOrderQuota: ukOrderQuotaModal,
    };

    return service;

}