module.exports = ['$uibModal', ecicsModal];

function ecicsModal($uibModal) {
    var modal = {
        goodsCode: null,
        searchQuery: null,
        show: showModal
    };

    ecicsModalController.$inject = ['$scope', '$uibModalInstance', 'ecicsDataService', 'goodsCode', 'searchQuery'];

    function ecicsModalController($scope, $uibModalInstance, ecicsDataService, goodsCode, searchQuery) {
        var vm = {
            attached: attached,
            cancel: cancel,
            changeActiveItem: changeActiveItem,
            service: ecicsDataService
        };

        var refresh;
        var opacity = 1;

        return vm;

        function attached() {
            // The following is a hack, introduced in October 2020. The reason for it is that
            // Chrome would not render the BTI images properly but a redraw would fix it.
            refresh = setInterval(function() {
                opacity = (opacity === 1 ? 0.99 : 1);
                document.body.style.opacity = opacity;
            }, 40);
            init();
            $scope.$on('$destroy', detached);
        }

        function detached() {
            document.body.style.opacity = 1;
            clearInterval(refresh);
        }



        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function changeActiveItem(cus) {
            ecicsDataService.changeActiveItem(cus);
        }

        function init() {
            ecicsDataService.clearActiveItem();
            ecicsDataService.resetFilter();
            ecicsDataService.filter.goodsCode = goodsCode;
            ecicsDataService.filter.searchQuery = searchQuery;
            ecicsDataService.loadNewItems();
        }


    }

    function showModal(goodsCode,searchQuery) {
        if (goodsCode)
            modal.goodsCode = goodsCode;
        if (searchQuery)
            modal.searchQuery = searchQuery;

        return $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: ecicsModalController,
            controllerAs: 'ctrlEcicsModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                goodsCode: function () {
                    return modal.goodsCode;
                },
                searchQuery: function () {
                    return modal.searchQuery;
                }
            },
            size: 'lg',
            templateUrl: 'ecics/modals/ecics-modal.html',
            windowClass: 'modal-bti'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}