module.exports = ['common', 'dataContext', 'identity', caseLawDataService];

function caseLawDataService(common, context, identity) {
    var service = {
        euCaseLawItemsPerHeading: {},
        dutchCaseLawItemsPerHeading: {},
        getCaseLawInfo: getCaseLawInfo        
    };
    return service;

    function getCaseLawInfo(chapterNumber) {
        var urlEU = common.config.apiHosts.generic + '/caselaw/eu/documents/countByHeading/' + chapterNumber;
        var urlNL = common.config.apiHosts.generic + '/caselaw/nl/documents/countByHeading/' + chapterNumber;
        if (identity.hasPermission('case-law-eu')) {
            var reqEU = context.get(urlEU);
            reqEU.then(function (data) {
                _.forEach(data, function (element) {
                    service.euCaseLawItemsPerHeading[element.Heading + "000000"] = element.N;
                });
            });
        }
        if (identity.hasPermission('case-law-nl')) {
            var reqNL = context.get(urlNL);
            reqNL.then(function (data) {
                _.forEach(data, function (element) {
                    service.dutchCaseLawItemsPerHeading[element.Heading + "000000"] = element.N;
                });
            });
        }
    }
}