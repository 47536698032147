﻿module.exports = {
    bindings: {
        evaluation: '<',
        groups: '<'
    },
    controller: measureConditionGroupsExpandedController,
    templateUrl: 'good/components/measure-condition-groups-expanded.html'
};

measureConditionGroupsExpandedController.$inject = [
    'applicationStateService',
    'ukCertificateGuidanceModal'
];

function measureConditionGroupsExpandedController(
    state,
    ukCertificateGuidanceModal
) {
    var vm = {
        referenceCountry: state.filter.referenceCountry,
        showUkGuidance: showUkGuidance
    };
    return vm;

    function showUkGuidance(conditionGroup, conditionIndex) {
        var certificates = _.chain(conditionGroup.conditions)
            .map(function (condition, index) {
                if (condition.certificate) {
                    condition.certificate.active = (index === conditionIndex);
                }
                return condition;
            })
            .filter(function (condition) {
                return !!condition.certificate;
            })
            .map(function (condition) {
                return condition.certificate;
            })
            .value();
        ukCertificateGuidanceModal.show(certificates);
    }
}