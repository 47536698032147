var moment = require('moment');

module.exports = [applicationStateService];

function applicationStateService() {

    var service = {
        filter: {
            date: moment().format('YYYY-MM-DD'),
            referenceCountry: 'NL',
            movement: 'import',
            countryCode: null,
            countryName: null,
            additionalCode: null
        },
        uccModeEnabled: 'no',
        remember: {
            country: false
        },
        currentCode: null,
        currentIndentation: null,
        countryHasChanged: countryHasChanged,
        updateCountryFilter: updateCountryFilter
    };

    function updateCountryFilter(country, rememberCountry) {
        if (country && (!service.filter.countryCode || service.filter.countryCode !== country.code)) {
            service.filter.countryCode = country.code;
            service.filter.countryName = country.name;
        }
        else if (!country && service.filter.countryCode) {
            service.filter.countryCode = null;
            service.filter.countryName = null;
        }
        service.remember.country = rememberCountry;
    }

    function countryHasChanged(country) {
        return (country && service.filter.countryCode !== country.code) || (!country && service.filter.countryCode);
    }


    return service;
}