﻿module.exports = [formatTaricCodeFilter];

function formatTaricCodeFilter() {
    return function (input) {
        if (!input)
            return input;
        if (input === null || input === 'undefined' || input.length === 0)
            return input;

        if (input.length > 8)
            input = input.substring(0, 4) + ' ' + input.substring(4, 6) + ' ' + input.substring(6, 8) + ' ' + input.substring(8);
        else if (input.length > 6)
            input = input.substring(0, 4) + ' ' + input.substring(4, 6) + ' ' + input.substring(6);
        else if (input.length > 4)
            input = input.substring(0, 4) + ' ' + input.substring(4);

        return input;
    };}