﻿module.exports = {
    controller: regionOverviewController,
    templateUrl: 'geography/components/region-overview-page.html'
};

regionOverviewController.$inject = [
    '$analytics', 
    '$timeout',
    'notificationsService', 
    'regionDataService',
];

function regionOverviewController(
    $analytics,
    $timeout, 
    notifications, 
    regionData
) {
    
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        regionFilter: '',
        countryFilter: '',
        isLoading: false,
        isLoadingRegion: false,
        countries: [],
        regions: [],
        filteredCountries: [],
        filteredRegions: [],
        selectedRegion: null,
        loadRegion: loadRegion,
        clearRegionFilter: clearRegionFilter,
        changeRegionFilter: changeRegionFilter,
        clearCountryFilter: clearCountryFilter,
        changeCountryFilter: changeCountryFilter,
        focusRegionFilter: focusRegionFilter,
        focusCountryFilter: focusCountryFilter,
    };

    function attached() {
        $analytics.pageTrack('/reference/geographical-areas');
        loadRegions();
        notifications.subscribe('filter_date_changed', loadRegions);
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', loadRegions);
    }

    function clearRegionFilter() {
        clearCountryFilter()
        vm.regionFilter = '';
        vm.filteredRegions = vm.regions;
        vm.countries = [];
        vm.filteredCountries = [];
        focusRegionFilter();
    }

    function changeRegionFilter() {
        vm.filteredRegions = vm.regions.filter(function (el) {
            return el.code.toLowerCase().includes(vm.regionFilter.toLowerCase()) || el.name.toLowerCase().includes(vm.regionFilter.toLowerCase());
        });
        if(vm.filteredRegions.length === 1) {
            loadRegion(vm.filteredRegions[0]);
        }
        if(vm.filteredRegions.length === 0) {
            vm.selectedRegion = null;
            vm.countries = [];
            vm.filteredCountries = [];
            vm.countryFilter = "";
        }
    }

    function clearCountryFilter() {
        vm.countryFilter = '';
        vm.filteredCountries = vm.countries;
        focusCountryFilter();
    }

    function changeCountryFilter() {
        vm.filteredCountries = vm.countries.filter(function (el) {
            return el.code.toLowerCase().includes(vm.countryFilter.toLowerCase()) || el.name.toLowerCase().includes(vm.countryFilter.toLowerCase());
        });
    }

    function loadRegions() {
        vm.isLoading = true;
        vm.countries = [];
        vm.selectedRegion = null;
        vm.regionFilter = '';
        regionData.get()
            .then(function (data) {
                vm.filteredRegions = data;
                vm.regions = data;
                vm.isLoading = false;
                focusRegionFilter();
            });
    }

    function loadRegion(region) {
        vm.isLoadingRegion = true;
        vm.selectedRegion = region.code;
        vm.countryFilter = "";
        regionData.getByCode(region.code)
            .then(function (data) {
                vm.filteredCountries = data;
                vm.countries = data;
                vm.isLoadingRegion = false;
                focusCountryFilter();
            });
    }

    function focusRegionFilter() {
        $timeout(function() {
            document.querySelector('.region-filter').focus();
        },0);
    }

    function focusCountryFilter() {
        $timeout(function() {
            document.querySelector('.country-filter').focus();
        },0);
    }

    return vm;
}
