// See https://app.taricsupport.com/#!/transaction-value-calculator
module.exports = {
    TypeOfCalculator: 'Type of calculator', // Label for radio buttons to select the type of calculator
    Basic: 'Basic', // Basic calculator
    Advanced: 'Advanced', // Advanced calculator

    AddField: 'Add field', // Shown when hovering over a button containing a plus sign. If this button is clicked, a new form (input) field is added.
    RemoveField: 'Remove field', // Shown when hovering over a button containing a delete (X) sign. This button is next to a form (input) field. If this button is clicked, the form field is deleted.
    
    Additions: 'Additions', // Form Header. Additions to the price paid or payable to get to the transaction value

    // The following are from article 71 of the UCC (Regulation (EU) No 952/2013) (32013R0952)
    CommisionsAndBrokerageExceptBuyingCommissions: 'Commissions and brokerage, except buying commisions',
    PackagingCosts: 'Costs of packing and packaging',
    CostOfContainersWhichAreOneWithGoods: 'Cost of containers which are treated as being one, for customs purposes, with the goods in question',
    HelpForCostOfContainersWhichAreOneWithGoods: '<p>General rule 5 for the interpretation of the Combined Nomenclature:</p><ol type="a"><li>camera cases, musical instrument cases, gun cases, drawing-instrument cases, necklace cases and similar containers, specially shaped or fitted to contain a specific article or set of articles, suitable for long-term use and presented with the articles for which they are intended, shall be classified with such articles when of a kind normally sold therewith. This rule does not, however, apply to containers which give the whole its essential character;</li><li>subject to the provisions of rule 5(a), packing materials and packing containers presented with the goods therein shall be classified with the goods if they are of a kind normally used for packing such goods. However, this provision is not binding when such packing materials or packing containers are clearly suitable for repetitive use.</li></ol><p>The terms ‘packing materials’ and ‘packing containers’ mean any external or internal containers, holders, wrappings or supports other than transport devices (for example, transport containers), tarpaulins, tackle or ancillary transport equipment. The term ‘packing containers’ does not cover the containers referred to in general rule 5(a)</p>',
    CostOfPacking: 'Cost of packing, whether for labour or materials',
    GoodsAndServicesProvidedByBuyer: 'Goods and services provided by buyer',
    MaterialsComponentsAndParts: 'Materials, components, parts and similar items incorporated into the imported goods',
    ToolsDiesAndMoulds: 'Tools, dies, moulds and similar items used in the production of the imported goods',
    MaterialsConsumedInProduction: 'Materials consumed in the production of the imported goods',
    EngineeringDevelopmentArtworkDesignPlansAndSketches: 'Engineering, development, artwork, design work, and plans and sketches undertaken elsewhere than in the Union and necessary for the production of the imported goods',
    RoyaltiesAndLicenseFees: 'Royalties and licence fees related to the goods being valued that the buyer must pay, either directly or indirectly, as a condition of sale of the goods being valued',
    ValueOfResaleDisposalOrUseThatAccruesToSeller: 'Value of any part of the proceeds of any subsequent resale, disposal or use of the imported goods that accrues directly or indirectly to the seller',
    DeliveryCosts: 'Delivery costs',
    TransportCostsBeforeEntryIntoCustomsTerritory: 'Cost of transport of the imported goods before their entry into the customs territory of the Union',
    InsuranceCostsBeforeEntryIntoCustomsTerritory: 'Cost of insurance of the imported goods before their entry into the customs territory of the Union',
    LoadingAndHandlingChargesBeforeEntryIntoCustomsTerritory: 'Loading and handling charges associated with the transport of the imported goods before their entry into the customs territory of the Union',
    
    OtherAdditions: 'Other additions', // Form label. Other additions to the price paid or payable to get to the transaction value

    Deductions: 'Deductions', // Form Header. Deductions from the price paid or payable to get to the transaction value
    
    // The following are from article 72 of the UCC (Regulation (EU) No 952/2013) (32013R0952)
    TransportCostsAfterEntryIntoCustomsTerritory: 'The cost of transport of the imported goods after their entry into the customs territory of the Union (NB: this includes related costs, such as handling and insurance costs)',
    InternalNoteForTransportCostsAfterEntryIntoCustomsTerritory: 'TODO: these costs should be added to the base value for VAT calculation (see https://www.belastingdienst.nl/bibliotheek/handboeken/html/boeken/HDU/douanewaarde-bijlage_1_waarden_en_prijzen.html)',
    ChargesForConstructionErectionAssemblyMaintenanceOrTechnicalAssistance: 'Charges for construction, erection, assembly, maintenance or technical assistance, undertaken after the entry into the customs territory of the Union of the imported goods such as industrial plants, machinery or equipment',
    ChargesForInterest: 'Charges for interest under a financing arrangement entered into by the buyer and relating to the purchase of the imported goods',
    ChargesForRightToReproduce: 'Charges for the right to reproduce the imported goods in the Union',
    BuyingCommisions: 'Buying commissions',
    ImportDuties: 'Import duties or other charges payable in the Union by reason of the import or sale of the goods',
    PaymentsMadeForRightToDistributeOrResell: 'Payments made by the buyer for the right to distribute or resell the imported goods, if such payments are not a condition of the sale for export to the Union of the goods',
    
    OtherDeductions: 'Other deductions', // Form label. Other deductions from the price paid or payable to get to the transaction value

    // Article 86.1 UCC (Regulation (EU) No 952/2013) (32013R0952)
    HelpForOtherDeductions: 'For example: costs for storage and for usual forms of handling in the Union during storage in a customs warehouse of a temporary storage facility.',

    Method: 'Method', // Label for radio buttons for choosing how to set the customs value, by calculating it or by entering it
    CalculateValue: 'Calculate value', // Radio button for choosing to calculate the customs value
    EnterValue: 'Enter value', // Radio button for choosing to enter the customs value

    Price: 'Price', // Form Header
    PriceActuallyPaidOrPayable: 'Price actually paid or payable', // Form label
    Result: 'Result', // Result of the customs value calculation
    TransactionValue: 'Transaction value', // Form label
    TransactionValueCalculator: 'Transaction Value Calculator', // Page title; breadcrumb

    
};
