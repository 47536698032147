module.exports = ['$analytics', '$uibModal', additionalCodeSelectorModal];

function additionalCodeSelectorModal($analytics, $uibModal) {
    var modal = {
        show: showModal
    };

    additionalCodeSelectorModalController.$inject = ['$uibModalInstance', 'possibleAdditionalCodes', 'additionalCode'];

    function additionalCodeSelectorModalController($uibModalInstance, possibleAdditionalCodes, additionalCode) {
        var vm = {
            additionalCode: additionalCode,
            cancel: cancel,
            possibleAdditionalCodes: possibleAdditionalCodes,
            ok: ok
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function ok(code) {
            $uibModalInstance.close(code);
        }

        return vm;
    }

    function showModal(possibleAdditionalCodes, additionalCode) {
        $analytics.eventTrack('open-modal-additional-code-selector', { category: 'additional-codes' });

        var modalInstance = $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: additionalCodeSelectorModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                additionalCode: function () {
                    return additionalCode;
                },
                possibleAdditionalCodes: function () {
                    return possibleAdditionalCodes;
                }
            },
            size: 'lg',
            templateUrl: 'additional-codes/modals/additional-code-selector.html',
            windowClass: 'modal-additional-code-selector'
        });

        modalInstance.rendered.then(function() {
            var elementToFocusOnInModal = document.querySelector('.modal.in .focus-on-open-modal');
            if (elementToFocusOnInModal) {
                elementToFocusOnInModal.focus();
            }
        });

        return modalInstance;
    }

    return modal;
}
