﻿module.exports = {
    controller: taricStatusController,
    templateUrl: 'widgets/components/taric-status.html'
};

taricStatusController.$inject = ['taricStatusService'];

function taricStatusController(taricStatusService) {
    var vm = {
        $onInit: attached,
        service: taricStatusService
    };
    return vm;

    function attached() {
        taricStatusService.loadLastSuccessfulDatabase();
    }
}