﻿module.exports = ['$analytics', '$uibModal', orderQuotaModal];

function orderQuotaModal($analytics, $uibModal) {
    var modal = {
        order: null,
        show: showModal
    };

    orderNumberModalController.$inject = ['$translate', '$uibModalInstance', 'orderNumber', 'quotaDataService'];

    function orderNumberModalController($translate, $uibModalInstance, orderNumber, quotaDataService) {

        var translations = [];
        $translate(['shared-ui.Loading', 'taric.InformationForOrderNumber']).then(function (result) {
            translations = result;
        });

        var vm = {
            cancel: cancel,
            chart: {
                colors: ['#B7CDE6'],
                data: null,
                datasetOverride: [{
                    backgroundColor: '#B7CDE6',
                    borderColor: '#667280',
                    borderWidth: 2,
                    lineTension: 0.1,
                    pointBorderColor: '#ffffff',
                    pointBorderWidth: 3,
                    pointRadius: 6,
                    label: 'Balance',
                    pointBackgroundColor: '#667280',
                    type: 'line'
                }],
                labels: [],
                options: null,
                series: ['Series A']
            },
            orderData: null,
            ordernumber: orderNumber,
            title: translations['shared-ui.Loading'],
            isLoading: true
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function init() {
            //$('#orderNumberBalanceChart').show();
            vm.isLoading = true;

            quotaDataService.getOrderDetails(vm.ordernumber)
                .then(function (data) {
                    vm.title = translations['taric.InformationForOrderNumber'] + ' ' + vm.ordernumber;
                    if (data === null || data === 'null') {
                        vm.isLoading = false;
                        return;
                    }

                    vm.orderData = data;

                    for (var i = 0; i < data.balance.length; i++) {
                        data.balance[i].start_occurence_date = data.balance[i].start_occurence_date.substring(8, 10) + '-' + data.balance[i].start_occurence_date.substring(5, 7) + '-' + data.balance[i].start_occurence_date.substring(0, 4);
                    }

                    vm.chart.labels = _.map(data.balance, function (b) { return b.start_occurence_date; });
                    vm.chart.data = [_.map(data.balance, function (b) { return b.new_balance; })];
                    vm.isLoading = false;

                }, function () {
                    vm.title = translations['taric.InformationForOrderNumber'] + ' ' + vm.ordernumber;
                    vm.isLoading = false;
                });

        }

        init();

        return vm;
    }

    function showModal(orderNumber) {
        $analytics.eventTrack('open-modal-quota-details', { category: 'quota' });
        return $uibModal.open({
            backdrop: true,
            controller: orderNumberModalController,
            controllerAs: 'vm',
            keyboard: true,
            modalFade: true,
            resolve: {
                orderNumber: function () {
                    return orderNumber;
                }
            },
            size: 'lg',
            templateUrl: 'quota/modals/order-quota.html',
            windowClass: 'modal-order-number'
        }).result.catch(function (res) {
            if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                throw res;
            }
        });
    }

    return modal;
}
