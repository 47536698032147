﻿module.exports = {
    ATRs: 'ATR\'s',
    AtrReference: 'Nummer ATR',
    attachments: 'Bijlagen',
    commodityDescription: 'Omschrijving goederen',
    copyDescription: 'Deze informatie is een kopie van de informatie in de ATR-database.',
    details: 'Details ATR',
    atrLinkText: 'Klik hier om de meest up-to-date versie te bekijken in deze database.',
    AdvanceTariffRulings: 'Advance Tariff Rulings',
    goodsDescription: 'Omschrijving van de goederen',
    issuedSince: 'Afgegeven sinds',
    Keywords: 'Sleutelwoorden',
    motivation: 'Motivering voor de indeling',
    NOIMAGE: 'GEEN AFBEELDINGEN',
    noLongerValid: 'Deze ATR is niet meer geldig en is daarom niet langer beschikbaar in de ATR-database.',
    notValidOn_referenceDate_: 'niet geldig op {{ referenceDate | date: \'dd-MM-yy\' }}',
    possiblyInvalid: 'NB: in sommige gevallen kan een ATR voor het aflopen van de normale geldigheidsduur ingetrokken worden. Het is daarom mogelijk dat deze ATR ondertussen niet meer geldig is.',
    SearchAdvanceTariffRulings: 'Zoek Advance Tariff Rulings',
    _StartDate_until_ExpiryDate_: '{{ StartDate | date: \'dd-MM-yyyy\' }} t/m {{ ExpiryDate | date: \'dd-MM-yyyy\' }}'
};
