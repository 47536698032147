﻿module.exports = [formatDescriptionFilter];

function formatDescriptionFilter() {
    var replacements = [
        [/\$(.)/g, '<sup>$1</sup>'],
        [/@(.)/g, '<sub>$1</sub>'],
        [/\|/g, '&nbsp;'],
        [/!X!/g, '&times;'],
        [/!x!/g, '&times;'],
        [/!O!/g, '&deg;'],
        [/!o!/g, '&deg;'],
        [/!>=!/g, '&ge;'],
        [/!<=!/g, '&le;'],
    ];
 
    return function (input) {
        if (input === null || input === undefined)
            return input;
        for (var i = 0; i < replacements.length; i++) {
           input = input.replace(replacements[i][0], replacements[i][1]);
        }
        return input;
    };
}