module.exports = [eztExciseCodeTypeaheadFilter];

// Takes care of ordering the results of the excise code typeahed. The logic is as follows:
// First, show the matches on the excise code, ordered by code (Id)
// Next, show the matches on the beginning of the excise code description, ordered by description
// Finally, show the matches anywhere else in the description, ordered by description
function eztExciseCodeTypeaheadFilter() {

    return function (exciseCodes, query) {
        if (query === null || query === undefined || query === '') {
            return _.sortBy(exciseCodes, function(exciseCode) {
                return exciseCode.Id + exciseCode.ProdLin;
            });
        }
        else {
            return _.chain(exciseCodes).filter(function (exciseCode) {
                return exciseCode.ProdLin !== '80' || 
                    exciseCode.Id.toLowerCase().indexOf(query.replace(' ', '').toLowerCase()) >= 0 ||
                    exciseCode.Description.toLowerCase().indexOf(query.toLowerCase()) >= 0;
            }).sortBy(function(exciseCode) {
                return exciseCode.Id + exciseCode.ProdLin;
            }).value();
        }
    }
}


