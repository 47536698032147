﻿module.exports = ['goodDisplayService', 'goodModelsService', measureGroupingService];

function measureGroupingService(goodDisplayService, goodModelsService) {

    // This variable contains the result of the grouping process
    var measureGroupingResult;

    var service = {
        getCertificateGroupsFromConditionGroups: getCertificateGroupsFromConditionGroups,
        group: group,
        groupAntiDumpingMeasures: groupAntiDumpingMeasures,
        groupConditions: groupConditions,
        groupFinancialMeasures: groupFinancialMeasures,
        groupMeasures: groupMeasures,
        groupMeasuresByType: groupMeasuresByType,
        groupVatMeasures: groupVatMeasures,
        sortMeasuresByRegion: sortMeasuresByRegion,
    };

    return service;

    function group(measures, referenceCountry) {

        var measureSeriesIds = _.chain(measures)
            .map(function (measure) {
                return measure.series;
            })
            .uniq()
            .sortBy()
            .value();

        measureGroupingResult = new goodModelsService.measuresDisplayModel();

        _.forEach(measureSeriesIds, function (measureSeriesId) {
            var measuresInMeasureSeries = _.filter(measures, function (measure) {
                return measure.series === measureSeriesId;
            });
            groupMeasuresForMeasureSeries(measuresInMeasureSeries, measureSeriesId, referenceCountry);
        });

        return measureGroupingResult;

    }

    function groupMeasuresForMeasureSeries(measures, measureSeriesId, referenceCountry) {

        let sortedMeasures = service.sortMeasuresByRegion(measures);

        _.forEach(sortedMeasures, function (measure) {
            measure.conditionGroups = service.groupConditions(measure.conditions);
            measure.certificateGroups = service.getCertificateGroupsFromConditionGroups(measure.conditionGroups);

            measure.numberOfCertificates = _.filter(measure.conditions, function (condition) {
                return !!condition.certificate;
            }).length;
        });

        let groupedMeasures = service.groupMeasures(sortedMeasures);

        switch (measureSeriesId) {
            case 'A': {
                let measuresByType = service.groupMeasuresByType(groupedMeasures);
                _.forEach(measuresByType, function (group) {
                    if (group.movement === 'import')
                        measureGroupingResult.import.prohibitions.push(group);
                    else if (group.movement === 'export')
                        measureGroupingResult.export.prohibitions.push(group);
                });
                break;
            }
            case 'B': {
                let measuresByType = service.groupMeasuresByType(groupedMeasures);
                _.forEach(measuresByType, function (group) {
                    if (group.movement === 'import')
                        measureGroupingResult.import.conditions.push(group);
                    else if (group.movement === 'export')
                        measureGroupingResult.export.conditions.push(group);
                    else
                        measureGroupingResult.general.conditions.push(group);
                });
                break;
            }
            case 'C': {
                let financialMeasures = service.groupMeasuresByType(service.groupFinancialMeasures(groupedMeasures));
                _.forEach(financialMeasures, function (group) {
                    switch (group.type_id) {
                        case "102":
                        case "104":
                        case "105":
                            measureGroupingResult.import.tariffs.other.push(group);
                            break;
                        case "103":
                            measureGroupingResult.import.tariffs.drd.push(group);
                            break;
                        case "106":
                        case "140":
                        case "142":
                        case "145":
                            measureGroupingResult.import.tariffs.preferenties.push(group);
                            break;
                        case "112":
                        case "115":
                        case "119":
                        case "141":
                            measureGroupingResult.import.tariffs.schorsingen.push(group);
                            break;
                        case "122":
                        case "123":
                        case "143":
                        case "144":
                        case "146":
                        case "147":
                        case "907":
                            measureGroupingResult.import.tariffs.contingenten.push(group);
                            break;
                        default:
                            measureGroupingResult.import.tariffs.other.push(group);
                            break;
                    }
                });
                break;
            }
            case 'D': {
                sortedMeasures = _.sortBy(sortedMeasures, function (measure) {
                    return measure.region.code + (measure.additional_code === null ? '' : (1000 - parseInt(measure.additional_code.code)));
                });

                let financialMeasures = service.groupAntiDumpingMeasures(sortedMeasures);
                _.forEach(financialMeasures, function (group) {
                    switch (group.type_id) {
                        case "551":
                        case "552":
                        case "553":
                        case "554":
                        case "555":
                            measureGroupingResult.import.antidumping.financieel.push(group);
                            break;
                    }
                });

                var measuresByType = service.groupMeasuresByType(groupedMeasures);
                _.forEach(measuresByType, function (group) {
                    if (['551', '552', '553', '554', '555'].indexOf(group.type_id) === -1) {
                        measureGroupingResult.import.antidumping.nietFinancieel.push(group);
                    }
                });
                break;
            }
            case 'E': {
                let financialMeasures = service.groupMeasuresByType(service.groupFinancialMeasures(groupedMeasures));
                _.forEach(financialMeasures, function (group) {
                    measureGroupingResult.export.restitutions.push(group);
                });
                break;
            }
            case 'F':
            case 'J': {
                let financialMeasures = service.groupMeasuresByType(service.groupFinancialMeasures(groupedMeasures));
                _.forEach(financialMeasures, function (group) {
                    if (group.movement === 'import')
                        measureGroupingResult.import.additionalDuties.push(group);
                    else if (group.movement === 'export')
                        measureGroupingResult.export.additionalDuties.push(group);
                });
                break;
            }
            case 'K':
            case 'M': {
                let financialMeasures = service.groupMeasuresByType(service.groupFinancialMeasures(groupedMeasures));
                _.forEach(financialMeasures, function (group) {
                    measureGroupingResult.import.referencePrices.push(group);
                });
                break;
            }
            case 'N': {
                let measuresByType = service.groupMeasuresByType(groupedMeasures);
                _.forEach(measuresByType, function (group) {
                    if (group.movement === 'import')
                        measureGroupingResult.import.toezichtAchteraf.push(group);
                    else if (group.movement === 'export')
                        measureGroupingResult.export.toezichtAchteraf.push(group);
                });
                break;
            }
            case 'O': {
                let measuresByType = service.groupMeasuresByType(service.groupFinancialMeasures(groupedMeasures));
                _.forEach(measuresByType, function (group) {
                    measureGroupingResult.general.units.push(group);
                });

                break;
            }
            case '4': { // TODO: BE only
                let financialMeasures = service.groupMeasuresByType(service.groupFinancialMeasures(groupedMeasures));
                _.forEach(financialMeasures, function (group) {
                    measureGroupingResult.import.vat.push(group);
                });
                break;
            }
            case 'P': // TODO: NL and GB only
            case 'Q': // TODO: NL and GB only
            case '5': { // TODO: BE only
                if (referenceCountry === 'DE') {
                    if (measureSeriesId === 'P') {
                        measureGroupingResult.import.vatDE = sortedMeasures;
                    }
                    else if (measureSeriesId === 'Q') {
                        measureGroupingResult.import.exciseDE = sortedMeasures;
                    }
                }
                else if (

                    referenceCountry !== 'GB' && 
                    referenceCountry !== 'SE' && 
                    referenceCountry !== 'IT' && 
                    _.filter(sortedMeasures, function (measure) { 
                        return (measure.series === 'P' && measure.additional_code !== null) || 
                            measure.series === 'Q' || measure.series === '5'; 
                    }).length > 0

                    ) {
                    
                    sortedMeasures = measures.sort(function (a, b) {
                        if (a.additional_code == null && b.additional_code != null) {
                            return -1;
                        }
                        else if (a.additional_code != null && b.additional_code == null) {
                            return 1;
                        }
                        else if (a.additional_code == null && b.additional_code == null) {
                            if (a.notes === null && b.notes !== null) {
                                return -1;
                            }
                            else if (a.notes !== null && b.notes === null) {
                                return 1;
                            }
                            else if (a.notes === null && b.notes === null) {
                                return 0;
                            }
                            if (a.notes.length === 0 && b.notes.length !== 0) {
                                return -1;
                            }
                            else if (a.notes.length !== 0 && b.notes.length === 0) {
                                return 1;
                            }
                            else if (a.notes.length === 0 && b.notes.length === 0) {
                                return 0;
                            }
                            if (a.notes[0].type > b.notes[0].type)
                                return 1;
                            else if (a.notes[0].type < b.notes[0].type)
                                return -1;
                            if (a.notes[0].code > b.notes[0].code)
                                return 1;
                            else if (a.notes[0].code < b.notes[0].code)
                                return -1;
                            return 0;
                        }
                        if (a.additional_code.type > b.additional_code.type)
                            return 1;
                        else if (a.additional_code.type < b.additional_code.type)
                            return -1;
                        if (a.additional_code.code > b.additional_code.code)
                            return 1;
                        else if (a.additional_code.code < b.additional_code.code)
                            return -1;
                        return 0;
                    });
                    if (referenceCountry === 'NL') {
                        measureGroupingResult.import.vatExtended = service.groupVatMeasures(sortedMeasures, measureGroupingResult.import.vatExtended);
                    }
                    else { // i.e. referenceCountry === 'BE')
                        measureGroupingResult.import.excise = service.groupVatMeasures(sortedMeasures, measureGroupingResult.import.excise);
                    }
                    _.forEach(measureGroupingResult.import.vatExtended, function (group) {
                        group.measures = group.measures.sort(function (a, b) {
                            return a.description > b.description ? 1 : -1;
                        });
                    });
                }
                else {
                    let financialMeasures = service.groupMeasuresByType(service.groupFinancialMeasures(groupedMeasures));
                    _.forEach(financialMeasures, function (group) {
                        measureGroupingResult.import.vat.push(group);
                    });
                }
                break;
            }
            case 'S': {
                let measuresByType = service.groupMeasuresByType(service.groupFinancialMeasures(groupedMeasures));
                _.forEach(measuresByType, function (group) {
                    measureGroupingResult.import.supplementaryAmount.push(group);
                });

                break;
            }
            default: {
                let measuresByType = service.groupMeasuresByType(groupedMeasures);
                _.forEach(measuresByType, function (group) {
                    if (group.movement === 'import')
                        measureGroupingResult.import.other.push(group);
                    else if (group.movement === 'export')
                        measureGroupingResult.export.other.push(group);
                    else
                        measureGroupingResult.general.other.push(group);
                });
                break;
            }
        }
    }


    function groupAntiDumpingMeasures(measures) {
        _.forEach(measures, function (measure) {
            if (measure.duty === '' && measure.conditions !== null && measure.conditions.length > 0) {
                var duties = [];
                _.forEach(measure.conditions, function (condition) {
                    duties.push(condition.duty);
                });
                measure.duty = _.chain(duties)
                    .uniq()
                    .filter(function(duty){
                        return !!duty;
                    })
                    .value()
                    .join('; ');
            }
        });


        var groups = _.reduce(measures, function (memo, measure) {
            var g = _.find(memo, function (group) {
                return group.description === measure.description &&
                    group.type_id === measure.type_id &&
                    group.duty === measure.duty &&
                    _.isEqual(group.conditions, measure.conditions) &&
                    _.isEqual(group.region, measure.region) &&
                    _.isEqual(group.excluded_regions, measure.excluded_regions) &&
                    _.isEqual(group.notes, measure.notes) &&
                    group.movement === measure.movement;
            });

            if (typeof (g) !== 'undefined') {
                if (g.hasAdditionalCodes === false)
                    g.hasAdditionalCodes = measure.hasAdditionalCodes
                if (g.isExpandable === undefined || g.isExpandable === false)
                    g.isExpandable = measure.conditions !== null && measure.conditions.length > 0;
                g.additional_codes.push(measure.additional_code);
                g.ids += '|' + measure.id;
                g.measures.push(measure);
            }
            else {
                memo.push({
                    ids: '' + measure.id,
                    additional_codes: [measure.additional_code],
                    duty: measure.duty,
                    hasAdditionalCodes: measure.hasAdditionalCodes,
                    isExpandable: measure.conditions !== null && measure.conditions.length > 0,
                    movement: measure.movement,
                    description: measure.description,
                    conditions: measure.conditions,
                    conditionEvaluation: measure.conditionEvaluation,
                    conditionGroups: measure.conditionGroups,
                    certificateGroups: measure.certificateGroups,
                    region: measure.region,
                    excluded_regions: measure.excluded_regions,
                    origin: measure.origin,
                    series: measure.series,
                    type_id: measure.type_id,
                    notes: measure.notes,
                    measures: [measure]
                });
            }
            return memo;
        }, []);

        groups = _.reduce(groups, function (memo, measure) {
            var g = _.find(memo, function (group) {
                return group.description === measure.description &&
                    group.type_id === measure.type_id &&
                    group.movement === measure.movement;
            });

            if (typeof (g) !== 'undefined') {
                if (g.hasAdditionalCodes === false)
                    g.hasAdditionalCodes = measure.hasAdditionalCodes
                if (g.isExpandable === undefined || g.isExpandable === false)
                    g.isExpandable = measure.additional_code !== null || (measure.conditions !== null && measure.conditions.length > 0);
                g.measures.push(measure);
                g.ids += '|' + measure.ids;
            }
            else {
                memo.push({
                    ids: measure.ids,
                    duty: measure.duty,
                    hasAdditionalCodes: measure.hasAdditionalCodes,
                    isExpandable: measure.isExpandable,
                    movement: measure.movement,
                    description: measure.description,
                    conditions: measure.conditions,
                    conditionEvaluation: measure.conditionEvaluation,
                    conditionGroups: measure.conditionGroups,
                    certificateGroups: measure.certificateGroups,
                    region: measure.region,
                    excluded_regions: measure.excluded_regions,
                    origin: measure.origin,
                    series: measure.series,
                    type_id: measure.type_id,
                    measures: [measure]
                });
            }
            return memo;
        }, []);

        return groups;
    }

    // Group conditions by code
    function groupConditions(conditions) {
        var groups = _.reduce(conditions, function (memo, condition) {
            var g = _.find(memo, function (group) {
                return group.code === condition.code;
            });

            if (typeof (g) !== 'undefined') {
                if (g.hasAmountDescription === false)
                    g.hasAmountDescription = (condition.amount_description !== null && condition.amount_description !== '');
                if (g.hasCertificates === false)
                    g.hasCertificates = (condition.certificate !== null);
                g.conditions.push(condition);
            }
            else {
                memo.push({
                    hasAmountDescription: condition.amount_description !== null && condition.amount_description !== '',
                    hasDuty:  condition.duty !== null && condition.duty !== '',
                    hasCertificates: condition.certificate !== null,
                    code: condition.code,
                    description: condition.description,
                    descriptions: goodDisplayService.getFinancialConditionDescription(condition.code),
                    conditions: [condition]
                });
            }
            return memo;
        }, []);

        return groups;
    }

    // Group measures by type, movement and duty
    function groupFinancialMeasures(measures) {
        var groups = _.reduce(measures, function (memo, measure) {
            var g = _.find(memo, function (group) {
                return group.type_id === measure.type_id &&
                    group.movement === measure.movement &&
                    group.duty === measure.duty &&
                    measure.duty !== null && measure.duty !== '';
            });

            if (typeof (g) !== 'undefined') {
                if (g.isExpandable === false)
                    g.isExpandable = measure.isExpandable;
                g.measures.push(measure);
                g.ids += '|' + measure.ids;
            }
            else {
                var grp = {
                    ids: measure.ids,
                    description: measure.description,
                    isExpandable: measure.isExpandable,
                    movement: measure.movement,
                    duty: measure.duty ? measure.duty.replace("euc", "&euro;") : measure.duty,
                    origin: measure.origin,
                    type_id: measure.type_id,
                    measures: [measure]
                };

                memo.push(grp);
            }
            return memo;
        }, []);

        return groups;
    }

    function groupMeasures(measures) {
        var groups = _.reduce(measures, function (memo, measure) {
            var g = _.find(memo, function (group) {

                return group.type_id === measure.type_id &&
                    group.movement === measure.movement &&
                    group.duty === measure.duty &&
                    group.regionType !== '1' && measure.region.type !== '1' &&
                    group.ucc_end_use_procedure_code_44 === measure.ucc_end_use_procedure_code_44 &&
                    _.isEqual(group.conditions, measure.conditions) &&
                    _.isEqual(group.notes, measure.notes) &&
                    _.isEqual(group.ordernumber, measure.ordernumber) &&
                    _.isEqual(group.preference_codes, measure.preference_codes) &&
                    _.isEqual(group.ucc_preference_codes, measure.ucc_preference_codes) &&
                    _.isEqual(group.additional_code, measure.additional_code)
                    ;
            });

            if (typeof (g) !== 'undefined') {
                if (g.hasAdditionalCodes === false)
                    g.hasAdditionalCodes = (measure.additional_code !== null);
                if (g.isExpandable === false)
                    g.isExpandable = measure.additional_code !== null || measure.conditions.length > 0;
                g.ids += '|' + measure.id;
                g.measures.push(measure);
            }
            else {
                var grp = {
                    ids: '' + measure.id,
                    description: measure.description,
                    hasAdditionalCodes: measure.additional_code !== null,
                    isExpandable: measure.additional_code !== null || measure.conditions.length > 0,
                    movement: measure.movement,
                    regionType: measure.region.type,
                    conditions: measure.conditions,
                    conditionEvaluation: measure.conditionEvaluation,
                    conditionGroups: measure.conditionGroups,
                    certificateGroups: measure.certificateGroups,
                    notes: measure.notes,
                    ordernumber: measure.ordernumber,
                    origin: measure.origin,
                    additional_code: measure.additional_code,
                    preference_codes: measure.preference_codes,
                    ucc_preference_codes: measure.ucc_preference_codes,
                    ucc_end_use_procedure_code_44: measure.ucc_end_use_procedure_code_44,
                    duty: measure.duty,
                    series: measure.series,
                    type_id: measure.type_id,
                    // region: measure.region,
                    excluded_regions: measure.excluded_regions,
                    numberOfCertificates: measure.numberOfCertificates,
                    uk_quota_information: measure.uk_quota_information,
                    measures: [measure]
                };

                memo.push(grp);
            }
            return memo;
        }, []);
        groups = _.sortBy(groups, function (group) {
            return (group.region ? group.region.code : '') + (group.additional_code ? group.additional_code.code : '');
        });
        return groups;
    }

    function groupMeasuresByType(measures) {
        var groups = _.reduce(measures, function (memo, measure) {
            var g = _.find(memo, function (group) {
                return group.description === measure.description &&
                    group.type_id === measure.type_id &&
                    group.movement === measure.movement;
            });

            if (typeof (g) !== 'undefined') {
                if (g.hasAdditionalCodes === false)
                    g.hasAdditionalCodes = measure.hasAdditionalCodes
                if (g.isExpandable === false)
                    g.isExpandable = measure.isExpandable;
                g.measures.push(measure);
                g.ids += '|' + measure.ids;
            }
            else {
                var grp = {
                    ids: measure.ids,
                    hasAdditionalCodes: measure.hasAdditionalCodes,
                    isExpandable: measure.isExpandable,
                    movement: measure.movement,
                    description: measure.description,
                    origin: measure.origin,
                    type_id: measure.type_id,
                    measures: [measure]
                };

                memo.push(grp);
            }
            return memo;
        }, []);

        return groups;
    }

    // Group measures by additional code
    function groupVatMeasures(measures, previouslyGroupedMeasures) {
        if (!previouslyGroupedMeasures) {
            previouslyGroupedMeasures = [];
        }
        var groups = _.reduce(measures, function (memo, measure) {
            var g = _.find(memo, function (group) {
                if (group.additional_code === null || measure.additional_code === null) {
                    return group.additional_code === null && measure.additional_code === null;
                }
                return group.additional_code.type === measure.additional_code.type &&
                    group.additional_code.code === measure.additional_code.code;
            });

            if (typeof (g) !== 'undefined') {
                g.measures.push(measure);
            }
            else {
                memo.push({
                    additional_code: measure.additional_code,
                    hasAdditionalCodes: !!measure.additional_code,
                    origin: measure.origin,
                    measures: [measure]
                });
            }
            return memo;
        }, previouslyGroupedMeasures);

        return groups;
    }

    function sortMeasuresByRegion(measures) {
        return measures.sort(function (a, b) {
            if (a.region.code > b.region.code) {
                return 1;
            }
            if (a.region.code < b.region.code) {
                return -1;
            }
            return 0;
        });
    }

    function getCertificateGroupsFromConditionGroups(conditionGroups) {
        return _.chain(conditionGroups)
            .map(function(conditionGroup) {
                return _.chain(conditionGroup.conditions)
                    .filter(function (condition) {
                        return !!condition.certificate;
                    })
                    .map(function (condition) {
                        return condition.certificate;
                    })
                    .value();
            })
            .filter(function (certificateGroup) {
                return certificateGroup.length > 0;
            })
            .value();
    }

}
