﻿angular.module('taric.compliance', [])
    .config(['$routeProvider', routeConfigurator])
    .component('complianceDetailsPage', require('./components/compliance-details-page'))
    .component('complianceNewPage', require('./components/compliance-new-page'))
    .component('complianceOverviewPage', require('./components/compliance-overview-page'))
    .factory('complianceDataService', require('./services/compliance-data'))
    .factory('complianceInfoModal', require('./modals/compliance-info'))
    .factory('complianceModalsService', require('./modals/modals'))
    .filter('jobStatusDisplay', require('./filters/job-status-display'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/compliance-check', {
            template: '<compliance-overview-page></compliance-overview-page>'
        })
        .when('/compliance-check/new', {
            template: '<compliance-new-page></compliance-new-page>'
        })
        .when('/compliance-check/:jobId', {
            template: '<compliance-details-page></compliance-details-page>'
        })
        ;
}