﻿module.exports = {
    bindings: {
        isLarge: '<',
        item: '<'
    },
    controller: atrButtonController,
    templateUrl: 'atr/components/atr-button.html'
};

atrButtonController.$inject = ['common', 'atrModal'];

function atrButtonController(common, atrModal) {
    
    var vm = {
        showAtrs: showAtrs
    };

    function showAtrs() {
        var numberPattern = /^\s*(?:\d{2}[\s.]*){1,5}$/;

        var code = common.formatting.removeTrailingZeros(vm.item.code);

        if (numberPattern.test(vm.item.searchQuery))
            atrModal.show(code, '');
        else
            atrModal.show(code, vm.item.searchQuery);
    }


    return vm;

}

