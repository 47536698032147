module.exports = {
    TypeOfCalculator: 'Type calculator',
    Basic: 'Eenvoudig',
    Advanced: 'Uitgebreid',
    AddField: 'Veld toevoegen',
    RemoveField: 'Veld verwijderen',
    
    Additions: 'Verhogingen',
    
    CommisionsAndBrokerageExceptBuyingCommissions: 'Commissies en courtage, met uitzondering van inkoopcommissies',
    PackagingCosts: 'Kosten verpakking',
    CostOfContainersWhichAreOneWithGoods: 'Kosten van verpakkingsmiddelen die voor douanedoeleinden worden geacht met de goederen één geheel te vormen',
    HelpForCostOfContainersWhichAreOneWithGoods: '<p>Algemene regel 5 voor de interpretatie van de gecombineerde nomenclatuur:</p><ol type="a"><li>etuis, foedralen en koffers voor camera\'s, voor muziekinstrumenten of voor wapens, dozen voor tekeninstrumenten, juwelenkistjes en dergelijke bergingsmiddelen, speciaal gevormd of ingericht voor het opbergen van een bepaald artikel of van een stel of assortiment van artikelen, geschikt voor herhaald gebruik en aangeboden met de artikelen waarvoor ze bestemd zijn, worden ingedeeld onder dezelfde post als die artikelen indien zij van de soort zijn die normaal daarmee wordt verkocht. Deze regel geldt echter niet voor bergingsmiddelen die aan het geheel het wezenlijk karakter verlenen;</li><li>behoudens het bepaalde onder 5 a) worden gevulde verpakkingsmiddelen ingedeeld met de verpakte goederen indien zij van de soort zijn die normaal als verpakking voor die goederen wordt gebruikt. Deze regel is echter niet verplichtend voor verpakkingsmiddelen die klaarblijkelijk geschikt zijn voor herhaald gebruik.</li></ol><p>Onder „verpakkingsmiddelen” worden verstaan, alle uitwendige en inwendige bergingsmiddelen, omhulsels, opwindmiddelen en dergelijke voorzieningen, met uitsluiting van vervoermiddelen — met name containers —, dekkleden en het stuw- en hulpmateriaal. Hieronder worden echter niet de in algemene regel 5, onder a), bedoelde bergingsmiddelen verstaan.</p>',
    CostOfPacking: 'Kosten van het verpakken, waaronder zowel het arbeidsloon als het materiaal is begrepen',
    GoodsAndServicesProvidedByBuyer: 'Goederen en diensten geleverd door koper',
    MaterialsComponentsAndParts: 'Materialen, samenstellende delen, onderdelen en dergelijke die in de ingevoerde goederen worden verwerkt',
    ToolsDiesAndMoulds: 'Werktuigen, matrijzen, gietvormen en dergelijke voorwerpen die bij de voortbrenging van de ingevoerde goederen worden gebruikt',
    MaterialsConsumedInProduction: 'Materialen die bij de voortbrenging van de ingevoerde goederen worden verbruikt',
    EngineeringDevelopmentArtworkDesignPlansAndSketches: 'Engineering, ontwikkeling, werken van kunst, ontwerpen, en tekeningen en schetsen die buiten de Unie zijn verricht of vervaardigd en die noodzakelijk zijn voor de productie van de ingevoerde goederen',
    RoyaltiesAndLicenseFees: 'Royalty\'s en licentierechten met betrekking tot de goederen waarvan de waarde wordt bepaald, die de koper als voorwaarde voor de verkoop van deze goederen direct of indirect moet betalen',
    DeliveryCosts: 'Leveringskosten',
    ValueOfResaleDisposalOrUseThatAccruesToSeller: 'Waarde van elk deel van de opbrengst van elke latere wederverkoop, overdracht of gebruik van de ingevoerde goederen dat de verkoper direct of indirect ten goede komt',
    TransportCostsBeforeEntryIntoCustomsTerritory: 'Vervoerskosten vóór binnenkomst in douanegebied van de Unie',
    InsuranceCostsBeforeEntryIntoCustomsTerritory: 'Verzekeringskosten vóór binnenkomst in douanegebied van de Unie',
    LoadingAndHandlingChargesBeforeEntryIntoCustomsTerritory: 'Kosten van het laden en behandelen van de ingevoerde goederen die verband houden met het vervoer vóór binnenkomst in douanegebied van de Unie',
    OtherAdditions: 'Overige verhogingen',
    
    Deductions: 'Verlagingen',
    
    TransportCostsAfterEntryIntoCustomsTerritory: 'Vervoerskosten na binnenkomst in douanegebied van de Unie en aanverwante kosten, zoals de buitenboordlossingskosten, verzekeringskosten, kosten voor het laden, kosten voor handling en de kosten voor het brengen in het vrije verkeer (documentkosten)',
    ChargesForConstructionErectionAssemblyMaintenanceOrTechnicalAssistance: 'Kosten van constructiewerkzaamheden, installatie, montage, onderhoud of technische bijstand welke met betrekking tot ingevoerde goederen, zoals industriële installaties, machines of materieel, na de binnenkomst ervan in het douanegebied van de Unie zijn verricht',
    ChargesForInterest: 'Te betalen rente uit hoofde van een door de koper in verband met de aankoop van ingevoerde goederen gesloten financieringsovereenkomst',
    ChargesForRightToReproduce: 'Kosten ter verkrijging van het recht tot verveelvoudiging van de in de Unie ingevoerde goederen',
    BuyingCommisions: 'Inkoopcommissies',
    ImportDuties: 'Rechten bij invoer en andere belastingen die in de Unie vanwege de invoer of de verkoop van de goederen dienen te worden voldaan',
    PaymentsMadeForRightToDistributeOrResell: 'Door de koper verrichte betalingen ter verkrijging van het recht tot distributie of wederverkoop van de ingevoerde goederen, indien deze betalingen geen voorwaarde zijn voor de verkoop van de goederen voor uitvoer naar de Unie',
    OtherDeductions: 'Overige verlagingen',

    // Article 86.1 UCC
    HelpForOtherDeductions: 'Bijvoorbeeld: kosten voor opslag en voor gebruikelijke behandelingen in de Unie gedurende de opslag in een douane-entrepot of een ruimte voor tijdelijke opslag.',

    Method: 'Methode',
    CalculateValue: 'Waarde berekenen',
    EnterValue: 'Waarde invoeren',

    Price: 'Prijs',
    PriceActuallyPaidOrPayable: 'Werkelijk betaalde of te betalen prijs',
    Result: 'Resultaat',
    TransactionValue: 'Transactiewaarde',
    TransactionValueCalculator: 'Calculator transactiewaarde'
};
