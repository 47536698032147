﻿// See https://app.taricsupport.com/#!/good/0702000007
// and press on one of the links marked "order"
// See https://ec.europa.eu/taxation_customs/dds2/taric/quota_consultation.jsp?Origin=&Status=&Year=2024&Screen=0&europaPath=europa&logoPath=europa%2fecl%2fimages%2flogo&Expand=true&Critical=&Lang=it&Code=&Offset=0
module.exports = {
    orderNumberPrefix: 'order:',
    orderNumber: {
        balance: 'Balance',
        changeDate: 'Change date',
        critical: 'Critical',
        currentVolume: 'Available volume',
        endDate: 'End date',
        initialVolume: 'Initial volume',
        measurementUnit: 'Measurement unit',
        noQuota: 'No quota could be found for this ordernumber.',
        noStatus: 'No status information available',
        periodStartDate: 'Period start date',
        region: 'Geographical area',
        startDate: 'Start date',
        status: 'Status'
    },
    quotaStatus: {
        block: 'Blocked',
        critical: 'Critical',
        exhaustion: 'Exhausted',
        reopening: 'Reopened',
        resume: 'Resumed',
        start: 'Started',
        suspension: 'Suspended',
        unblocking: 'Unblocked',
        unsuspension: 'Unsuspended'
    }
};
