module.exports = {
    controller: correlationTableController,
    templateUrl: 'correlation-table/components/correlation-table-page.html'
};

correlationTableController.$inject = ['$analytics', '$routeParams'];

function correlationTableController($analytics, $routeParams) {

    var vm = {
        $onInit: attached,
        activeSubnav: $routeParams.activeSubnav
    };

    function attached() {
        $analytics.pageTrack('/news/correlation-table');
    }
    
    return vm;
}
