module.exports = {
    controller: searchEuExplanatoryNotesController,
    templateUrl: 'search/components/pages/search-eu-explanatory-notes.html'
};

searchEuExplanatoryNotesController.$inject = [
    '$analytics', 
    '$location', 
    'notificationsService', 
    'dataContext', 
    'language', 
    'config', 
    'applicationStateService',
];

function searchEuExplanatoryNotesController(
    $analytics, 
    $location, 
    notificationsService, 
    dataContext, 
    language, 
    config, 
    state
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        isLoading: false,
        query: {
            QueryString: ''
        },
        results: [],
        scrollToDocument: scrollToDocument,
        search: search
    };

    return vm;

    function attached() {
        vm.query.QueryString = $location.search().query;
        $analytics.pageTrack('/eu-explanatory-notes-search');
        loadSearch();
        notificationsService.subscribe('filter_date_changed', loadSearch);
    }

    function detached() {
        notificationsService.unsubscribe('filter_date_changed', loadSearch);
    }

    function search(query) {
        $location.path('/search/explanatory-notes/eu').search(query);
    }

    function scrollToDocument(explanatoryNote) {
        var selector = '.explanatory-note-EU-' + explanatoryNote.NomenclatureLevel + '-' + explanatoryNote.GoodsCodeOrSectionNumber;
        document
            .querySelector(selector)
            .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }


    function loadSearch() {
        var url = config.apiHosts.generic + '/explanatory-notes/eu/search' +
            '?lang=' + language.get() +
            '&q=' + vm.query.QueryString +
            '&date=' + state.filter.date;
        if (vm.query.QueryString && vm.query.QueryString.length > 1) {
            vm.isLoading = true;
            dataContext.get(url)
                .then(function (data) {
                    vm.isLoading = false;
                    vm.results = data;
                });
        }
    }
}