﻿module.exports = {
    BindingTariffInformation: 'Informazioni Tariffarie Vincolanti',
    BtiFirstLetter: 'I',
    Btis: 'ITV',
    BtiReference: 'Riferimento ITV',
    // copyDescription: 'This information is a copy of the information in the EU EBTI-database.',
    countryOfOrigin: 'Paese che ha fornito l\'ITV / Lingua',
    dateAndPlaceOfIssue: 'Luogo e data di rilascio',
    details: 'Dettagli dell\'ITV',
    // ebtiLinkText: 'Click here to see the up-to-date version in this database.',
    EuropeanBindingTariffInformation: 'Informazioni Tariffarie Vincolanti Europee',
    goodsDescription: 'Descrizione della merce',
    issuedSince: 'Rilasciate a partire dal',
    Keywords: 'Parole chiave nazionali',
    motivation: 'Motivazione della classificazione',
    nationalKeywords: 'Parole chiave nazionali',
    // NOIMAGE: 'NO IMAGE', // Shown instead of an image when no image is available
    // noLongerValid: 'This BTI is no longer valid and can therefore no longer be found in the EU EBTI-database.',
    // notValidOn_referenceDate_: 'not valid on {{ referenceDate | date: \'dd-MM-yy\' }}',
    // possiblyInvalid: 'Note: in some cases, such as the publication of a classification regulation, BTIs may be revoked before their normal period of validity has expired. Hence, it is possible that this BTI is no longer valid.',
    PageTitleSearchBtis: 'Ricerca ITV',
    // _btiCount_bindingTariffInformationDecisionsFound: '{{ btiCount }} binding tariff information decision(s) found',
    // _StartDate_until_EndDate_: '{{ StartDate | date: \'dd-MM-yyyy\' }} until {{ EndDate | date: \'dd-MM-yyyy\' }}'
};
