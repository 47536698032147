var moment = require('moment');

module.exports = {
    controller: referenceDatePickerController,
    templateUrl: 'reference-date/components/reference-date-picker.html'
};

referenceDatePickerController.$inject = ['referenceDateService', 'applicationStateService'];

function referenceDatePickerController(referenceDateService, state) {
    var vm = {
        $onInit: attached,
        blur: blur,
        dateChanged: dateChanged,
        dateOptions: {
            minDate: moment('1972-01-01').toDate()
        },
        format: 'd!-M!-yyyy',
        popup: {
            opened: false,
        },
        open: open,
        query: {
            date: getDate()
        }
    };

    function attached() {
        if (state.filter.referenceCountry === 'CH') {
            vm.dateOptions.minDate = moment('2022-01-01').toDate();
        }
    }

    function getDate() {
        return referenceDateService.getReferenceDate();
    }

    function dateChanged() {
        if (state.filter.referenceCountry === 'CH' && moment(vm.query.date).isBefore('2022-01-01')) {
            return;
        }
        referenceDateService.setReferenceDate(vm.query.date);
    }

    function blur() {
        if (!vm.query.date) {
            vm.query.date = getDate()
        }
    }

    function open() {
        vm.popup.opened = true;
    }

    return vm;
}
