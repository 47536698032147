﻿module.exports = ['common', 'dataContext', 'applicationStateService', additionalCodesDataService];

function additionalCodesDataService(common, context, state) {

    var service = {
        additionalCodes: [],
        getByType: getByType,
        getTypes: getTypes
    };

    function getByType(additionalCodeType) {
        var url = common.config.apiHosts[common.config.currentApi] + '/additionalcodes' +
            '?type=' + additionalCodeType +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    function getTypes() {
        var url = common.config.apiHosts[common.config.currentApi] + '/additionalcodes/types' +
            '?origin=EU,' + state.filter.referenceCountry +
            '&lang=' + common.language.get() +
            '&referencecountry=' + state.filter.referenceCountry +
            '&date=' + state.filter.date;

        return context.get(url);
    }

    return service;

}
