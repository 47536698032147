module.exports = {
    bindings: {
        fieldName: '@',
        fieldType: '@',
        label: '@',
        onClickHelpText: '@'
    },
    controller: transactionValueComponentController,
    templateUrl: 'customs-value/components/transaction-value-component.html'
};

transactionValueComponentController.$inject = ['transactionValueDataService'];

function transactionValueComponentController(transactionValueDataService) {
    var vm = {
        data: transactionValueDataService.data,
        showOnClickHelpText: false,
        addField: addField,
        removeField: removeField,
        onChangeTransactionValue: onChangeTransactionValue
    };

    return vm;

    function addField(fieldType, fieldName) {
        transactionValueDataService.addField(fieldType, fieldName);
    }
    
    function removeField(fieldType, fieldName, index) {
        transactionValueDataService.removeField(fieldType, fieldName, index);
    }
    
    function onChangeTransactionValue() {
        transactionValueDataService.calculateResult();
    }
}

