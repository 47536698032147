module.exports = {
    bindings: { 
        additionalCode: '<', 
        placeholder: '<', 
        possibleAdditionalCodes: '< ', 
        selectionChanged: '&' 
    },
    controller: additionalCodePickerController,
    templateUrl: 'additional-codes/components/additional-code-picker.html'
};

additionalCodePickerController.$inject = ['additionalCodeSelectorModal'];

function additionalCodePickerController(additionalCodeSelectorModal) {
    var vm = {
        clear: clear,
        additionalCode: null,
        userInput: null,
        possibleAdditionalCodes: null,
        itemSelected: itemSelected,
        onBlur: onBlur,
        onEnter: onEnter,
        showModal: showModal
    };

    return vm;

    function showModal() {
        additionalCodeSelectorModal
            .show(vm.possibleAdditionalCodes, vm.additionalCode)
            .result
            .then(itemSelected)
            .catch(function (res) {
                if (!(res === 'cancel' || res === 'escape key press' || res === 'backdrop click')) {
                    throw res;
                }
            });
    }

    function clear(ev) {
        // console.log('clear');
        vm.userInput = null;
        clearSelection();
    }

    function clearSelection() {
        // console.log('clearSelection');
        vm.additionalCode = null;
        // state.filter.countryCode = null;
        // state.filter.countryName = null;
        if (vm.selectionChanged)
            vm.selectionChanged({ additionalCode: null });

    }

    function onBlur() {
        // console.log('onBlur', arguments, vm.userInput);
        // vm.additionalCode = _.chain(vm.possibleAdditionalCodes)
        //     .find(function(additionalCode) {
        //         return additionalCode.type + additionalCode.code === vm.userInput;
        //     })
        //     .value();
        // if (vm.additionalCode && vm.selectionChanged) {
        //     vm.selectionChanged({ additionalCode: vm.additionalCode });
        // }
        // if (state.filter.countryCode && !vm.country) {
        //     clearSelection();
        // }
    }

    function onEnter() {
        // console.log('onEnter', arguments);
        // if (state.filter.countryCode && !vm.country) {
        //     clearSelection();
        // }
    }

    function itemSelected(item, model) {
        // console.log('itemSelected', item, model, vm.userInput);
        vm.additionalCode = item;
        // if (item && item.code && item.code !== state.filter.countryCode) {
        //     state.filter.countryCode = item.code;
        //     state.filter.countryName = item.name;


        if (vm.selectionChanged)
            vm.selectionChanged({ additionalCode: item });
        // }
        // else if (state.filter.countryCode !== null) {
        //     clearSelection();
        // }
    }
}

