﻿module.exports = {
    bindings: {
        group: '<'
    },
    controller: groupedGeneralMeasuresController,
    templateUrl: 'good/components/grouped-general-measures.html'
};

groupedGeneralMeasuresController.$inject = ['goodDisplayService'];
function groupedGeneralMeasuresController(goodDisplayService) {
    var vm = {
        setExpanded: setExpanded
    };

    function setExpanded(val) {
        if (val) {
            goodDisplayService.expandMeasures(vm.group.measures);
        }
        else {
            goodDisplayService.collapseMeasures(vm.group.measures);
        }
    }

    return vm;
}