﻿module.exports = [convertToNumberDirective];

function convertToNumberDirective() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                if (!val) {
                    return val;
                }
                val = replaceCommaByDot(val);
                if (!isNumericOrNumber(val)) {
                    return null;
                }
                var result = parseFloat(val, 10);
                // console.log(val, result);
                // ngModel.$setViewValue(result);
                // ngModel.$render();
                return result;
            });
            ngModel.$formatters.push(function (val) {
                if (!val) {
                    return val;
                }
                return replaceDotByComma('' + val);
            });
        }
    };
}

function replaceCommaByDot(str) {
    if (!str) {
        return str;
    }
    if (typeof str !== "string") {
        return str;
    }
    return str.replace(/,/g, '.');
}

function removeDot(str) {
    if (!str) {
        return str;
    }
    if (typeof str !== "string") {
        return str;
    }
    return str.replace(/\./g, '');
}

function replaceDotByComma(str) {
    if (!str) {
        return str;
    }
    if (typeof str !== "string") {
        return str;
    }
    return str.replace(/\./g, ',');
}

function isNumericOrNumber(input) {
    return _.isNumber(input) || isNumeric(input);
}

function isNumeric(str) {
    if (typeof str != "string") {
        return false;  
    }
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
}
