﻿// To be translated by Taric Support and reviewed by translation agency
module.exports = {
    tabs: {
        Measures: 'Measures',
        ValidateDeclaration: 'Validate declaration',
        CalculateDuty: 'Calculate duties'
    },
    form: {
        legendValue: 'Value',
        radioMethodLabel: 'Method',	
        radioMethodOptionCalculateValue: 'Calculate value',
        radioMethodOptonEnterValue: 'Enter value'
    },
    financialConditions: {
        E: {
            title: 'The quantity or the price per unit declared, as appropriate, is equal or less than the specified maximum, or presentation of the required document',
            headers: {
                col1: 'Quantity or price per unit',
                col2: 'Tariff'
            }
        },
        F: {
            title: 'The net free at frontier price before duty must be equal to or greater than the minimum price',
            headers: {
                col1: 'Net free price at frontier, before duty',
                col2: 'Tariff'
            }
        },
        L: {
            title: 'CIF price must be higher than the minimum price',
            headers: {
                col1: 'CIF price',
                col2: 'Tariff'
            }
        },
        M: {
            title: 'Import price must be equal to or greater than the minimum price/reference price',
            headers: {
                col1: 'Import price',
                col2: 'Tariff'
            }
        },
        V: {
            title: 'Import price must be equal to or greater than the entry price',
            headers: {
                col1: 'Import price',
                col2: 'Tariff'
            }
        }
    },
    meursing: {
        calculateDescription: 'Fill in the information below to calculate the additional code for the agricultural element.',
        calculateTitle: 'Calculate additional code agricultural element',
        impossibleCombination: 'Invalid combination of ingredients (total weight over 100%)',
        milkfat: 'Milkfat (% weight)',
        milkproteins: 'Milkproteins (% weight)',
        saccharin: 'Sucrose/invert sugar/isoglucose (% weight)',
        starchGlucose: 'Starch/glucose (% weight)',
    }
};
