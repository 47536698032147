﻿module.exports = ['$http', versionHistoryService];

function versionHistoryService($http) {
	var service = {
        get: get
	};

    function get() {
		return $http.get('/version-history.json');
	}

	return service;
}
