module.exports = [orderNumberTypeaheadFilter];

// Takes care of ordering the results of the order number typeahead.
// It returns the matched order numbers, sorted
function orderNumberTypeaheadFilter() {


    return function (orderNumbers, query) {
        if (query === null || query === undefined)
            return orderNumbers;

        var orderNumberMatchPartition = _.partition(orderNumbers, function(orderNumber) {
            return orderNumber.toLowerCase().indexOf(query) === 0;
        });

        var startMatches = _.sortBy(orderNumberMatchPartition[0], function(orderNumber) {
            return orderNumber;
        });

        var otherMatchesPartition = _.partition(orderNumberMatchPartition[1], function(orderNumber) {
            return orderNumber.toLowerCase().indexOf(query) > 0;
        });

        var otherMatches = _.sortBy(otherMatchesPartition[0], function(orderNumber) {
            return orderNumber;
        });


        var result = _.concat(startMatches, otherMatches);

        return result;
    }
}
