angular.module('taric.links', [])
    .config(['$routeProvider', routeConfigurator])
    .component('caseLawLinkPage', require('./components/case-law-link-page'))
    .component('eurLexLinkPage', require('./components/eur-lex-link-page'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/links/case-law/:origin/:idType/:id', {
            template: '<case-law-link-page></case-law-link-page>'
        })
        .when('/links/eur-lex/:idType/:id', {
            template: '<eur-lex-link-page></eur-lex-link-page>'
        })
        ;
}