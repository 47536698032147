﻿var moment = require('moment');

module.exports = [formatting];

function formatting() {
    var service = {
        cleanGoodsCode: cleanGoodsCode,
        flattenArray: flattenArray,
        formatDate: formatDate,
        formatDateFromNow: formatDateFromNow,
        goodsCodeToGoodsnomId: goodsCodeToGoodsnomId,
        isChapterCode: isChapterCode,
        isCommodityCode: isCommodityCode,
        isHeadingCode: isHeadingCode,
        isNullOrEmpty: isNullOrEmpty,
        padLeft: padLeft,
        padRight: padRight,
        removeTrailingZeros: removeTrailingZeros,
        replaceAll: replaceAll,
        romanToNumber: romanToNumber,
        toChapterNumber: toChapterNumber
    };

    return service;

    function cleanGoodsCode(goodsCode) {
        if (!goodsCode) return null;
        return replaceAll(goodsCode, '[\\s.,]', '');
    }

    function flattenArray(item, elemName) {
        var output = [];

        if (item[elemName] !== null && item[elemName] !== undefined && item[elemName].length > 0) {
            for (var i = 0; i < item[elemName].length; i++) {
                output.push(item[elemName][i]);
                var subItems = flattenArray(item[elemName][i], elemName);
                output = output.concat(subItems);
            }
        }
        return output;
    }

    function formatDate(date) {
        return moment(date).format('DD-MM-YYYY HH:mm');
    }

    function formatDateFromNow(date, useAgo) {
        if (useAgo === undefined || useAgo === null)
            useAgo = false;
        return moment(date).fromNow(useAgo);
    }

    function goodsCodeToGoodsnomId(goodsCode) {
        if (!goodsCode) return null;
        return padRight(replaceAll(goodsCode, '[\\s.,]', ''), 10);
    }

    function isChapterCode(code) {
        if (!code) return false;
        var goodsnomid = goodsCodeToGoodsnomId(code);
        return /[0-9]{10}/.test(goodsnomid) && goodsnomid.substring(2) === '00000000';
    }

    function isHeadingCode(code) {
        if (!code) return false;
        var goodsnomid = goodsCodeToGoodsnomId(code);
        return /[0-9]{10}/.test(goodsnomid) && goodsnomid.substring(2) !== '00000000' && goodsnomid.substring(4) === '000000';
    }

    function isCommodityCode(code) {
        if (!code) return false;
        var goodsnomid = goodsCodeToGoodsnomId(code);
        return /[0-9]{10}/.test(goodsnomid);
    }

    function isNullOrEmpty(source) {
        return (source === undefined ||
            source === null ||
            source === '');
    }

    function padLeft(n, width, z) {
        if (!n) return null;
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    function padRight(n, width, z) {
        if (!n) return null;
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : n + new Array(width - n.length + 1).join(z);
    }

    function removeTrailingZeros(goodsCode) {
        if (!goodsCode) return null;
        var result = goodsCode.replace(/0+$/, '');
        return result.length % 2 === 0 ? result : result + '0';
    }

    function replaceAll(str, search, replacement) {
        return str.replace(new RegExp(search, 'g'), replacement);
    }

    function romanToNumber(roman) {
        switch (roman.toString().toLowerCase()) {
            case 'i':
                return '01';
            case 'ii':
                return '02';
            case 'iii':
                return '03';
            case 'iv':
                return '04';
            case 'v':
                return '05';
            case 'vi':
                return '06';
            case 'vii':
                return '07';
            case 'viii':
                return '08';
            case 'ix':
                return '09';
            case 'x':
                return '10';
            case 'xi':
                return '11';
            case 'xii':
                return '12';
            case 'xiii':
                return '13';
            case 'xiv':
                return '14';
            case 'xv':
                return '15';
            case 'xvi':
                return '16';
            case 'xvii':
                return '17';
            case 'xviii':
                return '18';
            case 'xix':
                return '19';
            case 'xx':
                return '20';
            case 'xxi':
                return '21';
            default:
                return roman;
        }
    }

    function toChapterNumber(code) {
        if (!code) return null;
        if (code.substring(0, 1) === '0') {
            return code.substring(1, 2);
        }
        return code.substring(0, 2);
    }
}
