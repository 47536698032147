module.exports =  {
    bindings: {},
    controller: btiListController,
    templateUrl: 'bti/components/bti-list.html'
}

btiListController.$inject = ['btiDataService'];

function btiListController(btiDataService) {
    var vm = {
        service: btiDataService,
        changeActiveBti: btiDataService.changeActiveBti,
        isLoading: false,
        loadMore: loadMore
    };
    return vm;

    function loadMore() {
        vm.isLoading = true;
        btiDataService.loadMoreBtis().then(function () {
            vm.isLoading = false;
        });
    }
}