module.exports = [serializationService];

function serializationService() {
    var service = {
        serializeCustomsValue: serializeCustomsValue,
        serializeQuantities: serializeQuantities
    };
    return service;

    function serializeCustomsValue(customsValue) {
        if (customsValue.amount === null || customsValue.amount === undefined || customsValue.unit === null || customsValue.unit === undefined || customsValue.unit === '') {
            return '';
        }
        return customsValue.amount + ' ' + customsValue.unit;
    }

    function serializeQuantities(quantities) 
    {
        var serializedQuantities = _.chain(quantities)
            .filter(function(quantity) {
                return quantity !== null && quantity !== undefined && quantity.Amount !== null && quantity.Amount !== undefined && quantity.Amount !== "";
            })
            .map(function(quantity) {
                var result = quantity.Amount + ' ' + quantity.Unit.code;
                if (quantity.Qualifier) {
                    result += ' ' + quantity.Qualifier.code;
                }
                return result;
            })
            .value();
        return serializedQuantities;
    }
}