var moment = require('moment');

module.exports = ['common', 'dataContext', 'applicationStateService', transactionValueDataService];

function transactionValueDataService(common, context, state) {

    var service = {
        data: {
            monetaryUnit: getDefaultMonetaryUnit(),
            transactionValue: null,
        },
        addField: addField,
        removeField: removeField,
        calculateResult: calculateResult,
        initTransactionValue: initTransactionValue
    };

    initTransactionValue();

    return service;

    function addField(fieldType, fieldName) {
        service.data.transactionValue[fieldType][fieldName].push(initializeMonetaryValue());
    }

    function removeField(fieldType, fieldName, index) {
        service.data.transactionValue[fieldType][fieldName] = _.filter(
            service.data.transactionValue[fieldType][fieldName], function(el, idx) { 
            return idx != index 
        });
        calculateResult();
    }

    function initTransactionValue() {
        service.data.transactionValue = {
            priceActuallyPaidOrPayble: initializeMonetaryValue(),
            additions: {
                commissionsAndBrokerage: [initializeMonetaryValue()],
                costOfContainersWhichAreOneWithGoods: [initializeMonetaryValue()],
                costOfPacking: [initializeMonetaryValue()],
                materialsComponentsAndParts: [initializeMonetaryValue()],
                toolsDiesAndMoulds: [initializeMonetaryValue()],
                materialsConsumedInProduction: [initializeMonetaryValue()],
                engineeringDevelopmentArtworkDesignPlansAndSketches: [initializeMonetaryValue()],
                royaltiesAndLicenseFees: [initializeMonetaryValue()],
                valueOfResaleDisposalOrUseThatAccruesToSeller: [initializeMonetaryValue()],
                transportCostsBeforeEntryIntoCustomsTerritory: [initializeMonetaryValue()],
                insuranceCostsBeforeEntryIntoCustomsTerritory: [initializeMonetaryValue()],
                loadingAndHandlingChargesBeforeEntryIntoCustomsTerritory: [initializeMonetaryValue()],
                otherAdditions: [initializeMonetaryValue()]
            },
            deductions: {
                transportCostsAfterEntryIntoCustomsTerritory: [initializeMonetaryValue()],
                chargesForConstructionErectionAssemblyMaintenanceOrTechnicalAssistance: [initializeMonetaryValue()],
                chargesForInterest: [initializeMonetaryValue()],
                chargesForRightToReproduce: [initializeMonetaryValue()],
                buyingCommisions: [initializeMonetaryValue()],
                importDuties: [initializeMonetaryValue()],
                paymentsMadeForRightToDistributeOrResell: [initializeMonetaryValue()],
                otherDeductions: [initializeMonetaryValue()]
            },
            result: initializeMonetaryValue()
        };
    }

    function calculateResult() {
        var amount = 0;
        if (service.data.transactionValue.priceActuallyPaidOrPayble.amount) {
            amount = service.data.transactionValue.priceActuallyPaidOrPayble.amount;
        }
        else {
            service.data.transactionValue.result.amount = undefined;
            return;
        }

        var additionFieldNames = [
            'commissionsAndBrokerage',
            'costOfContainersWhichAreOneWithGoods',
            'costOfPacking',
            'materialsComponentsAndParts',
            'toolsDiesAndMoulds',
            'materialsConsumedInProduction',
            'engineeringDevelopmentArtworkDesignPlansAndSketches',
            'royaltiesAndLicenseFees',
            'valueOfResaleDisposalOrUseThatAccruesToSeller',
            'transportCostsBeforeEntryIntoCustomsTerritory',
            'insuranceCostsBeforeEntryIntoCustomsTerritory',
            'loadingAndHandlingChargesBeforeEntryIntoCustomsTerritory',
            'otherAdditions'
        ];

        var deductionFieldNames = [
            'transportCostsAfterEntryIntoCustomsTerritory',
            'chargesForConstructionErectionAssemblyMaintenanceOrTechnicalAssistance',
            'chargesForInterest',
            'chargesForRightToReproduce',
            'buyingCommisions',
            // 'importDuties', // TODO: most likely we will remove this field completely!
            'paymentsMadeForRightToDistributeOrResell',
            'otherDeductions'
        ];

        _.forEach(additionFieldNames, function (additionFieldName) {
            _.forEach(service.data.transactionValue.additions[additionFieldName], function(additionField) {
                if (additionField.amount) {
                    amount += additionField.amount;
                }
            });
        });
        _.forEach(deductionFieldNames, function (deductionFieldName) {
            _.forEach(service.data.transactionValue.deductions[deductionFieldName], function(deductionField) {
                if (deductionField.amount) {
                    amount -= deductionField.amount;
                }
            });
        });
        
        service.data.transactionValue.result.amount = amount;
    }

    function getDefaultMonetaryUnit() {
        if (state.filter.referenceCountry === 'GB' && moment(state.filter.date).isAfter('2021-01-01')) {
            return 'GBP';
        }
        else {
            return 'EUR';
        }
    }

    function initializeMonetaryValue() {
        var monetaryUnit = getDefaultMonetaryUnit();
        return {
            amount: undefined,
            exchangeRate: 1,
            originalAmount: undefined,
            originalUnit: monetaryUnit,
            unit: monetaryUnit,
        };
    }

}