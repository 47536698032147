﻿module.exports = {
    account: require('./account'),
    bti: require('./bti'),
    atr: require('./atr'),
    caseLaw: require('./case-law'),
    classificationRegulation: require('./classification-regulation'),
    compliance: require('./compliance'),
    conclusion: require('./conclusion'),    
    correlationTable: require('./correlation-table'),
    customsValue: require('./customs-value'),
    customsValueCheck: require('./customs-value-check'),
    dashboard: require('./dashboard'),
    documentation: require('./documentation'),
    exchangeRates: require('./exchange-rates'),
    ezt: require('./ezt'),
    formLabels: require('./form-labels'),
    ecics: require('./ecics'),
    goods: require('./goods'),
    mainMenu: require('./main-menu'),
    news: require('./news'),
    nomenclature: require('./nomenclature'),
    quota: require('./quota'),
    referenceprices: require('./referenceprices'),
    regulation: require('./regulation'),
    search: require('./search'),
    shared: {
        messages: require('./shared-messages'),
        ui: require('./shared-ui'),
    },
    sortOptions: require('./sort-options'),
    status: require('./status'),
    tares: require('./tares'),
    taric: require('./taric'),
    tradeStatistics: require('./trade-statistics'),
    validateBusinessInfo: require('./validate-business-info'),
    validationErrors: require('./validation-errors'),
};
