module.exports = {
    bindings: {
        note: '<',
        type: '@'
    },
    controller: noteSourceController,
    templateUrl: 'texts/components/note-source.html'
};

noteSourceController.$inject = ['common'];

function noteSourceController(common) {

    var vm = {
        language: common.language.get(),
    };

    return vm;
}
