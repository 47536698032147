module.exports = {
    bindings: {
        regulation: '<',
        isListItem: '<'
    },
    controller: regulationController,
    templateUrl: 'regulations/components/regulation.html'
};

regulationController.$inject = ['regulationsDataService', 'measureTypesDataService', 'countryDataService', 'countryModals'];

function regulationController(regulationsData, measureTypesData, countryData, countryModals) {

    var vm = {
        measureOverview: [],
        measureTypesDictionary: {},
        isLoadingMeasures: true,
        init: init,
        loadMeasureOverview: loadMeasureOverview,
        openRegionModal: openRegionModal
    };
    init();
    return vm;

    function init() {
        measureTypesData.getMeasureTypesDictionary()
            .then(function(measureTypesDictionary) {
                vm.measureTypesDictionary = measureTypesDictionary;
                vm.countryNamesDictionary = countryData.countries;
                loadMeasureOverview(vm.regulation.celex, 0);
            });
    }

    function loadMeasureOverview(celex, pageNumber) {
        vm.isLoadingMeasures = true;
        regulationsData.getMeasureOverview(celex, pageNumber)
            .then(function(measureOverview) {
                vm.measureOverview = measureOverview;
                vm.isLoadingMeasures = false;
            });
    }

    function openRegionModal(regionId, $event) {
        $event.preventDefault();
        var region = {
            code: regionId,
            type: '1'
        };
        countryModals.region.show(region);
    }
}
