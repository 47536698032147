﻿module.exports = {
    BindingTariffInformation: 'Bindende Tariefinlichtingen',
    BtiFirstLetter: 'B',
    Btis: 'BTI\'s',
    BtiReference: 'BTI-referentie',
    copyDescription: 'Deze informatie is een kopie van de informatie in de EBTI-database van de EU.',
    countryOfOrigin: 'Land van afgifte / Taal',
    dateAndPlaceOfIssue: 'Datum en plaats van afgifte',
    details: 'Details BTI',
    ebtiLinkText: 'Klik hier om de meest up-to-date versie te bekijken in deze database.',
    EuropeanBindingTariffInformation: 'Europese Bindende Tariefinlichtingen',
    goodsDescription: 'Omschrijving van de goederen',
    issuedSince: 'Afgegeven sinds',
    Keywords: 'Sleutelwoorden',
    motivation: 'Motivering voor de indeling',
    nationalKeywords: 'Nationale sleutelwoorden',
    NOIMAGE: 'GEEN AFBEELDING',
    noLongerValid: 'Deze BTI is niet meer geldig en is daarom niet langer beschikbaar in de EBTI-database van de EU.',
    notValidOn_referenceDate_: 'niet geldig op {{ referenceDate | date: \'dd-MM-yy\' }}',
    possiblyInvalid: 'NB: in sommige gevallen, zoals de publicatie van een indelingsverordening, kan een BTI voor het aflopen van de normale geldigheidsduur ingetrokken worden. Het is daarom mogelijk dat deze EBTI ondertussen niet meer geldig is.',
    PageTitleSearchBtis: 'Zoek BTI\'s',
    _btiCount_bindingTariffInformationDecisionsFound: '{{ btiCount }} bindende tariefsinlichting(en) gevonden in deze post',
    _StartDate_until_EndDate_: '{{ StartDate | date: \'dd-MM-yyyy\' }} t/m {{ EndDate | date: \'dd-MM-yyyy\' }}'
};
