module.exports = ['$analytics', '$uibModal', eztExciseCodeSelectorModal];

function eztExciseCodeSelectorModal($analytics, $uibModal) {
    var modal = {
        show: showModal
    };

    eztExciseCodeSelectorModalController.$inject = ['$uibModalInstance', 'exciseNomenclature', 'exciseCode'];

    function eztExciseCodeSelectorModalController($uibModalInstance, exciseNomenclature, exciseCode) {
        var vm = {
            exciseCode: exciseCode,
            cancel: cancel,
            exciseNomenclature: exciseNomenclature,
            ok: ok
        };

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function ok(code) {
            if (code.ProdLin === '80') {
                $uibModalInstance.close(code);
            }
        }

        return vm;
    }

    function showModal(exciseNomenclature, exciseCode) {
        $analytics.eventTrack('open-modal-ezt-excise-code-selector', { category: 'additional-codes' });

        var modalInstance = $uibModal.open({
            animation: true,
            backdrop: true,
            bindToController: true,
            controller: eztExciseCodeSelectorModalController,
            controllerAs: 'ctrl',
            keyboard: true,
            modalFade: true,
            resolve: {
                exciseCode: function () {
                    return exciseCode;
                },
                exciseNomenclature: function () {
                    return exciseNomenclature;
                }
            },
            size: 'lg',
            templateUrl: 'ezt/modals/ezt-excise-code-selector.html',
            windowClass: 'modal-ezt-excise-code-selector'
        });

        modalInstance.rendered.then(function() {
            var elementToFocusOnInModal = document.querySelector('.modal.in .focus-on-open-modal');
            if (elementToFocusOnInModal) {
                elementToFocusOnInModal.focus();
            }
        });

        return modalInstance;
    }

    return modal;
}
