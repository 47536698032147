﻿angular.module('taric.certificateCodes', [])
    .config(['$routeProvider', routeConfigurator])
    // Components
    .component('certificateCodePicker', require('./components/certificate-code-picker'))
    // Pages
    .component('certificateCodesPage', require('./components/certificate-codes-page'))
    // Filters
    .filter('certificateCodeTypeahead', require('./filters/certificate-code-typeahead-filter'))
    // Modals
    .factory('certificateCodeSelectorModal', require('./modals/certificate-code-selector'))
    // Services
    .factory('certificateCodesDataService', require('./services/certificate-codes-data'))
    ;

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/certificate-codes', {
            template: '<certificate-codes-page></certificate-codes-page>'
        })
        ;
}