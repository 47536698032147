module.exports = {
    controller: taresMeasuresPageController,
    templateUrl: 'tares/components/tares-measures-page.html'
};

taresMeasuresPageController.$inject = [
    '$analytics',
    '$location',
    '$routeParams', 
    'common',
    'applicationStateService',
    'countryDataService',
    'countryModals',
    'nomenclatureDataService',
    'notificationsService', 
    'preferenceService',
    'regionDataService',
    'taresStatisticalKeysDataService',
    'taresTariffDataService', 
];

function taresMeasuresPageController(
    $analytics, 
    $location,
    $routeParams,
    common,
    state,
    countryDataService,
    countryModals,
    nomenclatureDataService,
    notifications,
    preferences,
    regionDataService,
    taresStatisticalKeysDataService,
    taresTariffDataService
) {
    
    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        ergaOmnesCountries: countryDataService.ergaOmnesCountries,
        onClickDescriptionLine: onClickDescriptionLine,
        onClickRegion: onClickRegion,
        onSelectCustomsReliefCode: onSelectCustomsReliefCode,
        onSelectStatisticalKey: onSelectStatisticalKey,
        countryPickerSelectionChanged: countryPickerSelectionChanged,
        input: {
            commodityCode: null,
            languageId: getLanguage(),
            statisticalKey: 0,
            customsReliefCode: 0
        },
        isLoading: false,
        state: state,
        tariffMasterData: null
    };

    return vm;

    function onSelectCustomsReliefCode(customsReliefCode) {
        vm.input.customsReliefCode = customsReliefCode;
        taresTariffDataService.filterTariffDataByCustomsReliefCode(vm.tariffMasterData, vm.input.customsReliefCode);
    }

    function onSelectStatisticalKey(key) {
        vm.input.statisticalKey = key;
    }

    function getLanguage() {
        var language = common.language.get();
        if (language !== 'en' && language !== 'de' && language !== 'fr' && language !== 'it') {
            return 'EN';
        }
        return language.toUpperCase();
    }

    function attached() {
        if (!$routeParams.commodityCode) {
            return;
        }
        // TODO: it may be better to create a component for the TARES tariff data,
        // so that we can re-use the same url as for the other trade tariffs
        if (state.filter.referenceCountry !== 'CH') {
            $location.path('/good/' + $routeParams.commodityCode);
        }
        $analytics.pageTrack('/tares-measures');
        vm.input.commodityCode = $routeParams.commodityCode;
        loadTariffData($routeParams.commodityCode);
        loadStatisticalKeys($routeParams.commodityCode);
        notifications.subscribe('filter_date_changed', dateChanged);
        notifications.subscribe('filter_movement_changed', movementChanged);
        notifications.subscribe('language_changed', languageChanged);

        // ergaOmnesCountries
        // countryPickerSelectionChanged(country, rememberCountry)
    }

    function countryPickerSelectionChanged(country, rememberCountry) {
        var countryHasChanged = state.countryHasChanged(country);
        state.updateCountryFilter(country, rememberCountry);
        if (rememberCountry && country) {
            preferences.saveCountryPreference(country);
        } else {
            preferences.clearCountryPreference();
        }
        if (countryHasChanged) {
            taresTariffDataService.filterTariffDataByCountry(vm.tariffMasterData, country ? country.code : null);
        }
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
        notifications.unsubscribe('filter_movement_changed', movementChanged);
        notifications.unsubscribe('language_changed', languageChanged);
    }

    function dateChanged() {
        loadTariffData($routeParams.commodityCode);
    }

    function movementChanged() {
        $location.url('/search/' + $routeParams.commodityCode);
    }

    function languageChanged() {
        loadTariffData($routeParams.commodityCode);
    }

    function onClickDescriptionLine(commodityCode, indentation) {
        state.currentCode = commodityCode;
        state.currentIndentation = indentation;
        state.navigatingFromMeasuresPage = true;
        $location.url('search/' + commodityCode);
    }

    function loadStatisticalKeys(commodityCode) {
        return taresStatisticalKeysDataService.loadStatisticalKeys(commodityCode)
            .then(function (statisticalKeys) {
                vm.statisticalKeys = statisticalKeys;
            })
    }

    function onClickRegion(region) {
        countryModals.region.show(region);
    }

    function loadTariffData(commodityCode) {
        vm.isLoading = true;
        return nomenclatureDataService.isDeclarable(commodityCode)
            .then(function(isDeclarable) {
                if (!isDeclarable) {
                    $location.url('search/' + commodityCode);
                }
                else {
                    common.$q.all([
                        taresTariffDataService.loadTariffData(commodityCode)
                            .then(function (tariffMasterData) {
                                _.forEach(tariffMasterData.Result.CustomsReliefCodes, function (customsReliefCode) {
                                    if (customsReliefCode.ReliefCodeOnTariffLine) {
                                        vm.input.customsReliefCode = customsReliefCode.ReliefCode;
                                    }
                                });
                                // if (tariffMasterData.Result.CustomsReliefCodes.length === 1) {
                                //     vm.input.customsReliefCode = tariffMasterData.Result.CustomsReliefCodes[0].ReliefCode;
                                // }
                                taresTariffDataService.filterTariffDataByTradeMovement(tariffMasterData, vm.state.filter.movement);
                                taresTariffDataService.filterTariffDataByCustomsReliefCode(tariffMasterData, vm.input.customsReliefCode);
                                taresTariffDataService.filterTariffDataByCountry(tariffMasterData, vm.state.filter.countryCode);
                                vm.tariffMasterData = tariffMasterData;
                                return tariffMasterData;
                            }),
                        nomenclatureDataService.loadDescription(commodityCode)
                            .then(function (description) {
                                vm.description = description;
                                return description;
                            }),
                        regionDataService.get()
                            .then(function(regions) {
                                vm.regions = _.keyBy(regions, 'code');
                            })
                    ]).then(function(results) {
                        vm.isLoading = false;
                    });
                }
            })
    }


}
