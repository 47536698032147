﻿// See https://app.taricsupport.com/#!/account/change-password
// See https://app.taricsupport.com/#!/account/login
// See https://app.taricsupport.com/#!/account/forgot-password
module.exports = {
    changePassword: {
        changePassword: 'Change password',
        currentPassword: 'Current password',
        errorCurrentPassword: 'The current password was not filled in',
        errorNewPasswordEmpty: 'The new password was not filled in',
        errorNewPasswordCompare: 'The new passwords do not match',
        newPassword1: 'New password',
        newPassword2: 'Confirm new password',
        passwordChangedSuccessfully: 'Password changed successfully.',
        errorChangingPassword: 'Error changing password. Please try again later.'
    },
    forgotPassword: {
        backToLogin: 'Back to the log in page',
        doneMessage: 'We\'ve sent an email with instructions to the email address connected to your account. It should arrive within the next few minutes.',
        explanation: 'Please fill in your your username and we will send you an email with instructions on how to create a new password for your account',
        forgotPassword: 'Forgot your password?',
        resetPassword: 'Reset password'
    },
    login: {
        error: 'Username or password incorrect',
        logIn: 'Log in',
        password: 'Password',
        rememberMe: 'Keep me logged in',
        username: 'Username'
    },
    logout: {
        signedOut: 'You have been logged off',
        signingOut: 'You are being logged off...',
        title: 'Log out'
    },
    resetPassword: {
        backToLogin: 'Back to the login page',
        doneMessage: 'You have succesfully created a new password. You can use it to log in to your Taric Support account.',
        errors: {
            'PASSWORD_INVALID_TOKEN': 'Your password reset request is not valid anymore',
            'PASSWORD_ISCURRENT': 'The password you\'ve entered as \'Current password\' is not your current password',
            'PASSWORD_MINIMUM_LENGTH': 'The password should be at least 8 characters long',
            'PASSWORD_NO_LOWERCASE': 'The password doesn\'t have any lowercase characters',
            'PASSWORD_NO_NONALPHA': 'The password doesn\'t have any non-alphanumeric characters',
            'PASSWORD_NO_NUMBERS': 'The password doesn\'t have any numbers',
            'PASSWORD_NO_UPPERCASE': 'The password doesn\'t have any uppercase characters',
            'PASSWORD_EMPTY': 'Please fill in a new password',
            'PASSWORDS_NOTEQUAL': 'The passwords you\'ve entered are not the same'
        },
        explanation: 'You are about to create a new password for your account. Please fill in the form below to continue. Your new password should be at least 8 characters long and should contain a combination of lowercase, uppercase and non-alphanumeric characters',
        invalidToken: 'Your link to create a new password has expired. Please request another link by filling out the \'forgot password\' form',
        password1: 'New password',
        password2: 'Repeat the new password',
        resetPassword: 'Create a new password',
        strength: {
            'characters': '8 characters',
            'lowercase': 'At least one lowercase character',
            'nonalpha': 'At least one non-alphanumeric character',
            'number': 'At least one number',
            'oneOfFour': 'Match 3 of the following 4 conditions:',
            'uppercase': 'At least one uppercase character',
        }
    }
};