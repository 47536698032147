// See the intro buttons at the top right of many pages in the application
module.exports = {
    abc: {
        intro: 'With Taric Support ABC you can upload master data files, order manifests and customs declarations and, with one click of a button, validate them and enrich them with data from the trade tariff. What used to take days, now takes minutes.<br><br>How does it work? Upload a file, select a validation and press start. Seconds later, you can download an Excel file containing a lot of useful information. <br><br>Taric Support ABC combines the power of our API with the flexibility of uploading and downloading Excel files'
    },
    abcNew: {
        file: 'Select an Excel file to be validated and enriched.',
        inputOptions: 'Indicate which columns in the Excel file contain which type of data and at what row the data starts.',
        outputOptions: 'Choose if you want to do a check for import or export declarations, which type of job you would like to perform and in which format you would like to receive the output data.',
        letsGo: 'Let\'s go!'
    },
    caseLaw: {
        intro: 'Under the heading "News" you will find the latest Dutch and European rulings. <br><br>Under the headings "Dutch case law" and "European case law" you will find advanced search functions to quickly find the relevant case law.',
    },
    chapter: {
        intro: 'On this page you can browse through a chapter. There are a lot of tools to help you with the classification process.',
        heading: 'Click a heading or subdivision to expand/collapse it.<br>Hint: you can double-click to also expand/collapse all of its subdivisions,',
        chapterButtons: 
            'The following data sources are available. You will always see the information which is relevant for the current chapter.' +
            '<ul><li>N is for Notes, legally binding</li>' +
            '<li>E is for Explanatory Notes (which also include WCO classification opinions and EU Classification Regulations)</li>' +
            '<li>B is for BTIs</li>' +
            '<li>C is for Case Law - classification issues are often resolved in court</li>' +
            '<li>L is for Legislation - find out about the latest EU regulations and notices to traders</li>' +
            '</ul>',
        headingButtons: 
            'The same data sources are also available for each heading. ' +
            'In order to save space, the buttons are only marked with a single letter. ' +
            'If you happen to forget, you can hover over a button to see what it does.'
    },
    chapters: {
        intro: 
            'This is the start page of the trade tariff and classification module. <br><br>' + 
            'You can use this page to start browsing through the sections and chapters of the nomenclature, or use the search box to query the tariff.',
        largeSearchBox: 'Use this search box or the one at the top of the page to search by commodity code or keyword. <br><br>Hint: in order to speed up your search, you can add "import" or "export" to specify the trade movement. You can also type the country of origin for import or the country of destination for export.',
        sectionNotesAndExplanatoryNotes: 
            'NB: in order to decide if a commodity may be classified under a given section or chapter, ' + 
            'you should always read the (legally) binding section and chapter notes. You can find them by clicking on the button marked by the letter N for Notes. <br><br>' +
            'Furthermore, the explanatory notes published by the WCO and the EU, while not legally binding, are indispensible tools for understanding ' +
            'the scope of sections, chapters, headings and subdivisions in the nomenclature. You can find them by clicking on the button marked by the letter E for Explanatory Notes',
    },
    correlationTable: {
        intro: 'Find the originators of new commodity codes and the successors of expired commodity codes. <br><br>You can search by start date or end date or you can search by commodity code (chapter, heading or subheading). <br><br>',
        searchSuccessors: 'Press to view successors of expired commodity codes. <br><br>Note that successors to expired codes are not necessarily new codes. It is also possible that the successor already existed and new commodities were added to it.',
        searchOriginators: 'Press to view originators (predecessors) of new commodity codes. <br><br>Note that the originator to a new commodity does not necessarily end. It\'s possible that only part of the commodities that were previously classified under the originator are now to be classified under the new code.'
    },
    ebti: {
        intro: 'Organizations in the EU can submit a request for a binding opinion to their national customs authorities. These are called BTIs. They are an extremely useful tool in determining the correct classification of your product. After all, if you are filing a declaration for the same product, it will likely have the same classification. <br><br>Taric Support helps you with a total overview of all BTIs issued in Europe with accompanying photos and drawings, including a history of BTIs that are no longer valid. These BTIs, like the rest of the information, are easy to find based on the relevant commodity code.',
        form:
            'The BTI search form allows you to search by commodity code (chapter, heading, subheading) and query by keyword and goods description, among other things.<br><br>' + 
            'NB: you can choose to either sort the BTIs by commodity code or by date, in essence creating a news feed with the latest BTIs, or use the issued since field to only show recent BTIs. <br><br>' +
            'NB: you can also choose to view the results as images. Often, going through a list of images can be a quick way to get an idea of ' + 
            'whether or not a certain part of the nomenclature is applicable for the commodity you are classifying.'
    },
    atr: {
        intro: 'Organizations in the UK can submit a request for a binding opinion to their national customs authorities. These are called ATRs (Advance Tariff Rulings). They are an extremely useful tool in determining the correct classification of your product. After all, if you are filing a declaration for the same product, it will likely have the same classification. <br><br>Taric Support helps you with a total overview of all ATRs issued in the UK with accompanying photos and drawings, including a history of ATRs that are no longer valid. These ATRs, like the rest of the information, are easy to find based on the relevant commodity code.',
        form:
            'The ATR search form allows you to search by commodity code (chapter, heading, subheading) and query by keyword and goods description, among other things.<br><br>' + 
            'NB: you can choose to either sort the ATRs by commodity code or by date, in essence creating a news feed with the latest ATRs, or use the issued since field to only show recent ATRs. <br><br>' +
            'NB: you can also choose to view the results as images. Often, going through a list of images can be a quick way to get an idea of ' + 
            'whether or not a certain part of the nomenclature is applicable for the commodity you are classifying.'
    },
    header: {
        referenceDatePicker: 'Use the date picker to change the date for which you want to look up tariffs or regulations.',
        tradeMovementPicker: 'Choose whether to browse nomenclature and trade tariff measures for import or export',
        languagePicker: 
            'Pick one of 22 European languages. ' + 
            'Note that the supported languages for the interface are English and Dutch. However, most of the information is available in all 22 languages. ' +
            'This may come in handy when communicating with a customer, supplier or colleague who has a different mother tongue.',
    },
    landingPage: {
        welcomeTitle: 'Welcome',
        welcomeIntro: 
            'Hello and welcome to Taric Support. Thank you for using our application. Please let us show you around. <br><br>' +
            'If you\'d prefer to continue in a different language, please click the button above to choose one of 22 languages.',
        referenceCountryPicker: 
            'The main data sources for Taric Support are a number of various trade tariffs. You can use these flags to choose between them. <br><br>' + 
            'This choice will affect both the nomenclature you will use for classification and the import and export measures that you will see. ' +
            'For instance, the nomenclature used in the UK Global Tariff is not the same as the nomenclature used in TARIC, the integrated customs tariff of the EU. <br><br>' +
            'NB: note that you can always change the current trade tariff by using the flags in the header bar.',
        introButton:
            'You can click on the intro button to find out more about the page you are on'
        
    },
    mainMenu: {
        intro: 'Via the main menu, you can access the various modules in your subscription. <br><br>Hint: use the enter key to go through this tour.',
        chapters: 'Browse the trade tariff and nomenclature',
        news: 'The latest customs news',
        ebti: 'The entire EBTI database: hundreds of thousands of examples of products that have been classified by customs authorities',
        abc: 'Validate and enrich hundreds of codes per minute with Taric Support ABC',
        correlationTable: 'Find the predecessors and successors of commodity codes',
        referencePrices: 'A complete list of daily standard import values and unit prices for fruits and vegetables',
        caseLaw: 'Dutch and European customs case law.'
    },
    measures: {
        intro: 
            'This page shows an overview of measures that apply for import or export of a commodity with this commodity code. <br><br>' +
            'We make use of a number of different colors to help you to quickly identify the various types of codes. ' +
            'In this way we can display a lot of information in a compact format. The colors are as follows: <br><br>' +
            '<ul class="nav nav-pills nav-stacked">' +
            '<li><a class="btn btn-certificate-code">Certificate Code</a></li>' +
            '<li><a class="btn btn-footnote">Footnote</a></li>' +
            '<li><a class="btn btn-addcod">Additional Code</a></li>' +
            '<li><a class="btn btn-prefcode">Preference Code</a></li>' +
            '</ul>',
        form:
            'With this form you can filter the measures by country code, apply additional codes and choose the preferred duty treatment. <br><br>' +
            'Hint: you can also add the country code and/or country name to the query that you type in the search box in the header bar.',
        validateDeclaration:
            'In this screen you can validate the data for a customs declaration. First filter measures by country code, apply additional codes ' +
            'and choose the preferred duty treatment. Next, enter the customs value and the relevant quantities. Finally choose the applicable ' +
            'particular provision codes (Y-codes) and certificate codes and run the validation.',
        calculateDuty:
            'In this screen you can calculate import duties and (for supported countries) national taxes. First filter measures by country code, apply additional codes ' +
            'and choose the preferred duty treatment. Next, enter the customs value and the relevant quantities. Finally choose the applicable ' +
            'particular provision codes (Y-codes) and certificate codes and press calculate duty.'
    },
    news: {
        intro: 
            'Choose "EU Legislation" for the latest regulations and announcements from EUR-Lex. <br><br>' +
            'Choose "Case Law" for the latest rulings by the EU Court of Justice, as well as Dutch national rulings. <br><br>' +
            'The menu item "Dutch Customs News Feed" shows news items published by the Dutch customs authorities (in Dutch only)'
    },
    ui: {
        done: 'Done',
        help: 'Help',
        next: 'Next',
        previous: 'Previous',
        startTour: 'Intro',
    },
};